import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal, Tab, Nav, Accordion, Dropdown } from 'react-bootstrap';
import AllCustomIcon from '../../JsonDataFile/AllCustomIcon';




const UploadFilePopupMessage = (props) => {

    const [show, setShow] = useState(false);
    const [fileValue, setFileValue] = useState();
    const [fileArray, setFileArray] = useState();
    const [fileName, setFileName] = useState();
    const [fileType, setFileType] = useState();


    const handleClose = () => {
        setShow(false);
        setFileValue("")
    }
    const handleShow = () => {

        setShow(true);

    };

    const FileUpload = (e) => {
        handleShow();
        const filePathName = e.target.files;
        // console.log();
        let list = []
        for (let i = 0; i < filePathName.length; i++) {
            console.log(filePathName[i]);
            list.push(filePathName[i])
        }
        setFileArray(list)
        console.log("fileeeeeeeeeee uploadeddddddddddd",fileArray)
    };
    function deleteFile(e) {
        const s = fileArray.filter((item, index) => index !== e);
        setFileArray(s);
        console.log(s);
    }



    const renderThumbnailContent = () => {

        if (fileArray != undefined) {
            console.log(fileArray);
            const arrayLength = fileArray.length > 1;
            return (
                <>
                    {fileArray.map((item,index) =>{
                        const itemType = item.name;
                        const imageURL = URL.createObjectURL(item);
                        const itemTypeExt = itemType.split('.')[1];
                        const imageCondition = itemTypeExt === "jpg" || itemTypeExt === "jpeg" || itemTypeExt === "svg" || itemTypeExt === "png"
                        return(
                            
                            <div className={"document-preview" + " " + (arrayLength ? 'multiple-ducument' : '')}>
                                <div className='box-wrap'>
                                    <div className='file-icon-wrap'>
                                        <div className='icon-wrap'>
                                        {(imageCondition ? <img src={imageURL}/> : AllCustomIcon.iconDocument)}
                                            
                                        </div>
                                        <span className={"fileType" + " " + (imageCondition ? 'image-uploade-thumb' : '')}>
                                            .{itemTypeExt}</span>
                                    </div>
                                    <div className='file-name-wrap'>
                                        <div className='file-name'>{item.name}</div>
                                    </div>
                                    <div className='delete-files'>
                                        <button className='btn-close' onClick={() => deleteFile(index)}></button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        }
        // }



    };



const fileUpload=()=>{
    props.fileHandle(fileArray)
    handleClose()
}
return (
    <>
            <Dropdown className="user-table-menu-dropdown custom-dropdown">
            <Dropdown.Toggle id="attachementDropdown">
            {AllCustomIcon.iconAttachment}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <div className='inputFile-wrap'>
            <input type="file" id="messageFile" name="messageFile" onChange={FileUpload} value={fileValue} multiple />
            <span>Upload from computer</span>
            </div>
            </Dropdown.Menu>
            </Dropdown>
            <Modal show={show} onHide={handleClose} className='upload-preview-popup add-user-popup' backdrop="static">

            <Modal.Header closeButton>
            <div className='title-wrap'>
            <span>Share with:</span>
            <div className="chat-single-inner">
            <div className='image-wrap'>
            <img src={props.messageUserImage} alt />
            </div>
            <div className='chat-user-data'>
            <h6>{props.messageUserName}</h6>
            </div>
            </div>
            </div>
            </Modal.Header>
            <Modal.Body>
            <div className='upload-file-preview-wrapper upload-file-preview-main'>
            <div className='upload-file-preview'>
            {renderThumbnailContent()}
            </div>
            </div>
            <div className='btns-wrap'>
            <Button className='btn-small border-button' onClick={handleClose}>Cancel</Button>
            <Button className='btn-primary btn-small' onClick={fileUpload}>Share</Button>
            </div>
            </Modal.Body>

            </Modal>
            </>
    )

}


export default UploadFilePopupMessage;