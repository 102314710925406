import React from 'react';
import { Pagination } from 'react-bootstrap';




const TablePagination = (props) => {
    return (
        <Pagination>
            {props.currentPage == 1 ? <Pagination.Prev onClick={props.prevdata} disabled /> : <Pagination.Prev onClick={props.prevdata} />}

            <Pagination.Item active>{props.currentPage}</Pagination.Item>

            {props.currentPage == props.totalPage ? <Pagination.Next onClick={props.handleLink} disabled /> : <Pagination.Next onClick={props.handleLink} />}
        </Pagination>
    )
}


export default TablePagination;