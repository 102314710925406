import React, { useEffect, useState } from 'react';
import { Navigate,Outlet } from 'react-router-dom';
import Auth from './Auth';
function FacilityAdminRoute() {

   let auth=false

   useEffect(()=>{
    if(localStorage.getItem("position")=="c_level_emp"){
        auth=true
    }
   })
    return (

            auth
            ? <Outlet/>
            : <Navigate to={'/notFound'}   />
    )
}


export default FacilityAdminRoute;