import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";
import App from './App';
import * as serviceWorker from './serviceWorker'
import { ContainerProvider } from './pages/utils/container'
import { ThemeProvider } from '@material-ui/styles'
import THEME from './pages/utils/theme'
import './index.css'
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(document.getElementById('root'));

// Sentry.init({
//   dsn:
//   "https://3051d7201ff044f3b78b05c76ddad640@o4504950281207808.ingest.sentry.io/4504950420013056",
//   integrations: [new Sentry.BrowserTracing()],
//   tracesSampleRate: 1.0,
//   });
  


root.render(
  <BrowserRouter>
  <ThemeProvider theme={THEME}>
    <ContainerProvider>
      <App />
    </ContainerProvider>
  </ThemeProvider>
  </BrowserRouter>
);
