import React, { useState, useEffect } from 'react';
import ApproveDeclinedSwitch from './ApproveDeclinedSwitch';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';
import ReusableAPI from './NotificationApi';

const CredentialTable = (props) => {
    const [CredentialTableData,setCredentialTableData]=useState()
    const [switchValue,setSwitchValue]=useState()

    const fcmData = (msg, id) => {
        //sending notification to mobile
        const credentials = {

            peerId: localStorage.getItem('user_id'),
            peerName: localStorage.getItem("facility"),
            channelId: localStorage.getItem("facility"),
            message: msg,
            message_type: "text",
            image: localStorage.getItem('avatarImage'),
            receiverId: id,

        }
        let token = localStorage.getItem('token')
        axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

            })
            .catch((error) => {

            })
    }


    useEffect(() => {

        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT+`/document/view/?search=${props.id}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                let data = []
               
                response.data.results.forEach((el) => {

                    if (el.clinician_id == props.id) {
                       data.push({"id":el.clinician_id,"LastDate":el.document_updated_date,"status":el.document_status,"pdfFileName":el.document_name})
                    }

                })
             
                const requestData1 = response.data.results.map(function (row) {
                    //const [statusTag,setStatustag]=useState()
                    var statusTag=""
                    var statusProgressClass=""
                    const switchValue=(e,id,clinician_id)=>{
                        setSwitchValue(e)
                        console.log(e,id,clinician_id)
                        async function apiCall(credentials) {
                            let token=localStorage.getItem('token')
                            await axios.patch(ENV.ENDPOINT+`/document/edit/${id}/`,credentials,{headers:{"Authorization":`Bearer ${token}`}})
                            .then(responsee=>{

                                let token = localStorage.getItem('token')
                                axios.get(ENV.ENDPOINT + `/myuser/?user_id=${clinician_id}`, { headers: { "Authorization": `Bearer ${token}` } })
                                    .then(response => {

                                        var notificationMsg = `${responsee.data.document_name} Document ${responsee.data.document_status}`
                                        ReusableAPI.notificationTriggerAPI(notificationMsg,response.data.results[0].id)

                                        fcmData(notificationMsg,response.data.results[0].id)
                
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                                
                                console.log(responsee.data)
                                props.handleRender()
                              
                            })
                            .catch((error)=>{
                                console.log(error.response.data)
                            })
                         }
                         
                        let document_status=e
                       
                        
                        const token =  apiCall({
                            document_status
                        });
                      

                    }
                    if(row.document_status=="Approved"){
                        statusProgressClass= ""
                        statusTag= "success"
                        setSwitchValue('')
                    }
                    else if(row.document_status=="Declined"){
                        statusProgressClass= ""
                        statusTag="failed"
                        setSwitchValue("")
                    }
                    else if(row.document_status=="Pending"){
                    statusProgressClass= <ApproveDeclinedSwitch handleSwitch={e=>switchValue(e,row.document_id,row.clinician_id)}/>
                    statusTag="no-status"
                    }
                 
                    
                    return {
                        clinician_id:row.clinician_id,
                        pdfFileSrc: row.document_path,
                        pdfFileName: row.document_name,
                        LastDate: moment(row.document_updated_date).format('DD/MM/YYYY'),
                        statusProgressToggle: statusProgressClass,
                        statusTagClassName: statusTag,
                        status: row.document_status,
                        documentId:row.document_id
                    }



                })
                console.log(requestData1)
                setCredentialTableData(requestData1)
            })

            .catch((error) => {
                console.log(error.response.data)
            })

    }, [])
    // const CredentialTableData = [
    //     {
    //         pdfFileSrc: "assets/images/document/sample.pdf",
    //         pdfFileName: "Background check",
    //         LastDate: "26/04/2022",
    //         statusProgressToggle: <ApproveDeclinedSwitch />,
    //         statusTagClassName: "no-status",
    //         status: "",
    //     },
    //     {
    //         pdfFileSrc: "assets/images/document/sample.pdf",
    //         pdfFileName: "IP Assignment Agreement",
    //         LastDate: "26/04/2022",
    //         statusProgressClassName: "status-done",
    //         statusTagClassName: "success",
    //         status: "Approved",
    //     },
    //     {
    //         pdfFileSrc: "assets/images/document/sample.pdf",
    //         pdfFileName: "Background check",
    //         LastDate: "26/04/2022",
    //         statusProgressClassName: "status-done",
    //         statusTagClassName: "success",
    //         status: "Approved",
    //     },
    //     {
    //         pdfFileSrc: "assets/images/document/sample.pdf",
    //         pdfFileName: "Background check",
    //         LastDate: "26/04/2022",
    //         statusProgressClassName: "status-done",
    //         statusTagClassName: "failed",
    //         status: "Declined",
    //     }
    // ]

    return (
        <table width="100%">
            <thead>
                <tr>
                    <th>Credentials</th>
                    <th>Last Update</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
            {CredentialTableData?(
                <>
               
                    {CredentialTableData.map((val, index) =>
                        <tr key={index}>
                            <td>
                                <div className='file-data'>
                                    <a href={val.pdfFileSrc} download className='file-name'>{val.pdfFileName}</a>
                                </div>
                            </td>
                            <td>{val.LastDate}</td>
                            <td>
                                {val.statusProgressToggle}
                                <span className={`status-tag ${val.statusTagClassName}`}>{val.status}</span>
                            </td>


                        </tr>

                    )}
              
                    
                </>
                  ):""}
            </tbody>
        </table >
    )
}

export default CredentialTable;