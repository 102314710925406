import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'





const OverViewCol = (props) => {
    const [requestData, setRequestData] = useState()
    let approvedList = []
    let declinedList = []
    let totalList = []
    let pendingList = []
    let totalNurses = []
    let facilityDeclined = []
    let clinicianDeclined = []
    let futureShifts = []
    let pendingLogs = []
    let noShowList = []
    const [overViewData, setOverviewData] = useState()

    const [facilityId , setfacilityId] = useState(0)
    
    useEffect(() => {
       const time = setTimeout(() => {
            setfacilityId(localStorage.getItem('facility_id'))
        }, 2000);

       return () => clearTimeout(time) 
    },[])
    


    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                response.data.results.forEach((el) => {
                    if (el.status == "Approved" || el.status == 'Open' || el.status == "Declined" || el.status == 'Cancelled' || el.status == "Completed") {
                        totalList.push(el)
                    }
                    if (el.status == "Approved") {

                        approvedList.push(el)

                    }
                    if (el.status == "Declined" || el.status == 'Cancelled') {
                        declinedList.push(el)

                    }
                    if (el.status == "Open" && el.requesting_users != null) {
                        pendingList.push(el)
                    }
                    if (el.declined_by == "Facility") {
                        facilityDeclined.push(el)
                    }
                    if (el.declined_by == "Clinician") {
                        clinicianDeclined.push(el)
                    }

                    if (((new Date(el.start_time) > new Date()) == true) && (el.status == 'Approved')) {
                        futureShifts.push(el)
                    }
                    if (((new Date(el.end_time) < new Date()) == true) && (el.check_in == "") && (el.status == 'Approved')) {
                        noShowList.push(el)
                       
                        noShowList.map(function (row) {
                            const credentials = {
                                check_in: "No Shows"
                            }
                            let token = localStorage.getItem('token')
                            axios.patch(ENV.ENDPOINT + `/scheduling/edit/${row.schedule_id}/`, credentials,{headers:{"Authorization":`Bearer ${token}`}})
                                .then(response => {
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                            let activity_title = `No show without noification`
                            let activity_status = 'success'
                            let acitivity_date = moment(row.end_time).format('YYYY-MM-DD')
                            let category = 'No Shows'
                            let facility_id=row.facility_id
                            const credentials1 = {
                                activity_title,
                                activity_status,
                                acitivity_date,
                                facility_id,
                                category
                            };
                            
                            axios.post(ENV.ENDPOINT + `/activities/create/`, credentials1, { headers: { "Authorization": `Bearer ${token}` } })
                                .then(response => {
                                    console.log(response.data)

                                })
                                .catch((error) => {
                                    console.log(error.response)
                                })
                        })
                    }

                    if ((moment(el.start_time).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) && (el.check_in == "") && (el.status == 'Approved')) {

                        pendingLogs.push(el)
                    }

                })



                const OverViewColShiftData1 = [
                    {
                        overviewShiftText: "Total Shift",
                        overviewShiftCount: totalList.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/total-shift-icon.svg"
                    },
                    {
                        overviewShiftText: "No Shows",
                        overviewShiftCount: noShowList.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/no-shows-icon.svg"
                    },
                    {
                        overviewShiftText: "Cancellations",
                        overviewShiftCount: declinedList.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/cancellation-icon.svg"
                    },
                    {
                        overviewShiftText: "Shift Request",
                        overviewShiftCount: pendingList.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/shift-request-icon.svg"
                    },
                    {
                        overviewShiftText: "Upcoming Shifts",
                        overviewShiftCount: futureShifts.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/upcoming-shift-icon.svg"
                    },
                    {
                        overviewShiftText: "Pending Logs",
                        overviewShiftCount: pendingLogs.length,
                        overviewShiftIcon: "assets/images/ico/overview-icon/pending-log-icon.svg"
                    }
                ]
                setOverviewData(OverViewColShiftData1)

            })
            .catch((error) => {
                //console.log(error.response.data)
            })
        props.handleRefresh(false)
    }, [props.refresh ,facilityId])

    // const OverViewColShiftData = [
    //     {
    //         overviewShiftText: "Total Shift",
    //         overviewShiftCount: totalList.length,
    //         overviewShiftIcon: "assets/images/ico/overview-icon/total-shift-icon.svg"
    //     },
    //     {
    //         overviewShiftText: "No Shows",
    //         overviewShiftCount: noShowList.length,
    //         overviewShiftIcon: "assets/images/ico/overview-icon/no-shows-icon.svg"
    //     },
    //     {
    //         overviewShiftText: "Cancellation",
    //         overviewShiftCount: "1",
    //         overviewShiftIcon: "assets/images/ico/overview-icon/cancellation-icon.svg"
    //     },
    //     {
    //         overviewShiftText: "Shift Request",
    //         overviewShiftCount: pendingList.length,
    //         overviewShiftIcon: "assets/images/ico/overview-icon/shift-request-icon.svg"
    //     },
    //     {
    //         overviewShiftText: "Upcoming Shift",
    //         overviewShiftCount: futureShifts.length,
    //         overviewShiftIcon: "assets/images/ico/overview-icon/upcoming-shift-icon.svg"
    //     },
    //     {
    //         overviewShiftText: "Pending Logs",
    //         overviewShiftCount: "2",
    //         overviewShiftIcon: "assets/images/ico/overview-icon/pending-log-icon.svg"
    //     }
    // ]

    //  console.log("overViewData" , overViewData) 
    //  console.log("totalList" , totalList) 

    return (
        <>
            {overViewData  ? (
                <div className='overview-wrap'>
                    <h2 className='section-title h5'>Overview</h2>
                    <div className='overview-shift-list row'>
                        {overViewData.map((val, index) =>
                            <Col sm={6} className='overview-shift-single' key={index}>
                                <div className='shift-col-inner'>
                                    <div className="icon-wrap">
                                        <img src={val.overviewShiftIcon} alt="" />
                                    </div>
                                    <div className='shift-data'>
                                        <span className='text-wrap'>{val.overviewShiftText}</span>
                                        <span className='count-wrap'>{val.overviewShiftCount}</span>
                                    </div>
                                </div>
                            </Col>

                        )}
                    </div>
                </div>

            ) : ""}
        </>

    )

}


export default OverViewCol;