import React, { useState, useEffect,useRef } from 'react';
import { Button } from 'react-bootstrap';
import SearchInput from '../inner-components/SearchInput';
import DropdownWithCheckbox from '../inner-components/DropdownWithCheckbox';
import TimesheetsTable from '../inner-components/TimesheetsTable';
import TablePagination from '../inner-components/TablePagination';
import axios from "axios";
import { ENV } from '../../env/env'
import { PATH } from '../../env/pdfPath'
import moment from 'moment';
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { useNavigate } from 'react-router-dom'
import RenewToken from '../inner-components/RenewToken';
import { snackbar} from '@mobiscroll/react';

const PositionsFilter = [
    { value: 'All Positions', label: 'All Positions' },
    { value: 'STNA', label: 'STNA' },
    { value: 'RN', label: 'RN' },
    { value: 'CNA', label: 'CNA' },
    { value: 'LPN', label: 'LPN' }
]

const TimesheetsContent = (props) => {
    const navigate = useNavigate()
    const [refresh, setrefresh] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')
    const [dropdownFilter, setDropDownFilter] = useState('')
    const [data, setData] = useState([])
    const [link, setLink] = useState(ENV.ENDPOINT+`/timesheet/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState()

    const [value, setValue] = useState([
        new DateObject().setDay(1),
        new DateObject().add(1, "month").setDay(15),
    ]);
    const [noOfMonth, setNoOfMonth] = React.useState(2);
    const calendarRef = useRef()


    const handleLink = () => {
        let token = localStorage.getItem('token')
        // let link=`http://127.0.0.1:8000/api/clinician/view/`
        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                setData(response.data.results)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)
                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setData(response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)


                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error)
            })
    }

    const handleRefresh = (e) => {
        setrefresh(e)
    }


    useEffect(() => {
        let date1 = value[0].year + "-" + value[0].month + "-" + value[0].day
        let date2 = ""
        if (value[1] != undefined) {
            date2 = value[1].year + "-" + value[1].month + "-" +value[1].day
        }
        let link=ENV.ENDPOINT + `/timesheet/view/?search=${searchFilter},${dropdownFilter}`;
        if(props.facilityName!="All Facilities"){
            link=ENV.ENDPOINT + `/timesheet/view/?facility_id=${props.facilityId}&search=${searchFilter},${dropdownFilter}` 
        }
        let token = localStorage.getItem('token')
        axios.get(link, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                let dateData = []
                
                const dateRange = response.data.results.map(function (rowDate) {
                    if (moment(rowDate.shift_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

                        if (moment(rowDate.shift_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
                            dateData.push(rowDate)
                        }
                    }
                    
                })
                setData(dateData)
            
                setCurrentPage(response.data.page)

                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)

                }
                setPrevLink(response.data.links.previous)
            })
            .catch((error) => {
                console.log(error.response.data)
                if(error.response.data.code=='token_not_valid'){
                    RenewToken.RenewTokenAPI()
                  }
            })
            props.handleRefresh(false)
          
    }, [searchFilter, dropdownFilter,refresh,value,props])

    const handleSearch = (e) => {
        setSearchFilter(e.target.value)
    }

    const handleFilter = (e) => {
        if(e.value=='All Positions'){
            setDropDownFilter('')
        }
        else{
            setDropDownFilter(e.value)
        }
        
    }

    


    

    const approveAll = () => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/timesheet/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                response.data.results.map(function (row) {
                    let poc_signature = "Approved"
                    let status="Approved"
                    const credentials = {
                        poc_signature,
                        status
                    }
                    axios.patch(ENV.ENDPOINT + `/timesheet/edit/${row.timesheet_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            handleRefresh(true)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })


            })
            .catch((error) => {
                console.log(error)
            })
    }


    const exportPDF = () => {
       
        let token = localStorage.getItem('token')
        let dateData=[]
        let list=[]
       
        if(data.length==0){
            setTimeout(() => {
                snackbar({

                    message: 'No Data Available'
                });
            });
        }else{
            const dateRange1 = data.map(function (row) {
           
                return ({
                    timesheet_id: row.timesheet_id?row.timesheet_id:"-",
                    clinician_id: row.clinician_id?row.clinician_id:"-",
                    clinician_name: row.clinician_name?row.clinician_name:"-",
                    facility_name:row.facility_name?row.facility_name:"-",
                    facility_id:row.facility_id?row.facility_id:"-",
                    clinician_position: row.clinician_position?row.clinician_position:"-",
                    check_in_time: row.check_in_time?moment(row.check_in_time).format('hh:mm A'):"-",
                    check_out_time: row.check_out_time?moment(row.check_out_time).format('hh:mm A'):"-",
                    shift_date: row.shift_date?moment(row.shift_date).format('DD/MM/YYYY'):"-",
                    facility_note:  row.facility_note?row.facility_note:"-",
                    clinician_note: row.clinician_note?row.clinician_note:"-",
                    status: row.status?row.status:"-",
                    feedback:row.feedback?row.feedback:"-",
                    ratings: row.ratings?row.ratings:"-",
                    poc_signature: row.poc_signature?row.poc_signature:"-",
                    shift_id:row.shift_id?row.shift_id:"-",
                    
                })
                
                
    
            })
           
            list.push(dateRange1)
            var dataa=list[0]
            const credentials = {
                dataa
            }
            axios.post(ENV.ENDPOINT + `/pdf/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    //window.location.href = `http://127.0.0.1:8000${response.data.path}`;
                    // window.open(`https://admin.shift-alerts.com${response.data.path}`,'_blank','noopener,noreferrer')
                    window.open(`${PATH.ENDPOINT}${response.data.path}`,'_blank','noopener,noreferrer')
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        
    }

    return (

        <div className='facility-tab-content tab-content-wrap timesheet-content-wrap '>
            
            <div className='d-flex flex-column flex-md-row justify-content-md-between filter-row-wrapper'>
            <div className='left-filter-option '>
                    <SearchInput searchInput={handleSearch} />
                    <div className='custom-range-picker range-calnedar-with-graph '>
                        <DatePicker
                            value={value}
                            onChange={setValue}
                            range
                            numberOfMonths={noOfMonth}
                            ref={calendarRef}
                            onOpen={() => {
                                setTimeout(() => {
                                    const pickerFooter = document.createElement("div");
                                    const buttonHtml = "<div class='datepicker-footer-btn'><button class='close-picker-btn btn' id='date-picker-cancel-btn'>Cancel</button><button class='apply-btn btn btn-primary' id='apply-picker-btn'>Apply</button></div>";
                                    pickerFooter.innerHTML = buttonHtml;
                                    const datepickerFooter = document.querySelector(".rmdp-range-picker-footer");
                                    const footerButton = document.querySelector(".datepicker-footer-btn");

                                    if (footerButton === null) {

                                        datepickerFooter.appendChild(pickerFooter);

                                        const applyButton = document.getElementById("apply-picker-btn");
                                        const cancelButton = document.getElementById("date-picker-cancel-btn");
                                        applyButton.addEventListener('click', function () {
                                            calendarRef.current.closeCalendar();
                                        });
                                        cancelButton.addEventListener('click', function () {
                                            setValue(value);
                                            calendarRef.current.closeCalendar();
                                        });


                                    }
                                }, 100);
                            }}
                            plugins={[
                                <Footer position="bottom" close="Close" apply="Apply" />
                            ]}
                        />
                    </div>
                </div>
                <div className='table-filter-row table-filter-timesheets-row justify-content-start justify-content-md-end'>
                    {/* <AddUserButton ModelTitle="Approve All"/> */}
                    <Button className="btn-without-icon border-btn " variant="outline-primary" onClick={approveAll} ref={props.timehseetsref4}>Approve All</Button>
                    <Button className='download-btn btn-square btn-white'><span className='icon-cloud' onClick={exportPDF}></span></Button>
                    <DropdownWithCheckbox optionData={PositionsFilter} dropClass="without-search" filterInput={handleFilter} />
                </div>
            </div>
            <div className='user-facilty-table-wrapper pagination-table'>
                <div className="table-wrapper table-responsive "> 
                    <TimesheetsTable handleRefresh={handleRefresh} refresh={refresh} data={data} timehseetsref2={props.timehseetsref2} timehseetsref3={props.timehseetsref3}/>
                </div>
                <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink}/>
            </div>

        </div>
    )

}


export default TimesheetsContent;