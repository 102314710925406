import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import OverViewCol from '../inner-components/OverViewCol';
import DayShiftSchedule from '../inner-components/DayShiftSchedule';
import { RoutesPath } from '../../App';


const DashboardMiddleSection = (props) => {
    return (
        <>
            <div className='dashboard-middle-wrapper'>
                <Row>
                    <Col xl={6} className="left-col-wrap overview-col" ref={props.ref2}>
                    {localStorage.getItem('facility_id')?(
                            <OverViewCol handleRefresh={props.handleRefresh} refresh={props.refresh} />
                        ): <OverViewCol handleRefresh={props.handleRefresh} refresh={props.refresh}/>}
                        
                    </Col>
                    <Col xl={6} className="right-col-wrap calender-shift-col" ref={props.ref3}>
                        {localStorage.getItem('facility_id')?(
                            <div >
                            <DayShiftSchedule handleRefresh={props.handleRefresh} refresh={props.refresh}/>
                            </div>
                        ):<DayShiftSchedule handleRefresh={props.handleRefresh} refresh={props.refresh} />}
                        
                    </Col>
                </Row>
            </div>
        </>
    )
}
export default DashboardMiddleSection;
