import React, { useState, useEffect } from 'react';
import AddCliniciansPopup from './AddCliniciansPopup';
import SearchInput from './SearchInput';
import CustomDropdown from './CustomDropdown';
import UserCliniciansTable from './UserCliniciansTable';
import TablePagination from './TablePagination';
import axios from "axios";
import { ENV } from '../../env/env'
import useGet from '../../hooks/useGet';


const UserCliniciansFilter = [
    { value: 'All Positions', label: 'All Positions' },
    { value: 'CNA', label: 'CNA' },
    { value: 'RN', label: 'RN' },
    { value: 'LPN', label: 'LPN' },
    { value: 'STNA', label: 'STNA' }
]



const CliniciansTabContent = (props) => {
    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState()
    const [filterInput, setFilterInput] = useState()
    const [nextPage, setNextPage] = useState(false)
    const [link, setLink] = useState(ENV.ENDPOINT +
        `/clinician/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(false)
    const [reRender, setReRender] = useState(false)



    const handleChange = (event) => {
        setSearchInput(event.target.value)

    }


    const handleRender = () => {
        setReRender(true)
    }
    const handleFilter = (selection) => {
        if (selection.value == "All Positions") {
            setFilterInput("")
        }
        else {
            setFilterInput(selection.value)
        }



    }




    const handleLink = () => {
        let token = localStorage.getItem('token')

        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setCurrentPage(response.data.page)
                setData(response.data.results)
                console.log("response.data.results" , response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)

                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)

                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log("error.response.data" , error.response)
            })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setData(response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)


                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        let token = localStorage.getItem('token')

        if (filterInput != "" && filterInput != undefined) {
            if (searchInput != undefined && searchInput != "") {
                axios.get(ENV.ENDPOINT +
                    `/clinician/view/?search=${filterInput},${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        setCurrentPage(response.data.page)
                        setData(response.data.results)
                        setTotalPage(response.data.total_pages)
                        if (response.data.links.next != undefined && response.data.links.next != "") {
                            setNextPage(true)
                            setNextLink(response.data.links.next)

                        }
                        setPrevLink(response.data.links.previous)
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                    })
            }
            else {
             
                axios.get(ENV.ENDPOINT +
                    `/clinician/view/?search=${filterInput}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        setCurrentPage(response.data.page)
                        setData(response.data.results)
                        setTotalPage(response.data.total_pages)
                        if (response.data.links.next != undefined && response.data.links.next != "") {
                            setNextPage(true)
                            setNextLink(response.data.links.next)

                        }
                        setPrevLink(response.data.links.previous)


                    })
                    .catch((error) => {
                        console.log(error.response.data)
                    })
            }

        }

        else if (searchInput != undefined && searchInput != "") {
         
            axios.get(ENV.ENDPOINT +
                `/clinician/view/?search=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    setCurrentPage(response.data.page)
                    setData(response.data.results)
                    setTotalPage(response.data.total_pages)
                    if (response.data.links.next != undefined && response.data.links.next != "") {
                        setNextPage(true)
                        setNextLink(response.data.links.next)

                    }
                    setPrevLink(response.data.links.previous)


                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        } else {
           
           
            axios.get(link, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    setData(response.data.results)
                    setCurrentPage(response.data.page)
                    setTotalPage(response.data.total_pages)
                    if (response.data.links.next != undefined && response.data.links.next != "") {
                        setNextPage(true)
                        setNextLink(response.data.links.next)

                    }
                    setPrevLink(response.data.links.previous)
                }, [])
                .catch((error) => {
                    console.log(error.response.data)
                })
        }
        setReRender(false)
    }, [reRender, searchInput, filterInput])

    return (

        <div className='facility-tab-content tab-content-wrap'>
            <div className='filter-row-wrapper'>
                <AddCliniciansPopup ModelTitle="Add Clinician" handleRender={handleRender} />
                <div className='table-filter-row'>
                    <SearchInput searchInput={handleChange} />
                    <CustomDropdown optionData={UserCliniciansFilter} dropClass="without-search" filterInput={handleFilter} />
                </div>
            </div>
            <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
                <div className="table-wrapper table-responsive">
                    <UserCliniciansTable searchInputKey={searchInput} nextData={data} filterInputKey={filterInput} handleRender={handleRender} renderData={reRender} />

                </div>
                <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
                {/* <TablePagination /> */}
            </div>

        </div>
    )

}


export default CliniciansTabContent;