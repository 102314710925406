import React, { useState, useRef, useEffect } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "./Map.css";
import axios from 'axios';

mapboxgl.accessToken =
  "pk.eyJ1IjoidHJpc2hhbmswMSIsImEiOiJjbGdtM3VmdG4wMjhlM2ZuZGY3NjJzZTRzIn0.4RJK1PeaiCISPQYps81Hsw";

const Map = ({ setLng, setLat, lng, lat , address  ,setAddress }) => {
  const [zoom, setZoom] = useState(2);
  const mapContainer = useRef(null);
  const markerRef = useRef(null);
 



  useEffect(() => {
    axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${lng}`)
      .then(response => {
        setAddress(response.data.display_name);
      })
      .catch(error => {
        console.log(error);
      });
  }, [lng ,lat ]);

  

 


  
  useEffect(() => {
    if (!mapContainer.current) return;
  
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [lng, lat],
      zoom: zoom,
    });
  
    const markerElement = document.createElement("div");
    markerElement.className = "marker";
    markerElement.style.backgroundImage =
      "url(https://i.imgur.com/MK4NUzI.png)";
    markerElement.style.width = "25px";
    markerElement.style.height = "25px";
    markerElement.style.backgroundSize = "contain";
    markerElement.style.backgroundRepeat = "no-repeat";
  

    
  // Create a p tag with dynamic text
   const p = document.createElement("p");
   p.style.width = "250px";
   p.style.position = "absolute";
   p.style.fontSize = "12px";
   p.style.top = "30px";
   p.style.left = "-100px";
   p.style.padding= "10px"
   p.style.borderRadius= "10px"
   p.style.fontWeight= "500"
   p.style.backgroundColor = "white";
   p.innerText = `${lat === null ? p.style.display = "none" : address}`;

// Append the p tag to the markerElement
markerElement.appendChild(p);


    const marker = new mapboxgl.Marker({
      element: markerElement,
      draggable: true,
    })
      .setLngLat([lng, lat])
      .addTo(map);
  
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      
    });
  
    map.addControl(geocoder);
  
  
    geocoder.on("result", (e) => {
      const result = e.result;
      const { center } = result;
      console.log("center" , center)
      setLng(center[0]);
      setLat(center[1]);
      setZoom(14);
      if (markerRef.current) {
        markerRef.current.setLngLat(center);
      } else {
        const marker = new mapboxgl.Marker({
          element: markerElement,
          draggable: true,
        })
          .setLngLat(center)
          .addTo(map);
        markerRef.current = marker;
      }
      map.flyTo({ center, zoom: 14 });
      markerRef.current = marker;

    });
  
    marker.on("dragend", (e) => {
      const lngLat = marker.getLngLat();
      setLng(lngLat.lng);
      setLat(lngLat.lat);
    });
  
    return () => map.remove();
  }, [lng, lat, zoom , address]);


  

  return (
    <div
      className="map-container-box"
      ref={mapContainer}
      style={{ position: "relative", overflow: "hidden" }}
     
    />
  );
};

export default Map;
