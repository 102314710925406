import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Notification from '../inner-components/Notification';
import MessagesButton from '../inner-components/MessagesButton';
import { RoutesPath } from '../../App';
import CustomDropdown from '../inner-components/CustomDropdown1';
import axios from 'axios';
import { ENV } from '../../env/env'

const TimesheetsTopHeaderSection = (props) => {
    const [facilities, setFacilities] = useState([])
    let optionsFacilities=[{value:"All Facilities",id:"0"}]
    useEffect(()=>{
        let token = localStorage.getItem('token')
    axios.get(ENV.ENDPOINT + '/facility/', { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
            setFacilities(response.data.payload)
            if (!localStorage.getItem('facility')) {
                localStorage.setItem("facility", response.data.payload[0].facility_name)
                localStorage.setItem("facility_id", response.data.payload[0].facility_id)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },[])
    
    if(localStorage.getItem('position')=="poc_admin"||localStorage.getItem('position')=="facility_admin"){
        props.handleFacility(localStorage.getItem('facility'),localStorage.getItem('facility_id'))
    }
    const handleFilter = (selection) => {
        // localStorage.setItem("facility", selection.value)
        // localStorage.setItem("facility_id", selection.id)
      
        if(selection.value=="All Facilities"){
            props.handleFacility("","")
            props.handleRefresh(true)
        }else{
            props.handleFacility(selection.value,selection.id)
            props.handleRefresh(true)
        }
        
       
    }


    facilities.map(function (row) {
        return optionsFacilities.push({ value: row.facility_name, id: row.facility_id })
    })
    
    return (
        <>
            <div className='dashboard-top-wrapper' style={{ backgroundImage: `url("/assets/images/timesheets-banner.jpg")`  }}>
                <Row>
                    <Col xl={7} lg={6} className="left-col-wrap">
                        <h1 className='h2 page-title'>Timesheets</h1>
                    </Col>
                    <Col xl={5} lg={6} className="right-col-wrap top-dropdown-col">
                        <div className='col-inner'>
                            {localStorage.getItem('position')=='c_level_emp'||localStorage.getItem('position')=='hmc_admin'?<CustomDropdown optionData={optionsFacilities} dashboard={true} filterInput={handleFilter} selectedFacility={props.facilityName} />:""}
                            <Notification />
                            <MessagesButton />
                        </div>
                    </Col>
                    <Col lg={12} className="full-col-wrap text-center">
                        <h4 className='h4'>{props.facilityName}</h4>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TimesheetsTopHeaderSection;