import React, { useState, useEffect } from 'react';
import TakeNotePopup from './TakeNotePopup';
import RatePopup from './RatePopup';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';

const Feedbackstable = (props) => {

    const [UserFacilitiesData, setUserFacilitiesData] = useState()
    const [clinicianData, setClinicianData] = useState()

    const [rate, setRate] = useState()
    const [starRatings, setStarRatings] = useState()

    useEffect(() => {
        ToggleActiveOnInit();
    });


    useEffect(() => {

        if (props.data != undefined) {

            const dateRange = props.data.map(function (row) {

                return ({
                    timesheet_id: row.timesheet_id,
                    clinician_id: row.clinician_id,
                    ClinicianPhoto: "assets/images/Nurse.png",
                    ClinicianName: row.clinician_name,
                    Position: row.clinician_position,
                    Ratings: row.ratings,
                    FeedbacksOrComments: row.feedback,
                })

            })
            let token = localStorage.getItem('token')
            axios.get(ENV.ENDPOINT + `/clinician/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setClinicianData(response.data.results)

                let list = []

                const merged = dateRange.map(function (row) {


                    clinicianData.map(function (row1) {
                        let Ratings = "Pending"
                        let FeedbacksOrComments = "Pending"
                        if (row.Ratings == '') {
                            Ratings = "Pending"
                            FeedbacksOrComments = "Pending"
                        }
                        else {
                            Ratings = row.Ratings
                            FeedbacksOrComments = row.FeedbacksOrComments
                        }

                        if (row1.clinician_id == row.clinician_id) {
                            list.push({
                                timesheet_id: row.timesheet_id,
                                clinician_id: row1.clinician_id,
                                ClinicianPhoto: row1.avatar_image,
                                ClinicianName: row.ClinicianName,
                                Position: row.Position,
                                Ratings: Ratings,
                                FeedbacksOrComments: FeedbacksOrComments,
                            })
                        }
                    })

                })
              
                setUserFacilitiesData(list)
                setLoader(false)
            })
            .catch((error) => {
                console.log(error.response.data)
            })
            // setUserFacilitiesData(dateRange)

        }

        props.handleRefresh(false)

    }, [props.refresh, props.data])


    // SwithToggle on Init
    const ToggleActiveOnInit = () => {
        var switchToggle = document.querySelectorAll('.switch');

        [].forEach.call(switchToggle, function (item) {
            var switchToggleValue = item.getAttribute("data-switch");
            if (switchToggleValue === "On") {
                var checkbox = item.querySelector('input');
                checkbox.checked = true;
            }
        });

    };
    
    const handleRate = (e) => {
        setRate(e.target.value)
    }
    const handleRatings = (e) => {
        setStarRatings(e)
    }

    //function to add activity notifications
    async function activityAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.post(ENV.ENDPOINT + `/activities/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {

        })
        .catch((error) => {
            console.log(error.response)
        })
    }

    const addRate = (val) => {
        let feedback = rate
        let ratings = starRatings
        const credentials = {
            feedback,
            ratings
        }
        let token = localStorage.getItem('token')
        axios.patch(ENV.ENDPOINT + `/timesheet/edit/${val.timesheet_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {

        })
        .catch((error) => {
            console.log(error)
        })
        let activity_title = `Gave Feedback to ${val.ClinicianName}`
        let activity_status = 'success'
        let acitivity_date = moment().format('YYYY-MM-DD')
        let facility_id = localStorage.getItem('facility')
        let category = 'Feedbacks'

        const activity = activityAPI({
            activity_title,
            activity_status,
            acitivity_date,
            facility_id,
            category
        });
    }
    const [loader, setLoader] = useState(true)
    const [classe, setClasse] = useState(false)
    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])
    const [noDataClass,setNoDataClass]=useState("no-data-divhide")
    useEffect(() => {
        setTimeout(() => {
            if(UserFacilitiesData==[]||UserFacilitiesData.length==0){
                setNoDataClass("no-data-div")
            }
        
        
        }, 2000);
    }, [UserFacilitiesData])

    

    return (
        <>
            <div className={classe}></div>
            <table width="100%">
            <thead>
            <tr>
            <th>Clinician</th>
            <th>Position</th>
            <th>Ratings</th>
            <th>Feedbacks/Comments</th>
            </tr>
            </thead>
            <tbody>
            {UserFacilitiesData ? (
                        <>
                        {UserFacilitiesData.map((val, index) =>
                                <tr key={index}>
                                <td className="clinician-td">
                                <div className='clinician-info'>
                                <img src={val.ClinicianPhoto} alt={val.ClinicianName} />
                                <h6>{val.ClinicianName}</h6>
                                </div>
                                </td>
                                <td className="positons-td">
                                <div className='flex-table-image d-block d-md-none'><img src="/assets/images/position-icon.svg" alt="Position Icon" /></div>
                                <div className='flex-table-title d-block d-md-none'>Position:</div>
                                {val.Position}
                                </td>
                                <td className="ratings-td">
                                <div className='flex-table-image d-block d-md-none'><img src="/assets/images/ratings-icon.svg" alt="Ratings Icon" /></div>
                                <div className='flex-table-title d-block d-md-none'>Ratings:</div>
                                {val.Ratings == "Pending" ? <Rater rating={0} total={5} interactive={false} /> : <Rater rating={val.Ratings} total={5} interactive={false} />}
                                </td>
                                <td className="reviews-td">
                                <div className='flex-table-image d-block d-md-none'><img src="/assets/images/feedback-icon.svg" alt="Feebacks Icon" /></div>
                                <div className='flex-table-title d-block d-md-none'>Feebacks:</div>
                                {val.FeedbacksOrComments == "Pending" ? <RatePopup ModelTitle="Review Clinician" data={val.clinician_id} onChange={handleRate} ratings={handleRatings} submit={e => addRate(val)} /> : val.FeedbacksOrComments}
                                </td>
                                </tr>

)}

</>
                    ) : ""}
                    </tbody>
                    </table>
                    {UserFacilitiesData && UserFacilitiesData.length == 0 && UserFacilitiesData!=[]? (<div className={noDataClass}>No data available</div>) : ""}
                    </>
    )
}

export default Feedbackstable;