import React, { useState, useEffect } from 'react';
import { Eventcalendar,} from '@mobiscroll/react';
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import ScheduleShiftData from '../../JsonDataFile/ScheduleShiftData';
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'

const now = new Date();

const UpcomingShift = () =>{
    const [myEvents, setEvents] = React.useState();
    const view = React.useMemo(() => {
        return {
            agenda: { type: 'year' }
        };
    }, []);
    useEffect(() => {
      
      let upcomingShiftList=[]
      



       

        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT+`/scheduling/view/?search=`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.results, "response data")
                
                const optionsFacilities = response.data.results.map(function (row) {
                    return {
                        id: row.schedule_id, color: "blue", imageSrc: 'assets/images/user-shift-image.png', start: row.start_time, end: row.end_time, title: row.title, shiftTypeText: row.clinician_position,
                        shiftUserName: row.clinician_name,
                        shiftNotes: row.clinician_name,
                        color: row.shift_color
                    }
                })
                optionsFacilities.forEach((el) => {
                    
                    if (moment(el.start) > moment()) {
                        
                      upcomingShiftList.push(el)
                    }
                })
                setEvents(upcomingShiftList)
              
                console.log(optionsFacilities, "list array data")

            })
            .catch((error) => {
                console.log(error.response.data)
            })
          
      
    }, [])

    const renderAgenda = React.useCallback((data) => {

        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var months = ['Jan', 'Feb', 'March', 'April', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var daysFullName = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        console.log(data);
        var dateText;
        var dayName;
        var monthName;
        var dayFullName;
        data.map((day) =>
        {
            // console.log(day.date.getDay())
            day.events.map((event) => {
                // const dayNames = event.startDate.getDay();
                // const monthNames = event.startDate.getMonth();
                // dayName =  days[dayNames];
                // dateText = event.startDate.getDate();
                // monthName = months[monthNames];
                // dayFullName = daysFullName[dayNames];
            })
        });
 
        return(
            <>
                
                {data.map((day) => {

                    day.events.map((event) => {
                        const dayNames = event.startDate.getDay();
                        const monthNames = event.startDate.getMonth();
                        dayName =  days[dayNames];
                        dateText = event.startDate.getDate();
                        monthName = months[monthNames];
                        dayFullName = daysFullName[dayNames];
                        
                    });
                    
                return(
                    <>
                    
                    <div className='upcoming-shift-list'>
                        <div className="list-single">
                            <div className='list-inner'>
                                <div className='date-wrap'>
                                    
                                    <span className='date'>{dateText}</span>
                                    <span className='day-name'>{dayName}</span>
                                </div>
                                <div className='shift-data'>
                                    <h6>
                                        {day.events.map((event) =>
                                            <span>{event.original.shiftTypeText}</span>
                                        )}
                                    </h6>
                                    <span className='full-date-text'>{dayFullName}, {monthName} {dateText}</span>
                                </div>
                                <div className='shift-wrapper'>
                                    {day.events.map((event) =>
                                        <div className='image-wrap' style={{borderColor:event.original.color}}>
                                            <img src={event.original.imageSrc} alt=""/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )
    })}
            
            </>
        )
    });


    return (
        <div className="upcoming-shift-wrapper">
            <h6>Upcoming shifts</h6>    
            <Eventcalendar
                theme="ios" 
                themeVariant="light"
                height={600}
                data={myEvents}
                dateFormat="DDD DD MMM, YYYY"
                renderAgenda={renderAgenda}
                view={view}
        />
       </div>
    ); 

}

export default UpcomingShift;