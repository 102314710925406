import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import InputGroupForm from './InputGroupForm';
import ImageUploadInput from './ImageUploadInput';
import DateofBirthInput from './DateofBirthInput';
import CustomDropdown from './CustomDropdown';
import DatePicker from "react-multi-date-picker";
import Select from 'react-select'
import { RoutesPath } from '../../App';
import axios from "axios";
import { ENV } from '../../env/env'
import { snackbar } from '@mobiscroll/react';

const AddInvoicePopup = (props) => {

    const JobSitesData = [

        { value: 'CNA', label: 'CNA' },
        { value: 'STNA', label: 'STNA' },
        { value: 'RN', label: 'RN' },
        { value: 'LPN', label: 'LPN' },
    ]

    const [show, setShow] = useState(false);
    const [errorMessages, setPositionerrorMessages] = useState({});


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [dob, setDob] = useState()
    const [data, setData] = useState()


    const handledDate = date => {
        let datevalue = date.format()
        setDob(datevalue)
        console.log(dob)
    }

    const { register, formState: { errors }, handleSubmit,reset } = useForm();

    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        await axios.post(ENV.ENDPOINT + '/invoice/create/', credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                console.log(response.data)
                reset()
                handleClose()
                props.handleRender(true)
                setTimeout(() => {
                    snackbar({
                        message: 'Invoice Added Successfully'
                    });
                });
            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    const onSubmit = (data) => {

        var { positionDropdown } = document.forms[0];
        const poisitionDropValue = positionDropdown.value;
        if (poisitionDropValue === "") {
            setPositionerrorMessages({ name: "CliniciansPosition", message: "Please select position" });
        }
        console.log("invoice data", data, poisitionDropValue, dob)


        let clinician_name = data.userName
        let service_date = dob
        let rate = data.rating
        let amount = data.amount
        let position = poisitionDropValue
        let hrs_worked = data.hrsworked



        const token = apiCall({
            clinician_name,
            service_date,
            rate,
            amount,
            position,
            hrs_worked
        });
    }
    const onSubmitDropdown = () => {

    }
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    return (

        <div className="add-user-pop-wrap">
            <Button className="border-btn" onClick={handleShow}><i className='icon-plus'></i> Add Invoice</Button>
            <Modal show={show} onHide={handleClose} className='add-invoice-popup add-user-popup'>
                <Modal.Header closeButton>
                    <h5>{props.ModelTitle}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='add-user-form'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-inner">
                                <Row className="form-row">
                                    <Col md={6}>

                                        <Form.Group className="form-group" controlId="userName">
                                            <Form.Label className="">User name</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="userName" {...register("userName", {
                                                required: true,
                                                maxLength: 50,
                                                minLength: 5,
                                                pattern: {
                                                    value: /^[a-zA-Z0-9_@.-]*$/,
                                                },
                                            })} />
                                            <span className="error-message">{errors.userName?.type === 'required' && "enter Username"}
                                                {errors.userName?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                {errors.userName?.type === 'minLength' && "enter minimum 5 characters"}
                                                {errors.userName?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"}</span>

                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <div className='form-group custom-dropdown'>
                                            <label className='form-label'>Position</label>
                                            <Select options={JobSitesData} isClearable="false" id='CliniciansPosition' name="positionDropdown" />
                                            {renderErrorMessage("CliniciansPosition")}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="form-group date-of-birth-input">
                                            <label className='form-label'>Service Date</label>
                                            <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" className='form-control' onChange={handledDate} />

                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="workHour">
                                            <Form.Label className="">Rate</Form.Label>
                                            <Form.Control type="number" placeholder="Text here" name="rating" {...register("rating", {
                                                required: true,
                                            })} />
                                            <span className="error-message">{errors.rating?.type === 'required' && "enter Rate"}
                                            </span>

                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="amount">
                                            <Form.Label className="">Amount(USD)</Form.Label>
                                            <Form.Control type="number" placeholder="Text here" name="amount" {...register("amount", {
                                                required: true,
                                            })} />
                                            <span className="error-message">{errors.amount?.type === 'required' && "enter Amount"}
                                            </span>

                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="hrsworked">
                                            <Form.Label className="">Hrs Worked</Form.Label>
                                            <Form.Control type="number" placeholder="Text here" name="hrsworked" {...register("hrsworked", {
                                                required: true,
                                            })} />
                                            <span className="error-message">{errors.hrsworked?.type === 'required' && "enter Hours Worked"}
                                            </span>

                                        </Form.Group>
                                    </Col>

                                </Row>
                                <div className='submit-btn'>
                                    <input type="submit" value="Submit" className="btn btn-primary" />
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    )

}


export default AddInvoicePopup;