import React, { useState, useEffect } from "react";
import ManageUserTab from "../inner-components/ManageUserTab";
import ManageUserTabSuperAdmin from "../inner-components/ManageUserTabSuperAdmin";
import EditUser from "../inner-components/EditUser";
import { RoutesPath } from "../../App";

const ManageUserTopFilterSectionHMCAdmin = () => {
  return (
    <>
      <div className="manage-user-main-section">
        <div className="user-tab-wrapper">
          <div className="user-tab-col">
            <div className="col-inner">
              <ManageUserTabSuperAdmin />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default ManageUserTopFilterSectionHMCAdmin;