import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { RoutesPath } from '../../App';
import axios from "axios";
import { ENV } from '../../env/env'


const VillageCommunityDropdown = (props) => {
    let approvedList=[]
    let declinedList=[]
    let totalList=[]
    let pendingList=[]
    let totalNurses=[]
   const [VillageCommunityDropdownData,setVillageCommunityDropdownData]=useState()
    useEffect(()=>{
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT+`/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
      
        
            response.data.results.forEach((el) => {
                totalList.push(el)
                if (el.status == "Approved") {
                    
                    approvedList.push(el)
                  
                }
                if(el.status=="Declined"){
                    declinedList.push(el)
                 
                }
                if(el.status=="Open"){
                    pendingList.push(el)
                }
               
            })
            totalList=response.data.results
            axios.get(ENV.ENDPOINT+`/clinician/view/?search=${localStorage.getItem('facility')}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
      
        
           
            totalNurses=response.data.results
            const VillageCommunityDropdownData1 = [
        
                {
                    itemTitle:"Nurses Assigned in Facility",
                    itemCount:totalNurses.length
                },
                {
                    itemTitle:"Non-Allocated Schedules",
                    itemCount:pendingList.length
                },
                {
                    itemTitle:"Allocated Schedules",
                    itemCount:approvedList.length
                }
            ]
            setVillageCommunityDropdownData(VillageCommunityDropdownData1)
          
         
        })
        .catch((error) => {
            console.log(error.response.data)
        })
        
          
         
        })
        .catch((error) => {
            console.log(error.response.data)
        })
        
    },[])
    
        // const VillageCommunityDropdownData = [
        
        //     {
        //         itemTitle:"Nurses Assigned in Facility",
        //         itemCount:"1 item"
        //     },
        //     {
        //         itemTitle:"Non-Allocated Schedules",
        //         itemCount:"6 items"
        //     },
        //     {
        //         itemTitle:"Allocated Schedules",
        //         itemCount:approvedList.status
        //     }
        // ]
    
   
 
    return (
        
        <>
        {VillageCommunityDropdownData?(
            <div className="filter-accrodion village-accordion">
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{localStorage.getItem('facility')}</Accordion.Header>
                    <Accordion.Body>
                    <ul>
                    {VillageCommunityDropdownData.map((val, index) =>
                               <li key={index}>
                               <h6>{val.itemTitle}</h6>
                               <span>{val.itemCount} item</span>
                               </li>
                            )}
                            </ul>

                            </Accordion.Body>
                            </Accordion.Item>
                            </Accordion>
                            </div>
        ):""}
           

           </>
    )
}

export default VillageCommunityDropdown;