import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AllCustomIcon from '../../JsonDataFile/AllCustomIcon';
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { RoutesPath } from '../../App';
import { Row, Col } from 'react-bootstrap';
import {
    Chart, Series, SeriesTemplate, CommonSeriesSettings, Legend, Export,
    HoverStyle,
} from 'devextreme-react/chart';
import axios from "axios";
import moment from 'moment';
import { StandardEvent } from '@fullcalendar/react';
import { ENV } from '../../env/env'

const NurseTierRequestRateSection = (props) => {
    const [dataSource, setDataSource] = useState()
    const [facility,setFacility]=useState('')
    const [value, setValue] = useState([
        new DateObject().setDay(1),
        new DateObject().add(1, "month").setDay(1),
    ]);
    var link=ENV.ENDPOINT + `/scheduling/view/`
    if(localStorage.getItem('position')=='facility_admin'){
        link=ENV.ENDPOINT + `/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`
    }

    useEffect(() => {
        let date1 = value[0].year + "-" + value[0].month + "-" + value[0].day
        let date2 = ""
        if (value[1] != undefined) {
            date2 = value[1].year + "-" + value[1].month + "-" + value[1].day
        }

     
        let list = []
        let token = localStorage.getItem('token')
        axios.get(link, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                let dateData = []
                const dateRange = response.data.results.map(function (rowDate) {
                    if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

                        if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
                            dateData.push(rowDate)
                        }
                    }
                   
                })
                if(localStorage.getItem('position')=='facility_admin'){
                   
                    const facility = response.data.results.map(function (row) {
                        return {
                            facility_id: row.facility_id,
                            facility: row.facility_name
                        }
                    })
                    const unique = [...new Set(facility.map(item => item.facility_id))];
                    const requestData1 = unique.map(function (row) {
                        let CNALst = []
                        let RNLst = []
                        let LPNLst = []
                        let STNALst = []
                        let completedRNList = []
    
                        let completedLPNList = []
                        let completedCNAList = []
                        let completedSTNAList = []
                        var facility
                        dateData.map(function (row1) {
                            if (row1.facility_id == row) {
                                facility = row1.facility_name
                                if (row1.clinician_position == "CNA") {
                                    CNALst.push(row)
                                }
                                if (row1.clinician_position == "CNA" && row1.status == 'Completed') {
                                    completedCNAList.push(row)
                                }
                                if (row1.clinician_position == "RN") {
                                    RNLst.push(row)
                                }
                                if (row1.clinician_position == "RN" && row1.status == 'Completed') {
    
                                    completedRNList.push(row)
                                }
                                if (row1.clinician_position == "LPN") {
                                    LPNLst.push(row)
                                }
                                if (row1.clinician_position == "LPN" && row1.status == 'Completed') {
                                    completedLPNList.push(row)
                                }
                                if (row1.clinician_position == "STNA") {
                                    STNALst.push(row)
                                }
                                if (row1.clinician_position == "STNA" && row1.status == 'Completed') {
                                    completedSTNAList.push(row)
                                }
                            }
                        })
    
                        return {
                            month: facility,
                            CNARequest: CNALst.length,
                            RNRequest: RNLst.length,
                            LPNRequest: LPNLst.length,
                            STNARequest: STNALst.length,
                            CompletedCNA: completedCNAList.length,
                            CompletedRN: completedRNList.length,
                            CompletedLPN: completedLPNList.length,
                            CompletedSTNA: completedSTNAList.length,
                        }
                    })
                    setDataSource(requestData1)
                }
                else{
                    const facility = response.data.results.map(function (row) {
                        return {
                            facility_id: row.facility_id,
                            facility: row.facility_name
                        }
                    })
                    const unique = [...new Set(facility.map(item => item.facility_id))];
    
                    const requestData1 = unique.map(function (row) {
                        let CNALst = []
                        let RNLst = []
                        let LPNLst = []
                        let STNALst = []
                        let completedRNList = []
    
                        let completedLPNList = []
                        let completedCNAList = []
                        let completedSTNAList = []
                        var facility
                        dateData.map(function (row1) {
                            if (row1.facility_id == row) {
                                facility = row1.facility_name
                                if (row1.clinician_position == "CNA") {
                                    CNALst.push(row)
                                }
                                if (row1.clinician_position == "CNA" && row1.status == 'Completed') {
                                    completedCNAList.push(row)
                                }
                                if (row1.clinician_position == "RN") {
                                    RNLst.push(row)
                                }
                                if (row1.clinician_position == "RN" && row1.status == 'Completed') {
    
                                    completedRNList.push(row)
                                }
                                if (row1.clinician_position == "LPN") {
                                    LPNLst.push(row)
                                }
                                if (row1.clinician_position == "LPN" && row1.status == 'Completed') {
                                    completedLPNList.push(row)
                                }
                                if (row1.clinician_position == "STNA") {
                                    STNALst.push(row)
                                }
                                if (row1.clinician_position == "STNA" && row1.status == 'Completed') {
                                    completedSTNAList.push(row)
                                }
                            }
                        })
    
                        return {
                            month: facility,
                            CNARequest: CNALst.length,
                            RNRequest: RNLst.length,
                            LPNRequest: LPNLst.length,
                            STNARequest: STNALst.length,
                            CompletedCNA: completedCNAList.length,
                            CompletedRN: completedRNList.length,
                            CompletedLPN: completedLPNList.length,
                            CompletedSTNA: completedSTNAList.length,
                        }
                    })
                    setDataSource(requestData1)
                }
                
            })
            .catch((error) => {
                console.log(error.response.data)
            })
        }, [value])

    // const dataSource = [{
    //     month: 'Oct 2021',
    //     CNASTNARequest: 125,
    //     CompletedLVNLPN: 50,
    //     CompletedCNASTNA: 73,
    //     RNRequest: 15,
    //     CompletedRN: 10,
    //     LVNRequest: 10,
    // }, {
    //     month: 'Nov 2021',
    //     CNASTNARequest: 280,
    //     CompletedLVNLPN: 140,
    //     CompletedCNASTNA: 100,
    //     RNRequest: 60,
    //     CompletedRN: 120,
    //     LVNRequest: 120,
    // }, {
    //     month: 'Nov 2021',
    //     CNASTNARequest: 50,
    //     CompletedLVNLPN: 15,
    //     CompletedCNASTNA: 20,
    //     RNRequest: 5,
    //     CompletedRN: 5,
    //     LVNRequest: 7,
    // }, {
    //     month: 'Jan 2022',
    //     CNASTNARequest: 3,
    //     CompletedLVNLPN: 3,
    //     CompletedCNASTNA: 100,
    //     RNRequest: 40,
    //     CompletedRN: 20,
    //     LVNRequest: 3,
    // }, {
    //     month: 'Feb 2022',
    //     CNASTNARequest: 160,
    //     CompletedLVNLPN: 60,
    //     CompletedCNASTNA: 25,
    //     RNRequest: 60,
    //     CompletedRN: 85,
    //     LVNRequest: 65,
    // }, {
    //     month: 'Mar 2022',
    //     CNASTNARequest: 120,
    //     CompletedLVNLPN: 190,
    //     CompletedCNASTNA: 135,
    //     RNRequest: 40,
    //     CompletedRN: 15,
    //     LVNRequest: 35,
    // }, {
    //     month: 'Apr 2022',
    //     CNASTNARequest: 5,
    //     CompletedLVNLPN: 10,
    //     CompletedCNASTNA: 5,
    //     RNRequest: 60,
    //     CompletedRN: 20,
    //     LVNRequest: 80,
    // }];

    const Item = props => (
        <Legend.Item {...props} className="flex-row-reverse" />
        );

        const right = [
            {
                //   value: 20,
            //   displayValue: "20 $",
            //   text: "Collected",
            color: "#4cb38e",
        },
    ]

    const left = [
        {
            //   value: 10,
            //   displayValue: "10 $",
            //   text: "Pending",
            color: "#eee36b",
        },
    ]

    return (
        <div className='request-rate-wrapper'>
            <div className='edit-user-wrapper'>
            <div className='user-header'>
            <Link to={RoutesPath.statistics} className='back-btn' id="UserbackBtn">
            <i className='icon-Back-arrow'></i>
            </Link>
            <h6 className='poc-name-title'>Nurse Tier Request Rate</h6>
            </div>

            <Row className="nurse-tier-row">

            <Col lg="12" className="range-calnedar-with-graph text-center">
            <DatePicker
                            value={value}
                            onChange={setValue}
                            range
                            numberOfMonths={2}
                            plugins={[
                                <Footer position="bottom" />
                            ]}
                            />
                    </Col>

                    <Col lg="12" className="bar-chart">
                    <div className='graph-box'>
                    <Chart
                                id="chart"
                                palette="Soft"
                                // title="Percent of Total Energy Production"
                                dataSource={dataSource}
                                >
                                <CommonSeriesSettings
                                    argumentField="month"
                                    type="bar"
                                    barPadding={0}
                                    ignoreEmptyPoints={true}
                                    />
                                    <Series valueField="CNARequest" name="CNA Request" color="#2753b8" />
                                    <Series valueField="RNRequest" name="RN Request" color="#ff9429" />
                                    <Series valueField="LPNRequest" name="LPN Request" color="#0210b0" />
                                    <Series valueField="STNARequest" name="STNA Request" color="#f5ca32" />

                                    <Series valueField="CompletedCNA" name="Completed CNA" color="#e42661" />

                                    <Series valueField="CompletedRN" name="Completed RN" color="#893deb" />
                                    <Series valueField="CompletedLPN" name="Completed LPN" color="#0ea80f" />
                                    <Series valueField="CompletedSTNA" name="Completed STNA" color="#b02e02" />

                                    <Legend verticalAlignment="top" horizontalAlignment="center" itemComponent={Item} />
                                    <Export enabled={true} />
                                    </Chart>
                                    </div>

                                    </Col>

                                    </Row>
                                    </div>
                                    </div>
    )
}

export default NurseTierRequestRateSection;