import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import InputGroupForm from '../inner-components/InputGroupForm';
import ImageUploadInput from '../inner-components/ImageUploadInput';
import DateofBirthInput from '../inner-components/DateofBirthInput';
import CustomDropdown from '../inner-components/CustomDropdown';
import axios from 'axios';
import { Buffer } from 'buffer';
import { ENV } from '../../env/env'

import S3FileUpload from 'react-s3';
import { encode } from 'punycode';
import { useNavigate } from 'react-router-dom';
import { snackbar } from '@mobiscroll/react';

const EditProfileSuperAdminSection = (props) => {
    const [loader, setLoader] = useState(true)
    const [classe, setClasse] = useState(false)
    const [userData, setUserData] = useState("")
    const [data, setData] = useState()

    const [dob, setDob] = useState()
    const [oldpass, setOldPass] = useState()
    const [newpass, setNewPass] = useState()
    const [conf, setConfPass] = useState()
    const [facility, setFacility] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [roles, setRole] = useState()
    const [userName, setUserName] = useState()
    const [Address, setAddress] = useState()
    const [Email, setEmail] = useState()
    const [fullName, setFullName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [modeifiedDate, setModifiedDate] = useState()
    const [oldpassword, setOldPassword] = useState()
    const [uploadedImage, setUploadedImage] = useState()
    const [defaultRole, setDefaultRole] = useState()

    window.Buffer = Buffer;
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState()

    const navigate = useNavigate()

    const RoleDropdownData = [
        { value: 'Facility Admin' },
        { value: 'Facility Access' },
        { value: 'HMC Admin' },
        { value: 'Super Admin' }
    ]
    // const RoleDropdownData = [
    //     { value: 'facility_admin',label:"Facility Admin" },
    //     { value: 'hmc_admin',label:"Facility Admin" },
    //     { value: 'c_level_emp',label:"Super Admin" }
    // ]
    // RoleDropdownData.forEach((el) => {

    //         if (el.value == localStorage.getItem('position')) { setDefaultRole(el)}


    //     })
    const rolee = ""
    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + '/user/profile/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log('user profile', response.data)
                setUserData(response.data.msg)
                setUserName(response.data.msg.username)
                if (response.data.msg.position == "c_level_emp") {
                    setRole("Super Admin")
                }
                if (response.data.msg.position == "hmc_admin") {
                    setRole("HMC Admin")
                }
                if (response.data.msg.position == "facility_admin") {
                    setRole("Facility Admin")
                }
                if (response.data.msg.position == "poc_admin") {
                    setRole("Facility Access")
                }


                axios.get(ENV.ENDPOINT + `/admin/view/?admin_id=${response.data.msg.Admin}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        console.log(response.data.results[0], "admin dataaa")
                        setData(response.data.results[0])
                        const [year, month, day] = response.data.results[0].date_of_birth.split('-');
                        const result1 = [month, day, year].join('/');
                        setModifiedDate(result1)
                        setPhoneNumber(response.data.results[0].contact_no)
                        setAddress(response.data.results[0].address)
                        setFullName(response.data.results[0].fullname)
                        setEmail(response.data.results[0].email)
                        setImageURL(response.data.results[0].avatar_image)
                        setOldPassword(response.data.results[0].password)

                        setLoader(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            })



    }, [setData, setUserData])
    const [neww, setNeww] = useState()
    const handledDate = date => {
        let datevalue = date.format()
        const [month, day, year] = datevalue.split('/');
        const result = [year, month, day].join('-');
        setNeww(result)
        console.log(neww)
    }



    const handleJobSites = (selection) => {

        setRole(selection.value)

    }

    const handleUsername = (event) => {
        setUserName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleFullName = (event) => {
        setFullName(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleAddress = (event) => {
        setAddress(event.target.value)
    }


    const JobSitesData = [
        { value: 'Select Facility', label: 'Select Facility', isdisable: "disable" },
        { value: 'bristol-village', label: 'Bristol Village' },
        { value: 'chillicothe-campus', label: 'Chillicothe Campus' },
        { value: 'Toledo', label: 'Toledo' },


    ]

    const [show, setShow] = useState(false);

    const handleClose = () => { setShow(false); setErrorMessages("") };
    const handleShow = () => setShow(true);

    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        // await axios.patch(ENV.ENDPOINT + `/myuser/edit/${userData.id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
        //     .then(response => {

        //         console.log(response.data.messages)

        //     })
        //     .catch((error) => {
        //         console.log(error.response.data)
        //     })

        await axios.patch(ENV.ENDPOINT + `/admin/edit/${data.admin_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                //console.log(response.data.payload)
                localStorage.setItem('avatar_image', response.data.payload.avatar_image)
                setTimeout(() => {
                    snackbar({

                        message: 'Profile Edited successfully'
                    });
                });
                navigate('/dashboard')

            })
            .catch((error) => {
                console.log(error.response.data)
            })

    }

    const handleImage = (e) => {
        console.log(e.size)
        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setErrorMessages({ name: "image", message: "" });
            setImage(e)
        }

    }


    const handleSubmit = (event) => {

        const getBase64 = (e) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {

                            let date_of_birth
                            if (neww) {

                                date_of_birth = neww
                            }
                            else {
                                const [month, day, year] = modeifiedDate.split('/');
                                const result = [year, month, day].join('-');
                                date_of_birth = result
                            }
                            let positionRole = ""
                            if (roles == "Facility Admin") {
                                positionRole = "facility_admin"
                            }
                            else if (roles == "Super Admin") {
                                positionRole = "c_level_emp"
                            }
                            else if (roles == "HMC Admin") {
                                positionRole = "hmc_admin"
                            }
                            else if (roles == "Facility Access") {
                                positionRole = "poc_admin"
                            }

                            let username = userName
                            let email = Email
                            let address = Address
                            let position = positionRole
                            let fullname = fullName
                            let role = positionRole


                            let password = oldpassword
                            let contact_no = phoneNumber
                            let avatar_image = response.data.location

                            const tokenn = apiCall({

                                username,
                                email,
                                address,
                                avatar_image,
                                position,
                                fullname,
                                contact_no,
                                date_of_birth,
                                role,
                                password
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })
                    console.log(encoded)

                };
                reader.onerror = error => reject(error);
            });
        }
        if (image != undefined) {
            getBase64(image)
        }
        else {
            let date_of_birth
            if (neww) {

                date_of_birth = neww
            }
            else {
                const [month, day, year] = modeifiedDate.split('/');
                const result = [year, month, day].join('-');
                date_of_birth = result
            }
            let positionRole = ""
            if (roles == "Facility Admin") {
                positionRole = "facility_admin"
            }
            else if (roles == "Super Admin") {
                positionRole = "c_level_emp"
            }
            else if (roles == "HMC Admin") {
                positionRole = "hmc_admin"
            }

            let username = userName
            let email = Email
            let address = Address
            let position = positionRole
            let fullname = fullName
            let role = positionRole


            let password = oldpassword
            let contact_no = phoneNumber
            let avatar_image = imageURL

            const tokenn = apiCall({

                username,
                email,
                address,
                avatar_image,
                position,
                fullname,
                contact_no,
                date_of_birth,
                role,
                password
            });
        }



        //navigate('/manage-users')

    }
    const [passwords, setPassword] = useState({
        oldPassword: "",
        createNewPassword: "",
        reenterNewPassword: ""

    })
    const handlePassword = (event) => {
        const value = event?.target?.value;
        //set it in the model
        setPassword({
            ...passwords,
            [event.target.name]: value
        });
        console.log(passwords.oldPassword)
    }

    const handleReset = (e) => {
        e.preventDefault()
        async function apiCall(credentials) {
            let token = localStorage.getItem('token')

            await axios.post(ENV.ENDPOINT + `/user/changepassword/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    axios.patch(ENV.ENDPOINT + `/admin/resetPassword/${data.admin_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            console.log(response.data)
                            handleClose()
                        })
                        .catch((error) => {
                            console.log(error.response)
                        })

                })
                .catch((error) => {
                    console.log(error.response.data.non_field_errors[0])
                    if (error.response.data.non_field_errors[0] == "Enter your correct current password") {
                        setErrorMessages({ name: "uname", message: "Enter your correct current password" });
                    }
                    if (error.response.data.non_field_errors[0] == "passwords not matched") {
                        setErrorMessages({ name: "uname", message: "Passwords not matched" });
                    }
                })
        }
        let old_password = Buffer.from(passwords.oldPassword).toString('base64')
        let password = Buffer.from(passwords.createNewPassword).toString('base64')
        let password2 = Buffer.from(passwords.reenterNewPassword).toString('base64')
        const token = apiCall({
            old_password,
            password,
            password2
        });

    }
    const [errorMessages, setErrorMessages] = useState({});
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

   
    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])

    console.log("data" , data)
    console.log("roles" , roles)

    return (
        <div className='edit-profile-main'>
            <div className='edit-user'>
                {(data && roles) ? (
                    <>
                        {/* <Form> */}
                        <div className="edit-user-top">
                            <div className='user-image'>
                                <div className='image-upload'>
                                    <ImageUploadInput UserImage={imageURL} handleImage={handleImage} />
                                </div>
                                {renderErrorMessage("image")}
                            </div>
                            <div className="edit-user-form">
                                <Row className="form-row">
                                    <Col md={12}>
                                        <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={userName} handleChange={handleUsername} />
                                    </Col>
                                    <Col md={12}>
                                        <InputGroupForm inputId="fullName" inputLabel="Full name" inputType="text" inputPlaceholder="Text here" inputName="fullName" inputValue={data.fullname} handleChange={handleFullName} />
                                    </Col>
                                    <Col md={12}>
                                        <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={data.email} handleChange={handleEmail} />
                                    </Col>



                                </Row>
                            </div>

                        </div>
                        <div className='edit-user-bottom edit-user-form'>
                            <Row className="form-row">
                                <Col md={6}>
                                    <div className='form-group'>
                                        <label className='form-label'>Role</label>
                                        <CustomDropdown optionData={RoleDropdownData} data={roles} handleChange={handleJobSites} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group date-of-birth-input">
                                        <label className='form-label'>Date of birth</label>
                                        <DateofBirthInput inputValue={modeifiedDate} handleChange={handledDate} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="phoneNumber" inputLabel="Phone" inputType="number" inputPlaceholder="Text here" inputName="phoneNumber" inputValue={data.contact_no} handleChange={handlePhoneNumber} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Address" inputLabel="Address" inputType="text" inputPlaceholder="Text here" inputName="Address" inputValue={data.address} handleChange={handleAddress} />
                                </Col>

                            </Row>
                        </div>
                        <div className='edit-user-footer'>
                            <div className='btn-wrap'>
                                <button type='button' className='btn border-btn' id='changePassword' onClick={handleShow}>Change Password</button>
                                <button className='btn btn-primary' onClick={handleSubmit}>Save Changes</button>
                            </div>
                            <div className='change-password-popup'>
                                <Modal show={show} onHide={handleClose} className='change-password-popup'>
                                    <Modal.Header closeButton>
                                        <h5>Change Password</h5>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-inner">
                                            <form>
                                                {renderErrorMessage("uname")}
                                                <InputGroupForm inputId="oldPassword" inputLabel="Enter old password" inputType="password" inputPlaceholder="*****" inputName="oldPassword" handleChange={handlePassword} />
                                                <div className='new-password-group'>
                                                    <InputGroupForm inputId="createNewPassword" inputLabel="Create new password" inputType="password" inputPlaceholder="Enter new password" inputName="createNewPassword" handleChange={handlePassword} />
                                                    <InputGroupForm inputId="reenterNewPassword" inputLabel="" inputType="password" inputPlaceholder="Re-enter new password" inputName="reenterNewPassword" handleChange={handlePassword} />
                                                </div>
                                                <div className='submit-btn'>
                                                    <input type="submit" className='btn btn-primary' value="Save changes" onClick={handleReset} />
                                                </div>
                                            </form>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        {/* </Form> */}
                    </>
                ) :  <div className={classe}></div>}

</div>
</div>
    )
}

export default EditProfileSuperAdminSection;