import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import TopQuotes from '../inner-components/TopQuotes';
import TopFilterDropdownsCol from '../inner-components/TopFilterDropdownsCol';
import { RoutesPath } from '../../App';


const DashboardTopSection = (props) => {
    return (
        <>
            <div className='dashboard-top-wrapper'>
                <Row>
                    <Col xl={7} lg={6} className="left-col-wrap">
                        <TopQuotes />
                    </Col>

                    <TopFilterDropdownsCol facilities={props.facilities} selectedFacility={localStorage.getItem('facility')} handleRefresh={props.handleRefresh} ref4={props.ref4} ref5={props.ref5} ref6={props.ref6}/>

                </Row>
            </div>
        </>
    )
}

export default DashboardTopSection;