import axios from "axios";
import moment from 'moment';
import {  useNavigate } from 'react-router-dom'
import { ENV } from '../../env/env'

export default {
    RenewTokenAPI: () => {
        // const navigate = useNavigate()
        const data = {
            refresh: localStorage.getItem('refreshToken')
        };
        var link = ENV.ENDPOINT + `/token/refresh/`;
        axios.post(link, data)
            .then(response => {

                localStorage.setItem('token', response.data.access);
                window.location.reload();
            })
            .catch((error) => {
                console.log(error.response);
                if (error.response.data.code == 'token_not_valid') {
                    console.log("renew token expired");
                    // localStorage.clear()
                    // navigate('/')
                    // window.location='/'
                }
            });
    }
}