import React, { useState, useEffect } from 'react';
import TimesheetsContent from './TimesheetsContent';
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';

const TimesheetsTopFilterSection = (props) => {
    const [value, setValue] = useState([
        new DateObject().setDay(1),
        new DateObject().add(1, "month").setDay(1),
    ]);
    const [dateRange, setDateRange] = useState({
        date1: "",
        date2: ""
    })


    useEffect(()=>{
       
    },[value])
    return (
        <>
            <div className='timesheets-main-section' ref={props.timehseetsref1}>
                <div className='user-tab-wrapper'>
                    <div className="user-tab-col">
                    {/* <Col lg="6" className="range-calnedar-with-graph">
                    <DatePicker
                        value={value}
                        onChange={setValue}
                        range
                        numberOfMonths={2}
                        plugins={[
                            <Footer position="bottom" />
                        ]}
                    />
                </Col> */}
                        <div className="col-inner">
                            <TimesheetsContent refresh={props.refresh} timehseetsref4={props.timehseetsref4} timehseetsref3={props.timehseetsref3} timehseetsref2={props.timehseetsref2} handleRefresh={props.handleRefresh} facilityName={props.facilityName} facilityId={props.facilityId}/>
                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TimesheetsTopFilterSection;