import React,{useEffect} from "react";
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import WorkforceTopHeaderSection from "../components/manage-user/ManageTopHeaderSection";
import WorkforceCalculatorSection from "../components/workforce-calculator/WorkforceCalculatorSection";
import { Row, Col } from 'react-bootstrap';


//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from './utils/container'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { log } from './utils/utils'
import useRouter from './utils/use-router'
import { Outlet, useNavigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'

const WorkforceCalculator = () => {

        //for agora chat and call login
        const navigate = useNavigate()
        const stateCtx = useGlobalState()
        const mutationCtx = useGlobalMutation()
    
        const routerCtx = useRouter()
    
        const history = useNavigate()
       
        useEffect(() => {
            if (stateCtx.loading === true) {
                mutationCtx.stopLoading()
            }
        }, [stateCtx.loading, mutationCtx])
    
        useEffect(() => {
            if (stateCtx.rtmClient.status === 'offLine') {
                stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
                    log(stateCtx.userCode, 'is online')
                }).catch(() => {
                    log('Login failure')
               
                    return
                })
            }
        }, [stateCtx.rtmClient])
    
        useEffect(() => {
    
    
            stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
    
                let content=JSON.parse(remoteInvitation.content)
     
                mutationCtx.updatePeerCode(remoteInvitation.callerId)
                mutationCtx.updateIncomingCode(content.name)
                mutationCtx.updateIncomingImage(content.image)
                mutationCtx.updateConfig({ channelName: content.channel })
    
                navigate({
                    pathname: `/call-invi/card/indexCard/incoming/`
                })
            })
    
            stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
              
                // navigate('/dashboard')
            })
    
            stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
                mutationCtx.toastError('Call not answered')
                navigate.goBack()
            })
    
            stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
                log('Accept success')
                mutationCtx.startLoading()
                let channel = stateCtx.config.channelName
                log('channel id', channel)
                navigate({
                    pathname: `/meeting/${channel}`
                })
            })
    
            stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
                mutationCtx.startLoading()
                let channel = stateCtx.config.channelName
                log('channel id', channel)
                navigate({
                    pathname: `/meeting/${channel}`
                })
            })
    
            stateCtx.rtmClient.on('LocalInvitationRefused', () => {
                mutationCtx.toastError('Peer is busy')
                navigate.goBack()
            })
    
            stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
             
                navigate('/call-invi')
            })
    
            stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
                navigate.goBack()
            })
    
            stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
                navigate({
                    pathname: `/dialling`
                })
            })
    
            stateCtx.rtmClient.on('LocalInvitationFailure', () => {
                mutationCtx.toastError('Call process failed')
                history.goBack()
            })
    
            return () => {
                stateCtx.rtmClient.eventBus.removeAllListeners()
                //   navigate('/card')
            }
    
        }, [stateCtx.rtmClient, stateCtx])


        return (
            <>
            <main className='app-main'>
            <HeaderMobile />
            <section className="main-section dashboard-section blue-bg-section manage-user-section">
            <div className="section-wrap">
            <Row className="dashboard-row-main">
            <LeftPanel />
            <Col lg="9" className="right-content-col grey-bg-col">
            <WorkforceTopHeaderSection pageTitle="Workforce calculator"/>
            <WorkforceCalculatorSection />
            </Col>
            </Row>
                        
            </div>
            </section>
            </main>
            </>
    )
}
export default WorkforceCalculator;
