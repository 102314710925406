import React, { useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function TextEditorBox(props) {
    const [value, setValue] = useState('');
   
    return (
        <ReactQuill defaultValue={props.editorValue} onChange={props.onChange} readOnly={false}/>
        );

    }


    export default TextEditorBox;