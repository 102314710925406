import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AllCustomIcon from '../../JsonDataFile/AllCustomIcon';
import { RoutesPath } from '../../App';
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'
import ReusableAPI from './NotificationApi';


const ShiftRequestpopup = (props) => {

    const [show, setShow] = useState(false);

    const [refresh, setRefresh] = useState(false)
    const [requestShifts, setRequestShifts] = useState()
    const [pendingData, setPendingData] = useState()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    async function editRequestAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.patch(ENV.ENDPOINT + `/scheduling/edit/${credentials.id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                let token = localStorage.getItem('token')
                axios.get(ENV.ENDPOINT + `/myuser/?user_id=${credentials.clinician_id}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        var notificationMsg = `Timesheet Rated`
                        ReusableAPI.notificationTriggerAPI(notificationMsg, response.data.results[0].id)

                    })
                    .catch((error) => {
                        console.log(error)
                    })
                //Triggering event notification
                // var notificationMsg = `${response.data.clinician_position} Shift Request ${response.data.status}`
                // ReusableAPI.notificationTriggerAPI(notificationMsg)


                setRefresh(true)
            })
            .catch((error) => {
                console.log(error.response)
            })
    }

    async function activityAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.post(ENV.ENDPOINT + `/activities/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setRefresh(true)
            })
            .catch((error) => {
                console.log(error.response)
            })
    }
    const fcmData=(msg,id)=>{
        //sending notification to mobile
        const credentials = {

            peerId: localStorage.getItem('user_id'),
            peerName: localStorage.getItem("facility"),
            channelId: localStorage.getItem("facility"),
            message: msg,
            message_type: "text",
            image: localStorage.getItem('avatarImage'),
            receiverId: id,

        }
        let token = localStorage.getItem('token')
        axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                
            })
            .catch((error) => {
               
            })
    }
    const handleRequest = (e, val) => {

        let status = e.target.value
        let id = val.id
        let clinician_name = val.requestClinicians
        let clinician_position = val.clinician_position
        let avatar_image = val.avatar_image
        let clinician_id = val.clinician_id
        const api = editRequestAPI({
            status,
            id,
            clinician_id,
            clinician_name,
            clinician_position,
            status,
            avatar_image

        });

        let activity_title = `Shift request ${status}`
        let activity_status = 'success'
        let acitivity_date = moment().format('YYYY-MM-DD')
        let facility_id = localStorage.getItem('facility')
        let category = 'Shift request approve'
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/myuser/?user_id=${clinician_id}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
          
            var notificationMsg = `Shift request ${status}`
            ReusableAPI.notificationTriggerAPI(notificationMsg,response.data.results[0].id)

            fcmData(`Shift request ${status}`,response.data.results[0].id)
        })
        .catch((error) => {
            console.log(error)
        })
        const activity = activityAPI({
            activity_title,
            activity_status,
            acitivity_date,
            facility_id,
            category
        });
        props.handleAllRender(true)
        setCount(false)
    }
    const [requestData, setRequestData] = useState()
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const [seconds, setSeconds] = useState(0);
    const [count,setCount]=useState(false)

    useEffect(() => {
        if(!count){
            
                let list = []
                let token = localStorage.getItem('token')
                axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        const requestData1 = response.data.results.map(function (row) {
                            let date = row.start_date.split("T")
                            date = new Date(date)
                            let time = moment(row.start_time)
                            let endtime = moment(row.end_time)
                            return {
                                id: row.schedule_id, color: "#408cff", requestTime: time.format("hh:mm a") + "-" + endtime.format("hh:mm a"), requestDate: moment(row.start_date).format('MMM DD, YYYY'), requestClinicians: row.clinician_name, position: row.clinician_position,
                                status: row.status, clinician_id: row.clinician_id, requesting_users: row.requesting_users,
                            }
                        })
                        const data = []
                        requestData1.forEach((el) => {
        
                            // if (el.status == "Pending") {
                            //     data.push(el)
                            //     list.push(el)
                            // }
                            if (el.requesting_users != undefined && el.status == 'Open') {
                                data.push(el)
                                list.push(el)
                            }
                        })
                        // setPendingData(list.length)
        
                        setRequestData(data)
        
                        setRefresh(false)
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                    })
                    setCount(true)
               
        }else{
           
            const interval = setInterval(() => {
                let list = []
                let token = localStorage.getItem('token')
                axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        const requestData1 = response.data.results.map(function (row) {
                            let date = row.start_date.split("T")
                            date = new Date(date)
                            let time = moment(row.start_time)
                            let endtime = moment(row.end_time)
                            return {
                                id: row.schedule_id, color: "#408cff", requestTime: time.format("hh:mm a") + "-" + endtime.format("hh:mm a"), requestDate: moment(row.start_date).format('MMM DD, YYYY'), requestClinicians: row.clinician_name, position: row.clinician_position,
                                status: row.status, clinician_id: row.clinician_id, requesting_users: row.requesting_users,
                            }
                        })
                        const data = []
                        requestData1.forEach((el) => {
        
                            // if (el.status == "Pending") {
                            //     data.push(el)
                            //     list.push(el)
                            // }
                            if (el.requesting_users != undefined && el.status == 'Open') {
                                data.push(el)
                                list.push(el)
                            }
                        })
                        // setPendingData(list.length)
        
                        setRequestData(data)
        
                        setRefresh(false)
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                    })
        
                }, 15000);
                return () => clearInterval(interval);
            }
    }, [refresh,props,count])

    useEffect(() => {
        if (requestData != undefined) {
            let list = []
            let length = []
            requestData.map(function (row) {
                const requestUserData = row.requesting_users.map(function (row1) {
                    length.push(row1)
                    let token = localStorage.getItem('token')
                    axios.get(ENV.ENDPOINT + `/clinician/view/?clinician_id=${row1}`, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {

                            const fff = {
                                id: row.id, color: "#408cff", requestTime: row.requestTime, requestDate: row.requestDate, requestClinicians: response.data.results[0].firstname + " " + response.data.results[0].lastname, position: response.data.results[0].clinician_position,
                                status: row.status, clinician_id: response.data.results[0].clinician_id, avatar_image: response.data.results[0].avatar_image,
                            }

                            list.push(fff)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })

            })
            setPendingData(length.length)
            setRequestShifts(list)
           

        }

    }, [requestData])
    if (requestShifts != undefined) {

    }


    return (
        <>
            {requestShifts != undefined ? (
                <div className="popup-wrapper" ref={props.Schedulingref4}>
                    <Button className="border-btn" onClick={handleShow}>Shifts Request <span className='request-count'>{pendingData}</span></Button>
                    <Modal show={show} onHide={handleClose} className='shift-request-popup custom-popup'>
                        <Modal.Header closeButton>
                            <h5>Shifts Request</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='shift-request-list'>
                                {requestShifts.map((val, index) =>

                                    <div className={`shift-request-single ${val.color}`} key={index}>
                                        <div className='request-inner'>
                                            <div className='title-wrap'>
                                                <h6>{val.position}</h6>
                                                <span className="request-status">{val.status}</span>
                                            </div>
                                            <div className='shift-request-content'>
                                                <ul>
                                                    <li>
                                                        <div className='label'>
                                                            {AllCustomIcon.iconClock}
                                                            <span>Time:</span>
                                                        </div>
                                                        <div className='data-wrap'>
                                                            <span>{val.requestDate}</span>
                                                            <span>{val.requestTime}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className='label'>
                                                            {AllCustomIcon.iconUser}
                                                            <span>Clinician:</span>
                                                        </div>
                                                        <div className='data-wrap'>
                                                            <span>{val.requestClinicians}</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='btn-wrap'>
                                                <button className='btn btn-red btn-small' value={"Declined"} id={val.id} clinician_id={val.clinician_id} onClick={e => handleRequest(e, val)}>Decline</button>
                                                <button className='btn btn-primary btn-small' value={"Approved"} id={val.id} clinician_id={val.clinician_id} onClick={e => handleRequest(e, val)}>Approve</button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                        </Modal.Body>

                    </Modal>
                </div>
            ) : ""}
        </>

    )
}

export default ShiftRequestpopup;