
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { RoutesPath } from '../../App';
import { useNavigate,Link } from 'react-router-dom';
import axios from "axios";
import { ENV } from '../../env/env'
import ReusableAPI from './NotificationApi';

const CliniciansTableMenuDropdown = (props) => {
    let token=localStorage.getItem('token')
    const navigate = useNavigate();
    const handleStatus=(data)=>{
        console.log(data)
        async function apiCall(credentials) {
            let token=localStorage.getItem('token')
            
            await axios.patch(ENV.ENDPOINT+`/clinician/applyShift/${data.clinician_id}/`,credentials,{headers:{"Authorization":`Bearer ${token}`}})
            .then(response=>{
                console.log(response.data.payload)
                props.handleRender()
            })
            .catch((error)=>{
                console.log(error.response.data)
            })
         }
         
        let is_active=""
        if(data.is_active==true){
            is_active=false
        }
        else{
            is_active=true
        }
        
        const token =  apiCall({
            is_active
        });
    }

    const handleDelete=(data)=>{
        axios.delete(ENV.ENDPOINT+`/clinician/delete/${data.clinician_id}/`,{headers:{"Authorization":`Bearer ${token}`}})
        .then(response=>{
            console.log(response.data) 
            props.handleRender()

             //Triggering event notification
             var notificationMsg=`Deleted ${data.firstname+" "+data.lastname} Clinician`
             ReusableAPI.notificationTriggerAPI(notificationMsg)

        })
        .catch((error)=>{
            console.log(error.response)
        })
    }

    return (
        <>
            <Dropdown className="user-table-menu-dropdown custom-dropdown">
                <Dropdown.Toggle id="tableMenuDropdown">
                    <i className='icon-toggle-menu'></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {/* <LinkContainer to={RoutesPath.managecliniciansEdit}> */}
                    <Dropdown.Item className="view-btn" onClick={()=>navigate(RoutesPath.managecliniciansEdit,{state:{data:props.data}})}>
                            <i className='icon-eye'></i> View
                        </Dropdown.Item>
                    {/* </LinkContainer> */}
                    <Dropdown.Item onClick={()=>navigate(RoutesPath.managecliniciansEdit,{state:{data:props.data}})}>
                        <i className='icon-edit'></i> Edit
                    </Dropdown.Item>
                    {localStorage.getItem('position')=='c_level_emp'?(
                        props.data.is_active==true?(
                            <Dropdown.Item href=""  onClick={() => {handleStatus(props.data)}}>
                            <i className='icon-disable'></i> Disable
                        </Dropdown.Item>
                        ):(
                            <Dropdown.Item href=""  onClick={() => {handleStatus(props.data)}}>
                            <i className='icon-disable'></i> Activate
                        </Dropdown.Item>
                        )
                    ):""}
                    
                    <Dropdown.Item href="" onClick={()=>{handleDelete(props.data)}}>
                        <i className='icon-trash'></i> Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>


        </>
    )
}

export default CliniciansTableMenuDropdown;