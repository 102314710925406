// import React, { useState, useEffect } from 'react';
// import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { RoutesPath } from '../../App';
// import PieChart, {
//   Legend,
//   Series,
//   Export,
//   Label,
//   SmallValuesGrouping,
//   Connector,
// } from 'devextreme-react/pie-chart';
// import axios from "axios";
// import moment from 'moment';
// import { ENV } from '../../env/env'

// const NurseTierRequestPieChart = (props) => {

//   const customPalette = ['#2753b8', '#e22960', '#893deb', '#ff9429', '#0ea80f'];


//   // const [nurseTierRequestRate, setNurseTierRequestRate] = useState()

//   useEffect(() => {
//     let date1 = props.dateRange.date1
//     let date2 = props.dateRange.date2
//     let filterParam = ""
//     if (localStorage.getItem('position') == 'facility_admin') {
//       filterParam = localStorage.getItem('facility_id')
//     }
//     let CNAList = []
//     let RNList = []
//     let CMAList = []
//     let STNAList = []
//     let LPNList = []

//     let token = localStorage.getItem('token')
//     axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
//       .then(response => {
//         console.log(response.data.results)
//         let dateData = []
//         const dateRange = response.data.results.map(function (rowDate) {
//           if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

//             if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
//               dateData.push(rowDate)
//             }
//           }
//           else {
//             console.log('false')
//           }
//         })

//         dateData.forEach((el) => {
//           // totalList.pus
//           if (el.clinician_position == "CNA") {

//             CNAList.push(el)
//             console.log(el, "CNA")
//           }
//           if (el.clinician_position == "RN") {

//             RNList.push(el)
//             console.log(el, "RN")
//           }
//           if (el.clinician_position == "CMA") {

//             CMAList.push(el)
//             console.log(el, "CMA")
//           }
//           if (el.clinician_position == "STNA") {

//             STNAList.push(el)
//             console.log(el, "STNA")
//           }
//           if (el.clinician_position == "LPN") {

//             LPNList.push(el)
//             console.log(el, "LPN")
//           }

//         })
//         console.log(CNAList.length, "List")
//         console.log(RNList.length, "List")
//         console.log(CMAList.length, "List")
//         console.log(STNAList.length, "List")
//         console.log(LPNList.length, "List")


//         const nurseTierRequestRate1 = [{
//           nurseTier: 'RN',
//           requestRate: RNList.length,
//         }, {
//           nurseTier: 'CNA',
//           requestRate: CNAList.length,
//         }, {
//           nurseTier: 'STNA',
//           requestRate: STNAList.length,
//         }, {
//           nurseTier: 'STNA/LPN',
//           requestRate: LPNList.length + STNAList.length,
//         }, {
//           nurseTier: 'CMA',
//           requestRate: CMAList.length,
//         }];
//         // setNurseTierRequestRate(nurseTierRequestRate1)

//       })
//       .catch((error) => {
//         console.log(error.response.data)
//       })

//   }, [props.dateRange])

//   const nurseTierRequestRate = [{
//       nurseTier: 'RN',
//       requestRate: 10,
//     }, {
//       nurseTier: 'CNA',
//       requestRate: 32,
//     }, {
//       nurseTier: 'STNA',
//       requestRate: 4,
//     }, {
//       nurseTier: 'STNA/LPN',
//       requestRate: 32,
//     }, {
//       nurseTier: 'CMA',
//       requestRate: 24,
//     }];

//     // pointClickHandler(arg) {
//     //   arg.target.select();
//     // }

//   return (
//     <Col lg="6" className='mt-0 mt-lg-5 pt-5'>
//       <div className='graph-box'>
//         <div className='graph-title d-flex align-items-center justify-content-between'>
//           <h6 className='mb-0'>Nurse Tier Request Rate</h6>
//           {/* <Button variant="link" className='link-btn'>View detail</Button> */}
//           <Link to={RoutesPath.nurseTierRequestRate} className="btn btn-link link-btn">View detail</Link>
//         </div>

//         <PieChart
//           id="pie"
//           type="doughnut"
//           // title="Top Internet Languages"
//           palette={customPalette}
//           dataSource={nurseTierRequestRate}
//         >
//           <Series argumentField="nurseTier" valueField="requestRate">
//             {/* <SmallValuesGrouping mode="topN" topCount={3} /> */}
//             <Label
//               visible={true}
//               format="fixedPoint"
//             // customizeText={this.customizeLabel}
//             >
//               <Connector visible={true} width={1} />
//             </Label>
//           </Series>
//           <Export enabled={true} />
//           <Legend horizontalAlignment="right" verticalAlignment="top" />
//         </PieChart>
//       </div>
//     </Col>
//   )
// }

// export default NurseTierRequestPieChart;



import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutesPath } from '../../App';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'


ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


const NurseTierRequestPieChart = (props) => {

  const customPalette = ['#2753b8', '#e22960', '#893deb', '#ff9429', '#0ea80f'];


  //   pointClickHandler(arg) {
  //     arg.target.select();
  //   }
  // const [nurseTierRequestRate, setNurseTierRequestRate] = useState()
  const [data,setData]=useState()
  const[label,setLabel]=useState()
  useEffect(() => {
    let date1 = props.dateRange.date1
    let date2 = props.dateRange.date2
    let filterParam = ""
    if (localStorage.getItem('position') == 'facility_admin') {
      filterParam = localStorage.getItem('facility_id')
    }
    let CNAList = []
    let RNList = []
    let CMAList = []
    let STNAList = []
    let LPNList = []

    let token = localStorage.getItem('token')
    axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
      
        let dateData = []
        const dateRange = response.data.results.map(function (rowDate) {
          if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

            if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
              dateData.push(rowDate)
            }
          }
         
        })

        dateData.forEach((el) => {
          // totalList.pus
          if (el.clinician_position == "CNA") {

            CNAList.push(el)
           
          }
          if (el.clinician_position == "RN") {

            RNList.push(el)
         
          }
          if (el.clinician_position == "CMA") {

            CMAList.push(el)
            
          }
          if (el.clinician_position == "STNA") {

            STNAList.push(el)
          
          }
          if (el.clinician_position == "LPN") {

            LPNList.push(el)
           
          }

        })
      
        const label1=['RN','CNA','STNA','LPN/STNA','CMA']


        const nurseTierRequestRate1 = [
      
          RNList.length,
        
          CNAList.length,
        
          STNAList.length,
        
          LPNList.length + STNAList.length,
        
          CMAList.length,
        ];
        // setNurseTierRequestRate(nurseTierRequestRate1)
        setData(nurseTierRequestRate1)
        setLabel(label1)
      
      })
      .catch((error) => {
        console.log(error.response.data)
      })

  }, [props.dateRange])



  const options = {

    cutout: "80%",
    plugins: {
      datalabels: {
        backgroundColor: "#fff",
        borderColor: '#c2c2c2',
        borderRadius: 25,
        borderWidth: 1,
        color: '#000',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          // var valuePercentage = `${value}%`;

          return `${value}%`;
        },
        font: {
          weight: '500'
        },
        padding: 8,
        
      },
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 30
        }
      },
    }
  };

  const spaceAddPlugin = {


    id: 'legendDistance',
    beforeInit(chart, args, opts) {
     
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Specify what you want to change, whether the height or width
        this.height = this.height + 100;
      }
    }


  };
  const nurseTierRequestRate = {
    labels: label?label:[],
    datasets: [{
      label: 'My First Dataset',
      data: data?data:[],
      backgroundColor: customPalette,
      spacing: 6,
      borderRadius: 60,
      borderWidth: 0,
      datalabels: {
        anchor: 'end'
      }
    }]
  };


  return (
    <Col lg="6" className='mt-0 mt-lg-5 pt-5'>
      <div className='graph-box'>
        <div className='graph-title d-flex align-items-center justify-content-between'>
          <h6 className='mb-0'>Nurse Tier Request Rate</h6>
          {/* <Button variant="link" className='link-btn'>View detail</Button> */}
          <Link to={RoutesPath.nurseTierRequestRate} className="btn btn-link link-btn">View detail</Link>
        </div>
        <Doughnut data={nurseTierRequestRate} options={options} plugins={[ChartDataLabels][spaceAddPlugin]} />
      </div>
    </Col>
  )
}

export default NurseTierRequestPieChart;