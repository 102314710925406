// import React, { useState, useEffect } from 'react';
// import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
// import PieChart, {
//     Legend,
//     Series,
//     Export,
//     Label,
//     SmallValuesGrouping,
//     Connector,
//     sizeGroup
//   } from 'devextreme-react/pie-chart';
//   import axios from "axios";
//   import { ENV } from '../../env/env'

// const FacilityEyeBirdsGraph = (props) => {

//     const customPalette = ['#2753B8', '#0EA80F', '#E42961', '#FF9429'];

//     const [facilityBirdsRate,setFacilityBirdsRate]=useState()

//     useEffect(()=>{

//       let filterParam=""
//         if(localStorage.getItem('position')=='facility_admin'){
//           filterParam=localStorage.getItem('facility')
//         }
//       let CNAList=[]
//       let RNList=[]

//       let STNAList=[]
//       let LPNList=[]

//       let token = localStorage.getItem('token')
//       axios.get(ENV.ENDPOINT + `/scheduling/view/?search=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
//       .then(response => {
//           console.log( response.data.results)


//           response.data.results.forEach((el) => {
//               // totalList.pus
//               if (el.clinician_position == "CNA") {

//                  CNAList.push(el)
//                   console.log(el,"CNA")
//               }
//               if (el.clinician_position == "RN") {

//                 RNList.push(el)
//                  console.log(el,"RN")
//               }
//               // if (el.clinician_position == "CMA") {

//               //   CMAList.push(el)
//               //     console.log(el,"CMA")
//               // }
//               if (el.clinician_position == "STNA") {

//                 STNAList.push(el)
//                   console.log(el,"STNA")
//               }
//               if (el.clinician_position == "LPN") {

//                 LPNList.push(el)
//                   console.log(el,"LPN")
//               }

//           })
//           console.log(CNAList.length,"List")
//           console.log(RNList.length,"List")
//           //console.log(CMAList.length,"List")
//           console.log(STNAList.length,"List")
//           console.log(LPNList.length,"List")


//           const nurseTierRequestRate1 = [{
//             facilityType: 'RN',
//             requestRate: RNList.length,
//           }, {
//             facilityType: 'CNA',
//             requestRate: CNAList.length,
//           }, {
//             facilityType: 'STNA',
//             requestRate: STNAList.length,
//           }, {
//             facilityType: 'LPN',
//             requestRate: LPNList.length,
//           },
//           //  {
//           //   facilityType: 'CMA',
//           //   requestRate: CMAList.length,
//           // }
//         ];
//           setFacilityBirdsRate(nurseTierRequestRate1)

//       })
//       .catch((error) => {
//           console.log(error.response.data)
//       })

//   },[])


//     // const facilityBirdsRate = [{
//     //     facilityType: 'RN',
//     //     requestRate: 16,
//     //   },
//     //   {
//     //     facilityType: 'CMA',
//     //     requestRate: 24,
//     //   }, 
//     //   {
//     //     facilityType: 'CNA',
//     //     requestRate: 32,
//     //   }, {
//     //     facilityType: 'LVN',
//     //     requestRate: 45,
//     //   }];


//       return (

//             <div className='graph-box'>
//                 <PieChart
//                   id="pie"
//                   type="doughnut"
//                   // title="Top Internet Languages"
//                   palette={customPalette}
//                   dataSource={facilityBirdsRate}
//                 >
//                   <Series argumentField="facilityType" valueField="requestRate">
//                     {/* <SmallValuesGrouping mode="topN" topCount={3} /> */}
//                     <Label
//                       visible={true}
//                       format="fixedPoint"
//                       // customizeText={this.customizeLabel}
//                     >
//                       <Connector visible={true} width={1} />
//                     </Label>
//                   </Series>
//                   <Export enabled={true} />
//                   <Legend horizontalAlignment="center" verticalAlignment="bottom" />
//                 </PieChart>
//             </div>

//     )
// }

// export default FacilityEyeBirdsGraph;


import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
import { ENV } from '../../env/env'
import RenewToken from '../inner-components/RenewToken';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);
// import PieChart, {
//     Legend,
//     Series,
//     Export,
//     Label,
//     SmallValuesGrouping,
//     Connector,
//     sizeGroup
//   } from 'devextreme-react/pie-chart';


const FacilityEyeBirdsGraph = (props) => {

  const customPalette = ['#2753B8', '#0EA80F', '#E42961', '#FF9429'];

  // const facilityBirdsRate = [{
  //     facilityType: 'RN',
  //     requestRate: 16,
  //   },
  //   {
  //     facilityType: 'CMA',
  //     requestRate: 24,
  //   }, 
  //   {
  //     facilityType: 'CNA',
  //     requestRate: 32,
  //   }, {
  //     facilityType: 'LVN',
  //     requestRate: 45,
  //   }];

  const [facilityBirdsRate1, setFacilityBirdsRate1] = useState()
  const [label, setLabel] = useState()
  const [url, setUrl] = useState('')
  const [allzeros,setAllZeros]=useState()
  useEffect(() => {

    setTimeout(function () {

      let filterParam = ""
    if (localStorage.getItem('position') == 'facility_admin') {
      filterParam = localStorage.getItem('facility_id')
    }
    let CNAList = []
    let RNList = []

    let STNAList = []
    let LPNList = []

    let token = localStorage.getItem('token')
    axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
        console.log(response.data.results,"responseeeeeeeeeeeeeeee")
        

        response.data.results.forEach((el) => {
          // totalList.pus
          if (el.clinician_position == "CNA") {

            CNAList.push(el)
            console.log(el, "CNA")
          }
          if (el.clinician_position == "RN") {

            RNList.push(el)
            console.log(el, "RN")
          }
          // if (el.clinician_position == "CMA") {

          //   CMAList.push(el)
          //     console.log(el,"CMA")
          // }
          if (el.clinician_position == "STNA") {

            STNAList.push(el)
            console.log(el, "STNA")
          }
          if (el.clinician_position == "LPN") {

            LPNList.push(el)
            console.log(el, "LPN")
          }

        })
        console.log(CNAList.length, "List")
        console.log(RNList.length, "List")
        //console.log(CMAList.length,"List")
        console.log(STNAList.length, "List")
        console.log(LPNList.length, "List")

        const label1 = ['RN', 'CNA', 'STNA', 'LPN']
        const nurseTierRequestRate1 = [
          RNList.length,
          CNAList.length,
          STNAList.length,
          LPNList.length,
        ];
        setLabel(label1)
        setFacilityBirdsRate1(nurseTierRequestRate1)
       
        const zero=nurseTierRequestRate1.every(item=>item===0);
        console.log(nurseTierRequestRate1,zero,"dataaaaa")
        setAllZeros(zero)
      })
      .catch((error) => {
        console.log(error.response.data)
        if (error.response.data.code == 'token_not_valid') {
          RenewToken.RenewTokenAPI()
        }
      })
      
  }, 1800);

    

  }, [url])

  const options = {

    cutout: "80%",
    plugins: {
      datalabels: {
        backgroundColor: "#fff",
        borderColor: '#c2c2c2',
        borderRadius: 25,
        borderWidth: 1,
        color: '#000',
        display: function (context) {
          var dataset = context.dataset;
          var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > count * 1.5;
        },
        font: {
          weight: '500'
        },
        padding: 8,
        formatter: Math.round
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 30
        }
      },
    }
  };

  const spaceAddPlugin = {


    id: 'legendDistance',
    beforeInit(chart, args, opts) {
      console.log('be');
      // Get reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Specify what you want to change, whether the height or width
        this.height = this.height + 100;
      }
    }


  };


 
  const facilityBirdsRate = {
    labels: label,
    datasets: [{
      label: 'My First Dataset',
      data: facilityBirdsRate1,
      backgroundColor: customPalette,
      spacing: 6,
      borderRadius: 60,
      borderWidth: 0,
      datalabels: {
        anchor: 'end'
      }
    }]
  };


  return (
   
    <div className='graph-box'>
      {allzeros!=true?<Doughnut data={facilityBirdsRate} options={options} plugins={[ChartDataLabels][spaceAddPlugin]} />:"No Data Available"}
      </div>
  )
}



export default FacilityEyeBirdsGraph;