import React, { useState, useEffect, useRef } from "react";
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import DashboardTopSection from "../components/dashboard/DashboardTopSection";
import DashboardMiddleSection from "../components/dashboard/DashboardMiddleSection";
import DashboardTableSection from "../components/dashboard/DashboardTableSection";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { ENV } from "../env/env";
import RenewToken from "../components/inner-components/RenewToken";
import { Button, Space, DatePicker, Modal, Tour } from "antd";


//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from "./utils/container";
import { log } from "./utils/utils";
import useRouter from "./utils/use-router";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { TextRotateUp } from "@material-ui/icons";
import useLocalStorage from "./utils/useLocalStorage";
import { useWindowSize } from "./utils/useWindowSize";
import Cookies from "js-cookie";




const Dashboard = (props) => {
  const [facilities, setFacilities] = useState([]);
  const [profileImage, setProfileImage] = useState();
  const [url, setUrl] = useState("");
  const [userData, setUserData] = useState({
    address: "",
    avatar_image: "",
    contact_no: "",
    date_of_birth: "",
    email: "",
    firstname: "",
    id: "",
    lastname: "",
    password: "",
    position: "",
    username: "",
    fullname: "",
  });

 const size = useWindowSize()
 

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + "/user/profile/", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserData(response.data.msg);
       // console.log("response.data" , response)
        localStorage.setItem("username1", response.data.msg.username);

        localStorage.setItem("position", response.data.msg.position);
        var link;
        if (response.data.msg.Admin != undefined) {
          link =
            ENV.ENDPOINT + `/admin/view/?admin_id=${response.data.msg.Admin}`;
        } else if (
          response.data.msg.user_id != undefined &&
          response.data.msg.position == "clinician"
        ) {
          link =
            ENV.ENDPOINT +
            `/clinician/view/?search=${response.data.msg.user_id}`;
        }
        axios
          .get(link, { headers: { Authorization: `Bearer ${token}` } })
          .then((response) => {
            localStorage.setItem(
              "avatarImage",
              response.data.results[0].avatar_image
            );
            setProfileImage(response.data.results[0].avatar_image);
            setUrl(2);

            localStorage.setItem(
              "scheduling",
              response.data.results[0].allow_scheduling
            );
            if (
              localStorage.getItem("position") == "facility_admin" ||
              localStorage.getItem("position") == "poc_admin"
            ) {
              localStorage.setItem(
                "facility_id",
                response.data.results[0].facility
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code == "token_not_valid") {
          RenewToken.RenewTokenAPI();
        }
      });

    axios
      .get(ENV.ENDPOINT + "/facility/", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setFacilities(response.data.payload);
        if (
          (!localStorage.getItem("facility") &&
            localStorage.getItem("position") == "poc_admin") ||
          localStorage.getItem("position") == "facility_admin"
        ) {
          response.data.payload.map(function (facility) {
            if (facility.facility_id == localStorage.getItem("facility_id")) {
              localStorage.setItem("facility", facility.facility_name);
            }
          });
        } else if (!localStorage.getItem("facility")) {
          localStorage.setItem(
            "facility",
            response.data.payload[0].facility_name
          );
          localStorage.setItem(
            "facility_id",
            response.data.payload[0].facility_id
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [url]);

  const [refresh, setRefresh] = useState(false);
  const handleRefresh = (e) => {
    setRefresh(e);
  };

  //for agora chat and call login
  const navigate = useNavigate();
  const stateCtx = useGlobalState();
  const mutationCtx = useGlobalMutation();

  const routerCtx = useRouter();

  const history = useNavigate();

  // stateCtx.rtmClient.inquire(['3']).then((res) => {
  //   console.log("responseeeeeeeeee",res)
  // })
  // .catch((err)=>{
  //   console.log(err,"response error")
  // })
  useEffect(() => {
    if (stateCtx.loading === true) {
      mutationCtx.stopLoading();
    }
  }, [stateCtx.loading, mutationCtx]);

  useEffect(() => {
    //   if (stateCtx.rtmClient.status === 'offLine') {
    //     stateCtx.rtmClient.login(stateCtx.userCode).then(() => {

    //     }).catch(() => {
    //         log('Login failure from dashboard')
    //         // mutationCtx.toastError('Logon failure')
    //         return
    //     })
    // }
    setTimeout(() => {
      stateCtx.rtmClient.login(localStorage.getItem("usercode")).then(() => {});
    }, 2000);
  }, [stateCtx.rtmClient]);

  useEffect(() => {
    stateCtx.rtmClient.on("RemoteInvitationReceived", (remoteInvitation) => {
      let content = JSON.parse(remoteInvitation.content);

      mutationCtx.updatePeerCode(remoteInvitation.callerId);
      mutationCtx.updateIncomingCode(content.name);
      mutationCtx.updateIncomingImage(content.image);
      mutationCtx.updateConfig({ channelName: content.channel });

      navigate({
        pathname: `/call-invi/card/indexCard/incoming/`,
      });
    });

    stateCtx.rtmClient.on("RemoteInvitationCanceled", () => {
      console.log("remote invitation cancelled");
      // navigate('/dashboard')
    });

    stateCtx.rtmClient.on("RemoteInvitationFailure", () => {
      mutationCtx.toastError("Call not answered");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationAccepted", () => {
      log("Accept success");
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationAccepted", () => {
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationRefused", () => {
      mutationCtx.toastError("Peer is busy");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationRefused", () => {
      console.log("call declined from inter-coding-card");
      navigate("/call-invi");
    });

    stateCtx.rtmClient.on("LocalInvitationCanceled", () => {
      navigate.goBack();
    });

    stateCtx.rtmClient.on("LocalInvitationReceivedByPeer", () => {
      navigate({
        pathname: `/dialling`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationFailure", () => {
      mutationCtx.toastError("Call process failed");
      history.goBack();
    });

    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners();
      //   navigate('/card')
    };
  }, [stateCtx.rtmClient, stateCtx]);

  // walkThrought 

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const steps = [
    {
      description:
        "We have here your dash board, where you can see everything in your facility at a glance!",
      target: () => ref1.current,
      placement: "right",
    },
    {
      description:
        "The overview section gives you details to assess the current state of your facility with just one look",
      target: () => ref2.current,
     
    },
    {
      description: "Here is a simplified version of the calendar system where we can see the clinicians who work on a specific day and time they will be working.",
      target: () => ref3.current,
      placement: "right",
    },
    {
      description: "You can find the notifications to get the latest updates here and also messaging if you ever need to react out to your clinicians or ondek health coordinators",
      target: () => ref4.current,
     
      
    },
    {
      description: "You can fin the notifications here and get the latest shift",
      //nextButtonProps : { children: ref5.current, onClick: () => navigate("/scheduling") },
      target: () => ref5.current,
    },
    {
      description: "And messaging here if you ever need to instantly reach our to your clinicians or our Ondek health coordinators",
      target: () => ref6.current,

    },
    {
      description: "Recent activities shows, and logs every engagemant may it be payments, shifts being approved, or a clinician marked as a new offer instantly see it here on the recent activities section.",
      target: () => ref7.current,
    },
  ];



  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

 

  const showModal = () => {
    setIsModalOpen(true);
   
  };
 


  useEffect(() => {
    if(size.width >= 1250){
    const isModalOpen = Cookies.get("modal") === "open";
    if (!isModalOpen ) {
      Cookies.set("modal", "open", { expires: 365, path: "" });
      const timeoutId = setTimeout(() => {
        showModal();
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
     }
    }
  }, [size.width]);

  const handleCloseBtn = () => {
    setOpen(true);
    handleCancel();
  };

  return (
    <>
      <main className="app-main">
      {localStorage.getItem("scheduling") ? <HeaderMobile /> : ""}

      <section className="main-section dashboard-section blue-bg-section">
      <div className="section-wrap">
      <Row className="dashboard-row-main">
      <Modal
                centered
                bodyStyle={{
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                  boxShadow: "none",
                }}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                >
                <Button
                  type="primary"
                  onClick={handleCloseBtn}
                  className="p-8 flex justify-start items-center text-[18px] rounded-xl"
                  >
                  Welcome to Shift alert
                  </Button>
              </Modal>
              {localStorage.getItem("scheduling") ? (
                <LeftPanel profileImage={profileImage} ref1={ref1}/>
              ) : (
                ""
              )}
              <Col lg="9" className="right-content-col grey-bg-col">
                         
                <DashboardTopSection
                  facilities={facilities}
                  handleRefresh={handleRefresh}
                  ref4={ref4}
                  ref5={ref5}
                  ref6={ref6}
                />
                <DashboardMiddleSection
                  handleRefresh={handleRefresh}
                  refresh={refresh}
                  ref2={ref2}
                  ref3={ref3}
                />
                 <div   >
                <DashboardTableSection
                  handleRefresh={handleRefresh}
                  refresh={refresh}
                  ref7={ref7}
                />
                </div>

              </Col>
            </Row>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps}  indicatorsRender={(current, total) => (
         <></>
        )}/>
          </div>
        </section>
      </main>
    </>
  );
};

export default Dashboard;
