import React, { useState, useEffect } from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import FacilityTabContentSuperAdmin from './FacilityTabContentSuperAdmin';
import CliniciansTabContent from './CliniciansTabContent';
import AdminTabContentSuperAdmin from './AdminTabContentSuperAdmin';


const ManageUserTabSuperAdmin = (props) => {

    const [key, setKey] = useState('facilities');
    const[refresh,setRefresh]=useEffect(false)

    const handleRefresh=(e)=>{
        setRefresh(e)
    }
    return (
        <div className='manage-tab-main'>
            <Tabs
                id="manageUserTab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className=""
                >
                <Tab eventKey="facilities" title="Facilities">
                <FacilityTabContentSuperAdmin handleRefresh={handleRefresh} refresh={refresh}/>
                </Tab>
                <Tab eventKey="clinicians" title="Clinicians">
                <CliniciansTabContent handleRefresh={handleRefresh} refresh={refresh}/>
                </Tab>
                <Tab eventKey="admin" title="Admin">
                <AdminTabContentSuperAdmin handleRefresh={handleRefresh} refresh={refresh}/>
                </Tab>
                </Tabs>
                </div>
    )

}

export default ManageUserTabSuperAdmin;
