import React, { useState, useEffect } from 'react';
import { Tabs, Tab} from 'react-bootstrap';
import FacilityTabContentSuperAdmin from './FacilityTabContentSuperAdmin';
import CliniciansTabContent from './CliniciansTabContent';
import AdminTabContentSuperAdmin from './AdminTabContentSuperAdmin';


const ManageUserTabSuperAdmin = (props) => {

    const [key, setKey] = useState('facilities');
    
    return (
        <div className='manage-tab-main'>
            <Tabs
                id="manageUserTab"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className=""
                >
                <Tab eventKey="facilities" title="Facilities">
                <FacilityTabContentSuperAdmin />
                </Tab>
                <Tab eventKey="clinicians" title="Clinicians">
                <CliniciansTabContent />
                </Tab>
                {localStorage.getItem('position')=='c_level_emp'?
                     <Tab eventKey="admin" title="Admin">
                     <AdminTabContentSuperAdmin />
                     </Tab>
                    :""}
                
                </Tabs>
                </div>
    )

}


export default ManageUserTabSuperAdmin;






// import React, { useState, useEffect } from 'react';
// import { Tabs, Tab} from 'react-bootstrap';
// import FacilityTabContent from './FacilityTabContent';
// import CliniciansTabContent from './CliniciansTabContent';
// import AdminTabContent from './AdminTabContent';



// const ManageUserTab = (props) => {

//     const [key, setKey] = useState('facilities');
    
//     return (
//         <div className='manage-tab-main'>
//             <Tabs
//                 id="manageUserTab"
//                 activeKey={key}
//                 onSelect={(k) => setKey(k)}
//                 className=""
//             >
//                 <Tab eventKey="facilities" title="Facilities">
//                     <FacilityTabContent />
//                 </Tab>
//                 <Tab eventKey="clinicians" title="Clinicians">
//                     <CliniciansTabContent />
//                 </Tab>
//                 {localStorage.getItem('position')=='hmc_admin'?null:
//                 <Tab eventKey="admin" title="Admin">
//                     <AdminTabContent />
//                 </Tab>}
//             </Tabs>
//         </div>
//     )

// }


// export default ManageUserTab;