import React, { useState, useEffect } from 'react';
import SearchInput from '../inner-components/SearchInput';
import CustomDropdown from '../inner-components/CustomDropdown';
import DocumentsTable from '../inner-components/DocumentsTable';
import TablePagination from '../inner-components/TablePagination';
import { RoutesPath } from '../../App';
import axios from "axios";
import { ENV } from '../../env/env'
import { useNavigate } from 'react-router-dom'
import RenewToken from '../inner-components/RenewToken';

const DocumentsMainSection = ({documentsref}) => {
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [filterInput, setFilterInput] = useState("")
    const [link, setLink] = useState(ENV.ENDPOINT + `/document/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [reRender, setReRender] = useState(false)
    const UserCliniciansFilter = [
        { value: 'All Position', label: 'All Position' },
        { value: 'CNA', label: 'CNA' },
        { value: 'RN', label: 'RN' },
        { value: 'LPN', label: 'LPN' },
        { value: 'STNA', label: 'STNA' }
    ]
    const handleChange = (event) => {
        setSearchInput(event.target.value)

    }
    const handleFilter = (selection) => {
        if (selection.value == "All Position") {
            setFilterInput("")
        }
        else {
            setFilterInput(selection.value)
        }



    }

    const handleRender = () => {
        setReRender(true)
    }
    const handleLink = () => {

        let token = localStorage.getItem('token')
        // let link=`http://127.0.0.1:8000/api/clinician/view/`
        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                setData(response.data.results)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)
                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setData(response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)


                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error)
            })
    }


    const [count,setCount]=useState(false)
    useEffect(() => {
        let token = localStorage.getItem('token')
        if (!count) {
            axios.get(ENV.ENDPOINT + `/document/view/?Search=${filterInput},${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    if (localStorage.getItem('position') == 'facility_admin') {
                        const filteredData = response.data.results.map(function (row) {
                            if (row.facility.includes(localStorage.getItem('facility_id'))) {
                                return {
                                    clinician_avatar: row.clinician_avatar,
                                    clinician_id: row.clinician_id,
                                    clinician_name: row.clinician_name,
                                    clinician_position: row.clinician_position,
                                    document_id: row.document_id,
                                    document_name: row.document_name,
                                    document_path: row.document_path,
                                    document_status: row.document_status,
                                    document_updated_date: row.document_updated_date,
                                    facility: row.facility
                                }
                            }
                        })

                        if (filteredData[0] != undefined) {
                            setData(filteredData)
                        }
                        else {
                            setData([])
                        }

                    } else {
                        setData(response.data.results)
                    }


                    setCurrentPage(response.data.page)

                    setTotalPage(response.data.total_pages)
                    if (response.data.links.next != undefined && response.data.links.next != "") {
                        setNextPage(true)
                        setNextLink(response.data.links.next)

                    }
                    setPrevLink(response.data.links.previous)
                })
                .catch((error) => {
                    console.log(error.response.data)
                    if (error.response.data.code == 'token_not_valid') {
                        RenewToken.RenewTokenAPI()
                    }
                })

            setReRender(false)
            setCount(true)

        }
        else {
            const interval = setInterval(() => {
                axios.get(ENV.ENDPOINT + `/document/view/?Search=${filterInput},${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                        if (localStorage.getItem('position') == 'facility_admin') {
                            const filteredData = response.data.results.map(function (row) {
                                if (row.facility.includes(localStorage.getItem('facility_id'))) {
                                    return {
                                        clinician_avatar: row.clinician_avatar,
                                        clinician_id: row.clinician_id,
                                        clinician_name: row.clinician_name,
                                        clinician_position: row.clinician_position,
                                        document_id: row.document_id,
                                        document_name: row.document_name,
                                        document_path: row.document_path,
                                        document_status: row.document_status,
                                        document_updated_date: row.document_updated_date,
                                        facility: row.facility
                                    }
                                }
                            })

                            if (filteredData[0] != undefined) {
                                setData(filteredData)
                            }
                            else {
                                setData([])
                            }

                        } else {
                            setData(response.data.results)
                        }


                        setCurrentPage(response.data.page)

                        setTotalPage(response.data.total_pages)
                        if (response.data.links.next != undefined && response.data.links.next != "") {
                            setNextPage(true)
                            setNextLink(response.data.links.next)

                        }
                        setPrevLink(response.data.links.previous)
                    })
                    .catch((error) => {
                        console.log(error.response.data)
                        if (error.response.data.code == 'token_not_valid') {
                            RenewToken.RenewTokenAPI()
                        }
                    })

                setReRender(false)
            }, 15000);
            return () => clearInterval(interval);
        }

    }, [reRender, searchInput, filterInput,count])
    return (
        <>
            <div className='document-main-wrap '>
                <div className="document-main-inner">
                    <div className='filter-row-wrapper'>
                        <div className='table-filter-row'>
                            <SearchInput searchInput={handleChange} />
                            <CustomDropdown optionData={UserCliniciansFilter} dropClass="without-search" filterInput={handleFilter} />
                        </div>
                    </div>
                    <div className='pagination-table document-table' ref={documentsref}>
                        <div className="table-wrapper table-responsive">
                            <DocumentsTable searchInputKey={searchInput} filterInputKey={filterInput} nextData={data} />
                        </div>
                        <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DocumentsMainSection;