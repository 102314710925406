import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Eventcalendar, Button, setOptions, toast, formatDate } from '@mobiscroll/react';
import ScheduleShiftData from '../../JsonDataFile/ScheduleShiftData';
import axios from "axios";
import { ENV } from '../../env/env'


const DayShiftSchedule = (props) => {

    const [view, setView] = React.useState('month');
    //const [myEvents, setEvents] = React.useState(ScheduleShiftData);
    const [myEvents, setEvents] = React.useState();

    const [calView, setCalView] = React.useState(
        {
            calendar: { type: 'week' },
            agenda: { type: 'day' }
        }
    );
    useEffect(() => {
       
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                const optionsFacilities = response.data.results.map(function (row) {
                    return {
                        id: row.schedule_id, color: row.shift_color, imageSrc: row.avatar_image, start: row.start_time, end: row.end_time, title: row.title, shiftTypeText: row.clinician_position,
                        shiftUserName: row.clinician_name,
                        shiftNotes: row.clinician_name,
                        color: row.shift_color
                    }
                })
                setEvents(optionsFacilities);     
            })
            .catch((error) => {
                //console.log(error.response.data)
            })
        props.handleRefresh(false)
    }, [props.refresh])

    const myCustomDay = (day) => {
        var date = day.date;
        return <>
            <div className='custom-week-header'>
                <div>{formatDate('DDD', date)}</div>
                <p>{formatDate('DD', date)}</p>
            </div>
        </>;
    }

   
    const renderEventContent = React.useCallback((data) => {
       

        return (
            <>
                <div className='schedule-list' >
                    <div className='schedule-single'>
                        <div className='time-wrapper'>
                            <span>{data.start}</span>-
                            <span>{data.end}</span>
                        </div>
                        <div className='schedule-shift-content'>
                            <div className='inner'>
                                <div className='image-wrap'>
                                    <img src={data.original.imageSrc} alt="" />
                                </div>
                                <div className='schedule-shift-text'>
                                    <h6>{data.original.shiftTypeText}</h6>
                                    <span>{data.original.shiftUserName}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </>
        )
    });


    return (
        <>
       {myEvents!=undefined?(
        <div className='calender-wrapper schedule-shift-calender' id="shiftScheduleCalender" ref={props.ref3}>
            <Eventcalendar
                // renderAgenda={renderEventContent}
                renderEventContent={renderEventContent}
                renderDay={myCustomDay}
                view={calView}
                data={myEvents}
                theme="windows"
                themeVariant="light"

            />
        </div>
        ):""}
        </>
    )
}

export default DayShiftSchedule