import React, { useState, useEffect } from 'react';
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import ManageTopHeaderSection from "../components/manage-user/ManageTopHeaderSection";
import FundingSummarySection from '../components/funding-summary/FundingSummarySection';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ENV } from '../env/env'

//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from './utils/container'
import { log } from './utils/utils'
import useRouter from './utils/use-router'
import { useNavigate } from 'react-router-dom'


const FundingSummary = () => {
    const [profileImage, setProfileImage] = useState()



    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/admin/view/?admin_id=${localStorage.getItem('user_data_id')}`, { headers: { "Authorization": `Bearer ${token}` }})
            .then(response => {
                localStorage.setItem('avatarImage', response.data.results[0].avatar_image)
                setProfileImage(response.data.results[0].avatar_image)

                axios.get(ENV.ENDPOINT + `/facility/view/?facility_id=${response.data.results[0].facility}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {

                        console.log(response.data.results[0])
                        localStorage.setItem('facility', response.data.results[0].facility_name)
                        localStorage.setItem('facility_id', response.data.results[0].facility_id)

                    })
                    .catch((error) => {
                        console.log(error)
                    })

            })
            .catch((error) => {
                console.log(error)
            })
    })



    //for agora chat and call login
    const navigate = useNavigate()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const routerCtx = useRouter()

    const history = useNavigate()

    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    useEffect(() => {
        setTimeout(() => {

            stateCtx.rtmClient.login(localStorage.getItem('usercode')).then(() => {

            }).catch(() => {
                log('Login failure from dashboard')
                // mutationCtx.toastError('Logon failure')
                return
            })

        }, 1000);
    }, [stateCtx.rtmClient])

    useEffect(() => {


        stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
            let content = JSON.parse(remoteInvitation.content)

            mutationCtx.updatePeerCode(remoteInvitation.callerId)
            mutationCtx.updateIncomingCode(content.name)
            mutationCtx.updateIncomingImage(content.image)
            mutationCtx.updateConfig({ channelName: content.channel })

            navigate({
                pathname: `/call-invi/card/indexCard/incoming/`
            })
        })

        stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
            console.log('remote invitation cancelled')
            // navigate('/dashboard')
        })

        stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
            mutationCtx.toastError('Call not answered')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
            log('Accept success')
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationRefused', () => {
            mutationCtx.toastError('Peer is busy')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
            console.log("call declined from inter-coding-card")
            navigate('/call-invi')
        })

        stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
            navigate.goBack()
        })

        stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
            navigate({
                pathname: `/dialling`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationFailure', () => {
            mutationCtx.toastError('Call process failed')
            history.goBack()
        })

        return () => {
            stateCtx.rtmClient.eventBus.removeAllListeners()
            //   navigate('/card')
        }

    }, [stateCtx.rtmClient, stateCtx])

    return (
        <>
            <main className='app-main'>
                <HeaderMobile />
                <section className="main-section dashboard-section blue-bg-section chart-main-section">
                    <div className="section-wrap">
                        <Row className="dashboard-row-main">
                            <LeftPanel profileImage={profileImage} />
                            <Col lg="9" className="right-content-col grey-bg-col">
                                <ManageTopHeaderSection pageTitle="Funding Summary" />
                                <FundingSummarySection />
                            </Col>
                        </Row>

                    </div>
                </section>
            </main>
        </>
    )
}

export default FundingSummary;