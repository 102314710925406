import React, { useState, useEffect } from "react";
import SwitchToggle from "./SwitchToggle";
import CliniciansTableMenuDropdown from "./CliniciansTableMenuDropdown";
import axios from "axios";
import { ENV } from "../../env/env";

const UserCliniciansTable = (props) => {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const [nextPage, setNextPage] = useState(false);



  useEffect(() => {
    setData(props.nextData);
  }, [props.nextData, props.renderdata, props.rerender]);


  const handleShiftSwitch = (e, id) => {
    async function apiCall(credentials) {
      let token = localStorage.getItem("token");
      await axios
        .patch(ENV.ENDPOINT + `/clinician/applyShift/${id}/`, credentials, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error.response.data);
        });
    }
    let apply_shift = e.target.checked;
    const token = apiCall({
      apply_shift,
    });
  };

  const UserCliniciansData = [
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Camille",
      LastName: "Moreno",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Active",
      statusTagClassName: "success",
      applyShiftchecked: "Of",
    },
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Arthur",
      LastName: "Hawkins",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Active",
      statusTagClassName: "success",
      applyShiftchecked: "On",
    },
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Dianne",
      LastName: "Edwards",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Deactive",
      statusTagClassName: "failed",
      applyShiftchecked: "On",
    },
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Shane",
      LastName: "Hawkins",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Active",
      statusTagClassName: "success",
      applyShiftchecked: "On",
    },
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Eduardo",
      LastName: "Richards",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Active",
      statusTagClassName: "success",
      applyShiftchecked: "On",
    },
    {
      UserImageSrc: "assets/images/user-shift-image.png",
      FirstName: "Mitchell",
      LastName: "Mitchell",
      Position: "CNA",
      Email: "cedrix@ondek.com",
      ContactNo: "09222222222",
      Status: "Active",
      statusTagClassName: "success",
      applyShiftchecked: "On",
    },
  ];
  let FaclityTrueStatusButtonClassName = "success";
  let FaclityFalseStatusButtonClassName = "failed";

  return (
    <>
      <table width="100%">
        <thead>
          <tr>
            <th></th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Status</th>
            <th>Applying Shifts</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data != undefined ? (
            <>
              {data.map((val, index) => (
                <tr key={index}>
                  <td>
                    <div className="clinicians-image-thumbnail">
                      <img src={val.avatar_image} alt="" />
                    </div>
                  </td>
                  <td>{val.firstname}</td>
                  <td>{val.lastname}</td>
                  <td>{val.clinician_position}</td>
                  <td className="email-td">{val.email}</td>
                  <td className="mobile-td">{val.contact_no}</td>

                  <td>
                    <span
                      className={
                        val.is_active == true
                          ? `status-tag ${FaclityTrueStatusButtonClassName}`
                          : `status-tag ${FaclityFalseStatusButtonClassName}`
                      }
                    >
                      {val.is_active == true ? "Active" : "Deactive"}
                    </span>
                  </td>
                  <td>
                    <SwitchToggle
                      dataSwitch={val.apply_shift}
                      switchToggleID="shiftApproveSwitch"
                      data={(e) => handleShiftSwitch(e, val.clinician_id)}
                    />
                  </td>
                  <td>
                    <CliniciansTableMenuDropdown
                      data={val}
                      handleRender={props.handleRender}
                    />
                  </td>
                </tr>
              ))}
            </>
          ) : (
            ""
          )}
        </tbody>
      </table>
    </>
  );
};

export default UserCliniciansTable;
