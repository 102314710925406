import React, { useState, useEffect } from 'react';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';
import InputGroupForm from './InputGroupForm';
import ColorPickerDrodown from './ColorPickerDrodown';
import TimeCustomPicker from './TimeCustomPicker';
import AllCustomIcon from '../../JsonDataFile/AllCustomIcon';
import { RoutesPath } from '../../App';
import axios from "axios";
import { ENV } from '../../env/env'
import Select, { OnChangeValue, StylesConfig, useStateManager } from 'react-select';
import moment from 'moment';
import { useForm } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

const CreateTemplatePopup = (props) => {

    const { register, formState: { errors }, handleSubmit } = useForm();

    const [color, setColor] = useState('#0ea80f')
    const [startTime, setStartTime] = useState()
    const [startDate, setStartDate] = useState()
    const [endTime, setEndTime] = useState()
    const [endDate, setEndDate] = useState()
    const [popupShiftTypeTitle, setPopupShiftTypeTitle] = React.useState('');
    const [model, setModel] = useState({
        templateName: "",
        Position: "",
        Shift: "",
        repeat: "False",
        Note: "",

    }, []);

    const positionData = [
        { label: 'CNA', value: 'cna' },
        { label: 'RN', value: 'rn' },
        { label: 'LPN', value: 'lpn' },
        { label: 'STNA', value: 'stna' }
    ];
    const shiftTitleChange = React.useCallback((ev) => {

        setPopupShiftTypeTitle(ev.label);

    }, []);
    const positiondropdownDefaultValue = () => {
        var selectedValueoption;
        positionData.map((option) => {
            if (option.label === popupShiftTypeTitle) {
                selectedValueoption = option;
            }

        })
        return selectedValueoption
    };

    async function addTemplateAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.post(ENV.ENDPOINT + `/shift/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                console.log(response.data)
                handleClose()
                props.handleRefresh()
            })
            .catch((error) => {
                console.log(error.response)
            })


    }

    const handleChange = (event) => {
        // get the value of the field
        console.log("hello from child")

        const value = event?.target?.value;
        //console.log(value)
        //set it in the model
        setModel({
            ...model,
            [event.target.name]: value
        });

        console.log(model, "after submit")

    };

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleColor = (e) => {
        setColor(e)

    }
    function formatDate(oDate) {
        var oTempDate = new Date(oDate),
            sMonth = '' + (oTempDate.getMonth() + 1),
            sDay = '' + oTempDate.getDate(),
            iYear = oTempDate.getFullYear();

        if (sMonth.length < 2) { sMonth = '0' + sMonth; }
        if (sDay.length < 2) { sDay = '0' + sDay; }

        return [iYear, sMonth, sDay].join('-');
    }
    const handleStartTime = (e) => {
        console.log(e)
        setStartTime(moment(e.year + "-" + e.month.number + "-" + e.day + " " + e.hour + ":" + e.minute + ":" + e.second).format('hh:mm'))
        console.log(moment(e.year + "-" + e.month.number + "-" + e.day + " " + e.hour + ":" + e.minute + ":" + e.second).format('hh:mm'))
        setStartDate(formatDate(e.year + "-" + e.month.number + "-" + e.day))
    }
    const handleEndTime = (e) => {
        setEndTime(moment(e.year + "-" + e.month.number + "-" + e.day + " " + e.hour + ":" + e.minute + ":" + e.second).format('hh:mm'))
        setEndDate(formatDate(e.year + "-" + e.month.number + "-" + e.day))
    }
    const onSubmit = (data) => {
        // console.log('template submit data', data,color,startTime,startDate,endTime,endDate,shiftTitleChange)

        let template_name=data.templateName
        // let clinician_position=popupShiftTypeTitle
        let clinician_position=popupShiftTypeTitle
        let shift_title=data.Shift
        let shift_color=color

        let start_date=startDate
        let start_time=startTime
        let end_date=endDate
        let end_time=endTime
        let daily_repeat = model.repeat
        let shift_note=model.Note

        const token = addTemplateAPI({
            template_name,
            clinician_position,
            shift_title,
            shift_color,

            start_date,
            start_time,
            end_date,
            end_time,
            daily_repeat,
            shift_note,

        });
    }


    return (
        <>
            <div className="popup-wrapper">
                <Button className="border-btn" onClick={handleShow}>Create new</Button>
                <Modal show={show} onHide={handleClose} className='add-shift-popup custom-popup'>
                    <Modal.Header closeButton>
                        <h5>Create template</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='form-wrapper'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='form-inner'>
                                    <Row className="form-row">
                                        <Col md={12}>
                                            <Form.Group className="form-group" controlId="templateName">
                                                <Form.Label className="">Template name</Form.Label>
                                                <Form.Control type="text" placeholder="Text here" name="templateName" {...register("templateName", {
                                                    required: true,
                                                    maxLength: 50,
                                                    minLength: 5,
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9_@.-]*$/,
                                                    },
                                                })} />
                                                <span className="error-message">{errors.templateName?.type === 'required' && "enter template name"}
                                                    {errors.templateName?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                    {errors.templateName?.type === 'minLength' && "Enter minimum 5 characters"}
                                                    {errors.templateName?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"}</span>

                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            {/* <InputGroupForm inputId="Position" inputLabel="Position" inputType="text" inputPlaceholder="Text here" inputName="Position" handleChange={handleChange}/> */}
                                            <Form.Group className="form-group" controlId="Position">
                                                <Form.Label className="">Position</Form.Label>
                                                <div className='custom-dropdown select-custom-dropdown'>
                                                    <Select
                                                        options={positionData}
                                                        onChange={shiftTitleChange}
                                                        defaultValue={positiondropdownDefaultValue}
                                                        // name={'Position'}
                                                        // {...register("Position", {
                                                        //     required: true,
                                                        //     maxLength: 50,
                                                        //     minLength: 5,
                                                        //     pattern: {
                                                        //         value: /^[a-zA-Z0-9_@.-]*$/,
                                                        //     },
                                                        // })}
                                                    //menuIsOpen="true"
                                                    />
                                                    <span className="error-message">{errors.Position?.type === 'required' && "enter position"}
                                                        {errors.Position?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                        {errors.Position?.type === 'minLength' && "enter minimum 5 characters"}
                                                        {errors.Position?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"}</span>
                                                </div>
                                                {/* {renderErrorMessage("shiftposition")} */}

                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <div className='shift-input-wrap'>

                                            <Form.Group className="form-group" controlId="Shift">
                                                    <Form.Label className="">Shift</Form.Label>
                                                    <Form.Control type="text" placeholder="Text here" name="Shift" {...register("Shift", {
                                                        required: true,
                                                        maxLength: 50,
                                                        minLength: 5,
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9_@.-]*$/,
                                                        },
                                                    })} />
                                                    <span className="error-message">{errors.Shift?.type === 'required' && "enter shift"}
                                                        {errors.Shift?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                        {errors.Shift?.type === 'minLength' && "enter minimum 5 characters"}
                                                        {errors.Shift?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"}</span>

                                                </Form.Group>
                                                <div className="color-dropdown">
                                                    <label className="form-label">Color</label>
                                                    <ColorPickerDrodown handleChange={handleColor}/>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='form-group time-picker'>
                                                {/* <label className='form-label'>Start</label>
                                                <TimeCustomPicker handleChange={handleStartTime} /> */}
                                                <label className='form-label'>Start</label>
                                                <DatePicker
                                                    disableDayPicker
                                                    format="hh:mm A"
                                                    required="true"
                                                    name="startTimepicker"
                                                    plugins={[
                                                        <TimePicker hideSeconds />
                                                    ]}
                                                    onChange={handleStartTime}
                                                />
                                               
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='form-group time-picker'>
                                                {/* <label className='form-label'>End</label>
                                                <TimeCustomPicker handleChange={handleEndTime} /> */}
                                                <label className='form-label'>End</label>
                                                <DatePicker
                                                    disableDayPicker
                                                    format="hh:mm A"
                                                    required="true"
                                                    plugins={[
                                                        <TimePicker hideSeconds />
                                                    ]}
                                                    onChange={handleEndTime}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group checkbox-single">
                                                <div className='custom-checkbox-wrapper'>
                                                    <Form.Control type="checkbox" name="repeat" onChange={handleChange} />
                                                    <span>Repeat daily</span>
                                                    <span className="checkmark"></span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12}>
                                            <div className="form-group textarea-group">
                                                <label className='form-label'>Note</label>
                                                <textarea placeholder='Text here' name="Note" onChange={handleChange}></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='btn-wrap'>
                                        <button type="submit" value="Create template" className='btn btn-primary' >Create template</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}

export default CreateTemplatePopup;