import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import VillageCommunityDropdown from '../inner-components/VillageCommunityDropdown';
import ShiftRequestpopup from '../inner-components/ShiftRequestpopup';
import SchedulingFilterAccordion from '../inner-components/SchedulingFilterAccordion';
import ShiftTemplateAccordion from '../inner-components/ShiftTemplateAccordion';
import SchedulingCalenderSection from './SchedulingCalenderSection';
import { RoutesPath } from '../../App';
import { setRef } from '@fullcalendar/react';
import { SelectionState } from 'draft-js';
import { set } from 'react-hook-form';


const SchedulingMainSection = (props) => {
   
   
    const initialvalues = {
        CNA: " ",
        RN: " ",
        LPN:" ",
        STNA_CNA:" ",
        STNA:" ",
        RN_LPN:" ",
        
      };
    const [values,setValues]=useState(initialvalues)
    const [allRender,setAllRender]=useState(false)
    let refreshh=""
   

    let filter = []
    const handleFilter = (e) => {
        let filter = []
        if(e.target.checked){
            setValues({...values,[e.target.name]: e.target.value })
            filter.push(e.target.value)
        }
       if(e.target.checked==false){
            setValues({...values,[e.target.name]: "" })
        }
        
    }
        
    useEffect(()=>{
    },[values])
    const handleAllRender=(e)=>{
        setAllRender(e)
    }
        return (
            <>
                <div className='scheduling-main-section-wrap'>
                    <Row className="scheduling-main-row">
                        <Col xl={9} className="schedule-calender-col" ref={props.Schedulingref1}>
                            <div className='col-inner'>
                                <SchedulingCalenderSection filterRequest={values} handleRefresh={props.handleRefresh} refresh={props.refresh} allRender={allRender} handleAllRender={handleAllRender} Schedulingref2={props.Schedulingref2} Schedulingref5={props.Schedulingref5}/>
                            </div>
                        </Col>
                        <Col xl={3} className="schedule-right-col">
                            <div className='col-inner'>
                                <VillageCommunityDropdown />
                                <ShiftRequestpopup allRender={allRender} handleAllRender={handleAllRender} Schedulingref4={props.Schedulingref4}/>
                                <SchedulingFilterAccordion handleFilter={handleFilter} />
                                <ShiftTemplateAccordion Schedulingref3={props.Schedulingref3} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    export default SchedulingMainSection;