// import React, { useState, useEffect } from 'react';
// import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { RoutesPath } from '../../App';
// import PieChart, {
//   Legend,
//   Series,
//   Export,
//   Label,
//   SmallValuesGrouping,
//   Connector,
// } from 'devextreme-react/pie-chart';
// import axios from "axios";
// import { Position } from 'devextreme-react/autocomplete';
// import moment from 'moment';
// import { ENV } from '../../env/env'

// const FacilityRequestRatePieChart = (props) => {

//   const [fillRate,setFillRate]=useState()
//     const customPalette = ['#2753b8', '#0ea80f', '#e22960'];
//     useEffect(()=>{
//       let date1=props.dateRange.date1
//       let date2=props.dateRange.date2
//       let filterParam=""
//       if(localStorage.getItem('position')=='facility_admin'){
//         filterParam=localStorage.getItem('facility_id')
//       }

//       let is_CompletedList=[]
//       let DeclinedByFacilityList=[]
//       let DeclinedByClinicianList=[]

//       let token = localStorage.getItem('token')
//       axios.get(ENV.ENDPOINT+`/scheduling/view/?facility_id=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
//       .then(response => {
//           console.log( response.data.results)

//           let dateData = []
//                 const dateRange = response.data.results.map(function (rowDate) {
//                     if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

//                         if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
//                             dateData.push(rowDate)
//                         }
//                     }
//                     else {
//                         console.log('false')
//                     }
//                 })
     

//           dateData.forEach((el) => {
             
//               if (el.status == "Completed") {
              
//                   is_CompletedList.push(el)

//               }
//               if (el.declined_by == "Clinician") {
              
//                 DeclinedByClinicianList.push(el)

//               }
//               if (el.declined_by == "Facility") {
                    
//                 DeclinedByFacilityList.push(el)

//               }
//           })

//           console.log(is_CompletedList.length,"List")
//           console.log(DeclinedByFacilityList.length,"List")
//           console.log(DeclinedByClinicianList.length,"List")

//           const facilityRequestRate1 = [{
//             facility: 'Fill rate',
//             requestRate: is_CompletedList.length,
//             color: "#000"
//         }, {
//             facility: 'Cancellation rate (CLN)',
//             requestRate: DeclinedByClinicianList.length,
//             color: "#000"
//         }, {
//             facility: 'Cancellation rate (Facility)',
//             requestRate: DeclinedByFacilityList.length,
//             color: "#000"
//         }];
    
//         setFillRate(facilityRequestRate1)
//       })
//   },[props.dateRange])

//     // const facilityRequestRate = [{
//     //     facility: 'Fill rate',
//     //     requestRate: 44,
//     //     color: "#000"
//     // }, {
//     //     facility: 'Cancellation rate (CLN)',
//     //     requestRate: 24,
//     //     color: "#000"
//     // }, {
//     //     facility: 'Cancellation rate (Facility)',
//     //     requestRate: 32,
//     //     color: "#000"
//     // }];

//     return (
//         <Col lg="6" className='mt-0 mt-lg-5 pt-5'>
//             <div className='graph-box'>
//                 <div className='graph-title d-flex align-items-center justify-content-between'>
//                     <h6 className='mb-0'>Facility Request Rate</h6>
//                     <Link to={RoutesPath.facilityRequestRate} className="btn btn-link link-btn">View detail</Link>
//                 </div>

//                 <PieChart
//                   id="pie"
//                   type="doughnut"
//                   // title="Top Internet Languages"
//                   palette={customPalette}
//                   dataSource={fillRate}
//                 >
//                   <Series argumentField="facility" valueField="requestRate">
//                     {/* <SmallValuesGrouping mode="topN" topCount={3} /> */}
//                     <Label
//                       visible={true}
//                       format="fixedPoint"
//                       // customizeText={this.customizeLabel}
//                     >
//                       <Connector visible={true} width={1} />
//                     </Label>
//                   </Series>
//                   <Export enabled={true} />
//                   <Legend horizontalAlignment="right" verticalAlignment="top" />
//                 </PieChart>
//             </div>
//         </Col>
//     )
// }

// export default FacilityRequestRatePieChart;


import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RoutesPath } from '../../App';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'


const FacilityRequestRatePieChart = (props) => {
  const [fillRate,setFillRate]=useState()
  const [label,setLabel]=useState()
    
    const customPalette = ['#2753b8', '#0ea80f', '#e22960'];

     useEffect(()=>{
      let date1=props.dateRange.date1
      let date2=props.dateRange.date2
      let filterParam=""
      if(localStorage.getItem('position')=='facility_admin'){
        filterParam=localStorage.getItem('facility_id')
      }

      let is_CompletedList=[]
      let DeclinedByFacilityList=[]
      let DeclinedByClinicianList=[]

      let token = localStorage.getItem('token')
      axios.get(ENV.ENDPOINT+`/scheduling/view/?facility_id=${filterParam}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
          let dateData = []
                const dateRange = response.data.results.map(function (rowDate) {
                    if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

                        if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
                            dateData.push(rowDate)
                        }
                    }
                 
                })
     

          dateData.forEach((el) => {
             
              if (el.status == "Completed") {
              
                  is_CompletedList.push(el)

              }
              if (el.declined_by == "Clinician") {
              
                DeclinedByClinicianList.push(el)

              }
              if (el.declined_by == "Facility") {
                    
                DeclinedByFacilityList.push(el)

              }
          })

       

          const label1=['Fill rate','Cancellation rate (CLN)','Cancellation rate (Facility)']
          const facilityRequestRate1 = [is_CompletedList.length,
            DeclinedByClinicianList.length,
           DeclinedByFacilityList.length,
            
        ];
        setLabel(label1)
        setFillRate(facilityRequestRate1)
      })
  },[props.dateRange])


    const options = {

      cutout: "80%",
      plugins: {
        datalabels: {
          backgroundColor: "#fff",
          borderColor: '#c2c2c2',
          borderRadius: 25,
          borderWidth: 1,
          color: '#000',
          display: function (context) {
            var dataset = context.dataset;
            var count = dataset.data.length;
            var value = dataset.data[context.dataIndex];
            // var valuePercentage = `${value}%`;
  
            return `${value}%`;
          },
          font: {
            weight: '500'
          },
          padding: 8,
          
        },
        legend: {
          display: true,
          position: 'right',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 30
          }
        },
      }
    };
  
    const spaceAddPlugin = {
  
  
      id: 'legendDistance',
      beforeInit(chart, args, opts) {
      
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;
        // Override the fit function
        chart.legend.fit = function fit() {
          // Call original function and bind scope in order to use `this` correctly inside it
          originalFit.bind(chart.legend)();
          // Specify what you want to change, whether the height or width
          this.height = this.height + 100;
        }
      }
  
  
    };
    const facilityRequestRate = {
      labels: label,
      datasets: [{
        label: 'My First Dataset',
        data: fillRate,
        backgroundColor: customPalette,
        spacing: 6,
        borderRadius: 60,
        borderWidth: 0,
        datalabels: {
          anchor: 'end'
        }
      }]
    };

    return (
        <Col lg="6" className='mt-0 mt-lg-5 pt-5'>
            <div className='graph-box'>
                <div className='graph-title d-flex align-items-center justify-content-between'>
                    <h6 className='mb-0'>Facility Request Rate</h6>
                    <Link to={RoutesPath.facilityRequestRate} className="btn btn-link link-btn">View detail</Link>
                </div>
                <Doughnut data={facilityRequestRate} options={options} plugins={[ChartDataLabels][spaceAddPlugin]} />
            </div>
        </Col>
    )
}
export default FacilityRequestRatePieChart;
