import React, { useState, useEffect, useRef } from 'react';
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import ManageTopHeaderSection from "../components/manage-user/ManageTopHeaderSection";
import DocumentsMainSection from '../components/documents/DocumentsMainSection';
import { Row, Col } from 'react-bootstrap';

//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from './utils/container'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { log } from './utils/utils'
import useRouter from './utils/use-router'
import { Outlet, useNavigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import useLocalStorage from './utils/useLocalStorage';
import { Tour } from 'antd';
import { useWindowSize } from './utils/useWindowSize';
import Cookies from 'js-cookie';

const Documents = () => {

    //for agora chat and call login
    const navigate = useNavigate()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const routerCtx = useRouter()

    const history = useNavigate()
    
    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    useEffect(() => {
        if (stateCtx.rtmClient.status === 'offLine') {
            stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
                log(stateCtx.userCode, 'is online')
            }).catch(() => {
                log('Login failure')
                
                return
            })
        }
    }, [stateCtx.rtmClient])

    useEffect(() => {


        stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
            let content=JSON.parse(remoteInvitation.content)
     
            mutationCtx.updatePeerCode(remoteInvitation.callerId)
            mutationCtx.updateIncomingCode(content.name)
            mutationCtx.updateIncomingImage(content.image)
            mutationCtx.updateConfig({ channelName: content.channel })

            navigate({
                pathname: `/call-invi/card/indexCard/incoming/`
            })
        })

        stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
          
            // navigate('/dashboard')
        })

        stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
            mutationCtx.toastError('Call not answered')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
            log('Accept success')
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationRefused', () => {
            mutationCtx.toastError('Peer is busy')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
           
            navigate('/call-invi')
        })

        stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
            navigate.goBack()
        })

        stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
            navigate({
                pathname: `/dialling`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationFailure', () => {
            mutationCtx.toastError('Call process failed')
            history.goBack()
        })

        return () => {
            stateCtx.rtmClient.eventBus.removeAllListeners()
            //   navigate('/card')
        }

    }, [stateCtx.rtmClient, stateCtx])


    const documentsref = useRef(null);
    const size = useWindowSize()
    const [modalDocuments, setModalDocuments] = useLocalStorage(
      "tourdocuments",
      "opendocument"
    );
  
    const [openDocuments, setOpenDocuments] = useState(false);
    const steps = [
      {
        description:"This will mainly be used for credentialing. Clinicians will be needed to sumbit certain documents, so to eliminate sending files through email or elsewhere, we added it here so that we can look back and verify it when needed.",
        target: () => documentsref.current,
      },
    ];



    useEffect(() => {
        if(size.width >= 1250){
        const isModalOpen = Cookies.get("opendocument") === "open";
        if (!isModalOpen ) {
          Cookies.set("opendocument", "open", { expires: 365, path: "" });
          const timeoutId = setTimeout(() => {
            setOpenDocuments(true);
          }, 3000);
          return () => {
            clearTimeout(timeoutId);
          };
         }
        }
      }, [size.width]);

    

    return (
        <>
            <main className='app-main'>
                <HeaderMobile />
                <section className="main-section dashboard-section blue-bg-section document-section">
                    <div className="section-wrap">
                        <Row className="dashboard-row-main">
                            <LeftPanel />
                            <Col lg="9" className="right-content-col grey-bg-col">
                                <ManageTopHeaderSection pageTitle="Documents"/>
                                <DocumentsMainSection documentsref={documentsref} />
                            </Col>
                        </Row>
                        <Tour
              open={openDocuments}
              onClose={() => setOpenDocuments(false)}
              steps={steps}
              indicatorsRender={(current, total) => <></>}
            />
                    </div>
                </section>
            </main>
        </>
    )
}

export default Documents;