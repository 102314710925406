import React, { useState, useEffect } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import AllCustomIcon from "../../JsonDataFile/AllCustomIcon";
import SearchInput from "../inner-components/SearchInput";
import axios from "axios";
import { ENV } from "../../env/env";
import moment from "moment";

const TransactionsTable = (props) => {
  const [invoiceTransactionData, setInvoiceTransactionData] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [sort, setSort] = useState(false);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
    .get(ENV.ENDPOINT + `/invoice/view/?search=${searchInput}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      console.log(response.data.results, "invoice");
      const data = response.data.results.map(function (row) {
        return {
          invoiceNumber: `#${row.invoice_id}`,
          dueDate: row.service_date,
          originalAmout: row.rate,
          Balance: row.amount,
        };
      });
      if (sort == true) {
        data.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
      }
      setInvoiceTransactionData(data);
    })
    .catch((error) => {
      console.log(error.response.data);
    });

    props.handleRender(false);
    //setSort(false)
  }, [props, searchInput, sort]);

  const UserFacilitiesData = [
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
    {
      invoiceNumber: "#12398",
      dueDate: "03/14/2022",
      originalAmout: "530",
      Balance: "530",
    },
  ];
  const handleSearch = (e) => {
    console.log(e.target.value);
    setSearchInput(e.target.value);
  };
  const handleSorting = () => {
    setSort(true);
  };

  return (
    <div className="transacation-table-wrapper">
      <div className="filter-row-wrapper">
      <div className="table-filter-row">
      <div className="search-input-wrap table-search-filter">
      <div className="search-icon">
      <i className="icon-search"></i>
      </div>
      <Form.Control
              type="search"
              placeholder="Find invoice no."
              name="Search"
              onChange={handleSearch}
              />
              </div>
              <Button className="btn border-btn sort-btn" onClick={handleSorting}>
              Oldest date{AllCustomIcon.iconSort}
              </Button>
              </div>
              </div>
              <div className="transacation-table">
              <div className="table-wrapper table-responsive">
              <table width="100%">
              <thead>
              <tr>
              <th></th>
              <th>Desciption</th>
              <th>Due date</th>
              <th>Original amount</th>
              <th>Balance</th>
              </tr>
              </thead>
              <tbody>
              <>
              {invoiceTransactionData &&
                  invoiceTransactionData.map((val, index) => (
                    <tr key={index}>
                      <td>
                      <div class="custom-checkbox-wrapper">
                      <input type="checkbox" class="form-control" />
                      <span class="checkmark"></span>
                      </div>
                      </td>
                      <td>Invoice {val.invoiceNumber}</td>
                      <td>{val.dueDate}</td>
                      <td>{val.originalAmout}</td>
                      <td>{val.Balance}</td>
                      </tr>
                  ))}
                  </>
                  </tbody>
                  </table>
                  </div>
                  </div>
                  </div>
  );
};


export default TransactionsTable;