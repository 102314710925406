import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import TransactionsTable from './TransactionsTable';
import FacilityEyeBirdsGraph from './FacilityEyeBirdsGraph';
import InvoiceTransactionTable from './InvoiceTransactionTable';
import AddInvoicePopup from '../inner-components/AddInvoicePopup';

const FundingSummarySection = (props) => {

    const [render, setRender] = useState(false)


    const dataSource = [{
        month: 'May 2',
        totalRequest: 12.5,
        completed: 4.9,
    }, {
        month: 'May 3',
        totalRequest: 10.1,
        completed: 2.5,
    }, {
        month: 'May 4',
        totalRequest: 7.5,
        completed: 6,
    }, {
        month: 'May 5',
        totalRequest: 15.2,
        completed: 2.4,
    }, {
        month: 'May 6',
        totalRequest: 5.2,
        completed: 0.5,
    }, {
        month: 'May 7',
        totalRequest: 10,
        completed: 3,
    }, {
        month: 'May 8',
        totalRequest: 15,
        completed: 11,
    }];

    const handleRender = (e) => {
        setRender(e)
    }
    return (
        <div className='statistics-section-main funding-summary-main'>
            <Row className="dashboard-row-main">
                <Col lg="5" className="birds-eye-view-col">
                    <div className='col-inner'>
                        <h5>Facility Bird's Eye View</h5>
                        <FacilityEyeBirdsGraph />
                    </div>

                </Col>
                <Col lg={7} className="transacation-table-col">
                    <div className="col-inner">
                        <h5>Outstanding Transactions</h5>
                        <TransactionsTable handleRender={handleRender} />
                    </div>
                </Col>
                <Col lg={12} className="invoice-transaction-table-col tab-content">
                    <div className="col-inner">
                        <h5><i className='icon-document'></i>Invoice #12398</h5>
                        <div className='add-invoice-button-wrapper'>
                            <AddInvoicePopup ModelTitle="Add Invoice" handleRender={handleRender} />
                        </div>
                        <InvoiceTransactionTable handleRender={handleRender} />
                    </div>
                </Col>

            </Row>

        </div>


    )
}
export default FundingSummarySection;
