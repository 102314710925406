import React, { useState, useEffect } from 'react';
import SwitchToggle from './SwitchToggle';
import AdminTableMenuDropdown from './AdminTableMenuDropdown';
import axios from "axios";
import { ENV } from '../../env/env'

const UserAdminTable = (props) => {
    const [UserCliniciansData, setUserCliniciansData] = useState()
    useEffect(() => {
        let userList = []


        const adminData = props.nextData.map(function (row) {


            return ({
                admin_id: row.admin_id,
                username: row.username,
                password: row.password,
                position: row.role,
                UserImageSrc: row.avatar_image,
                FirstName: row.firstname,
                LastName: row.lastname,
                Email: row.email,
                ContactNo: row.contact_no,
                is_active:row.is_active,
                scheduleAccess: row.allow_scheduling,
                Dob: row.date_of_birth,
                Address: row.address
            })


        })
        setUserCliniciansData(adminData)
    }, [props])


    const handleScheduleSwitch = (e, id) => {
        async function apiCall(credentials) {
            let token = localStorage.getItem('token')
            await axios.patch(ENV.ENDPOINT + `/admin/allowScheduling/${id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                   
                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        }
        let allow_scheduling = e.target.checked
        const token = apiCall({
            allow_scheduling
        });
    }
    // const UserCliniciansData = [
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Camille",
    //         LastName:"Moreno",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Arthur",
    //         LastName:"Hawkins",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Dianne",
    //         LastName:"Edwards",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Shane",
    //         LastName:"Hawkins",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Eduardo",
    //         LastName:"Richards",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    //     {

    //         UserImageSrc:"assets/images/user-shift-image.png",
    //         FirstName:"Mitchell",
    //         LastName:"Mitchell",
    //         Email:"cedrix@ondek.com",
    //         ContactNo:"09222222222",
    //         scheduleAccess: "On",
    //     },
    // ]
    let FaclityTrueStatusButtonClassName= "success"
    let FaclityFalseStatusButtonClassName= "failed"
    return (
        <>
            <table width="100%">
                <thead>
                    <tr>
                        <th></th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th>Status</th>
                        <th>Scheduling Access</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {UserCliniciansData ? (
                        <>

                            {UserCliniciansData.map((val, index) =>
                                <tr key={index}>
                                    <td>
                                        <div className='clinicians-image-thumbnail'>
                                            <img src={val.UserImageSrc} alt="" />
                                        </div>
                                    </td>
                                    <td>{val.FirstName}</td>
                                    <td>{val.LastName}</td>
                                    <td className='email-td'>{val.Email}</td>
                                    <td className='mobile-td'>{val.ContactNo}</td>
                                    <td>
                                        <span className={val.is_active == true ? `status-tag ${FaclityTrueStatusButtonClassName}` : `status-tag ${FaclityFalseStatusButtonClassName}`}>{val.is_active == true ? "Active" : "Deactive"}</span>
                                    </td>
                                    <td>
                                        <SwitchToggle dataSwitch={val.scheduleAccess} switchToggleID="shiftApproveSwitch" data={e => handleScheduleSwitch(e, val.admin_id)} />
                                    </td>
                                    <td>
                                        <AdminTableMenuDropdown data={val} handleRender={props.handleRender} />
                                    </td>
                                </tr>

                            )}

                        </>
                    ) : ""}
                </tbody>
            </table>

        </>

    )
}

export default UserAdminTable;