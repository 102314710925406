import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { RoutesPath } from '../../App';
import axios from "axios";
import moment from 'moment';

const DocumentsTable = (props) => {
    const [UserCliniciansData, setUserCliniciansData] = useState()
    const [searchInput, setSearchInput] = useState("")
    const [filterInput, setFilterInput] = useState()
    const [count,setCount]=useState(false)
    useEffect(() => {

        const unique = [...new Set(props.nextData.map(item => item.clinician_id))];
        const requestData1 = unique.map(function (row) {
            let pendingList=[]
            let userList=[]
            
          
            props.nextData.map(function (row1){
                if(row1.clinician_id==row){
                    if(row1.document_status=="Pending"){
                        pendingList.push(row1)
                    }
                    userList.push(row1)
                    
                }
            })
           
            return{
                        id:userList[0].clinician_id,
                        UserImageSrc: userList[0].clinician_avatar,
                        UserName:userList[0].clinician_name,
                        UserPosition: userList[0].clinician_position,
                        PendingDocuments: pendingList.length,
                        LastUpdateDate: moment(userList[0].document_updated_date).format('DD/MM/YYYY'),
                    }
         })
       setUserCliniciansData(requestData1)
       setLoader(false)

    }, [props.searchInputKey, searchInput, props.filterInputKey, filterInput, props.nextData])


    const [loader, setLoader] = useState(true)
    const [classe, setClasse] = useState(false)
    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])

    return (
        <>
            {UserCliniciansData ? (
                <table width="100%">
                    <thead>
                        <tr>
                            <th>Clinician Name</th>
                            <th>Position</th>
                            <th>Documents</th>
                            <th>Last updated</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                        <div className={classe}></div>
                            {UserCliniciansData.map((val, index) =>
                            
                                <tr key={index}>
                                    <td>
                                        <div className="clinincians-user-name">
                                            <div className='clinicians-image-thumbnail'>
                                                <img src={val.UserImageSrc} alt="" />
                                            </div>
                                            <span>{val.UserName}</span>
                                        </div>

                                    </td>
                                    <td>{val.UserPosition}</td>
                                    <td>
                                        <div className="file-data">
                                            {val.PendingDocuments} Pending
                                        </div>
                                    </td>
                                    <td>{val.LastUpdateDate}</td>
                                    <td>
                                        <Link to={RoutesPath.documentsDetails} state={val.id} className="btn btn-primary border-btn">See details</Link>
                                    </td>
                                </tr>

                            )}
                        </>
                    </tbody>
                </table>
            ) : ""}
            {UserCliniciansData&&UserCliniciansData.length==0?(<div className='no-data-div'>No data available</div>):""}


        </>
    )
}
export default DocumentsTable;
