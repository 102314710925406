import React, { useState, useEffect } from 'react';
import FeedbacksContent from './FeedbacksContent';



const FeedbacksTopFilterSection = (props) => {

    return (
        <>
            <div className='timesheets-main-section '>
                <div className='user-tab-wrapper'>
                    <div className="user-tab-col">
                        <div className="col-inner" ref={props.feedbacksref} >
                            <FeedbacksContent handleRefresh={props.handleRefresh} refresh={props.refresh} facilityName={props.facilityName} facilityId={props.facilityId}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbacksTopFilterSection;