import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import InputGroupForm from '../components/inner-components/InputGroupForm';
import ImageUploadInput from '../components/inner-components/ImageUploadInput';
import DateofBirthInput from '../components/inner-components/DateofBirthInput';
import { useNavigate, Link } from 'react-router-dom';
import CustomDropdown from '../components/inner-components/CustomDropdown';
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { ENV } from '../env/env'
import { Buffer } from 'buffer';
import S3FileUpload from 'react-s3';
import { snackbar } from '@mobiscroll/react';
import SwitchToggle from '../components/inner-components/SwitchToggle';

const ManageAdminEdit = (props) => {

    const inputRef = useRef(null)

    const JobSitesData = [
        { value: 'Select Facility', label: 'Select Facility', isdisable: "disable" },
        { value: 'bristol-village', label: 'Bristol Village' },
        { value: 'chillicothe-campus', label: 'Chillicothe Campus' },
        { value: 'Toledo', label: 'Toledo' },


    ]

    const [show, setShow] = useState(false);
    const [errorMessages, setErrorMessages] = useState("");
    const [loader, setLoader] = useState(false)
    const [classe, setClasse] = useState()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    const navigate = useNavigate();
    const location = useLocation();

    window.Buffer = Buffer;
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState(location.state.data.UserImageSrc)


    const [dob, setDob] = useState(location.state.data.date_of_birth)
    const [model, setModel] = useState({
        admin_id: location.state.data.admin_id,
        userName: location.state.data.username,
        Email: location.state.data.Email,
        Password: location.state.data.password,
        FirstName: location.state.data.FirstName,
        LastName: location.state.data.LastName,
        Position: location.state.data.position,
        Contact_No: location.state.data.ContactNo,
        UserImageSrc: location.state.data.UserImageSrc,
        scheduleAccess: location.state.data.scheduleAccess,
        Address: location.state.data.Address
    });
    const [password, setPassword] = useState(location.state.data.password)

    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        await axios.patch(ENV.ENDPOINT + `/admin/edit/${location.state.data.admin_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setLoader(false)
                setTimeout(() => {
                    snackbar({
                        message: 'Admin Edited Successfully'
                    });
                });
                navigate('/manage-users')
            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])


    const handleChange = (event) => {
        // get the value of the field

        const value = event?.target?.value;
        //set it in the model
        setModel({
            ...model,
            [event.target.name]: value
        });



    };
    const handleDropDown = (event) => {
        const value = event?.target?.value;

        setModel({
            ...model,
            jobsites: value
        });
    }

    const [year, month, day] = location.state.data.Dob.split('-');
    const result1 = [month, day, year].join('/');
    const handledDate = date => {
        let datevalue = date.format()

        const [month, day, year] = datevalue.split('/');
        const result = [year, month, day].join('-');

        setDob(result)
    }
    const [sites, setSites] = useState()
    const [facilities, setFacilities] = useState([])
    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + '/facility/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {


                setFacilities(response.data.payload)


            })
            .catch((error) => {
                console.log(error)
            })

        setSites(location.state.data.job_sites)
    }, [])
    const optionsFacilities = facilities.map(function (row) {
        return { value: row.facility_name }
    })


    const handleJobSites = (selection) => {

        setSites(selection.value)

    }



    const handleImage = (e) => {

        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setErrorMessages({ name: "image", message: "" });
            setImage(e)
        }

    }

    const handleSubmit = (event) => {

        setLoader(true)
        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {

                            let username = model.userName
                            let email = model.Email

                            let role = model.Position
                            let firstname = model.FirstName
                            let lastname = model.LastName
                            let contact_no = model.Contact_No
                            let avatar_image = response.data.location
                            let password = model.Password
                            let address = model.Address
                            let date_of_birth = dob
                            let allow_scheduling=model.scheduleAccess

                            const token = apiCall({
                                username,
                                email,
                                password,
                                allow_scheduling,
                                firstname,
                                lastname,
                                contact_no,
                                avatar_image,
                                role,
                                address,
                                date_of_birth
                            });

                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })


                };
                reader.onerror = error => reject(error);
            });
        }
        if (image != undefined) {
            getBase64(image)
        }
        else {
            let username = model.userName
            let email = model.Email
            let allow_scheduling=model.scheduleAccess
            let role = model.Position
            let firstname = model.FirstName
            let lastname = model.LastName
            let contact_no = model.Contact_No
            let avatar_image = imageURL
            let password = model.Password
            let address = model.Address
            let date_of_birth = dob

            const token = apiCall({
                username,
                email,
                password,
                allow_scheduling,
                firstname,
                lastname,
                contact_no,
                avatar_image,
                role,
                address,
                date_of_birth
            });
        }







        //navigate('/manage-users')
    }

    const handleDelete = () => {
        let token = localStorage.getItem('token')

        axios.delete(ENV.ENDPOINT + `/admin/delete/${location.state.data.admin_id}/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {


                navigate('/manage-users')

            })
            .catch((error) => {
                console.log(error.response.data)
            })

    }

    const handleResetPass = () => {
        inputRef.current.value = ""
    }
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const handleScheduleSwitch = (e, id) => {
        setModel({
            ...model,
            scheduleAccess: e.target.checked
        });
    }

    return (
        <div className='edit-profile-main'>
            <div className='edit-user-wrapper'>
                <div class="user-header">
                    <a class="back-btn" id="UserbackBtn" href="/manage-users"><i class="icon-Back-arrow"></i></a>
                    <h6 class="poc-name-title">{model.FirstName + " " + model.LastName}</h6>

                </div>
                <div className='edit-user'>
                    {/* <Form> */}
                    <div className="edit-user-top">
                        <div className='user-image'>
                            <div className='image-upload'>
                                <ImageUploadInput UserImage={imageURL} handleImage={handleImage} />
                            </div>
                            {renderErrorMessage("image")}
                        </div>

                        <div className="edit-user-form">
                            <Row className="form-row">
                                <Col md={6}>
                                    <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={model.userName} handleChange={handleChange} />
                                </Col>
                                {/* <Col md={12}>
                                        <InputGroupForm inputId="fullName" inputLabel="Full name" inputType="text" inputPlaceholder="Text here" inputName="fullName" inputValue="Mellani Jones" />
                                    </Col> */}
                                <Col md={6}>
                                    <InputGroupForm inputId="firstName" inputLabel="First name" inputType="text" inputPlaceholder="Text here" inputName="FirstName" inputValue={model.FirstName} handleChange={handleChange} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="lastName" inputLabel="Last name" inputType="text" inputPlaceholder="Text here" inputName="LastName" inputValue={model.LastName} handleChange={handleChange} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={model.Email} handleChange={handleChange} />
                                </Col>
                                <Col md={6} className="password-field">
                                    <div className="password-group">
                                        <Form.Group className="form-group" controlId={'Password'}>
                                            <Form.Label className="">{"Password"}</Form.Label>
                                            <Form.Control ref={inputRef} type={"password"} placeholder={"*****"} name={"Password"} defaultValue={password} onChange={handleChange} />
                                        </Form.Group>
                                        {/* <InputGroupForm inputId="Password" inputLabel="Password" inputType="password" inputPlaceholder="*****" inputName="Password" inputValue={password} /> */}
                                        <button type='button' className='btn reset-pass-btn btn-primary' onClick={handleResetPass}>Reset</button>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="form-group date-of-birth-input">
                                        <label className='form-label'>Date of birth</label>
                                        <DateofBirthInput inputValue={result1} handleChange={handledDate} />
                                    </div>
                                </Col>


                            </Row>
                        </div>

                    </div>
                    <div className={classe}></div>
                    <div className='edit-user-bottom edit-user-form'>
                        <Row className="form-row">
                            {model.Position == 'facility_admin' ? (
                                <Col md={6}>
                                    <div className='form-group'>
                                        <label className='form-label'>Job Sites</label>
                                        <CustomDropdown optionData={JobSitesData} />
                                    </div>
                                </Col>
                            ) : ""}


                            <Col md={6}>
                                <InputGroupForm inputId="phoneNumber" inputLabel="Phone" inputType="number" inputPlaceholder="Text here" inputName="Contact_No" inputValue={model.Contact_No} handleChange={handleChange} />
                            </Col>
                            <Col md={6}>
                                <InputGroupForm inputId="Address" inputLabel="Address" inputType="text" inputPlaceholder="Text here" inputName="Address" inputValue={model.Address} handleChange={handleChange} />
                            </Col>
                            <Col md={6}>
                                <div className="scheduling-btn">
                                    <h6>Scheduling Access</h6>
                                    <SwitchToggle dataSwitch={model.scheduleAccess} data={e => handleScheduleSwitch(e, model.admin_id)} />
                                </div>
                            </Col>

                        </Row>
                    </div>
                    <div className='edit-user-footer'>
                        <div className='btn-wrap'>
                            <button className='btn border-btn' onClick={handleDelete}>Delete Admin</button>
                            <button className='btn btn-primary' onClick={handleSubmit}>Save Changes</button>
                        </div>
                        <div className='change-password-popup'>
                            <Modal show={show} onHide={handleClose} className='change-password-popup'>
                                <Modal.Header closeButton>
                                    <h5>Change Password</h5>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="form-inner">
                                        <form>
                                            <InputGroupForm inputId="oldPassword" inputLabel="Enter old password" inputType="password" inputPlaceholder="*****" inputName="oldPassword" />
                                            <div className='new-password-group'>
                                                <InputGroupForm inputId="createNewPassword" inputLabel="Create new password" inputType="password" inputPlaceholder="Enter new password" inputName="createNewPassword" />
                                                <InputGroupForm inputId="reenterNewPassword" inputLabel="" inputType="password" inputPlaceholder="Re-enter new password" inputName="reenterNewPassword" />
                                            </div>
                                            <div className='submit-btn'>
                                                <input type="submit" className='btn btn-primary' value="Save changes" />
                                            </div>
                                        </form>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                    {/* </Form> */}
                </div>
            </div>
        </div>


    )
}

export default ManageAdminEdit;