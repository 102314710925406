import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { log, debounce } from './utils/utils'
import { useGlobalState, useGlobalMutation } from './utils/container'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { ENV } from '../env/env'

const useStyles = makeStyles(theme => ({
  call: {
    color: '#fff'
  },
  midItem: {
    marginTop: '1rem',
    marginBottom: '6rem'
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  coverCall: {
    background: 'linear-gradient(to bottom, #307AFF, 50%, #46cdff)',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  coverContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    position: 'relative'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    display: 'flex',
    minWidth: 350,
    maxWidth: 350,
    minHeight: 500,
    maxHeight: 500,
    borderRadius: '10px',
    boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
  },
  input: {
    maxWidth: '250px',
    minWidth: '250px',
    alignSelf: 'center'
  },
  grid: {
    margin: '0 !important'
  },
  wait: {
    fontFamily: 'HelveticaNeue',
    fontSize: '18px',
    color: '#ffffff',
    textAlign: 'left',
    marginTop: '-78px',
  },
  number: {
    fontFamily: 'HelveticaNeue',
    fontSize: '48px',
    color: '#ffffff',
    letterSpacing: '10px',
    textAlign: 'left',
  },
  iconCall: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    height: '44px',
    width: '260px',
    '&:hover': {
      backgroundColor: '#307AFF'
    },
    margin: theme.spacing(1),
    marginTop: '33px',
    backgroundColor: '#44a2fc',
    borderRadius: '30px'
  }
}))

export default function CardPage() {
  const navigate = useNavigate()
  const mutationCtx = useGlobalMutation()
  const classes = useStyles()
  const stateCtx = useGlobalState()
  const [refuse, setRefuse] = useState(false)
  const [answer, setAnswer] = useState(false)

  const handleClickStartCall = () => {
  
    stateCtx.rtmClient.acceptCall()
    // let token = localStorage.getItem('token')
    // const credentials = {

    //   peerId: localStorage.getItem('user_id'),
    //   peerName: localStorage.getItem("fullname"),
    //   channelId: CHANNEL_NAME,
    //   message: "Remote Call Accepted",
    //   message_type: "call",
    //   image: localStorage.getItem('avatarImage'),

    // }
    // axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
    //   .then(response => {

    //   })
    //   .catch((error) => {
    //   })
  }

  const handleClickEndCall = () => {

    stateCtx.rtmClient.refuseCall()

  }

  const mouseOverRefuse = () => {
    setRefuse(true)
  }

  const mouseOutRefuse = () => {
    setRefuse(false)
  }

  const mouseOverAnswer = () => {
    setAnswer(true)
  }

  const mouseOutAnswer = () => {
    setAnswer(false)
  }
  useEffect(() => {

   
    stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {

      mutationCtx.updatePeerCode(remoteInvitation.callerId)
      mutationCtx.updateIncomingCode(remoteInvitation.callerId)

      mutationCtx.updateConfig({ channelName: remoteInvitation.content[2] })

      navigate({
        pathname: `/call-invi/card/indexCard/incoming/`
      })
    })

    stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
    
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
      mutationCtx.toastError('Call not answered')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
      log('Accept success')
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      log('channel id', channel)
      let token = localStorage.getItem('token')
    const credentials = {

      peerId: localStorage.getItem('user_id'),
      peerName: localStorage.getItem("fullname"),
      channelId: channel,
      message: "Remote Call Accepted",
      message_type: "call",
      image: localStorage.getItem('avatarImage'),

    }
    axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {

      })
      .catch((error) => {
      })
      navigate({
        pathname: `/meeting/${channel}`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      log('channel id', channel)
      navigate({
        pathname: `/meeting/${channel}`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationRefused', () => {
      mutationCtx.toastError('Peer is busy')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
      console.log("call declined from another user")
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/calling/`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationFailure', () => {
      mutationCtx.toastError('Call process failed')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })
    console.log(stateCtx)
    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners()
      //   navigate('/card')
    }

  }, [stateCtx.rtmClient, stateCtx])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Card className={classes.card}>
        <Box display="flex" flex="1" >
          <div className={classes.coverCall + " " + "call-box-wrapper"}>
            <div className='call-top-header'>
              <div className={classes.item + " " + "call-box-single"}>
                <div className='head-box'>
                  <div className='agora-pluse'></div>
                  <div className='head-image'>
                    <img src={stateCtx.incomingImage} alt='' />
                  </div>
                </div>
              </div>
              <div className={classes.item + " " + "call-box-single"}>
                <div className={classes.number}>{stateCtx.incomingCode}</div>
              </div>
            </div>
            <div className={classes.item + " " + "call-box-single"}>
              <div className={classes.wait + " " + "calling-text"}>Incoming...</div>
            </div>
            <div className={classes.item + " " + "call-box-single"}>
              <div className={classes.iconCall + " " + "call-btn-wrapper"}>
                {/* <div 
                  onMouseOver={mouseOverRefuse} onMouseOut={mouseOutRefuse}
                  className={refuse ? 'endCall-image-incoming-pressed' : 'endCall-image-incoming'}
                  onClick={debounce(handleClickEndCall, 200)}
                /> */}
                {/* <div
                  onMouseOver={mouseOverAnswer} onMouseOut={mouseOutAnswer}
                  className={answer ? 'startCall-image-pressed' : 'startCall-image'} 
                  onClick={debounce(handleClickStartCall, 200)}
                /> */}
                <button onMouseOver={mouseOverRefuse} onMouseOut={mouseOutRefuse} className={refuse ? 'endCall-image-incoming-pressed call-btn end-call-btn' : 'endCall-image-incoming call-btn end-call-btn'} onClick={debounce(handleClickEndCall, 200)}></button>
                <button onMouseOver={mouseOverAnswer} onMouseOut={mouseOutAnswer} className={answer ? 'startCall-image-pressed call-btn start-call-btn' : 'startCall-image call-btn start-call-btn'} onClick={debounce(handleClickStartCall, 200)}></button>
              </div>
            </div>
          </div>
        </Box>
      </Card>
    </Box>
  )
}
