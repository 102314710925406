import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomDropdown from '../inner-components/CustomDropdown';
import FilterByDate from '../inner-components/FilterByDate';
import RecentActivitiyTable from '../inner-components/RecentActivitiyTable';
import { RoutesPath } from '../../App';
import moment from 'moment';

const DashboardTableSection = (props) => {
    const [filterDate,setFilterDate]=useState()
    const [dropdownData,setDropdownData]=useState("")
    // const FacilityOptions = [
    //     { value: 'All', label: 'All' },
    //     { value: 'Success', label: 'Success' },
    //     { value: 'Failed', label: 'Failed' },
    //     { value: 'Processing', label: 'Processing' },
    //     { value: 'Pending', label: 'Pending' }
    // ]
    const FacilityOptions = [
        { value: 'All', label: 'All' },
        { value: 'Payments', label: 'Payments' },
        { value: 'No Shows', label: 'No Shows' },
        { value: 'Cancellations', label: 'Cancellations' },
        { value: 'Clock ins', label: 'Clock ins' },
        { value: 'Clock outs', label: 'Clock outs' },
        { value: 'Feedbacks', label: 'Feedbacks' },
        { value: 'Shift request approve', label: 'Shift request approve' },
       
    ]
    const handleDate=(e)=>{
     
        setFilterDate(moment(e.year+"-"+e.month+"-"+e.day).format('YYYY-MM-DD'))
    }
    const handleFilter = (e) => {
        // console.log(selection)
        // if(e.value=='all'){
        //     e.value=""
        // }
        
        setDropdownData(e.value)
        // if (selection.value == "Active") {
        //     setFilterInput("True")
        // }
        // else if (selection.value == "Deactive") {
        //     setFilterInput("False")
        // }
        // else {
        //     setFilterInput("")
        // }

    }
   
    return (
  
            <div className='dashboard-bottom-wrapper dashboard-table-section  ' >
                <div className="table-top-wrapper">
                    <Row className="table-top-row " >
                        <Col className="col-md-8 table-title-col ">
                            <div className="col-inner">
                                <h2 className='h5 section-title ' >Recent Activity</h2>
                                <div className='activity-dropdown'>
                                    <CustomDropdown optionData={FacilityOptions} dropClass="without-search" filterInput={handleFilter}/>
                                </div>
                            </div>
                        </Col>
                        <Col className="col-md-4 calender-col">
                            <div className='col-inner'>
                                <div className="calender-filter">
                                    <FilterByDate handleChange={handleDate}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="table-wrapper recent-activity-table table-responsive">
                    <RecentActivitiyTable filterDate={filterDate} dropdownData={dropdownData} handleRefresh={props.handleRefresh} refresh={props.refresh}/>
                </div>
            </div>
    )
}

export default DashboardTableSection;