import React, { useState, useEffect } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';


const ApproveDeclinedSwitch = (props) => {

    const approveRequest = (e) => {
        props.handleSwitch("Approved")
    };

    const declineRequest = (e) => {
       
        props.handleSwitch("Declined")
    };

    return (

        <div className="status-toggle-switch">
            <button type='button' className='approve-btn' onClick={approveRequest}>
            </button>
            <button type='button' className='decline-btn' onClick={declineRequest}>
            </button>
        </div>

    )
}

export default ApproveDeclinedSwitch;