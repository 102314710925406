import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import { HalfPieChart } from "half-pie-chart";
import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'

const HalfCirclePieChart = (props) => {
    
    const [totalRequest,setTotalRequest]=useState()
    const [totalFacilityRequest,setTotalFacilityRequest]=useState()
    const customPalette = ['#2753b8', '#0ea80f', '#e22960'];

    const values = [5];

    const format = {
    type: 'fixedPoint',
    precision: 1,
    };

    useEffect(()=>{
     
      let date1=props.dateRange.date1
      let date2=props.dateRange.date2
      let token = localStorage.getItem('token') 
      axios.get(ENV.ENDPOINT+`/scheduling/view/`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
        
        // setTotalRequest(response.data.results.length)
        let dateData = []
                const dateRange = response.data.results.map(function (rowDate) {
                    if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

                        if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
                            dateData.push(rowDate)
                        }
                    }
                   
                })
     
                setTotalRequest(dateData.length)
      })
      .catch((error) => {
          console.log(error.response.data)
      })

      axios.get(ENV.ENDPOINT+`/scheduling/view/?facility_id=${localStorage.getItem('facility_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
      .then(response => {
        let dateData = []
                const dateRange = response.data.results.map(function (rowDate) {
                    if (moment(rowDate.start_date).format('YYYY-MM-DD') >= moment(date1).format('YYYY-MM-DD')) {

                        if (moment(rowDate.start_date).format('YYYY-MM-DD') <= moment(date2).format('YYYY-MM-DD')) {
                            dateData.push(rowDate)
                        }
                    }
                   
                })
         setTotalFacilityRequest(dateData.length)

      })
      .catch((error) => {
          console.log(error.response.data)
      })
    },[props.dateRange])

    const totalRequestRight = [
        {
          value: totalFacilityRequest?totalFacilityRequest:0,
          displayValue: "0",
        //   text: "Collected",
          color: "#2753b8",
        },
    ]

    const totalRequestLeft = [
        {
          value:  totalRequest?totalRequest-totalFacilityRequest:1.1,
          displayValue: totalRequest?totalRequest:0,
        //   text: "Pending",
          color: "#fff",
        },
    ]

   
    return (
        <HalfPieChart
            name="totalRequestChart"
            right={totalRequestRight}
            left={totalRequestLeft}
            // title="Rent Status"
            centerText={"Total Request"}
        />
    )
}

export default HalfCirclePieChart;