import React, { useContext } from 'react'
import { BrowserRouter, HashRouter as Router, Route, Routes } from 'react-router-dom'

export const RouterContext = React.createContext({})

export const BrowserRouterHook = ({ children }) => {
  return(
    <Routes>
    <Route>
      {(routeProps) => (
        <RouterContext.Provider value={routeProps}>
          {children}
        </RouterContext.Provider>
      )}
    </Route>
  </Routes>
  )

}

 
   
  


export default function useRouter () {
  return useContext(RouterContext)
}
