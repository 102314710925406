import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import AddFacilityPopup1 from './AddFacilityPopup1';
import AddFacilityAdminPopup from './AddFacilityAdminPopup';
import AddFacilityAccessPopup from './AddFacilityAccessPopup';
import SearchInput from './SearchInput';
import UserAdminFacilityTable from './UserAdminFacilityTable';
import TablePagination from './TablePagination';
import CustomDropdown from './CustomDropdown';
import SwitchToggle from './SwitchToggle';
import axios from "axios";
import { ENV } from '../../env/env'

import { RoutesPath } from '../../App';
import { useNavigate, Link } from 'react-router-dom';
import RenewToken from '../inner-components/RenewToken';


const UserFacilityFilter = [
    { value: 'All Facilities', label: 'All Facilities' },
    { value: 'Active', label: 'Active' },
    { value: 'Deactive', label: 'Deactive' }
]
const FacilityTabContentSuperAdmin = (props) => {

    const navigate = useNavigate();

    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [filterInput, setFilterInput] = useState("")
    const [link, setLink] = useState(ENV.ENDPOINT + `/admin/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [reRender, setReRender] = useState(false)

    const [facilities, setFacilities] = useState()
    useEffect(() => {
        let token = localStorage.getItem('token')

        axios.get(ENV.ENDPOINT + `/facility/view/?search=${searchInput},${filterInput}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setFacilities(response.data.results)
                
            })
            .catch((error) => {
                if (error.response.data.code == 'token_not_valid') {
                    RenewToken.RenewTokenAPI()
                }
            })
            
    }, [reRender, searchInput, filterInput,props])


    const handleChange = (event) => {
        setSearchInput(event.target.value)

    }
    const handleFilter = (selection) => {
        if (selection.value == "Active") {
            setFilterInput("True")
        }
        else if (selection.value == "Deactive") {
            setFilterInput("False")
        }
        else {
            setFilterInput("")
        }
    }


    const handlePage = (e) => {
        setCurrentPage(e.page)
        setData(e.results)
        setTotalPage(e.total_pages)
        if (e.links.next != undefined && e.links.next != "") {
            setNextPage(true)
            setNextLink(e.links.next)

        }
        setPrevLink(e.links.previous)
    }
    const handleLink = () => {

        let token = localStorage.getItem('token')
        // let link=`http://127.0.0.1:8000/api/clinician/view/`
        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                setData(response.data.results)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)
                }
                setPrevLink(response.data.links.previous)
            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        // let link=`http://127.0.0.1:8000/api/clinician/view/`
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setData(response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)
                }
                setPrevLink(response.data.links.previous)
            })
            .catch((error) => {
                console.log(error)
            })

    }
    const handleRender = (e) => {
        setReRender(e)
    }
  
    
    return (
        <div className='facility-tab-content tab-content-wrap facility-accordion-tab-content'>
            <div className='filter-row-wrapper'>
                <AddFacilityPopup1 ModelTitle="Add Facility" handleRender={handleRender} handleRefresh={props.handleRefresh}/>
                <div className='table-filter-row'>
                    <SearchInput searchInput={handleChange} />
                    <CustomDropdown optionData={UserFacilityFilter} dropClass="without-search" filterInput={handleFilter} />
                </div>
            </div>
            <div className='all-facility-wrapper'>
                <div className='facility-accordion'>

                    <Accordion defaultActiveKey="1">
                        {facilities && facilities.map((val, index) =>
                            <Accordion.Item eventKey={index} key={val.facility_id + index}>
                                <Accordion.Header>
                                    {val.facility_name}
                                    <div className='edit-btn'>
                                        <a href='' className='btn btn-primary' onClick={() => navigate(RoutesPath.managefacilityEdit, { state: { data: val } })}><i class="icon-edit" ></i>Edit</a>
                                    
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='facility-content-wrap'>
                                        <div className="facility-type-single">
                                            <div className='facility-inner'>
                                                <div className='title-wrap'>
                                                    <h5 className='facility-title'>Facility Admin</h5>
                                                    <div className="add-facility-popup-btn-wrapper">
                                                        <AddFacilityAdminPopup ModelTitle="Add Facility admin" facility_id={val.facility_id} handleRender={handleRender} />
                                                    </div>
                                                </div>
                                                <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
                                                    <div className='table-wrapper table-responsive'>
                                                        <UserAdminFacilityTable facility_id={val.facility_id} role={"facility_admin"} handlePage={handlePage} rerender={reRender} handleRender={handleRender} />
                                                    </div>
                                                    <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="facility-type-single">
                                            <div className='facility-inner'>
                                                <div className='title-wrap'>
                                                    <h5 className='facility-title'>Facility Access</h5>
                                                    <div className="add-facility-popup-btn-wrapper">
                                                        <AddFacilityAccessPopup ModelTitle="Add facility access" facility_id={val.facility_id} handleRender={handleRender} />
                                                    </div>
                                                </div>

                                                <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
                                                    <div className='table-wrapper table-responsive'>
                                                        <UserAdminFacilityTable facility_id={val.facility_id} role={"poc_admin"} handlePage={handlePage} rerender={reRender} handleRender={handleRender} />
                                                    </div>
                                                    <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        )}

                        {/* <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Chillicothe Campus
                                <div className='edit-btn'>
                                    <a href='#' className='btn btn-primary'><i class="icon-edit"></i>Edit</a>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className='facility-content-wrap'>
                                    <div className="facility-type-single">
                                        <div className='facility-inner'>
                                            <div className='title-wrap'>
                                                <h5 className='facility-title'>Facility Admin</h5>
                                                <div className="add-facility-popup-btn-wrapper">
                                                    <AddFacilityPopup ModelTitle="Add facility admin" />
                                                </div>
                                            </div>
                                            <h5 className='facility-title'>Facility Admin</h5>
                                            <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
                                                <div className='table-wrapper table-responsive'>
                                                    <UserAdminFacilityTable />
                                                </div>
                                                <TablePagination />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="facility-type-single">
                                        <div className='facility-inner'>
                                        <div className='title-wrap'>
                                                <h5 className='facility-title'>Facility Access</h5>
                                                <div className="add-facility-popup-btn-wrapper">
                                                    <AddFacilityPopup ModelTitle="Add facility access" />
                                                </div>
                                            </div>
                                            <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
                                                <div className='table-wrapper table-responsive'>
                                                    <UserAdminFacilityTable />
                                                </div>
                                                <TablePagination />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item> */}
                    </Accordion>
                </div>
            </div>

        </div>
    )

}


export default FacilityTabContentSuperAdmin;