import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { ENV } from "../../env/env";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Notification = (props) => {
  const [showClass, setShowClass] = useState("");
  const [NotificationData, setNotificationData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [unread, setUnRead] = useState(false);

  const [count, setCount] = useState(false);
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (!count) {
      axios
        .get(
          ENV.ENDPOINT +
            `/notification/view/?user_id=${localStorage.getItem("user_id")}`,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          let list = [];
          const dateRange = response.data.results.map(function (row) {
            if (row.seen == "active") {
              setUnRead(true);
            }
            return {
              notification_id: row.notification_id,
              notificationText: row.notification,
              notificationDate: moment(row.created_date).format(
                "MMM D, YYYY, "
              ),
              notificationTime: moment(row.created_date).format("h:mm:ss A"),
              unreadNotificationClassName: row.seen,
            };
          });
          list.push(dateRange);

          setNotificationData(list[0]);
        })
        .catch((error) => {
          console.log(error);
        });

      setRefresh(false);
      setCount(true);
    } else {
      const interval = setInterval(() => {
        axios
          .get(
            ENV.ENDPOINT +
              `/notification/view/?user_id=${localStorage.getItem("user_id")}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          .then((response) => {
            let list = [];
            const dateRange = response.data.results.map(function (row) {
              if (row.seen == "active") {
                setUnRead(true);
              }
              return {
                notification_id: row.notification_id,
                notificationText: row.notification,
                notificationDate: moment(row.created_date).format(
                  "MMM D, YYYY, "
                ),
                notificationTime: moment(row.created_date).format("h:mm:ss A"),
                unreadNotificationClassName: row.seen,
              };
            });
            list.push(dateRange);

            setNotificationData(list[0]);
          })
          .catch((error) => {
            console.log(error);
          });

        setRefresh(false);
      }, 15000);
      return () => clearInterval(interval);
    }
  }, [refresh, unread, count]);
  useEffect(() => {}, [unread]);

  const popupOpen = () => {
    setShowClass("show");
    document.body.classList.add("modal-open");
  };

  const popupClose = (e) => {
    setShowClass("");
    document.body.classList.remove("modal-open");
    handleRead();
  };

  const handleDelete = (val) => {
    console.log("delete", val);
    let token = localStorage.getItem("token");
    axios
      .delete(ENV.ENDPOINT + `/notification/delete/${val.notification_id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleRead = () => {
    console.log("read function");
    let token = localStorage.getItem("token");
    var seen;
    var credentials = {
      seen: "deactive",
    };
    NotificationData.map(function (row) {
      axios
        .patch(
          ENV.ENDPOINT + `/notification/edit/${row.notification_id}/`,
          credentials,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setUnRead(false);
          setRefresh(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  return (
    <div className={`notification-wrap ${showClass}`} ref={props.ref5}>
      <Button    
        className="notification-btn btn-white btn-square"
        onClick={popupOpen}
      >
        {unread ? (
          <>
            <div className="newDiv" >
              <span className="icon-button__badge"></span>
            </div>
            <div className="newDiv2">
              <span className="material-icons notification-icon-badge">
                notifications
              </span>
            </div>
          </>
        ) : (
          <span className="material-icons notification-icon">notifications</span>
        )}
      </Button>
      <div
        className={`notification-main all-notification notification-popup ${showClass}`}
      >
        <div className="all-notification-inner">
          <div className="popup-header">
            <h5 className="notification-title">Notification</h5>
            <button className="close-btn" onClick={popupClose}></button>
          </div>
          {NotificationData ? (
            <div className="notification-list">
              {NotificationData.map((val, index) => (
                <div
                  className={`notification-single ${val.unreadNotificationClassName}`}
                  key={index}
                >
                  <div className="notification-text">
                    <h6>{val.notificationText}</h6>
                    <div className="time-date-wrap">
                      <span>{val.notificationDate}</span>
                      <span>{val.notificationTime}</span>
                    </div>
                  </div>
                  <Button
                    className="delete-btn btn-white"
                    onClick={(e) => handleDelete(val)}
                  >
                    <i className="icon-trash"></i>
                  </Button>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Notification;
