
import AgoraRTM from 'agora-rtm-sdk'
import { log } from './utils/utils'
import EventEmitter from 'events'
import { useNavigate } from 'react-router-dom'

const appID = 'c501ed932b7b4571b68af340322001e0'
 
export default class RTMClient {
  
  constructor () {
    this._client = AgoraRTM.createInstance(appID)
    this._uid = ''
    this._token = undefined
    this._invitation = null
    this._remoteInvitation = null
    this.eventBus = new EventEmitter() 

    //This status is used to control whether the phone can be accessed
    //The 'onLine' is ok.
    this.status = 'offLine' //onLine, offLine, calling, meeting
   
    this.peerInvitation()
    //console.clear()
  }
  
 
  //login uid
  async login(uid) {
    await this._client.login({ uid, token: null })
    // this._uid = uid
    // await this._client.login({
    //   token: null,
    //   uid: uid,
    // })
   
    this.status = 'onLine'
    //console.clear()
  }
  
  //Query login status of peers and return asynchronous
  inquire(peerIds) {
    
    return this._client.queryPeersOnlineStatus(peerIds)

  }

  localInvitation(calleeId, channel,userName,userImage) {
    if(this._invitation !== null) {
      this._invitation.removeAllListeners()
      this._invitation = null
    }

    //Create a local invitation
    this._invitation = this._client.createLocalInvitation(calleeId,userName)

    this.status = 'calling'
    this.eventBus.emit('LocalInvitationReceivedByPeer')

    this._invitation.on('LocalInvitationReceivedByPeer', () => {
    
      this.status = 'Ringing'
      this.eventBus.emit('LocalInvitationReceivedByPeer')
    })


    //Cancel call invitation
    this._invitation.on('LocalInvitationCanceled', () => {
     
      this.status = 'onLine'
      this.eventBus.emit('LocalInvitationCanceled')
    })

    //Called accepted call invitation
    this._invitation.on('LocalInvitationAccepted', () => {
   
      this.status = 'meeting'
      this.eventBus.emit('LocalInvitationAccepted')
    })

    //Called down
    this._invitation.on('LocalInvitationRefused', () => {
    
      this.status = 'onLine'
      this.eventBus.emit('LocalInvitationRefused')
    })

    //Local call failed
    this._invitation.on('LocalInvitationFailure', () => {
    
      this.status = 'onLine'
      this.eventBus.emit('LocalInvitationFailure')
    })
    
    //this._invitation.content = [userName,userImage,channel]
    this._invitation.content = JSON.stringify({name:userName,image:userImage,channel:channel})
    this._invitation.channelId=channel

    //Send call invitation locally
    this._invitation.send()  
  }
  
  peerInvitation() {
    //Remote monitor receives call invitation
    this._client.on('RemoteInvitationReceived', (remoteInvitation) => {
    
      if(this.status !== 'onLine') {
        setTimeout(() => {
          remoteInvitation.refuse()
        }, 1000)
        return
      }
      if(this._remoteInvitation !== null) {
        this._remoteInvitation.removeAllListeners()
        this._remoteInvitation = null
      }
      this.status = 'calling'
      this._remoteInvitation = remoteInvitation 
      this.peerEvents()
      this.eventBus.emit('RemoteInvitationReceived', remoteInvitation)
    }) 
  }
//admin/edit
//clinician/edit
  peerEvents () {
    //The caller has cancelled the call invitation
    this._remoteInvitation.on('RemoteInvitationCanceled', () => {
     
      this.status = 'onLine'
      this.eventBus.emit('RemoteInvitationCanceled')
    })

    //Accepted call invitation successfully
    this._remoteInvitation.on('RemoteInvitationAccepted', () => {
    
      this.eventBus.emit('RemoteInvitationAccepted')
    })

    //Call invitation rejected successfully
    this._remoteInvitation.on('RemoteInvitationRefused', () => {
    
      this.eventBus.emit('RemoteInvitationRefused')
    })

    //Call invitation process failed
    this._remoteInvitation.on('RemoteInvitationFailure', () => {
    
      this.status = 'onLine'
      this.eventBus.emit('RemoteInvitationFailure')
    })
  }
  
  //Cancel call invitation
  cancelCall() {
    this._invitation && this._invitation.cancel()
    this.status = 'onLine'
  }

  //Accept call invitation
  acceptCall() {
    this._remoteInvitation && this._remoteInvitation.accept()
    this.status = 'meeting'
  }

  //Decline call invitation
  refuseCall() {
    this._remoteInvitation && this._remoteInvitation.refuse()
    this.status = 'onLine'
  }

  on (evt, callback) {
    const customEvents = [
      'LocalInvitationReceivedByPeer',
      'LocalInvitationCanceled',
      'LocalInvitationAccepted',
      'LocalInvitationRefused',
      'LocalInvitationFailure',
      'RemoteInvitationReceived',
      'RemoteInvitationCanceled',
      'RemoteInvitationAccepted',
      'RemoteInvitationRefused',
      'RemoteInvitationFailure',
    ]
    if (customEvents.indexOf(evt) !== -1) {
      this.eventBus.on(evt, callback)
      return
    }
    this._client.on(evt, callback)
  }
}
