import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal, Button } from 'react-bootstrap';
import CustomDropdown from '../inner-components/CustomDropdown';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';

const InvoiceTransactionTable = (props) => {
    const [invoiceTransactionData, setInvoiceTransactionData] = useState()
    const [render,setRender]=useState(false)

    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT +
            `/invoice/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                console.log(response.data.results, "invoice")
                const data=response.data.results.map(function (row) {
                    return {
                        invoice_id: row.invoice_id,
                        serviceDate: moment(row.service_date).format('MM/DD/YYYY'),
                        cliniciansName: row.clinician_name,
                        position: row.position,
                        workhours: row.hrs_worked,
                        rateText: row.rate,
                        amount: row.amount,
                    }
                })
                setInvoiceTransactionData(data)
            })
            .catch((error) => {
                console.log(error.response.data)
            })
            setRender(false)
            props.handleRender(false)
    }, [render,props])

    const handleDelete=(id)=>{
        let token = localStorage.getItem('token')
        axios.delete(ENV.ENDPOINT+`/invoice/delete/${id}/`,{headers:{"Authorization":`Bearer ${token}`}})
        .then(response=>{
           setRender(true) 
        })
        .catch((error)=>{
            console.log(error.response)
        })
    }

    const invoiceTransactionData1 = [
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "CNA",
            workhours: 12,
            rateText: 41.85,
            amount: 512,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "RN",
            workhours: 12.89,
            rateText: 72,
            amount: 300,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "CNA",
            workhours: 12,
            rateText: 41.85,
            amount: 512,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Emily Wilson",
            position: "LVN",
            workhours: 12,
            rateText: 28.85,
            amount: 245,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "CNA",
            workhours: 12,
            rateText: 41.85,
            amount: 512,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "CNA",
            workhours: 12,
            rateText: 41.85,
            amount: 512,
        },
        {
            serviceDate: "03/14/2022",
            cliniciansName: "Jane Doe",
            position: "CNA",
            workhours: 12,
            rateText: 41.85,
            amount: 512,
        },
    ]
   
    
    const discountAmountData = [
        { value: 'Discount Percent', label: 'Discount Percent'},
        { value: '50', label: '50%' },
        { value: '20', label: '20%' },
        { value: '10', label: '10%' },
    ]
    return (



        <div className='invoice-table-wrapper'>
            <div className='user-facilty-table-wrapper pagination-table invoice-transaction-table'>
                <div className="table-wrapper table-responsive">
                    <table width="100%">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Service date</th>
                                <th>Clinician name</th>
                                <th>Position</th>
                                <th>Hrs worked</th>
                                <th>Rate</th>
                                <th>Amount (USD)</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {invoiceTransactionData&&invoiceTransactionData.map((val, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{val.serviceDate}</td>
                                        <td>{val.cliniciansName}</td>
                                        <td>{val.position}</td>
                                        <td>{val.workhours}</td>
                                        <td>{val.rateText}</td>
                                        <td>{val.amount}</td>
                                        <td><Button className="delete-btn" onClick={e=>handleDelete(val.invoice_id)}><i className='icon-trash'></i></Button></td>
                                    </tr>

                                )}
                            </>
                        </tbody>
                    </table>
                </div>
                <div className="amount-total-counter-wrapper">
                    <ul>
                        <li>
                            <div className="amount-label">
                                <span>Subtotal</span>
                            </div>
                            <div className='amount-count'>
                                <span>1,041.65</span>
                            </div>
                        </li>
                        <li>
                            <div className="amount-label">
                                <CustomDropdown optionData={discountAmountData} dropClass="without-search" />
                                <input type="text" placeHolder="Enter" id="discountPercentage" />
                            </div>
                            <div className='amount-count'>
                                <span>00.00</span>
                            </div>
                        </li>
                        <li>
                            <div className="amount-label">
                                <span>Total</span>
                            </div>
                            <div className='amount-count'>
                                <span>1,041.65</span>
                            </div>
                        </li>
                        <li>
                            <div className="amount-label">
                                <span>Amount received</span>
                            </div>
                            <div className='amount-count'>
                                <span>937.49</span>
                            </div>
                        </li>
                        <li>
                            <div className="amount-label">
                                <span>Balance due</span>
                            </div>
                            <div className='amount-count'>
                                <span>104.16</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


    )
}

export default InvoiceTransactionTable;