import React, { useState, useEffect } from 'react';
import { Row, Col, Tab, Nav, Accordion, Card, CustomToggle, Modal, Button } from 'react-bootstrap';
import TextEditorBox from '../inner-components/TextEditorBox';
import SearchInput from '../inner-components/SearchInput';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';
import Parser from 'html-react-parser';
import { setRef } from '@material-ui/core';
import { useNavigate } from 'react-router-dom'
import RenewToken from '../inner-components/RenewToken';
import { snackbar } from '@mobiscroll/react';

const NotesMainSection = (props) => {

    const navigate = useNavigate()
    const [noteValue, setNoteValue] = useState()
    const [noteAttriutes, setNoteAttributes] = useState()
    const [notesData, setNotesData] = useState()
    const [id, setId] = useState()
    const [searchInput, setSearchInput] = useState('')
    const [refresh, setRefresh] = useState(false)
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const searchFilter = (e) => {
        setSearchInput(e.target.value)
    }
    const defaultNote = [
        {
            id: 1,
            notesTitle: "Your First Note",
            notesDate: "May 19th",
            notesFullDate: "19 May 2022",
            FullNoteTime: "11:48 am",
            FullNoteDatap1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non nulla bibendum sapien vel dolor in vulputate consequat. Tincidunt eget pellentesque metus sed enim.",
            FullNoteDatap2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non nulla bibendum sapien vel dolor in vulputate consequat. Tincidunt eget pellentesque metus sed enim. ",
            FullNoteDatap3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non nulla bibendum sapien vel dolor in vulputate consequat. Tincidunt eget pellentesque metus sed enim. ",
        }
    ]


    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/notes/view/?user_id=${localStorage.getItem('user_id')}&search=${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setId(response.data.results[0].note_id)
                const dateRange = response.data.results.map(function (row) {
                    const format = [{ attributes: JSON.parse(row.attribute), insert: row.note }]
                    if (row.attribute == null) {
                        const format = row.note
                    }
    
                    return ({
                        id: row.note_id,
                        notesTitle: row.note,
                        notesDate: moment(row.created_date).format('MMM Do'),
                        notesFullDate: moment(row.created_date).format('DD MMM YYYY'),
                        FullNoteTime: moment(row.created_time).format('hh:mm a'),
                        FullNoteDatap1: row.note,
                        formattedString: format
                    })

                })
            
                setNotesData(dateRange)
               
          
            })
            .catch((error) => {
                console.log(error)
                if (error.response.data.code == 'token_not_valid') {
                    RenewToken.RenewTokenAPI()
                }
            })
        setRefresh(false)

    }, [refresh, searchInput]);

    useEffect(()=>{
        
    },[notesData])

    const ops = [{
        attributes
            :
            { bold: true },
        insert
            :
            "hello"
    }]
   

    const viewNoteChange = () => {
        const EditBtn = document.querySelectorAll('.edit-note-btn');
        [].forEach.call(EditBtn, function (item) {
            item.onclick = function () {
                const editNote = item.parentNode.querySelector('.edit-note');
                const viewNote = item.parentNode.querySelector('.view-note');
                editNote.classList.add('show');
                viewNote.classList.add('hide');
                item.classList.add('hide');
            }
        });
        addNoteChange();
    }

    const addNoteChange = () => {
        const addNewBtn = document.querySelector('.add-note-btn');
        const addNewNote = document.querySelector('.add-note');
        const nodataDiv = document.querySelector('.no-notes-text');
        const closeNoteBtn = document.querySelector('.note-close-btn');
        addNewBtn.onclick = function () {
            nodataDiv.classList.add("hide");
            addNewNote.classList.add('show');
            addNewNote.nextElementSibling.classList.add('hide');
        }
        closeNoteBtn.onclick = function () {
            addNewNote.classList.remove('show');
            nodataDiv.classList.remove("hide");
            addNewNote.nextElementSibling.classList.remove('hide');
        }
    }

    useEffect(() => {
        viewNoteChange();

    }, [notesData]);

    async function createNotes(credentials) {
        let token = localStorage.getItem('token')
        axios.post(ENV.ENDPOINT + '/notes/create/', credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setTimeout(() => {
                    snackbar({
                        message: 'Note Added successfully'
                    });
                });


            })
            .catch((error) => {
                console.log(error)
            })


    }
    async function editNotes(credentials) {
        let token = localStorage.getItem('token')
        axios.patch(ENV.ENDPOINT + `/notes/edit/${id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setTimeout(() => {
                    snackbar({
                        message: 'Note Edited successfully'
                    });
                });


            })
            .catch((error) => {
                console.log(error)
            })


    }

    // const handleNotes = (content, delta, source, editor) => {
    //     setNoteValue(editor)
    //     setNoteAttributes(editor.getContents().ops[0].attributes)
    //     console.log(editor.getContents().ops[0].insert)
    //     var quill=editor.getContents()
    //     console.log(editor.getContents().ops[0])

    // }
    const handleNotes = (e) => {
        setNoteValue(e)
    }
    function handleEdit() {
        let note = noteValue
        const token = editNotes({
            note,
        });

    }

    const handleDelete = () => {
     
        let token = localStorage.getItem('token')

        axios.delete(ENV.ENDPOINT + `/notes/delete/${id}/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setRefresh(true)
                handleClose()
                setTimeout(() => {
                    snackbar({
                        message: 'Note Deleted successfully'
                    });
                });
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleSubmit = () => {

        let note = noteValue
        let user_id = localStorage.getItem('user_id')
        let created_date = moment().format('YYYY-MM-DD')
        let created_time = moment()
        let attribute = JSON.stringify(noteAttriutes)
        const token = createNotes({
            note,
            user_id,
            created_date,
            created_time,
            attribute
        });
    }
    return (
        <>


            <div className="notes-main-wrapper">
                <div className="notes-tab-wrapper">

                    <div className={notesData == undefined ? 'notes-tab no-notes-wrapper' : 'notes-tab'}>

                        <Tab.Container id="notesTab" defaultActiveKey="1">
                            <Row>
                                <Col md={4} className="notes-tab-btn-col">
                                    <div className='col-inner'>
                                        <div className="notes-top-header">
                                            <div className="top-header-inner">
                                                <SearchInput searchInput={searchFilter} />

                                            </div>

                                        </div>
                                        {notesData ? (
                                            <Nav variant="pills" className="flex-column" onSelect={e => setId(e)}>
                                                {notesData.map((val, index) =>
                                                    <Nav.Item key={val.id}>
                                                        <Nav.Link eventKey={val.id} >
                                                            <div className="notes-tab-header">
                                                                <h6>{Parser(val.notesTitle)}</h6>
                                                                <span className='note-sub-text'>
                                                                    <span>{Parser(val.FullNoteDatap1)}</span>
                                                                    <span className='date'>{val.notesDate}</span>
                                                                </span>
                                                                <button className='btn-square btn delete-btn' onClick={handleShow}>
                                                                    <i className='icon-trash'></i>
                                                                </button>
                                                                <Modal show={show} onHide={handleClose} className='custom-popup delete-popup' >
                                                                <Modal.Header closeButton>

                                                                </Modal.Header>
                                                                <Modal.Body>
                                                                    <div className='modal-content-inner'>
                                                                        <p>Are you sure to delete note?</p>
                                                                        <div className='btn-wrap'>
                                                                            <Button className="btn btn-primary btn-small" onClick={handleDelete}>
                                                                                Delete
                                                                            </Button>
                                                                        </div>

                                                                    </div>

                                                                </Modal.Body>

                                                            </Modal>
                                                        </div>
                                                    </Nav.Link>
                                                    </Nav.Item>
                                        )}
                                    </Nav>
                                        ) : ""}
                                </div>
                            </Col>
                            <Col md={8} className="notes-tab-content-col">
                                <div className='no-notes-text'>
                                    No Notes available
                                </div>
                                <div className='add-note edit-note'>
                                    <form>
                                        <div className='form-inner'>
                                            <div className="add-note-header">
                                                <h4>Add new note</h4>
                                                <button type='button' className="close-btn note-close-btn"></button>
                                            </div>

                                            <div className='editor-wrapper'>
                                                <TextEditorBox editorValue={noteValue} onChange={handleNotes} />
                                                <button className='btn btn-primary' onClick={handleSubmit}>Add Note</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                {notesData ? (
                                    <Tab.Content>
                                        <Accordion defaultActiveKey="12">
                                            {notesData.map((val, index) =>
                                                <Tab.Pane eventKey={val.id} key={val.id} >
                                                    <Accordion.Item eventKey={val.id} >

                                                        <Accordion.Header>
                                                            <div className="notes-tab-header">
                                                                <h6>{Parser(val.notesTitle)}</h6>
                                                                <span className='note-sub-text'>
                                                                    <span>{Parser(val.FullNoteDatap1)}</span>
                                                                    <span className='date'>{val.note}</span>
                                                                </span>
                                                                <button className='btn-square btn delete-btn' onClick={handleDelete}>
                                                                    <i className='icon-trash'></i>
                                                                </button>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='notes-tab-content'>
                                                                <button className='btn btn-primary edit-note-btn'><i className='icon-edit'></i>Edit</button>
                                                                <div className='view-note'>
                                                                    <span className='date-text'>Created {val.notesFullDate} at {val.FullNoteTime}</span>
                                                                    <h4 className='h2'>{Parser(val.notesTitle)}</h4>
                                                                    <div className='notes-tab-text'>
                                                                        <p>{Parser(val.FullNoteDatap1)}</p>
                                                                        <p>{val.FullNoteDatap2}</p>
                                                                        <p>{val.FullNoteDatap3}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='edit-note'>
                                                                    <form>
                                                                        <div className='form-inner'>
                                                                            <TextEditorBox editorValue={val.FullNoteDatap1} onChange={handleNotes} />
                                                                            <button className='save-changes-btn btn btn-primary' onClick={handleEdit}>Save changes</button>
                                                                        </div>
                                                                    </form>
                                                                </div>

                                                            </div>
                                                        </Accordion.Body>

                                                    </Accordion.Item>
                                                </Tab.Pane>
                                            )}
                                        </Accordion>
                                    </Tab.Content>
                                ) : ""}
                            </Col>
                        </Row>
                    </Tab.Container>

                </div>

            </div>
        </div>

        </>
    )
}

export default NotesMainSection;