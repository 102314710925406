import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col, Modal } from "react-bootstrap";
import InputGroupForm from "./InputGroupForm";
import ImageUploadInput from "./ImageUploadInput";
import DateofBirthInput from "./DateofBirthInput";
import CustomDropdown from "./CustomDropdown";
import DatePicker from "react-multi-date-picker";
import { RoutesPath } from "../../App";
import axios from "axios";
import { ENV } from "../../env/env";
import { Buffer } from "buffer";
import S3FileUpload from "react-s3";
import ReusableAPI from "./NotificationApi";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { snackbar } from "@mobiscroll/react";
const AddCliniciansPopup = (props) => {
  window.Buffer = Buffer;
  const [image, setImage] = useState();
  const [errorMessages, setErrorMessages] = useState("");
  const [clinicianPosition, setClinicianPosition] = useState();
  const [loader, setLoader] = useState(false);
  const [classe, setClasse] = useState();

  const navigate = useNavigate();

  async function apiCall(credentials) {
    let token = localStorage.getItem("token");

    await axios
      .post(ENV.ENDPOINT + "/clinician/", credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.validationEmail) {
          setError("email", {
            type: "server",
            message: "Email Already Exists",
          });
          setLoader(false);
          return;
        } else if (response.data.validationUsername) {
          setError("userName", {
            type: "server",
            message: "Username Already Exists",
          });
          setLoader(false);
          return;
        } else {
          //Triggering event notification
          console.log(credentials.firstname + " " + credentials.lastname);
          var notificationMsg = `Added ${
            credentials.firstname + " " + credentials.lastname
          } as Clinician`;
          ReusableAPI.notificationTriggerAPI(notificationMsg);
        }
        if (response.data.errors == undefined) {
          props.handleRender(true);
          setLoader(false);
          handleClose();
          reset();

          setTimeout(() => {
            snackbar({
              message: "Clinician Added Successfully",
            });
          });
        }
      })
      .catch((error) => {
        console.log(error.response);

        if (error.response.data.email) {
          setError("email", {
            type: "server",
            message: "Email Already Exists",
          });
        }
        if (error.response.data.username) {
          setError("userName", {
            type: "server",
            message: "Username Already Exists",
          });
        }
      });
  }

  useEffect(() => {
    if (loader == true) {
      setClasse("custom-loader");
    } else {
      setClasse("custom-loader hide");
    }
  }, [loader]);

  const [dob, setDob] = useState();
  const handledDate = (date) => {
    let datevalue = date.format();
    setDob(datevalue);
  };

  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + "/facility/", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setFacilities(response.data.payload);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const optionsFacilities = facilities.map(function (row) {
    return { value: row.facility_name, label: row.facility_name };
  });

  const [sites, setSites] = useState();

  const handleJobSites = (e) => {
    let list = [];

    e.map(function (row) {
      list.push(row.value);
    });
    setSites(list);
  };
  const positionData = [
    { value: "", label: "Select Position", isdisable: "disable" },
    { value: "CNA", label: "CNA" },
    { value: "RN", label: "RN" },
    { value: "LPN", label: "LPN" },
    { value: "STNA", label: "STNA" },
  ];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
  } = useForm();

  const handleImage = (e) => {
    if (e.size > 500000) {
      setErrorMessages({
        name: "image",
        message: "Please upload image below 500KB in size",
      });
      setImage();
    } else {
      setErrorMessages({ name: "image", message: "" });
      setImage(e);
    }
  };

  const handlePosition = (e) => {
    setClinicianPosition(e.value);
  };

  const onSubmit = (data) => {
    if (clinicianPosition === undefined && image === undefined) {
      setErrorMessages({ name: "image", message: "Please upload image" });
      setErrorMessages({
        name: "positionDropdown",
        message: "Please select position",
      });
    } else if (clinicianPosition === undefined) {
      setErrorMessages({
        name: "positionDropdown",
        message: "Please select position",
      });
      setErrorMessages({ name: "image", message: "" });
    } else if (image === undefined) {
      setErrorMessages({ name: "positionDropdown", message: "" });
      setErrorMessages({
        name: "image",
        message: "Please upload image & image must be less than 500kb",
      });
    } else {
      setErrorMessages({ name: "image", message: "" });
      setErrorMessages({ name: "positionDropdown", message: "" });
      if (image instanceof Blob) {
        setLoader(true);
        getBase64(image);
      } else {
        setLoader(false);
        const [month, day, year] = dob.split("/");
        const result = [year, month, day].join("-");

        let username = data.CliniciansName;
        let email = data.email;
        let password = data.password;
        let clinician_position = clinicianPosition;
        let firstname = data.FirstName;
        let lastname = data.LastName;
        let contact_no = data.ContactNumber;
        let avatar_image = image;
        let role = data.CliniciansRole;
        let job_sites = sites;
        let date_of_birth = result;
        let position = "clinician";
        let address = data.Address;

        const token = apiCall({
          username,
          email,
          password,
          clinician_position,
          firstname,
          lastname,
          contact_no,
          avatar_image,
          role,
          job_sites,
          date_of_birth,
          position,
          address,
        });
      }
    }

    function getBase64(e) {
      setErrorMessages({ name: "image", message: "" });
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = async () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          // setImage(encoded)
          const credentials = {
            user_id: localStorage.getItem("user_id"),
            data: encoded,
          };
          let token = localStorage.getItem("token");

          await axios
            .post(ENV.ENDPOINT + `/s3upload/image/`, credentials, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              setImage(response.data.location);
              const [month, day, year] = dob.split("/");
              const result = [year, month, day].join("-");

              let username = data.CliniciansName;
              let email = data.email;
              let password = data.password;
              let clinician_position = clinicianPosition;
              let firstname = data.FirstName;
              let lastname = data.LastName;
              let contact_no = data.ContactNumber;
              let avatar_image = response.data.location;
              let role = data.CliniciansRole;
              let job_sites = sites;
              let date_of_birth = result;
              let position = "clinician";
              let address = data.Address;

              const token = apiCall({
                username,
                email,
                password,
                clinician_position,
                firstname,
                lastname,
                contact_no,
                avatar_image,
                role,
                job_sites,
                date_of_birth,
                position,
                address,
              });
            })
            .catch((error) => {
              console.log(error.response);
            });
          const byteSize = (str) => new Blob([str]).size;
        };
        reader.onerror = (error) => reject(error);
      });
    }
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <div className="add-user-pop-wrap">
      <Button className="border-btn" onClick={handleShow}>
        <i className="icon-plus"></i> Add Clinician
      </Button>
      <Modal show={show} onHide={handleClose} className="add-user-popup">
        <Modal.Header closeButton>
          <h5>{props.ModelTitle}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className={classe}></div>
          <div className="add-user-form">
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="new-password">
              <div className="form-inner">
                <Row className="form-row">
                  <Col md={6}>
                    <div className="image-upload">
                      <ImageUploadInput handleImage={handleImage} />
                    </div>
                    {renderErrorMessage("image")}
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-group"
                      controlId="CliniciansName"
                      autoComplete="false"
                    >
                      <Form.Label className="">User name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        autoComplete="new-password"
                        name="CliniciansName"
                        {...register("CliniciansName", {
                          required: true,
                          maxLength: 50,
                          minLength: 1,
                          pattern: {
                            // value: /^[a-zA-Z0-9_@.-]*$/,
                            value: /^[a-zA-Z_@.-]*$/,
                          },
                        })}
                      />
                      <span className="error-message">
                        {errors.CliniciansName?.type === "required" &&
                          "enter Username"}
                        {errors.CliniciansName?.type === "maxLength" &&
                          "It accepts maximum 50 characters"}
                        {errors.CliniciansName?.type === "minLength" &&
                          "enter minimum 1 characters"}
                        {/* {errors.CliniciansName?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"} */}
                        {errors.CliniciansName?.type === "pattern" &&
                          "It doesn't allow special characters excepts _ . @ -"}
                        {errors.CliniciansName && errors.CliniciansName.message}
                      </span>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="Email">
                      <Form.Label className="">Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Text here"
                        name="Email"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          },
                        })}
                      />
                      <span className="error-message">
                        {errors.email?.type === "required" && "enter Email"}
                        {errors.email?.type === "pattern" &&
                          "Please enter a valid email"}
                        {errors.email?.type === "server" &&
                          "Email Already Exists"}
                      </span>
                    </Form.Group>
                    <Form.Group className="form-group" controlId="Password">
                      <Form.Label className="">Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Text here"
                        name="Password"
                        {...register("password", {
                          required: true,
                          pattern: {
                            value:
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,50}$/i,
                          },
                        })}
                      />
                      <span className="error-message">
                        {errors.password?.type === "required" &&
                          "enter Password"}
                        {errors.password?.type === "pattern" &&
                          "The password must match the following criteria: Minimum 8 characters, Include at least 1 letter, Include at least 1 number and maximum 12 characters long."}
                      </span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={12}>
                    <Form.Group className="form-group" controlId="Address">
                      <Form.Label className="">Location</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="State"
                        name="Address"
                        {...register("Address", {
                          required: true,
                          maxLength: 200,
                          minLength: 5,
                        })}
                      />
                      <span className="error-message">
                        {errors.Address?.type === "required" && "enter Address"}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group" controlId="FirstName">
                      <Form.Label className="">Firstname</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        name="FirstName"
                        {...register("FirstName", {
                          required: true,
                          maxLength: 50,
                          minLength: 1,
                          pattern: {
                            value: /^[a-zA-Z]*$/,
                          },
                        })}
                      />
                      <span className="error-message">
                        {errors.FirstName?.type === "required" &&
                          "enter Firstname"}
                        {errors.FirstName?.type === "maxLength" &&
                          "It accepts maximum 50 characters"}
                        {errors.FirstName?.type === "minLength" &&
                          "enter minimum 1 characters"}
                        {errors.FirstName?.type === "pattern" &&
                          "It is text field"}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="form-group" controlId="LastName">
                      <Form.Label className="">Lastname</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        name="LastName"
                        {...register("LastName", {
                          required: true,
                          maxLength: 50,
                          minLength: 1,
                          pattern: {
                            value: /^[a-zA-Z]*$/,
                          },
                        })}
                      />
                      <span className="error-message">
                        {errors.LastName?.type === "required" &&
                          "enter Lastname"}
                        {errors.LastName?.type === "maxLength" &&
                          "It accepts maximum 50 characters"}
                        {errors.LastName?.type === "minLength" &&
                          "enter minimum 1 characters"}
                        {errors.LastName?.type === "pattern" &&
                          "It is text field"}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <div className="form-group date-of-birth-input">
                      <label className="form-label">Date of birth</label>
                      <DatePicker
                        format="MM/DD/YYYY"
                        placeholder="mm/dd/yyyy"
                        className="form-control"
                        maxDate={new Date()}
                        onChange={handledDate}
                      />
                      <span className="error-message">
                        {errors.dateBirth?.type === "required" &&
                          "select date of birth"}
                      </span>
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-group"
                      controlId="ContactNumber"
                    >
                      <Form.Label className="">Contact Number</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Text here"
                        name="ContactNumber"
                        {...register("ContactNumber", {
                          required: true,
                          maxLength: 20,
                          minLength: 10,
                        })}
                      />
                      <span className="error-message">
                        {errors.LastName?.type === "required" &&
                          "enter Contact Number"}
                        {errors.ContactNumber?.type === "maxLength" &&
                          "It accepts maximum 20 characters"}
                        {errors.ContactNumber?.type === "minLength" &&
                          "enter minimum 10 characters"}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <div className="form-group custom-dropdown">
                      <label className="form-label">Position</label>
                      <Select
                        options={positionData}
                        isClearable="false"
                        id="CliniciansPosition"
                        name="positionDropdown"
                        onChange={handlePosition}
                      />
                      {renderErrorMessage("positionDropdown")}
                    </div>
                  </Col>
                  <Col md={6}>
                    <Form.Group
                      className="form-group"
                      controlId="CliniciansRole"
                    >
                      <Form.Label className="">Role</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        name="CliniciansRole"
                        {...register("CliniciansRole", {
                          required: true,
                          maxLength: 50,
                          minLength: 2,
                        })}
                      />
                      <span className="error-message">
                        {errors.CliniciansRole?.type === "required" &&
                          "enter Role"}
                        {errors.CliniciansRole?.type === "maxLength" &&
                          "It accepts maximum 50 characters"}
                        {errors.CliniciansRole?.type === "minLength" &&
                          "enter minimum 2 characters"}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <div className="form-group custom-dropdown">
                      <label className="form-label">Job Sites</label>
                      <Select
                        options={optionsFacilities}
                        isMulti
                        className="multi-select-dropdown"
                        isClearable="false"
                        onChange={handleJobSites}
                      />
                      <span className="error-message">
                        {errors.CliniciansRole?.type === "required" &&
                          "Select Job Sites"}
                      </span>
                    </div>
                  </Col>
                </Row>
                <div className="submit-btn">
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddCliniciansPopup;