import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import CustomDropdown from './CustomDropdown1';
import Notification from './Notification';
import MessagesButton from './MessagesButton';
import { useNavigate } from 'react-router-dom';


const TopFilterDropdownsCol = (props) => {
    const [filterInput,setFilterInput]=useState()
    
    const handleFilter=(selection)=>{  
        localStorage.setItem("facility",selection.value)
        localStorage.setItem("facility_id",selection.id)
        props.handleRefresh(true)
    }




    const optionsFacilities=props.facilities.map(function(row){
        return {value:row.facility_name,id:row.facility_id}
    })
    return (
        <>
            <Col xl={5} lg={6} className="right-col-wrap top-dropdown-col" >
                <div className='col-inner'>
                    {localStorage.getItem('position')=='c_level_emp'||localStorage.getItem('position')=='hmc_admin'?<CustomDropdown optionData={optionsFacilities} dashboard={true} filterInput={handleFilter} selectedFacility={props.selectedFacility}/>:""}
                    <div className='flex gap-2'  ref={props.ref4}>
                    <Notification  ref5={props.ref5}/>
                    <MessagesButton ref6={props.ref6}/>
                    </div>
                </div>
            </Col>
        </>
    )
}




export default TopFilterDropdownsCol;