import React, { useState, useEffect } from 'react';
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import ManageTopHeaderSection from "../components/manage-user/ManageTopHeaderSection";
import EditProfileSection from '../components/edit-profile/EditProfileSection';
import EditProfileSection2 from '../components/edit-profile/EditProfileSection2';
import EditProfileFacilityAccessSection from '../components/edit-profile/EditProfileFacilityAccessSection';
import EditProfileSuperAdminSection from '../components/edit-profile/EditProfileSuperAdminSection';
import { Row, Col } from 'react-bootstrap';

const EditProfile = () => {

    
    return (
        <>
            <main className='app-main'>
                <HeaderMobile />
                <section className="main-section dashboard-section blue-bg-section edit-profile-section">
                    <div className="section-wrap">
                        <Row className="dashboard-row-main">
                            <LeftPanel />
                            <Col lg="9" className="right-content-col grey-bg-col">
                            <ManageTopHeaderSection pageTitle="Edit Profile"/>
                            {localStorage.getItem("position")=='pocc_admin'?<EditProfileFacilityAccessSection />:localStorage.getItem("position")=='c_level_emp'||localStorage.getItem("position")=='hmc_admin'||localStorage.getItem("position")=='facility_admin'||localStorage.getItem("position")=='poc_admin'?<EditProfileSuperAdminSection /> : <EditProfileSection />}
                            </Col>
                        </Row>
                        
                    </div>
                </section>
            </main>
        </>
    )
}

export default EditProfile;