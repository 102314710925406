import React, { useState, useEffect } from 'react';
import SwitchToggle from './SwitchToggle';
import FacilityTableMenuDropdown from './FacilityTableMenuDropdown';
import axios from "axios";
import { ENV } from '../../env/env'


const UserAdminFacilityTable = (props) => {
    const [userData,setUserData]=useState()
    useEffect(()=>{
        let token = localStorage.getItem('token')

        axios.get(ENV.ENDPOINT + `/admin/view/?facility=${props.facility_id}&role=${props.role}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
              
                setUserData(response.data.results)
                const data=response.data.results.map(function (row) {
                    if(row.role==props.role){
                        
                    }
                    let status
                    let shiftAccess
                    let statusTagClassName
                    if(row.is_active==true){
                        status='Active'
                        statusTagClassName="success"
                    }else{
                        status='Inactive'
                        statusTagClassName="failed"
                    }
                    if(row.allow_scheduling=='true'){
                        shiftAccess="On"
                    }
                    else{
                        shiftAccess='Of'
                    }
                    return({
                        admin_id:row.admin_id,
                        UserImageSrc:row.avatar_image,
                        username:row.username,
                        FirstName:row.firstname,
                        LastName:row.lastname,
                        Email:row.email,
                        ContactNo:row.contact_no,
                        Status:status,
                        statusTagClassName: statusTagClassName,
                        applyShiftchecked: row.allow_scheduling,
                        date_of_birth:row.date_of_birth,
                        Address:row.address,
                        is_active:row.is_active,
                        password:row.password,
                        position:row.roles
                    })
                })
                setUserData(data)
                props.handlePage(response.data)
            })
            .catch((error) => {
                console.log(error.response.data)
            })
            props.handleRender(false)
    },[props.rerender])

    const handleShiftSwitch=(e,id)=>{
      
        async function apiCall(credentials) {
            let token=localStorage.getItem('token')
            await axios.patch(ENV.ENDPOINT+`/admin/allowScheduling/${id}/`,credentials,{headers:{"Authorization":`Bearer ${token}`}})
            .then(response=>{ 
              
            })
            .catch((error)=>{
                console.log(error.response.data)
            })
         }
        let allow_scheduling=e.target.checked
        const token =  apiCall({
            allow_scheduling
        });
    }

    const UserCliniciansData = [
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Camille",
            LastName:"Moreno",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Active",
            statusTagClassName:"success",
            applyShiftchecked: "Of",
        },
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Arthur",
            LastName:"Hawkins",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Active",
            statusTagClassName:"success",
            applyShiftchecked: "On",
        },
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Dianne",
            LastName:"Edwards",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Deactive",
            statusTagClassName:"failed",
            applyShiftchecked: "On",
        },
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Shane",
            LastName:"Hawkins",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Active",
            statusTagClassName:"success",
            applyShiftchecked: "On",
        },
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Eduardo",
            LastName:"Richards",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Active",
            statusTagClassName:"success",
            applyShiftchecked: "On",
        },
        {
        
            UserImageSrc:"assets/images/user-shift-image.png",
            FirstName:"Mitchell",
            LastName:"Mitchell",
            Position:"CNA",
            Email:"cedrix@ondek.com",
            ContactNo:"09222222222",
            Status:"Active",
            statusTagClassName:"success",
            applyShiftchecked: "On",
        },
    ]
    return (
        <>
        <table width="100%">
            <thead>
                <tr>
                    <th></th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Contact</th>
                    <th>Status</th>
                    {props.role=='facility_admin'?"":<th>Scheduling Access</th>}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <>
                    {userData && userData.map((val, index) =>
                        <tr key={index}>
                            <td>
                                <div className='clinicians-image-thumbnail'>
                                    <img src={val.UserImageSrc} alt=""/>
                                </div>
                            </td>
                            <td>{val.FirstName}</td>
                            <td>{val.LastName}</td>
                           
                            <td className='email-td'>{val.Email}</td>
                            <td className='mobile-td'>{val.ContactNo}</td>
                            
                            <td>
                                <span className={`status-tag ${val.statusTagClassName}`}>{val.Status}</span>
                            </td>
                            {props.role=='facility_admin'?"":(
                                 <td>
                                 <SwitchToggle dataSwitch={val.applyShiftchecked} switchToggleID="shiftApproveSwitch" data={e=>handleShiftSwitch(e,val.admin_id)}/>
                             </td>
                            )}
                           
                            <td>
                                <FacilityTableMenuDropdown  data={val} handleRender={props.handleRender}/>
                            </td>
                        </tr>

                    )}
                </>
            </tbody>
        </table>
        
        </>
    )
}

export default UserAdminFacilityTable;