import React, { useEffect, useRef, useState } from "react";
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import TimesheetsTopHeaderSection from "../components/timesheets/TimesheetsTopHeaderSection";
import TimesheetsTopFilterSection from "../components/timesheets/TimesheetsTopFilterSection";
import { Row, Col } from "react-bootstrap";
import "../assets/css/Custom.css";
import moment from "moment";

//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from "./utils/container";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { log } from "./utils/utils";
import useRouter from "./utils/use-router";
import { Outlet, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";
import useLocalStorage from "./utils/useLocalStorage";
import { Tour } from "antd";
import POCSignature from "../assets/POCSignature.png"
import { useWindowSize } from "./utils/useWindowSize";
import Cookies from "js-cookie";

const Timesheets = () => {
  //for agora chat and call login
  const navigate = useNavigate();
  const stateCtx = useGlobalState();
  const mutationCtx = useGlobalMutation();

  const routerCtx = useRouter();

  const history = useNavigate();

  useEffect(() => {
    if (stateCtx.loading === true) {
      mutationCtx.stopLoading();
    }
  }, [stateCtx.loading, mutationCtx]);

  useEffect(() => {
    if (stateCtx.rtmClient.status === "offLine") {
      stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
        log(stateCtx.userCode, "is online");
      });
    }
  }, [stateCtx.rtmClient]);

  useEffect(() => {
    stateCtx.rtmClient.on("RemoteInvitationReceived", (remoteInvitation) => {
      let content = JSON.parse(remoteInvitation.content);

      mutationCtx.updatePeerCode(remoteInvitation.callerId);
      mutationCtx.updateIncomingCode(content.name);
      mutationCtx.updateIncomingImage(content.image);
      mutationCtx.updateConfig({ channelName: content.channel });

      navigate({
        pathname: `/call-invi/card/indexCard/incoming/`,
      });
    });

    stateCtx.rtmClient.on("RemoteInvitationCanceled", () => {
      // navigate('/dashboard')
    });

    stateCtx.rtmClient.on("RemoteInvitationFailure", () => {
      mutationCtx.toastError("Call not answered");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationAccepted", () => {
      log("Accept success");
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationAccepted", () => {
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationRefused", () => {
      mutationCtx.toastError("Peer is busy");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationRefused", () => {
      navigate("/call-invi");
    });

    stateCtx.rtmClient.on("LocalInvitationCanceled", () => {
      navigate.goBack();
    });

    stateCtx.rtmClient.on("LocalInvitationReceivedByPeer", () => {
      navigate({
        pathname: `/dialling`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationFailure", () => {
      mutationCtx.toastError("Call process failed");
      history.goBack();
    });

    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners();
      //   navigate('/card')
    };
  }, [stateCtx.rtmClient, stateCtx]);

  const [refresh, setRefresh] = useState(false);
  const handleRefresh = (e) => {
    setRefresh(e);
  };
  const [facilityName, setFacilityName] = useState("All Facilities");
  const [facilityId, setFacilityId] = useState("");
  const handleFacility = (a, b) => {
    setFacilityName(a);
    setFacilityId(b);
  };

  const timehseetsref1 = useRef(null);
  const timehseetsref2 = useRef(null);
  const timehseetsref3 = useRef(null);
  const timehseetsref4 = useRef(null);
 
  const [openTimehseets, setOpenTimehseets] = useState(false);

  const steps = [
    {
      description:
        "Timesheets, when the clinician finishes their shift, it automatically shows here, both facility members and the clinician can leave a note in case there's something they need to address during their shift/assignment.",
      target: () => timehseetsref1.current,
    },
    {
      description:
        "Also make sure to Rate them right after, in that way we can assess which clinicians are doing great.",
      target: () => timehseetsref2.current,
    },
    {
      description:
        "Be sure not to forget to approve or decline the clinician's timesheet right after their shift. You can do so here",
      target: () => timehseetsref3.current,
      cover: (
        <img
          alt="tour.png"
          src={POCSignature}
          style={{width: "100px" , height : "50px"}}
          placement = "right"
        />
      ),
      placement: "right",
    },
    {
      description:
        "If there are too many clicians in your facility or you're just to busy to go through them one by one, the Approve All button is just for you !",
      target: () => timehseetsref4.current, 
    },
  ];


  const size = useWindowSize()


  useEffect(() => {
    if(size.width >= 1250){
    const isModalOpen = Cookies.get("tourTimehseets") === "open";
    if (!isModalOpen ) {
      Cookies.set("tourTimehseets", "open", { expires: 365, path: "" });
      const timeoutId = setTimeout(() => {
        setOpenTimehseets(true);
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
     }
    }
  }, [size.width]);

  return (
    <>
      <main className="app-main">
        <HeaderMobile />
        <section className="main-section dashboard-section blue-bg-section">
          <div className="section-wrap">
            <Row className="dashboard-row-main">
              <LeftPanel />
              <Col
                lg="9"
                className="right-content-col right-content-col-with-banner grey-bg-col"
              >
                <TimesheetsTopHeaderSection
                  handleRefresh={handleRefresh}
                  handleFacility={handleFacility}
                  facilityName={facilityName}
                />
                <TimesheetsTopFilterSection
                timehseetsref1={timehseetsref1}
                timehseetsref2={timehseetsref2}
                timehseetsref3={timehseetsref3}
                timehseetsref4={timehseetsref4}
                  handleRefresh={handleRefresh}
                  refresh={refresh}
                  facilityName={facilityName}
                  facilityId={facilityId}
                />
              </Col>
            </Row>
            <Tour
              open={openTimehseets}
              onClose={() => setOpenTimehseets(false)}
              steps={steps}
              indicatorsRender={(current, total) => <></>}
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default Timesheets;
