import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Modal, Tab, Nav, Accordion, Dropdown } from 'react-bootstrap';
import SearchInput from '../inner-components/SearchInput';
import AllCustomIcon from '../../JsonDataFile/AllCustomIcon';
import AgoraRTM from "agora-rtm-sdk";
import { stringify, v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { ENV } from '../../env/env'
import { CommonSeriesSettingsHoverStyle } from 'devextreme-react/chart';
import { Buffer } from 'buffer';
import S3FileUpload from 'react-s3';
import moment from 'moment';
import UploadFilePopupMessage from './UploadFilePopupMessage';
import { useNavigate } from 'react-router-dom';
import { localeLt } from '@mobiscroll/react';
import { useGlobalState, useGlobalMutation } from '../../pages/utils/container'
import { log, getRoomCode } from '../../pages/utils/utils'

//agora chat and calllogin

import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import useRouter from '../../pages/utils/use-router'

import { Routes, Route } from 'react-router-dom'
import { fireEvent } from '@testing-library/react';
// const uid = uuidv4()
var encode = require('hashcode').hashCode;
const uid = localStorage.getItem('user_id')
const userId = localStorage.getItem('user_data_id')


const MessagesButton1 = (props) => {


    //for agora chat and call login
    const navigate = useNavigate()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const routerCtx = useRouter()

    const history = useNavigate()
   
    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    useEffect(() => {
        if (stateCtx.rtmClient.status === 'offLine') {
            stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
                log(stateCtx.userCode, 'is online')
            }).catch(() => {
                log('Login failure')
                // mutationCtx.toastError('Logon failure')
                return
            })
        }
    }, [stateCtx.rtmClient])

    useEffect(() => {


        stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {

            mutationCtx.updatePeerCode(remoteInvitation.callerId)
            mutationCtx.updateIncomingCode(remoteInvitation.content[0])
            mutationCtx.updateIncomingImage(remoteInvitation.content[1])
            mutationCtx.updateConfig({ channelName: remoteInvitation.content[2] })

            navigate({
                pathname: `/call-invi/card/indexCard/incoming/`
            })
        })

        stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
            
            // navigate('/dashboard')
        })

        stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
            
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
            log('Accept success')
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationRefused', () => {
            mutationCtx.toastError('Peer is busy')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
          
            navigate('/call-invi')
        })

        stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
            navigate.goBack()
        })

        stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
            navigate({
                pathname: `/dialling`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationFailure', () => {
            mutationCtx.toastError('Call process failed')
            history.goBack()
        })

        return () => {
            stateCtx.rtmClient.eventBus.removeAllListeners()
            //   navigate('/card')
        }

    }, [stateCtx.rtmClient, stateCtx])




    // const navigate = useNavigate()
    window.Buffer = Buffer;
    const [CHANNEL_NAME, setCHANNEL_NAME] = useState("")
    //const CHANNEL_NAME = '12345'

    const [text, setText] = useState('')
    const [channel, setChannel] = useState()
    const [messages, setMessages] = useState([])
    const [show, setShow] = useState(false);
    const [chatsData, setChatsData] = useState();
    const [file, setFile] = useState()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [lastMsg, setLastMsg] = useState()
    const [allChats, setAllChats] = useState()
    const [searchInput, setSearchInput] = useState()
    const [status, setStatus] = useState("Deactive")
    const [update, setUpdate] = useState(false)
    const [peerID, setPeerID] = useState()
    const [peerImage, setPeerImage] = useState()
    const [peerUserName, setPeerUserName] = useState()

    const messagesRef = useRef();
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {

        setShow(true);
        mobileTab();
        mobileTabBackEvent();
        // backButtonClick();

    };




    const mobileTab = () => {
        if (window.matchMedia("(max-width: 767px)").matches) {
            setTimeout(() => {
                var tabMainDiv = document.querySelector(".chat-tab-content-col");
                var tabNavButton = document.querySelectorAll(".chat-button-tab-col .nav-link");

                [].forEach.call(tabNavButton, function (item) {
                    item.addEventListener("click", function () {
                        tabMainDiv.classList.add("active");
                    });
                });
                // tabMainDiv.classList.add("active");
            }, 200);
        }
    }

    const mobileTabBackEvent = () => {
        if (window.matchMedia("(max-width: 767px)").matches) {
            setTimeout(() => {

                var chatTabs = document.querySelectorAll(".chat-tab-content-col .tab-pane");
                var tabMain = document.querySelector(".chat-tab-content-col");
                var backButton = document.getElementById("chatBackArrow");

                [].forEach.call(chatTabs, function (item) {
                    item.classList.remove("active");
                    tabMain.classList.remove("active");
                });
                backButton.addEventListener("click", function () {
                    tabMain.classList.remove("active");
                });


            }, 200);
        }

    }



    useEffect(() => {
      
        const activity = storeChat({
            channel: CHANNEL_NAME,
            chat_data: JSON.stringify(messages),
            read: "true",
            last_chat: moment(),
            last_chat_by: uid,
            user_id: localStorage.getItem('user_id'),
            user_fullname: localStorage.getItem('fullname'),
            user_avatar_image: localStorage.getItem('avatarImage'),
            peer_id: peerID,
            peer_fullname: peerUserName,
            peer_avatar_image: peerImage,
            last_message: lastMsg,
            message_by: uid,
            message_to: peerID,
            message_time: new Date(),
            displayed: 'false'
        });
        setUpdate(false)
    }, [update])


    useEffect(() => {

        //for getting left panel chats and data 
        let token = localStorage.getItem('token')
        const last = axios.get(ENV.ENDPOINT + `/chat/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                const chatData = response.data.results.map(function (row) {
                    return { channel: row.channel, chat_data: JSON.parse(row.chat_data), read: row.read, last_chat: row.last_chat, last_chat_by: row.last_chat_by }
                })
                setAllChats(chatData)
                setLoading(true)
            })
    }, [refresh])
   

    useEffect(() => {

        if (searchInput != '' && searchInput != undefined) {

            let token = localStorage.getItem('token')


            axios.get(ENV.ENDPOINT + `/chat/users/`, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    let filtered = []
                    let arr = response.data.payload
                    function filterit(arr) {
                        return arr.filter(function (obj) {
                            return obj.username.search(searchInput) != -1;
                        })
                    }

                    const chatUsers = filterit(arr).map(function (row) {
                        return {
                            id: row.user_id,
                            userImage: row.avatar_image,
                            userName: row.username,
                            notReadMessage: '',
                            user_id: row.user_id,
                            // lastMessageTime: "11:48 am",
                            lastMessageTime: '',
                            lastMessageText: '',
                            last_chat_by: '',

                        }
                    })
                   
                    setChatsData(chatUsers)
                })
                .catch((error) => {
                    console.log(error)
                })


        }
        else if (props.userData != undefined) {
            const chatUsers = [{
                id: props.userData.user
                ,
                userImage: props.userData.avatar_image,
                userName: props.userData.firstname+" "+ props.userData.lastname,
                notReadMessage: '',
                user_id: props.userData.user,

                // lastMessageTime: "11:48 am",
                lastMessageTime: '',
                lastMessageText: '',
                last_chat_by: '',

            }]

        
            setChatsData(chatUsers)
            // selectUser(chatUsers)

        }
        else {
            let token = localStorage.getItem('token')


            axios.get(ENV.ENDPOINT + `/chat/history/view/?user_id=${localStorage.getItem('user_id')}`, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                  
                    const chatUsers = response.data.map(function (row) {
                        return {
                            id: row.chat_hisory_id
                            ,
                            userImage: row.peer_avatar_image,
                            userName: row.peer_username,
                            notReadMessage: row.displayed,
                            user_id: row.peer_id,
                            lastMessageTime: row.message_time,
                            lastMessageText: row.last_message,
                            last_chat_by: row.message_by,

                        }
                    })
                    setChatsData(chatUsers)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [searchInput])

    const [client1, setClient1] = useState()
    const login = async (peerId) => {
        console.log("inside login",peerId)
      
        const APP_ID = 'c501ed932b7b4571b68af340322001e0';
        // const APP_ID = '8e1a42ce9a024271af1ee2651ead3226';
        const client = AgoraRTM.createInstance(APP_ID);
        //await client.login({ uid, token: null })
        setClient1(client)
        stateCtx.rtmClient._client.on('MessageFromPeer', (message, peerId) => {
            setMessages(currentMessages => [...currentMessages, {
                uid: peerId,
                text: message.text
            }])
        })

        setLoading(true)

        // const connection = connect();

        return () => {
            const logout = async () => {
                await client.logout();
            }
            logout();
        }
    }







    const sendMessage = async (e) => {

        e.preventDefault()

        if (text == '') return;

        let msgType = "text"
        let time = new Date()


        await stateCtx.rtmClient._client.sendMessageToPeer(
            {
                text,
                type: 'text',
                time,
                msgType: msgType,
            },
            localStorage.getItem('peer_id_rtm').toString(),
        ).then(sendResult => {

            setMessages(currentMessages => [...currentMessages, {
                uid,
                text,
                time,
                msgType,
            }])
            setLastMsg(text)
            setText('')
            setUpdate(true)
            if (sendResult.hasPeerReceived) {

                // document.getElementById("log").appendChild(document.createElement('div')).append("Message has been received by: " + peerId + " Message: " + peerMessage)

            } else {

                // document.getElementById("log").appendChild(document.createElement('div')).append("Message sent to: " + peerId + " Message: " + peerMessage)

            }
        })
            .catch(err => {
                console.log(err, 'send msg eror')
            })
    }



    const storeChat = (credentials) => {
        let token = localStorage.getItem('token')
        axios.patch(ENV.ENDPOINT + `/chat/history/edit/${CHANNEL_NAME}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
             
                if (response.data.message == "Not found.") {
                    axios.post(ENV.ENDPOINT + `/chat/history/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                           
                        })
                        .catch((error) => {
                            console.log(error.response)

                        })
                }
                setLoading(true)
            })
            .catch((error) => {
                console.log(error.response)

            })
    }


    useEffect(() => {
        if (props.userData != undefined) {
            const zeroPad = (num, places) => {
                String(num).padStart(places, '0')
            }
           
            let n = props.userData.user
            var zerofilled = ('0000' + n).slice(-4);
            localStorage.setItem('peer_id', props.userData.user)
            localStorage.setItem('peer_id_rtm', zerofilled)
            setPeerID(props.userData.user)
            setPeerImage(props.userData.avatar_image)
            setPeerUserName(props.userData.firstname+" "+props.userData.lastname)
    
            stateCtx.rtmClient.inquire([zerofilled]).then((res) => {
                if (res[zerofilled]) {
                    setStatus("Online")
                } else {
                    setStatus("Offline")
                    return
                }
            }).catch(() => {
                console.log('The query failed')
               
            })
    
            let username = localStorage.getItem('username1')
            let peer_username = props.userData.userName
    
            let namee
    
            var userHash = parseInt(uid)
            var peerHash = props.userData.user
    
            if (userHash > peerHash) {
                namee = userHash + "_" + peerHash
            }
            else {
                namee = peerHash + "_" + userHash
            }
    
    
    
            setCHANNEL_NAME(namee)
            let token = localStorage.getItem('token')
            const credentials = {
                read: "false"
            }
            axios.patch(ENV.ENDPOINT + `/chat/edit/${CHANNEL_NAME}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    setRefresh(true)
                })
    
            axios.get(ENV.ENDPOINT + `/chat/view/`, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    const chatData = response.data.results.map(function (row) {
                        return { channel: row.channel, chat_data: JSON.parse(row.chat_data) }
                    })
    
                    let list = []
                    chatData.map(function (row) {
    
                        if (row.channel == namee) {
    
                            list.push(row.chat_data)
                        }
    
                    })
                    if (list[0] == undefined) {
                        setMessages([])
                    } else {
                        setMessages(list[0])
                    }
                })
            login(props.userData.user)
        }
    }, [])



    const selectUser = async (val) => {
       

        const produceCode = () => {
            let code = ''
            for (let i = 0; code.length < 4; i++) {
                let number = val.user_id
                code = code + number
            }
            return code
        }
       
        localStorage.setItem('peer_id', produceCode())

        setPeerID(val.user_id)
        setPeerImage(val.userImage)
        setPeerUserName(val.userName)
        let code = produceCode()
        stateCtx.rtmClient.inquire([code]).then((res) => {
            console.log(code, "code")

            if (res[code]) {
            
                setStatus("Online")
            } else {
            
                setStatus("Offline")
                return
            }
        }).catch(() => {
            console.log('The query failed')
           
        })

        let username = localStorage.getItem('username1')
        let peer_username = val.userName

        let namee
        // if (username.length < peer_username.length) {
        //     namee = username + peer_username
        // }
        // else if (username.length == peer_username.length) {
        //     let usernameAscii = username.charCodeAt(0)
        //     let peer_usernameAscii = peer_username.charCodeAt(0)
        //     if (usernameAscii < peer_usernameAscii) {
        //         namee = username + peer_username
        //     } else {
        //         namee = peer_username + username
        //     }
        // }
        // else {
        //     namee = peer_username + username
        // }
        var userHash = parseInt(uid)
        var peerHash = val.user_id

        if (userHash > peerHash) {
            namee = userHash + "_" + peerHash
        }
        else {
            namee = peerHash + "_" + userHash
        }

        

        setCHANNEL_NAME(namee)
        let token = localStorage.getItem('token')
        const credentials = {
            read: "false"
        }
        axios.patch(ENV.ENDPOINT + `/chat/edit/${CHANNEL_NAME}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setRefresh(true)
            })

        axios.get(ENV.ENDPOINT + `/chat/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                const chatData = response.data.results.map(function (row) {
                    return { channel: row.channel, chat_data: JSON.parse(row.chat_data) }
                })
              
                let list = []
                chatData.map(function (row) {

                    if (row.channel == namee) {
                       
                        list.push(row.chat_data)
                    }

                })
                if (list[0] == undefined) {
                    setMessages([])
                } else {
                    setMessages(list[0])
                }
            })
        login(namee)
    }

    const fileFunction = (e) => {
        setLoader(true)
        setFile(e)



        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setFile(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded,
                        type: e.type
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/chatfile/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(async response => {
                            let text = response.data.location
                            let msgType = "text"
                            let time = new Date()

                            await client1.sendMessageToPeer(
                                {
                                    text,
                                    type: 'text',
                                    time,
                                    msgType: msgType,
                                },
                                peerID.toString(),
                            ).then(sendResult => {

                                setMessages(currentMessages => [...currentMessages, {
                                    uid,
                                    text,
                                    time,
                                    msgType,
                                }])
                                setLoader(false)
                                setLastMsg(text)

                                setUpdate(true)
                                if (sendResult.hasPeerReceived) {

                                    // document.getElementById("log").appendChild(document.createElement('div')).append("Message has been received by: " + peerId + " Message: " + peerMessage)

                                } else {

                                    // document.getElementById("log").appendChild(document.createElement('div')).append("Message sent to: " + peerId + " Message: " + peerMessage)

                                }
                            })
                                .catch(err => {
                                    console.log(err, 'send msg eror')
                                })


                        })


                        .catch((error) => {
                            console.log(error)
                        })


                };
                reader.onerror = error => reject(error);
            });
        }

        e.map(function (row) {
            getBase64(row)
        })
    }

    const callStart = (val) => {
        localStorage.setItem("peerUserName", val.userName)
        localStorage.setItem("peerImage", val.userImage)
        navigate('/call-invi')
    }
    const searchFilter = (e) => {
    
        setSearchInput(e.target.value)
    }
    const handleEnter = (e) => {
        if (e.keyCode == 13) {
            sendMessage(e)
        }
    }
    const [loader, setLoader] = useState(false)
    const [classe, setClasse] = useState(false)
    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])
    return (
        <>

            <div className='message-wrap chat-popup-wrap'>
                {/* <Button className="message-btn btn-white btn-square" onClick={handleShow}>
                    <i className='icon-message-solid'></i>
                </Button> */}
                <Button className="btn border-btn" onClick={handleShow}><i class="icon-message-solid"></i>Message</Button>
                <Modal show={show} onHide={handleClose} className='chat-popup'>

                    <Modal.Body>
                        <div className='chat-popup-content'>
                            <div className='chat-popup-tab-wrap'>
                                <Tab.Container id="chatTab" defaultActiveKey="0">
                                    <Row>
                                        {/* <Col md={4} className="chat-button-tab-col">
                                            <div className='col-inner'>
                                                <div className="chat-top-header">
                                                    <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                                                    <div className="top-header-inner">
                                                        <h4 className='popup-title'>Message</h4>
                                                        <SearchInput searchInput={searchFilter} />
                                                    </div>

                                                </div>
                                                <Nav variant="pills" className="flex-column">
                                                    {chatsData && chatsData.map((val, index) =>
                                                        <Nav.Item key={val.id} onClick={e => selectUser(val)}>
                                                            <Nav.Link eventKey={val.id}>
                                                                <div className='chat-tab-single' isactive={val.last_chat_by != uid ? val.notReadMessage : "false"}>
                                                                    <div className="chat-single-inner">
                                                                        <div className='image-wrap'>
                                                                            <img src={val.userImage} alt />
                                                                        </div>
                                                                        <div className='chat-user-data'>
                                                                            <h6>{val.userName}</h6>
                                                                         
                                                                            <span className='last-message'>{val.lastMessageText}</span>
                                                                            <span className='time-text'>{moment(val.lastMessageTime).format('HH:mm a')}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                </Nav>
                                            </div>
                                        </Col> */}
                                        <Col md={12} className="chat-tab-content-col active">
                                            <div className='content-inner'>
                                                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}></button>
                                                <Tab.Content>
                                                    <div className="backarrow">
                                                        <button type='button' id="chatBackArrow"><i className="icon-Back-arrow"></i></button>
                                                    </div>
                                                    {chatsData && chatsData.map((val, index) =>
                                                        <Tab.Pane eventKey={0} key={0}>
                                                            <div className='tab-content-inner'>
                                                                <div className='chat-user-header chat-single-inner'>

                                                                    <div className='image-wrap'>
                                                                        <img src={val.userImage} alt="" />
                                                                    </div>
                                                                    <div className='chat-user-data'>
                                                                        <h6>{val.userName}</h6>
                                                                        <span className='user-online-text'>{status}</span>
                                                                    </div>
                                                                    <div className='calling-button'>
                                                                        <button className='call-button' onClick={e => callStart(val)}></button>
                                                                    </div>
                                                                </div>
                                                                <div className='chat-message-box' ref={messagesRef}>
                                                                    {messages && messages.map((message, index) => (


                                                                        <div className='message-box'>
                                                                            <div className={classe}></div>
                                                                            {message.uid !== uid && (
                                                                                <div className='message-single by-them'>
                                                                                    <div className="image-wrap">
                                                                                        <img src={val.userImage} alt="" />
                                                                                    </div>
                                                                                    <div className='message'><p>{message.text}</p></div>
                                                                                </div>
                                                                            )}
                                                                            {message.uid === uid && (
                                                                                <div className='message-single'>

                                                                                    <div className='message'><p>{message.text}</p></div>
                                                                                </div>
                                                                            )}

                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className='chat-message-footer'>
                                                                    <div className="message-send-wrapper">
                                                                        {/* <div className='attach-dropdown-wrapper'>
                                                                        <Dropdown className="user-table-menu-dropdown custom-dropdown">
                                                                            <Dropdown.Toggle id="attachementDropdown">
                                                                                {AllCustomIcon.iconAttachment}
                                                                            </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <input type="file" onChange={fileFunc} />
                                                                                <Dropdown.Item href="">
                                                                                    <input type='file' value={file} onChange={e=>setFile(e.target.value)}>Upload from computer</input> 
                                                                                    
                                                                                </Dropdown.Item>

                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div> */}
                                                                        <div className='attach-dropdown-wrapper'>
                                                                            <UploadFilePopupMessage messageUserImage={val.userImage} messageUserName={val.userName} fileHandle={fileFunction} />
                                                                        </div>

                                                                        <div className='message-field-input'>
                                                                            <input type="text" name='textMessage' placeholder='Text message here' value={text} onChange={(e) => setText(e.target.value)} onKeyDown={handleEnter} />
                                                                        </div>
                                                                        <div className='send-button'>
                                                                            <button type='button' onClick={sendMessage}>{AllCustomIcon.iconSend}</button>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                    )}
                                                </Tab.Content>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        </>
    )

}


export default MessagesButton1;