import React, { useEffect, useState, createContext, useRef } from 'react';
import { Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/Custom-icons.css';
import './assets/css/App.css';
import './assets/css/Responsive.css';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import ManageUser from './pages/ManageUser';
import ManageFacilityEdit from './pages/ManageFacilityEdit';
import ManageCliniciansEdit from './pages/ManageCliniciansEdit';
import WorkforceCalculator from './pages/WorkforceCalculator';
import Notes from './pages/Notes';
import Timesheets from './pages/Timehseets';
import Documents from './pages/Documents';
import DocumentDetails from './pages/DocumentDetails';
import EditProfile from './pages/EditProfile';
import FeedbacksAndRatings from './pages/FeedbacksAndRatings';
import Scheduling from './pages/Scheduling';
import EditProfileHMCadmin from './pages/EditProfileHMCadmin';
import Statistics from './pages/Statistics';
import NurseTierRequestRate from './pages/NurseTierRequestRate';
import FacilityRequestRate from './pages/FacilityRequestRate';
import FundingSummary from './pages/FundingSummary';
import ManageUserSuperAdmin from './pages/ManageUserSuperAdmin';
import ManageAdminEditt from './pages/ManageAdminEditt';
import ManageFacilityAdminEdit from './pages/ManageFacilityAdminEdit';
import Call from './pages/call';
import AgoraCall from './pages/agora-call';
import Chat from './pages/chat';
import Index from './pages/index'

import ProtectedRoute from './pages/Protected'
import SARoute from './pages/SARoutes';
import HMCRoute from './pages/HMCRoutes';
import FacilityAdminRoute from './pages/FacilityAdminRoute';
import { appendErrors } from 'react-hook-form';
import { BrowserRouterHook } from './pages/utils/use-router'
import Calling from './pages/calling';
import IndexCard from './pages/index/index-card'
import InterCodingCard from './pages/index/inter-coding-card'
import Incoming from './pages/incoming'
import Card from './pages/index/card'
import Meeting from './pages/meeting/index'
import MessagesButton from './components/inner-components/MessagesButton'
import useLocalStorage from './pages/utils/useLocalStorage';
import { Tour } from 'antd';
import PageNotFound from './components/inner-components/PageNotFound';
import PayCard from './pages/PayCard';
export const RoutesPath = {
  indexURL: '/',
  resetPassword: '/reset-password',
  dashboard: '/dashboard',
  manageUser: '/manage-users',
  managefacilityEdit: '/edit-facility',
  managecliniciansEdit: '/edit-clinicians',
  workforceCalculator: '/workforce-calculator',
  notes: '/notes',
  timesheets: '/timesheets',
  documents: '/documents',
  documentsDetails: '/document-details',
  editProfile: '/edit-profile',
  feedbackratings: '/feedback-ratings',
  scheduling: '/scheduling',
  editHMCadminProfile: '/edit-profile-hmc-admin',
  statistics: '/statistics',
  nurseTierRequestRate: '/nurse-tier-request-rate',
  facilityRequestRate: '/facility-request-rate',
  fundingSummary: '/funding-summary',
  manageuserSuperAdmin: '/manage-user-super-admin',
  ManageAdminEditt: '/edit-admin',
  manageFacilityAdminEdit: '/edit-facility-admin',
  Call: '/call',
  AgoraCall: '/agora-call',
  Index: '/call-invi',
  Chat: '/chat',
  Calling: '/call-invi/card/indexCard/dialling/calling/',
  Card: '/call-invi/card',
  IndexCard: '/call-invi/card/indexCard',
  InterCodingCard: '/call-invi/card/indexCard/dialling',
  Incoming: '/call-invi/card/indexCard/incoming',
  MessagesButton: '/messageButton',
  Meeting: '/meeting/:name',
  PayCard : '/add-paycard'
}

function App() {
  // console.error=()=>{}
  // console.info=()=>{}
  const UserContext = createContext("");
  const [user, setUser] = useState("");
  localStorage.getItem('position')





  

  //  permissionss
  //  superadmin 1
  //  hmcadmin=2
  //  facilityadmin=3
  //  facility access=4
  //  clinicin=5 

  // useEffect(()=>{
  //   console.error=()=>{}
  //   console.log=()=>{}
  //   console.info=()=>{}
  // },[true])
  

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Routes>
        {/* For Super Admin */}
        <Route path={RoutesPath.dashboard} element={<ProtectedRoute permissions={[1, 2, 3, 4, 5]}><Dashboard /></ProtectedRoute>} />
        <Route path={RoutesPath.PayCard} element={<ProtectedRoute permissions={[1, 2, 3, 4, 5]}><PayCard /></ProtectedRoute>} />
        <Route path={RoutesPath.documents} element={<ProtectedRoute permissions={[1, 2, 3, 4]}><Documents /></ProtectedRoute>} />
        <Route path={RoutesPath.manageUser} element={<ProtectedRoute permissions={[1, 2]}><ManageUser /></ProtectedRoute>} />
        <Route path={RoutesPath.managefacilityEdit} element={<ProtectedRoute permissions={[1, 2]}><ManageFacilityEdit /></ProtectedRoute>} />
        <Route path={RoutesPath.managecliniciansEdit} element={<ProtectedRoute permissions={[1, 2]}><ManageCliniciansEdit /></ProtectedRoute>} />
        <Route path={RoutesPath.workforceCalculator} element={<ProtectedRoute permissions={[1, 2, 3]}><WorkforceCalculator /></ProtectedRoute>} />
        <Route path={RoutesPath.timesheets} element={<ProtectedRoute permissions={[1, 2, 4]}><Timesheets /></ProtectedRoute>} />
        <Route path={RoutesPath.notes} element={<ProtectedRoute permissions={[1, 2, 3]}><Notes /></ProtectedRoute>} />
        <Route path={RoutesPath.documents} element={<ProtectedRoute permissions={[1, 2, 3, 4]}><Documents /></ProtectedRoute>} />
        <Route path={RoutesPath.documentsDetails} element={<ProtectedRoute permissions={[1, 2, 3, 4]}><DocumentDetails /></ProtectedRoute>} />
        <Route path={RoutesPath.editProfile} element={<ProtectedRoute permissions={[1, 2, 3, 4, 5]}><EditProfile /></ProtectedRoute>} />
        <Route path={RoutesPath.feedbackratings} element={<ProtectedRoute permissions={[1, 2, 3, 4]}><FeedbacksAndRatings /></ProtectedRoute>} />
        <Route path={RoutesPath.scheduling} element={<ProtectedRoute permissions={[0, 1, 2, 4]}><Scheduling /></ProtectedRoute>} />


        <Route path={RoutesPath.statistics} element={<Statistics />} />
        <Route path={RoutesPath.nurseTierRequestRate} element={<NurseTierRequestRate />} />
        <Route path={RoutesPath.facilityRequestRate} element={<FacilityRequestRate />} />
        <Route path={RoutesPath.fundingSummary} element={<FundingSummary />} />
        <Route path={RoutesPath.manageuserSuperAdmin} element={<ManageUserSuperAdmin />} />
        <Route path={RoutesPath.ManageAdminEditt} element={<ProtectedRoute permissions={[1, 2]}><ManageAdminEditt /></ProtectedRoute>} />
        <Route path={RoutesPath.manageFacilityAdminEdit} element={<ProtectedRoute permissions={[1, 2]}><ManageFacilityAdminEdit /></ProtectedRoute>} />



        {/* <Route path={RoutesPath.Call} element={<ProtectedRoute permissions={[1,2]}><Call /></ProtectedRoute>} />
      <Route path={RoutesPath.AgoraCall} element={<ProtectedRoute permissions={[1,2]}><AgoraCall /></ProtectedRoute>} />
      <Route path={RoutesPath.Chat} element={<ProtectedRoute permissions={[1,2]}><Chat /></ProtectedRoute>} /> */}
        {/* <Route path="/call-invi/*" element={Index}></Route> */}


        <Route path={RoutesPath.Index} element={<ProtectedRoute permissions={[1, 2,3,4]}><Index /></ProtectedRoute>} />
        <Route path={RoutesPath.Calling} element={<ProtectedRoute permissions={[1, 2,3,4]}><Calling /></ProtectedRoute>} />
        <Route path={RoutesPath.Card} element={<ProtectedRoute permissions={[1, 2,3,4]}><Card /></ProtectedRoute>} />
        <Route path={RoutesPath.IndexCard} element={<ProtectedRoute permissions={[1, 2,3,4]}><IndexCard /></ProtectedRoute>} />
        <Route path={RoutesPath.InterCodingCard} element={<ProtectedRoute permissions={[1, 2,3,4]}><InterCodingCard /></ProtectedRoute>} />

        <Route path={RoutesPath.Incoming} element={<ProtectedRoute permissions={[1, 2,3,4]}><Incoming /></ProtectedRoute>} />
        <Route path={RoutesPath.Meeting} element={<ProtectedRoute permissions={[1, 2,3,4]}><Meeting /></ProtectedRoute>} />


        <Route path={RoutesPath.MessagesButton} element={<ProtectedRoute permissions={[1, 2,3,4]}><MessagesButton /></ProtectedRoute>} />



        <Route index element={<Login />} />
        <Route path={RoutesPath.resetPassword} element={<ResetPassword />} />
        <Route path="*" element={<PageNotFound/>} />

      
      </Routes>
      
    </UserContext.Provider>
  );
}

export default App;
