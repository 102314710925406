import React from 'react'
import HeaderMobile from '../components/HeaderMobile'
import { Col, Row } from 'react-bootstrap'
import LeftPanel from '../components/LeftPanel'
import PageNotFound from '../components/inner-components/PageNotFound'

const PayCard = () => {
  return (
    <>
    <main className='app-main'>
    <HeaderMobile />
    <section className="main-section dashboard-section blue-bg-section manage-user-section">
        <div className="section-wrap">
            <Row className="dashboard-row-main">
                <LeftPanel />
                <Col lg="9" className="right-content-col grey-bg-col">
                   <PageNotFound/>
                </Col>
            </Row>

        </div>
    </section>
</main>
</>
  )
}

export default PayCard