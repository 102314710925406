import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import InputGroupForm from '../inner-components/InputGroupForm';
import ImageUploadInput from '../inner-components/ImageUploadInput';
import DateofBirthInput from '../inner-components/DateofBirthInput';
import CustomDropdown from '../inner-components/CustomDropdown';
import axios from 'axios';
import { hasShrinkWidth } from '@fullcalendar/react';
import { ENV } from '../../env/env'



const EditProfileSection = (props) => {
    const RoleDropdownData = [
        { value: 'admin', label: 'Admin' },
        { value: 'hmc_admin', label: 'HMC admin' },
        { value: 'c_level_emp', label: 'Super Admin' }
    ]
    const [userData, setUserData] = useState("")
    const [data, setData] = useState({
        address: "",
        avatar_image: "",
        contact_no: "",
        date_of_birth: "",
        email: "",
        fullname: "",
        admin_id: "",
        password: "",
        role: "",
        facility: ""

    })
    const [errorMessages, setErrorMessages] = useState("");
    const [dob, setDob] = useState()
    const [oldpass, setOldPass] = useState()
    const [newpass, setNewPass] = useState()
    const [conf, setConfPass] = useState()
    const [facility, setFacility] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [roles, setRole] = useState()
    const [userName, setUserName] = useState()
    const [Address, setAddress] = useState()
    const [Email, setEmail] = useState()
    const [fullName, setFullName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [modeifiedDate, setModifiedDate] = useState()
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState()

    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + '/user/profile/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.msg)
                setUserData(response.data.msg)
                setUserName(response.data.msg.username)
                if (response.data.msg.position == 'facility') {


                    axios.get(ENV.ENDPOINT + `/facility/view/?search=${response.data.msg.user_id}`, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            setData(response.data.results[0])
                            console.log(response.data.results[0], "hjjjjjjjjjjjjjjjjjjjjjjjj")
                            let token = localStorage.getItem('token')

                            setIsLoading(false)

                            // axios.get(`http://127.0.0.1:8000/api/facility/view/?search=${response.data.results[0].facility}`,{headers:{"Authorization":`Bearer ${token}`}})
                            // .then(response=>{
                            //     setFacility(response.data.results[0])
                            //     console.log(response.data,"facilityyyy")    
                            //     setRole(response.data.results[0].facility_name)
                            //     setIsLoading(false)
                            // })
                            // .catch((error)=>{
                            //     console.log(error)
                            // })   
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                } else if (response.data.msg.position == 'c_level_emp' || response.data.msg.position == 'hmc_admin') {
                    axios.get(ENV.ENDPOINT + `/admin/view/?search=${response.data.msg.Admin}`, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            setData(response.data.results[0])
                            console.log(response.data.results[0])
                            let token = localStorage.getItem('token')
                            setDob(response.data.results[0].date_of_birth)
                            setIsLoading(false)
                            setEmail(response.data.results[0].email)
                            setImageURL(response.data.results[0].avatar_image)
                            setFullName(response.data.results[0].fullname)
                            setAddress(response.data.results[0].address)
                            setPhoneNumber(response.data.results[0].contact_no)
                            const [year, month, day] = response.data.results[0].date_of_birth.split('-');
                            const result1 = [month, day, year].join('/');



                            setModifiedDate(result1)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    setIsLoading(true)
                }
            })
            .catch((error) => {
                console.log(error)
            })



    }, [setData, setUserData])



    console.log(userData)
    console.log(data)

    console.log(facility)


    const handleUsername = (event) => {
        setUserName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleFullName = (event) => {
        setFullName(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleAddress = (event) => {
        setAddress(event.target.value)
    }


    useEffect(() => {
        // const [year, month, day]=data.date_of_birth.split('-');
        // const result1=[ month, day,year].join('/');
    })





    const handledDate = date => {
        let datevalue = date.format()
        console.log(datevalue, "date valueeeeeeeeeeeeeeeeee")

        const [month, day, year] = datevalue.split('/');
        const result = [year, month, day].join('-');
        console.log(result, "resulttttttttttttttttt")
        setDob(result)
    }

    // const [roles,setRole]=useState()
    // useEffect(()=>{
    //     if(data.role!=undefined&&data.role!=""){
    //         console.log("roleeeeeeeeeeeeee",data.role)

    //     }
    // })


    const handleJobSites = (selection) => {
        console.log(selection.value)
        setRole(selection.value)

    }

    const JobSitesData = [
        { value: 'Select Facility', label: 'Select Facility', isdisable: "disable" },
        { value: 'bristol-village', label: 'Bristol Village' },
        { value: 'chillicothe-campus', label: 'Chillicothe Campus' },
        { value: 'Toledo', label: 'Toledo' },


    ]

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [passwords, setPassword] = useState({
        oldPassword: "",
        createNewPassword: "",
        reenterNewPassword: ""

    })
    const handlePassword = (event) => {
        const value = event?.target?.value;
        //set it in the model
        setPassword({
            ...passwords,
            [event.target.name]: value
        });
        console.log(passwords.oldPassword)
    }
    //console.log(userData.date_of_birth,"dobbbbbbbbbbbbbbbbbb")
    // const [year, month, day]=userData.date_of_birth.split('-');
    // const result=[ month, day,year].join('/');
    // console.log("new date=========",result)

    if (data && data.role == "c_level_emp") {
        data.role = "Admin"
    }
    else if (data && data.role == "hmc_admin") {
        data.role = "HMC Admin"
    }


    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        await axios.patch(ENV.ENDPOINT + `/myuser/edit/${userData.id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.messages)

            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }


    const handleReset = () => {
        async function apiCall(credentials) {
            let token = localStorage.getItem('token')

            await axios.post(ENV.ENDPOINT + `/user/changepassword/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {

                    console.log(response.data.messages)

                })
                .catch((error) => {
                    console.log(error.response.data)
                })
        }
        let old_password = passwords.oldPassword
        let password = passwords.createNewPassword
        let password2 = passwords.reenterNewPassword
        const token = apiCall({
            old_password,
            password,
            password2


        });

    }

    const handleImage = (e) => {
        console.log(e.size)
        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setErrorMessages({ name: "image", message: "" });
            setImage(e)
        }

    }

  


    const handleSubmit = (event) => {


        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            let username = userName
                            let email = Email
                            let address = Address
                            let role = roles
                            let fullname = fullName

                            let date_of_birth = dob


                            let phone_no = phoneNumber
                            let avatar_image = response.data.location



                            const token = apiCall({

                                username,
                                email,
                                address,
                                avatar_image,
                                role,
                                fullname,
                                phone_no,
                                date_of_birth

                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })
                    console.log(encoded)

                };
                reader.onerror = error => reject(error);
            });
        }
        if (image != undefined) {
            getBase64(image)
        }
        else {
            let username = userName
            let email = Email
            let address = Address
            let role = roles
            let fullname = fullName

            let date_of_birth = dob


            let phone_no = phoneNumber
            let avatar_image = imageURL



            const token = apiCall({

                username,
                email,
                address,
                avatar_image,
                role,
                fullname,
                phone_no,
                date_of_birth

            });
        }





        //navigate('/manage-users')
    }


    const renderErrorMessage = (name) =>
    name === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
    );
    return (
        <div className='edit-profile-main'>
            <div className='edit-user'>
                {(!isLoading && data != undefined && data.role != "") ? (

                    <><div className="edit-user-top">
                        <div className='user-image'>
                            <div className='image-upload'>
                                <ImageUploadInput UserImage={image} handleImage={handleImage} />
                            </div>
                            {renderErrorMessage("image")}
                        </div>
                        <div className="edit-user-form">
                            <Row className="form-row">
                                <Col md={6}>
                                    <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={userData.username} handleChange={handleUsername} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="fullName" inputLabel="Full name" inputType="text" inputPlaceholder="Text here" inputName="fullName" inputValue={data.fullname} handleChange={handleFullName} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={data.email} handleChange={handleEmail} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="phoneNumber" inputLabel="Phone" inputType="number" inputPlaceholder="Text here" inputName="phoneNumber" inputValue={data.contact_no} handleChange={handlePhoneNumber} />
                                </Col>
                                {/* <Col md={6}>
        <InputGroupForm inputId="favFood" inputLabel="Favorite Food" inputType="text" inputPlaceholder="Text here" inputName="favFood" inputValue="Fruit" />
    </Col> */}
                                <Col md={6}>
                                    <div className="form-group date-of-birth-input">
                                        <label className='form-label'>Date of birth</label>
                                        <DateofBirthInput inputValue={modeifiedDate} handleChange={handledDate} />
                                    </div>
                                </Col>


                            </Row>
                        </div>

                    </div><div className='edit-user-bottom edit-user-form'>
                            <Row className="form-row">
                                <Col md={6}>
                                    <div className='form-group'>
                                        <label className='form-label'>Role</label>
                                        <CustomDropdown optionData={RoleDropdownData} data={data.role} handleChange={handleJobSites} />
                                    </div>
                                </Col>

                                <Col md={6}>
                                    <InputGroupForm inputId="Address" inputLabel="Address" inputType="text" inputPlaceholder="Text here" inputName="Address" inputValue={data.address} handleChange={handleAddress} />
                                </Col>
                                {/* <Col md={6}>
        <InputGroupForm inputId="Mainline" inputLabel="Mainline" inputType="text" inputPlaceholder="Text here" inputName="Mainline"  inputValue={facility.phone_no}/>
    </Col> */}

                            </Row>
                        </div><div className='edit-user-footer'>
                            <div className='btn-wrap'>
                                <button type='button' className='btn border-btn' id='changePassword' onClick={handleShow}>Change Password</button>
                                <button className='btn btn-primary' onClick={handleSubmit}>Save Changes</button>
                            </div>
                            <div className='change-password-popup'>
                                <Modal show={show} onHide={handleClose} className='change-password-popup'>
                                    <Modal.Header closeButton>
                                        <h5>Change Password</h5>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-inner">

                                            <InputGroupForm inputId="oldPassword" inputLabel="Enter old password" inputType="password" inputPlaceholder="*****" inputName="oldPassword" inputValue={oldpass} handleChange={handlePassword} />
                                            <div className='new-password-group'>
                                                <InputGroupForm inputId="createNewPassword" inputLabel="Create new password" inputType="password" inputPlaceholder="Enter new password" inputValue="gg" inputName="createNewPassword" handleChange={handlePassword} />
                                                <InputGroupForm inputId="reenterNewPassword" inputLabel="" inputType="password" inputPlaceholder="Re-enter new password" inputName="reenterNewPassword" inputValue="gg" handleChange={handlePassword} />
                                            </div>
                                            <div className='submit-btn'>
                                                <input type="submit" className='btn btn-primary' value="Save changes" onClick={handleReset} />
                                            </div>

                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div></>

                ) : ""}
            </div>
        </div>


    )
}

export default EditProfileSection;