import React, { useState, useEffect } from 'react';
import TakeNotePopup from './TakeNotePopup';
import RatePopup from './RatePopup';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';
import ApproveDeclinedSwitch from './ApproveDeclinedSwitchForPOC';
import { setRef } from '@fullcalendar/react';
import ReusableAPI from './NotificationApi';

const TimesheetsTable = (props) => {

    const [UserFacilitiesData, setUserFacilitiesData] = useState()
    const [facility_note, setFacilityNote] = useState()
    const [clinician_note, setClinicianNote] = useState()
    const [feedbackk, setFeedback] = useState()
    const [switchValue, setSwitchValue] = useState()
    const [rate, setRate] = useState()
    const [starRatings, setStarRatings] = useState()
    
    const [feedbackData, setFeedbackData] = useState()

    useEffect(() => {
        ToggleActiveOnInit();
    });

    const [refresh, setRefresh] = useState(false)

   

    useEffect(() => {

    
        if (props.data != undefined) {

            const dateRange = props.data.map(function (row) {


                var clinicaian_note
                var facility_note
                var feedback
                var ratings
                var statusProgressClass
                var statusTag
                var signature
                let token = localStorage.getItem('token')




                const switchValue = (e, id) => {
                    setSwitchValue(e)
                    async function apiCall(credentials) {
                        let token = localStorage.getItem('token')
                        await axios.patch(ENV.ENDPOINT + `/timesheet/edit/${id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(response => {
                                const data={
                                    status:"Completed"
                                }
                                axios.get(ENV.ENDPOINT + `/myuser/?user_id=${response.data.clinician_id}`, { headers: { "Authorization": `Bearer ${token}` } })
                                .then(response => {
                                  
                                    var notificationMsg = `Timesheet ${e}`
                                    ReusableAPI.notificationTriggerAPI(notificationMsg,response.data.results[0].id)
            
                                    fcmData(`Timesheet ${e}`,response.data.results[0].id)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })


                                
                                axios.patch(ENV.ENDPOINT + `/scheduling/edit/${row.shift_id}/`, data, { headers: { "Authorization": `Bearer ${token}` } })
                                .then(response => {
                                    props.handleRefresh(true)
                                    //Triggering event notification
                                // var notificationMsg = `Timesheet ${response.data.status}`
                                // ReusableAPI.notificationTriggerAPI(notificationMsg)
                                })
                                .catch((error) => {
                                    console.log(error.response.data)
                                })

                                


                            })
                            .catch((error) => {
                                console.log(error.response.data)
                            })

                    }

                    let poc_signature = e
                    let status = e

                    const token = apiCall({
                        poc_signature,
                        status
                    });


                }


                if (row.clinician_note == "" || row.clinician_note == undefined) {
                    clinicaian_note = "Take note"
                }
                else {
                    clinicaian_note = row.clinician_note
                }
                if (row.facility_note == "" || row.facility_note == undefined) {
                    facility_note = "Take note"
                }
                else {
                    facility_note = row.facility_note
                }
                if (row.feedback == "" || row.feedback == undefined) {
                    feedback = "Rate"
                }
                else {
                    feedback = row.feedback
                }
                if (row.ratings == "" || row.ratings == undefined) {
                    ratings = "none"
                }
                else {
                    ratings = row.ratings
                }

                if (row.status == "Approved") {
                    statusProgressClass = ""
                    statusTag = "success"
                    setSwitchValue("")
                    signature = "Reinstate"

                }
                else if (row.status == "Declined") {
                    statusProgressClass = ""
                    statusTag = "failed"
                    setSwitchValue("")
                    signature = "Reinstate"
                }
                else if (row.status == "Pending") {
                    statusProgressClass = <ApproveDeclinedSwitch handleSwitch={e => switchValue(e, row.timesheet_id)} />
                    statusTag = "no-status"

                }




                return ({
                    id: row.timesheet_id,
                    clinician_id: row.clinician_id,
                    EmployeeName: row.clinician_name,
                    Position: row.clinician_position,
                    FacilityName:row.facility_name,
                    FacilityId:row.facility_id,
                    ClockIn: row.check_in_time,
                    ClockOut: row.check_out_time,
                    Date: moment(row.shift_date).format('MM/DD/YYYY'),
                    FacilityNote: facility_note,
                    ClinicNote: clinicaian_note,
                    Status: row.status,
                    Reviews: feedback,
                    Stars: ratings,
                    POCSignature: signature,
                    statusTagClassName: statusTag,
                    statusProgressToggle: statusProgressClass,
                })

            })
          
            setUserFacilitiesData(dateRange)

            setLoader(false)

        }


        props.handleRefresh(false)
       
    }, [props])


    // SwithToggle on Init
    const ToggleActiveOnInit = () => {
        var switchToggle = document.querySelectorAll('.switch');

        [].forEach.call(switchToggle, function (item) {
            var switchToggleValue = item.getAttribute("data-switch");
            if (switchToggleValue === "On") {
                var checkbox = item.querySelector('input');
                checkbox.checked = true;

            }
        });

    };
    const fcmData=(msg,id)=>{
        //sending notification to mobile
        const credentials = {

            peerId: localStorage.getItem('user_id'),
            peerName: localStorage.getItem("facility"),
            channelId: localStorage.getItem("facility"),
            message: msg,
            message_type: "text",
            image: localStorage.getItem('avatarImage'),
            receiverId: id,

        }
        let token = localStorage.getItem('token')
        axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                
            })
            .catch((error) => {
               
            })
    }

    const handleFacilityNote = (e, id) => {
        setFacilityNote(e.target.value)
    }
    const handleClinicianNote = (e) => {
        setClinicianNote(e.target.value)
    }
    const handleRate = (e) => {
        setRate(e.target.value)
     
    }
    const handleRatings = (e) => {
        setStarRatings(e)
     
    }
    const addFacilityNote = (id) => {
        let token = localStorage.getItem('token')
        const credentials = {
            facility_note
        }
        axios.patch(ENV.ENDPOINT + `/timesheet/edit/${id}/`, credentials,{ headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const addClinicianNote = (id) => {
        let token = localStorage.getItem('token')
        const credentials = {
            clinician_note
        }
        axios.patch(ENV.ENDPOINT + `/timesheet/edit/${id}/`, credentials,{ headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

            })
            .catch((error) => {
                console.log(error)
            })
    }



    const addRate = (id, clinician_id) => {
      
        let feedback = rate
        let ratings = starRatings
        const credentials = {
            feedback,
            ratings,

        }
        let token = localStorage.getItem('token')
        axios.patch(ENV.ENDPOINT + `/timesheet/edit/${id}/`, credentials,{ headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                let token = localStorage.getItem('token')
                axios.get(ENV.ENDPOINT + `/myuser/?user_id=${clinician_id}`, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(response => {
                      
                        var notificationMsg = `Timesheet Rated`
                        ReusableAPI.notificationTriggerAPI(notificationMsg,response.data.results[0].id)

                        fcmData("Timesheet Rated",response.data.results[0].id)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                
            })
            .catch((error) => {
                console.log(error)
            })
    }
    
    const reinstate = (e) => {
      

        let poc_signature = "Pending"
        let status = "Pending"
        const credentials = {
            poc_signature,
            status
        }
        let token = localStorage.getItem('token')
        axios.patch(ENV.ENDPOINT + `/timesheet/edit/${e.id}/`, credentials,{ headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                props.handleRefresh(true)
                //window.location.reload()
            })
            .catch((error) => {
                console.log(error)
            })
        setRefresh(true)

    }
    const [loader, setLoader] = useState(true)
    const [classe, setClasse] = useState(false)
    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])
    const [noDataClass,setNoDataClass]=useState("no-data-divhide")
    useEffect(() => {
       setTimeout(() => {
        if(UserFacilitiesData==[]||UserFacilitiesData?.length==0){
            setNoDataClass("no-data-div")
        }
        
        
       }, 2000);
    }, [UserFacilitiesData])
    return (
        <>
            <table width="100%">
                <thead>
                    <tr>
                        <th>Facility Name</th>
                        <th>Employee Name</th>
                        <th>Position</th>
                        <th>Date</th>
                        <th>Clock In</th>
                        <th>Clock Out</th>
                        <th>Facility Note</th>
                        <th>Clinician Note</th>
                        <th >Status</th>
                        <th ref={props.timehseetsref2}>Reviews</th>
                        <th  ref={props.timehseetsref3}>POC Signature</th>
                    </tr>
                </thead>
                <tbody>
                <div className={classe}></div>
                    {UserFacilitiesData? (
                        <>



                            {UserFacilitiesData.map((val, index) =>
                                <tr key={index}>
                                    <td className="employee-name-td">{val.FacilityName}</td>
                                    <td className="employee-name-td">{val.EmployeeName}</td>
                                    <td>{val.Position}</td>
                                    <td>{val.Date}</td>
                                    <td className="clock-in-td">{moment(val.ClockIn).format('hh:mm A')}</td>
                                    <td className="clock-out-td">{moment(val.ClockOut).format('hh:mm A')}</td>
                                    <td className="note-td">
                                        {val.FacilityNote == "Take note" ? <TakeNotePopup ModelTitle="Note to the facility" onChange={e => handleFacilityNote(e, val.id)} submit={e => addFacilityNote(val.id)} /> : val.FacilityNote}
                                    </td>
                                    <td className="note-td">
                                        {val.ClinicNote == "Take note" ? <TakeNotePopup ModelTitle="Note to the clinician" onChange={handleClinicianNote} submit={e => addClinicianNote(val.id)} /> : val.ClinicNote}
                                    </td>
                                    <td>
                                        <span className={`status-tag ${val.Status}`}>{val.Status}</span>
                                    </td>
                                    <td className={`reviews-td ${val.POCSignature == 'Pending' ? 'reviews-disable' : ''}`}>
                                        {val.Reviews == "Rate" ? <RatePopup ModelTitle="Review Clinician" data={val.clinician_id} onChange={handleRate} ratings={handleRatings} submit={e => addRate(val.id, val.clinician_id)} handleRefresh={props.handleRefresh}/> : <Rater rating={val.Stars} total={5} interactive={false} />}
                                        {val.Reviews == "Rate" ? null : val.Reviews}
                                    </td>
                                    {/* <td className="note-td">{val.POCSignature}</td> */}
                                    <td className='switch-toggle-td'>
                                        {val.statusProgressToggle}
                                        {/* <span className={`status-tag ${val.statusTagClassName}`} >{val.POCSignature}</span> */}
                                        <a href='#' onClick={e => reinstate(val)}>{val.POCSignature}</a>

                                    </td>
                                </tr>

                            )}

                        </>
                    ) :""}
                </tbody>
           
            </table>
            {UserFacilitiesData&&UserFacilitiesData.length==0?(<div className={noDataClass}>No data available</div>):""}
        </>
    )
}

export default TimesheetsTable;



