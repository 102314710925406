import React, {
    useState,
    useEffect,
    useCallback,
    useContext,
    createContext,
} from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { RoutesPath } from "../../App";
import PropTypes from "prop-types";
import axios from "axios";
import { ENV } from "../../env/env";
import { Buffer } from "buffer";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { snackbar } from "@mobiscroll/react";

const LoginForm = () => {
    // const [usernameDetails,setUsernameDetails]=useState()
  // const [passwordDetails,setPasswordDetails]=useState()
  const UserContext = createContext("");
  const { setUser } = useContext(UserContext);
  const [data, setData] = useState();
  const [tokenn, setTokenn] = useState();

  
  async function loginUser(credentials) {
    axios
    .post(ENV.ENDPOINT + "/user/web/login/", credentials)
    .then((response) => {
        if (response.data.payload == "login failed") {
            console.log("faileddd");
            setErrorMessagesInvalidCredentials({
                name: "invalidCredentials",
                message: "Incorrect Username or Password",
            });
        } else if (response.data.payload == "Not Authorized") {
            setErrorMessagesInvalidCredentials({
                name: "invalidCredentials",
                message: "This user is not authorized to access web",
            });
        } else {
            setTimeout(() => {
                snackbar({
                    message: "Logged In successfully",
                });
            });
        }
        console.log(response.data.data.recaptcha, "recaptcha");
        localStorage.clear();
        localStorage.setItem("token", response.data.token.access);
        localStorage.setItem("refreshToken", response.data.token.refresh);
        setData(response.data.token);
        setTokenn(response.data.token);
        localStorage.setItem("isLoggedIn", "true");

        // Remember me functionalty on Submit

        if (rememberCheck.checked == true && credentials.email != "") {
            localStorage.username = Buffer.from(credentials.email).toString(
                "base64"
                );
                localStorage.password = Buffer.from(credentials.password).toString(
                    "base64"
                    );
                    localStorage.checkbox = rememberCheck.value;
                } else {
                    localStorage.username = "";
                    localStorage.password = "";
                    localStorage.checkbox = "";
                }

        // Remember me functionalty Submit

        let token = localStorage.getItem("token");
        axios
        .get(ENV.ENDPOINT + "/user/profile/", {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            localStorage.setItem("user_id", response.data.msg.id);
            localStorage.setItem("username1", response.data.msg.username);
            localStorage.setItem("avatarImage", response.data.msg.avatar_image);
            localStorage.setItem("position", response.data.msg.position);

            let n = response.data.msg.id;
            var zerofilled = ("0000" + n).slice(-4);
            localStorage.setItem("usercode", zerofilled);

            localStorage.setItem("user_data_id", response.data.msg.Admin);
            //console.log("response.data.msg.Admin" , response.data.msg.Admin)
            axios
            .get(
                ENV.ENDPOINT +
                `/admin/view/?admin_id=${response.data.msg.Admin}`,
                { headers: { Authorization: `Bearer ${token}` } }
                )
                .then((response) => {
                    localStorage.setItem(
                        "scheduling",
                        response.data.results[0].allow_scheduling
                        );
                        localStorage.setItem(
                            "fullname",
                            response.data.results[0].firstname +
                            " " +
                            response.data.results[0].lastname
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                        if (localStorage.getItem("position") == "facility_admin") {
                            navigate(RoutesPath.fundingSummary);
                        } else {
                            navigate(RoutesPath.dashboard);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
                //   if(response.data.token!=""&&response.data.token!=undefined){
    //     localStorage.setItem('token',response.data.token.access)
    //     setData(response.data.token);
    //     setTokenn(response.data.token);
    //     navigate(RoutesPath.dashboard);
    //   }
    //   else{
    //     navigate("/");
    //   }
}

  // Remember me functionalty Init
  var rememberCheck;
  var emailInput;
  var emailPassword;

  useEffect(() => {
    rememberCheck = document.getElementById("rememberCheckbox");
    emailInput = document.getElementById("loginEmail");
    emailPassword = document.getElementById("loginPassword");

    setTimeout(function () {
        if (localStorage.checkbox && localStorage.checkbox != "") {
            rememberCheck.setAttribute("checked", "checked");
            let encodedEmail = Buffer.from(
                localStorage.getItem("username"),
                "base64"
                ).toString();
                let decodedEmail = JSON.parse(encodedEmail);
                let encodedPassword = Buffer.from(
                    localStorage.getItem("password"),
                    "base64"
                    ).toString();
                    let decodedPassword = JSON.parse(encodedPassword);
                    emailInput.value = decodedEmail;
                    emailPassword.value = decodedPassword;
                } else {
                    rememberCheck.removeAttribute("checked");
                    emailInput.value = "";
                    emailPassword.value = "";
                }
            }, 400);
            // Remember me functionalty Init
        });

        const [errorMessagesInvalidCredentials, setErrorMessagesInvalidCredentials] =
        useState({});
        const [errorMessages, setErrorMessages] = useState({});
        const [errorMessagesPassword, setErrorMessagesPassword] = useState({});
        const [isSubmitted, setIsSubmitted] = useState(false);

  // Render error msg for Email
  const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
    );

  // Render error msg for passWord
  const renderErrorMessagePassword = (name) =>
  name === errorMessagesPassword.name && (
    <div className="error">{errorMessagesPassword.message}</div>
    );

  // Render error msg for Email
  const renderErrorMessageInvalidCredentials = (name) =>
  name === errorMessagesInvalidCredentials.name && (
    <div className="error">{errorMessagesInvalidCredentials.message}</div>
    );

  // Login Email Id and password
  // const database = [
  //     {
  //         emailId: "healthcare@gmail.com",
  //         password: "healthcare@2022"
  //     }
  // ];
  // Login Email Id and password

  // Show Error message Text
  const errors = {
    invalidCredentials: "Username or Password Incorrect",
    uname: "Incorrect Username",
    pass: "Incorrect password",
    EmptyEmail: "enter username",
    Emptypass: "enter password",
};

  // Show Error message Text

  const navigate = useNavigate();

  // On form submit
  const handleSubmit = async (event) => {
    var recaptchaToken;
    // Prevent page reload
    event.preventDefault();

    // window.grecaptcha.ready(async () => {

    //     await window.grecaptcha.execute('6LdtKOkhAAAAAMBeVDTJ8PRUBcywu9KwfWuM_tJ9', { action: 'submit' }).then(async token => {
    //         recaptchaToken = token;

    //         console.log(recaptchaToken, "googlr tokennnnnnnnnnnnnnnn")
    var { emailAddress, pass } = document.forms[0];

    window.grecaptcha.ready(function () {
        window.grecaptcha
        .execute("6LdtKOkhAAAAAMBeVDTJ8PRUBcywu9KwfWuM_tJ9", {
            action: "submit",
        })
        .then(async function (token) {
            recaptchaToken = token;
          // Send form value as well as token to the server
          if (pass.value == "" && emailAddress.value == "") {
            setErrorMessages({ name: "uname", message: errors.EmptyEmail });
            setErrorMessagesPassword({
                name: "pass",
                message: errors.Emptypass,
            });
            setErrorMessagesInvalidCredentials({
                name: "invalidCredentials",
                message: "",
            });
        } else if (emailAddress.value == "") {
            setErrorMessages({ name: "uname", message: errors.EmptyEmail });
        } else {
            let email = emailAddress.value;
            let username = emailAddress.value;
            let password = pass.value;

            password = Buffer.from(password).toString("base64");
            const token = await loginUser({
                // username,
                email,
                password,
                recaptchaToken,
            });
            if (!tokenn) {
                setErrorMessages({ name: "uname", message: "" });
                setErrorMessagesPassword({ name: "pass", message: "" });
            }

            // Username not found
        }
    });
});

const score = (credentials) => {
    axios.post(ENV.ENDPOINT + "/user/web/score/", credentials);
};
// Find user login info

var LoginEmailaddress;
var LoginPassWord;
// for (const user of database) {
    //     LoginEmailaddress = user.emailId;
    //     LoginPassWord = user.password;
    // };

    // const userData = LoginEmailaddress === emailAddress.value;
    // console.log("enteres data========",emailAddress.value)
    // // Compare user info
    // if (userData) {
    //     setErrorMessages({ name: "uname", message: "" });
    //     setErrorMessagesPassword({ name: "pass", message: "" });
    //     if (LoginPassWord !== pass.value && pass.value !== "") {
    //         setErrorMessagesPassword({ name: "pass", message: errors.pass });
    //     } else if (pass.value === "") {
    //         setErrorMessagesPassword({ name: "pass", message: errors.Emptypass });
    //     } else {

    //         // navigate dashboard page
    //             navigate(RoutesPath.dashboard);
    //         // navigate dashboard page
    //     }
    // } else if (pass.value === "" && emailAddress.value === "") {

    //     setErrorMessages({ name: "uname", message: errors.EmptyEmail });
    //     setErrorMessagesPassword({ name: "pass", message: errors.Emptypass });

    // } else if (emailAddress.value === "") {
    //     setErrorMessages({ name: "uname", message: errors.EmptyEmail });
    // }
    // else {
    //     // Username not found
    //     setErrorMessages({ name: "uname", message: errors.uname });
    // }

    // If Blank inputs

    // // Compare user info
    // if (localStorage.getItem('token')) {
    //     setErrorMessages({ name: "uname", message: "" });
    //     setErrorMessagesPassword({ name: "pass", message: "" });
    //     setErrorMessagesInvalidCredentials({ name: "invalidCredentials", message: "" });

    //     console.log("from condition 1111111111")

    // }

    //  recaptchaToken = token;
    // });
    // });
};
// On form submit

const responseFacebook = (response) => {
    let email = response.email;
    const token = socialLogin({
        email,
    });
};

function decodeJwtResponse(token) {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
}
const socialLogin = (credentials) => {
    axios
    .post(ENV.ENDPOINT + "/user/web/social-login/", credentials)
    .then((response) => {
        if (response.data.payload == "login failed") {
            console.log("faileddd");

            setErrorMessagesInvalidCredentials({
                name: "invalidCredentials",
                message: "Enter Correct Username or Password",
            });
        } else if (response.data.payload == "Not Authorized") {
            setErrorMessagesInvalidCredentials({
                name: "invalidCredentials",
                message: "This user is not authorized to access web",
            });
        }
        localStorage.clear();
        localStorage.setItem("token", response.data.token.access);
        setData(response.data.token);
        setTokenn(response.data.token);

        let token = localStorage.getItem("token");
        axios
        .get(ENV.ENDPOINT + "/user/profile/", {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
            console.log(response.data);

            localStorage.setItem("username1", response.data.msg.username);
            localStorage.setItem("avatarImage", response.data.msg.avatar_image);
            localStorage.setItem("position", response.data.msg.position);
            localStorage.setItem("user_id", response.data.msg.id);
            if (localStorage.getItem("position") == "facility_admin") {
                navigate(RoutesPath.fundingSummary);
            } else {
                navigate(RoutesPath.dashboard);
            }
            if (
                response.data.msg.Admin != undefined &&
                response.data.msg.Admin != ""
                ) {
                    localStorage.setItem("user_data_id", response.data.msg.Admin);
               
                } else if (
                    response.data.msg.user_id != undefined &&
                    response.data.msg.user_id != ""
                    ) {
                        localStorage.setItem("user_data_id", response.data.msg.user_id);
             
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log(error);
            });
        };
        return (
            <>
      <div className="login-form form-white">
      <h1 className="page-title">Log in Here</h1>

      <form action="" autoComplete="off" onSubmit={handleSubmit}>
      {renderErrorMessageInvalidCredentials("invalidCredentials")}
      <br></br>
      <Row>
      <Form.Group
              className="col-lg-12 form-group input-email"
              controlId="loginEmail"
              >
              <Form.Label className="hide">Email address</Form.Label>
              <Form.Control
                type=""
                placeholder="Username / Email Address"
                name="emailAddress"
                autoComplete="false"
                />
                {renderErrorMessage("uname")}
                </Form.Group>

                <Form.Group
              className="col-lg-12 form-group input-password"
              controlId="loginPassword"
              >
              <Form.Label className="hide">Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Your password"
                name="pass"
                autoComplete="false"
                />
                {renderErrorMessagePassword("pass")}
                </Form.Group>
                <Form.Group
              className="col-6 form-group input-remember-me"
              controlId="rememberCheckbox"
              >
              <div className="custom-checkbox-wrapper">
              <Form.Control type="checkbox" />
              Remember me
              <span class="checkmark"></span>
              </div>
              </Form.Group>
              <Form.Group
              className="col-6 form-group input-fotgot-password"
              controlId="forgotPassword"
              >
              <Link to={RoutesPath.resetPassword}>Forgot Password?</Link>
              </Form.Group>
              <input
              type="submit"
              className="col form-group btn btn-primary"
              value="Log in"
              data-action="submit"
              />
              <Form.Group
              className="col-lg-12 form-group login-with-button"
              controlId="loginWith"
              >
              <span>- or continue with - </span>
              <div className="login-with-btn-wrap">
                {/* <GoogleButton
                                    onClick={openGoogleLoginPage}
                                    label="Sign in with Google"
                                    disabled={!process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                /> */}

                {/* <GoogleLogin
                                    clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                    buttonText="Login"
                                    onSuccess={onGoogleLoginSuccess} // perform your user logic here
                                    onFailure={responseGoogle}
                                    cookiePolicy={'single_host_origin'}
                                    plugin_name= {'react-google-login'}
                                />, */}
                {/* <Button className="col" variant="secondary" type="button" onClick={openGoogleLoginPage}>
                                    <img src="assets/images/google-icon.svg" />
                                    <span>Google</span>
                                </Button> */}
                {/* <Button className="col" variant="secondary" type="button">
                                    <img src="assets/images/facebook-icon.svg" />
                                    <span>Facebook</span>
                                </Button> */}

<GoogleOAuthProvider
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  >
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                        const responsePayload = decodeJwtResponse(
                            credentialResponse.credential
                            );

                            let email = responsePayload.email;
                            const token = socialLogin({
                                email,
                            });
                        }}
                        onError={() => {
                            console.log("Login Failed");
                        }}
                        useOneTap
                        />
                        </GoogleOAuthProvider>

                        <FacebookLogin
                  appId="656897552806496"
                  autoLoad={false}
                  fields="name,email,picture"
                  callback={responseFacebook}
                  />
                  </div>
                  </Form.Group>
                  </Row>
                  </form>
                  </div>
                  </>
  );
};

export default LoginForm;
