import React, { useRef, useState } from "react";

const useWalkThrough = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

   const steps = [
    {
      description:
        "We have here your dash board, where you can see everything in your facility at a glance!",
      target: () => ref1.current,
      placement: "right",
    },
    {
      description:
        "The overview section gives you details to assess the current state of your facility with just one look",
      target: () => ref2.current,
    },
    {
      title: "3rth action",
      description: "Click to see 3rth other actions.",
      target: () => ref3.current,
      placement: "right",
    },
    {
      title: "4rth action",
      description: "Click to see 4rth other actions.",
      target: () => ref4.current,
      //nextButtonProps : () => Navigate("/scheduling")
    },
  ];

  return  [ref1 , steps]
  
};

export default useWalkThrough;
