import React, { useState, useEffect } from 'react';
import SchedulingCalender from '../inner-components/SchedulingCalender';
import { RoutesPath } from '../../App';


const SchedulingCalenderSection = (props) => {
    // const [total,setTotal]=useState([])
    // useEffect(()=>{
    //     console.log(props.filterRequest,"from calender")
    // },[props.filterRequest])
    return (
        <>
            <div className='calender-wrapper'>
                <SchedulingCalender filterRequest={props.filterRequest} Schedulingref2={props.Schedulingref2}  handleRefresh={props.handleRefresh} refresh={props.refresh} allRender={props.allRender} handleAllRender={props.handleAllRender} Schedulingref5={props.Schedulingref5}/>
            </div>
        </>
    )
}

export default SchedulingCalenderSection;