import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Modal } from 'react-bootstrap';
import InputGroupForm from '../inner-components/InputGroupForm';
import ImageUploadInput from '../inner-components/ImageUploadInput';
import DateofBirthInput from '../inner-components/DateofBirthInput';
import CustomDropdown from '../inner-components/CustomDropdown';
import axios from "axios";
import { Modifier } from 'draft-js';
import { Buffer } from 'buffer';
import { ENV } from '../../env/env'

import S3FileUpload from 'react-s3';


const EditProfileFacilityAccess = (props) => {

    const [userData, setUserData] = useState("")
    const [data, setData] = useState({
        address: "",
        avatar_image: "",
        contact_no: "",
        date_of_birth: "",
        email: "",
        fullname: "",
        admin_id: "",
        password: "",
        role: "",
        facility: ""

    })
    const [errorMessages, setErrorMessages] = useState("");
    const [dob, setDob] = useState()
    const [oldpass, setOldPass] = useState()
    const [newpass, setNewPass] = useState()
    const [conf, setConfPass] = useState()
    const [facility, setFacility] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [roles, setRole] = useState()
    const [userName, setUserName] = useState()
    const [Address, setAddress] = useState()
    const [Email, setEmail] = useState()
    const [fullName, setFullName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [modeifiedDate, setModifiedDate] = useState()
    const [facilities, setFacilities] = useState([])
    const [position, setPosition] = useState()
    const [loader, setLoader] = useState(false)
    const [classe, setClasse] = useState()

    window.Buffer = Buffer;
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState()

    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + '/facility/view/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                
                setFacilities(response.data.results)
            })
            .catch((error) => {
                console.log(error)
            })



        axios.get(ENV.ENDPOINT + '/user/profile/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.msg)
                setUserData(response.data.msg)
                if (response.data.msg.position == 'poc_admin') {
                    console.log("facility POC admin")

                    axios.get(ENV.ENDPOINT + `/admin/view/?admin_id=${response.data.msg.Admin}`, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            setData(response.data.results[0])
                            console.log(response.data.results[0], "hjjjjjjjjjjjjjjjjjjjjjjjj")
                            setUserName(response.data.results[0].username)
                            setEmail(response.data.results[0].email)

                            setPhoneNumber(response.data.results[0].phone_no)
                            setAddress(response.data.results[0].address)
                            setFacility(response.data.results[0].facility_name)
                            const [year, month, day] = response.data.results[0].date_of_birth.split('-');
                            const result1 = [month, day, year].join('/');
                            setModifiedDate(result1)
                            setPosition(response.data.results[0].poc_position)
                            setImageURL(response.data.results[0].facilty_image)
                            let token = localStorage.getItem('token')

                            setIsLoading(false)
                            // const [year, month, day]=response.data.results[0].date_of_birth.split('-');
                            // const result1=[ month, day,year].join('/'); 
                            // setModifiedDate(result1)
                            setRole(response.data.results[0].facility_name)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }

            })
            .catch((error) => {
                console.log(error)
            })



    }, [setData, setUserData])

    console.log(data)

   
    const optionsFacilities = facilities.map(function (row) {
        return { value: row.facility_name }
    })



    const handleUsername = (event) => {
        setUserName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleFullName = (event) => {
        setFullName(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleAddress = (event) => {
        setAddress(event.target.value)
    }
    const handlePosition = (event) => {
        setPosition(event.target.value)
    }


    useEffect(() => {

    })

    const handledDate = date => {
        let datevalue = date.format()
        console.log(datevalue, "date valueeeeeeeeeeeeeeeeee")

        const [month, day, year] = datevalue.split('/');
        const result = [year, month, day].join('-');
        console.log(result, "resulttttttttttttttttt")
        setDob(result)
    }

    const handleJobSites = (selection) => {
        console.log(selection.value)
        setRole(selection.value)

    }

    const JobSitesData = [
        { value: 'Select Facility', label: 'Select Facility', isdisable: "disable" },
        { value: 'bristol-village', label: 'Bristol Village' },
        { value: 'chillicothe-campus', label: 'Chillicothe Campus' },
        { value: 'Toledo', label: 'Toledo' },


    ]



    const [show, setShow] = useState(false);

    const handleClose = () => { setShow(false); setErrorMessages("") };
    const handleShow = () => setShow(true);

    const [passwords, setPassword] = useState({
        oldPassword: "",
        createNewPassword: "",
        reenterNewPassword: ""

    })
    const handlePassword = (event) => {
        const value = event?.target?.value;
        //set it in the model
        setPassword({
            ...passwords,
            [event.target.name]: value
        });
        console.log(passwords.oldPassword)
    }


    if (data && data.role == "c_level_emp") {
        data.role = "Admin"
    }
    else if (data && data.role == "hmc_admin") {
        data.role = "HMC Admin"
    }


    async function apiCall(credentials) {
        let token = localStorage.getItem('token')
        await axios.patch(ENV.ENDPOINT + `/myuser/edit/${userData.id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setLoader(false)
                console.log(response.data.messages)

            })
            .catch((error) => {
                console.log(error.response.data)
            })

        await axios.patch(ENV.ENDPOINT + `/admin/edit/${data.admin_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.messages)

            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }


    const handleReset = (e) => {
        e.preventDefault()
        async function apiCall(credentials) {
            let token = localStorage.getItem('token')

            await axios.post(ENV.ENDPOINT + `/user/changepassword/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                .then(response => {
                    axios.patch(ENV.ENDPOINT + `/facility/resetPassword/${data.facility_id}/`, password = passwords.createNewPassword, { headers: { "Authorization": `Bearer ${token}` } })
                    console.log(response.data.messages)
                    handleClose()
                })
                .catch((error) => {
                    console.log(error.response.data.non_field_errors[0])
                    if (error.response.data.non_field_errors[0] == "Enter your correct current password") {
                        setErrorMessages({ name: "uname", message: "Enter your correct current password" });
                    }
                    if (error.response.data.non_field_errors[0] == "passwords not matched") {
                        setErrorMessages({ name: "uname", message: "Passwords not matched" });
                    }
                })
        }
        let old_password = Buffer.from(passwords.oldPassword).toString('base64')
        let password = Buffer.from(passwords.createNewPassword).toString('base64')
        let password2 = Buffer.from(passwords.reenterNewPassword).toString('base64')
        const token = apiCall({
            old_password,
            password,
            password2


        });

    }
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    const handleImage = (e) => {
        console.log(e.size)
        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setErrorMessages({ name: "image", message: "" });
            setImage(e)
        }

    }


   

    const handleSubmit = (event) => {
        setLoader(true)
        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {
                            let date_of_birth


                            if (dob) {
                    
                                date_of_birth = dob
                            }
                            else {
                                const [month, day, year] = modeifiedDate.split('/');
                                const result = [year, month, day].join('-');
                                date_of_birth = result
                            }
                    
                            let username = userName
                            let email = Email
                            let address = Address
                            let poc_position = position
                            let fullname = fullName
                    
                            let phone_no = phoneNumber
                            let avatar_image = response.data.location
                    
                    
                    
                            const token = apiCall({
                    
                                username,
                                email,
                                address,
                                avatar_image,
                                poc_position,
                                fullname,
                                phone_no,
                                date_of_birth
                    
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })
                    console.log(encoded)

                };
                reader.onerror = error => reject(error);
            });
        }
        if (image != undefined) {
            getBase64(image)
        }
        else {
            let date_of_birth


            if (dob) {
    
                date_of_birth = dob
            }
            else {
                const [month, day, year] = modeifiedDate.split('/');
                const result = [year, month, day].join('-');
                date_of_birth = result
            }
    
            let username = userName
            let email = Email
            let address = Address
            let poc_position = position
            let fullname = fullName
    
            let phone_no = phoneNumber
            let avatar_image = imageURL
    
    
    
            const token = apiCall({
    
                username,
                email,
                address,
                avatar_image,
                poc_position,
                fullname,
                phone_no,
                date_of_birth
    
            });
        }

       


        //navigate('/manage-users')
    }




    return (

        <div className='edit-profile-main'>
            <div className='edit-user'>
                {/* <Form> */}
                {(data && roles) ? (
                    <><div className="edit-user-top">
                        <div className='user-image'>
                            <div className='image-upload'>
                                <ImageUploadInput UserImage={image} handleImage={handleImage} />
                            </div>
                            {renderErrorMessage("image")}
                        </div>
                        <div className="edit-user-form">
                            <Row className="form-row">
                                <Col md={6}>
                                    <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={userData.username} handleChange={handleUsername} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="fullName" inputLabel="Full name" inputType="text" inputPlaceholder="Text here" inputName="fullName" inputValue={data.fullname} handleChange={handleFullName} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={data.email} handleChange={handleEmail} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="phoneNumber" inputLabel="Phone" inputType="number" inputPlaceholder="Text here" inputName="phoneNumber" inputValue={data.phone_no} handleChange={handlePhoneNumber} />
                                </Col>
                                {/* <Col md={6}>
            <InputGroupForm inputId="favFood" inputLabel="Favorite Food" inputType="text" inputPlaceholder="Text here" inputName="favFood" inputValue="Fruit" />
        </Col> */}
                                <Col md={6}>
                                    <div className="form-group date-of-birth-input">
                                        <label className='form-label'>Date of birth</label>
                                        <DateofBirthInput inputValue={modeifiedDate} handleChange={handledDate} />
                                    </div>
                                </Col>


                            </Row>
                        </div>

                    </div><div className='edit-user-bottom edit-user-form'>
                            <Row className="form-row">
                                <Col md={6}>
                                    <div className='form-group'>
                                        <label className='form-label'>Job Sites</label>
                                        <CustomDropdown optionData={optionsFacilities} data={data.facility_name} isdisable="true" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Position" inputLabel="Position" inputType="text" inputPlaceholder="Text here" inputName="Position" inputValue={position} handleChange={handlePosition} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Address" inputLabel="Address" inputType="text" inputPlaceholder="Text here" inputName="Address" inputValue={data.address} handleChange={handleAddress} />
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Mainline" inputLabel="Mainline" inputType="text" inputPlaceholder="Text here" inputName="Mainline" inputValue={data.phone_no} handleChange={handlePhoneNumber} />
                                </Col>

                            </Row>
                        </div><div className='edit-user-footer'>
                            <div className='btn-wrap'>
                                <button type='button' className='btn border-btn' id='changePassword' onClick={handleShow}>Change Password</button>
                                <button className='btn btn-primary' onClick={handleSubmit}>Save Changes</button>
                            </div>
                            <div className='change-password-popup'>
                                <Modal show={show} onHide={handleClose} className='change-password-popup'>
                                    <Modal.Header closeButton>
                                        <h5>Change Password</h5>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="form-inner">
                                            <form>
                                                {renderErrorMessage("uname")}
                                                <InputGroupForm inputId="oldPassword" inputLabel="Enter old password" inputType="password" inputPlaceholder="*****" inputName="oldPassword" inputValue={oldpass} handleChange={handlePassword} />
                                                <div className='new-password-group'>
                                                    <InputGroupForm inputId="createNewPassword" inputLabel="Create new password" inputType="password" inputPlaceholder="Enter new password" inputName="createNewPassword" handleChange={handlePassword} />
                                                    <InputGroupForm inputId="reenterNewPassword" inputLabel="" inputType="password" inputPlaceholder="Re-enter new password" inputName="reenterNewPassword" handleChange={handlePassword} />
                                                </div>
                                                <div className='submit-btn'>
                                                    <input type="submit" className='btn btn-primary' value="Save changes" onClick={handleReset} />
                                                </div>
                                            </form>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div></>
                ) : ""}
                {/* </Form> */}

            </div>

        </div>


    )

}

export default EditProfileFacilityAccess;