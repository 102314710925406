import React, {useState, useEffect}  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import { log, debounce } from './utils/utils'
import { useGlobalState, useGlobalMutation } from './utils/container'
import { useNavigate } from 'react-router-dom'
import { validate } from 'uuid'
import axios from 'axios';
import { ENV } from '../env/env'

const useStyles = makeStyles(theme => ({
  call: {
    color: '#fff'
  },
  midItem: {
    marginTop: '1rem',
    marginBottom: '6rem'
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  coverCall: {
    background: 'linear-gradient(to bottom, #307AFF, 50%, #46cdff)',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  coverContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: '#fff',
    position: 'relative'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    display: 'flex',
    minWidth: 350,
    maxWidth: 350,
    minHeight: 500,
    maxHeight: 500,
    borderRadius: '10px',
    boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
  },
  input: {
    maxWidth: '250px',
    minWidth: '250px',
    alignSelf: 'center'
  },
  grid: {
    margin: '0 !important'
  },
  wait: {
    fontFamily: 'HelveticaNeue',
    fontSize: '18px',
    color: '#ffffff',
    textAlign: 'left',
    marginTop: '-78px'
  },
  number: {
    fontFamily: 'HelveticaNeue',
    fontSize: '48px',
    color: '#ffffff',
    letterSpacing: '10px',
    textAlign: 'left',
  },
  button: {
    height: '44px',
    width: '260px',
    '&:hover': {
      backgroundColor: '#307AFF'
    },
    margin: theme.spacing(1),
    marginTop: '33px',
    backgroundColor: '#44a2fc',
    borderRadius: '30px'
  }
}))

export default function CardPage () {
  const navigate=useNavigate()
  const classes = useStyles()
  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()
  const [cancel, setCancel] = useState(false)

  const handleClickEndCall = () => {
    localStorage.removeItem('peer_id')
    stateCtx.rtmClient.cancelCall()
    // let token = localStorage.getItem('token')
    // const credentials = {

    //   peerId: localStorage.getItem('user_id'),
    //   peerName: localStorage.getItem("fullname"),
    //   channelId: stateCtx.config.channelName,
    //   message: "Remote Call End",
    //   message_type: "call",
    //   image: localStorage.getItem('avatarImage'),

    // }
    // axios.post(ENV.ENDPOINT + `/fcm-data/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
    //   .then(response => {

    //   })
    //   .catch((error) => {
    //   })
  }

  const mouseOver = () => {
    setCancel(true)
  }

  const mouseOut = () => {
    setCancel(false)
  }

  useEffect(() => {
   
    
    stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
     
      mutationCtx.updatePeerCode(remoteInvitation.callerId)
      mutationCtx.updateIncomingCode(remoteInvitation.callerId)
      mutationCtx.updateConfig({ channelName: remoteInvitation.content })
      
     navigate({
        pathname: `/call-invi/card/indexCard/incoming/`
      })
    })

    stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
      mutationCtx.toastError('Call not answered')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
      log('Accept success')
      console.log("cann aceepted from inter-coding-card")
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      log('channel id', channel)
      navigate({
        pathname: `/meeting/${channel}`
      }) 
    })

    stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
      console.log("cann aceepted from inter-coding-card")
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      log('channel id', channel)
      navigate({
        pathname: `/meeting/${channel}`
      })
    }) 

    stateCtx.rtmClient.on('LocalInvitationRefused', () => {
      mutationCtx.toastError('Peer is busy')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
   
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
      console.log("call declined from another user")
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/calling/`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationFailure', () => {
      mutationCtx.toastError('Call process failed')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })
   
    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners()
    //   navigate('/card')
    }
    
  }, [stateCtx.rtmClient, stateCtx])

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
      <Card className={classes.card}>
        <Box display="flex" flex="1" >
          <div className={classes.coverCall + " " + "call-box-wrapper"}>
            <div className='call-top-header'>
              <div className={classes.item + " " + "call-box-single"}>
                <div className='head-box'>
                  <div className='agora-pluse'></div>
                  <div className='head-image'>
                    <img src={localStorage.getItem('peerImage')} alt=''/>
                  </div>
                </div>
              </div>
              <div className={classes.item + " " + "call-box-single"}>
                <div className={classes.number + " " + "call-code-number"}>{localStorage.getItem("peerUserName")}</div>
              </div>
            </div>
            <div className={classes.item + " " + "call-box-single"}>
              <div className={classes.wait + " " + "calling-text"}>{localStorage.getItem('peerStatus')=='true'?'Ringing...':'Calling...'}</div>
            </div>
            <div className={classes.item + " " + "call-box-single"}>
              {/* <div
                onMouseOver={mouseOver} onMouseOut={mouseOut}
                className={cancel ? 'endCall-image-calling-pressed' : 'endCall-image-calling'}
                onClick={debounce(handleClickEndCall, 200)}
              /> */}
              <button onMouseOver={mouseOver} onMouseOut={mouseOut}  className={cancel ? 'endCall-image-calling-pressed call-btn end-call-btn' : 'endCall-image-calling call-btn end-call-btn'} onClick={debounce(handleClickEndCall, 200)}>
                  
              </button>
            </div>
          </div>
        </Box>
      </Card>
    </Box>
  )
}
