import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ENV } from '../../env/env'
import moment from 'moment';

const RecentActivitiyTable = (props) => {
    const [recentTableData, setRecentTableData] = useState()
    const [filterDate, setFilterDate] = useState(moment().format('YYYY-MM-DD'))
    const [dropdownData, setDropdownData] = useState('')
    const [facilityId , setfacilityId] = useState(0)
    
    useEffect(() => {
        setTimeout(() => {
            setfacilityId(localStorage.getItem('facility_id'))
        }, 2000);
    },[])
    
    useEffect(() => {
        let token = localStorage.getItem('token')
       
     
        if(props.dropdownData!=undefined&&props.dropdownData!=""&&props.dropdownData=='all'){
            setDropdownData('')
        }
        else if(props.dropdownData!=undefined&&props.dropdownData!=""){
            setDropdownData(props.dropdownData)
        }
       
        if(props.filterDate!=undefined && props.filterDate!=""){
            setFilterDate(props.filterDate)
        }
        // if(props.dropdownData!=undefined && props.filterDate!=undefined){
        //     setDropdownData(props.dropdownData)
        //     setFilterDate(props.filterDate)
        //     axios.get(ENV.ENDPOINT+`/activities/view/?search=${props.filterDate},${dropdownData}`, { headers: { "Authorization": `Bearer ${token}` } })
        //     .then(response => {



        //         const optionsFacilities = response.data.results.map(function (row) {
        //             return {
        //                 activityIconClassName: "icon-payment-solid",
        //                 activityName: row.activity_title,
        //                 activityAmount: "",
        //                 activityStatus: "Success",
        //                 activityStatusClassName: "success",
        //                 activityDate: row.acitivity_date
        //             }
        //         })
        //         setRecentTableData(optionsFacilities)


        //     })
        //     .catch((error) => {
        //         console.log(error.response.data)
        //     })
        // }
        // else if(props.dropdownData==undefined && props.filterDate!=undefined){

        //     setFilterDate(props.filterDate)
        //     axios.get(ENV.ENDPOINT+`/activities/view/?search=${props.filterDate}`, { headers: { "Authorization": `Bearer ${token}` } })
        //     .then(response => {



        //         const optionsFacilities = response.data.results.map(function (row) {
        //             return {
        //                 activityIconClassName: "icon-payment-solid",
        //                 activityName: row.activity_title,
        //                 activityAmount: "",
        //                 activityStatus: "Success",
        //                 activityStatusClassName: "success",
        //                 activityDate: row.acitivity_date
        //             }
        //         })
        //         setRecentTableData(optionsFacilities)


        //     })
        //     .catch((error) => {
        //         console.log(error.response.data)
        //     })
        // }
        // else if(props.dropdownData!=undefined && props.filterDate==undefined){
        //     setDropdownData(props.dropdownData)

        //     axios.get(ENV.ENDPOINT+`/activities/view/?search=${props.dropdownData}`, { headers: { "Authorization": `Bearer ${token}` } })
        //     .then(response => {



        //         const optionsFacilities = response.data.results.map(function (row) {
        //             return {
        //                 activityIconClassName: "icon-payment-solid",
        //                 activityName: row.activity_title,
        //                 activityAmount: "",
        //                 activityStatus: "Success",
        //                 activityStatusClassName: "success",
        //                 activityDate: row.acitivity_date
        //             }
        //         })
        //         setRecentTableData(optionsFacilities)


        //     })
        //     .catch((error) => {
        //         console.log(error.response.data)
        //     })
        // }

        
       
        axios.get(ENV.ENDPOINT + `/activities/view/?search=${localStorage.getItem('facility_id')},${filterDate},${dropdownData}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {



                const optionsFacilities = response.data.results.map(function (row) {
                    let icon=''
                    if(row.category=='Shift request approve'){
                        icon='icon-shift-request-solid'
                    }
                    else if(row.category=='No Shows'){
                        icon='icon-shift-request-solid'
                    }
                    else if(row.category=='Payments'){
                        icon='icon-payment-solid'
                    }
                    else if(row.category=='Feedbacks'){
                        icon='icon-shift-request-solid'
                    }
                    return {
                        activityIconClassName: icon,
                        activityName: row.activity_title,
                        activityAmount: "",
                        activityStatus: "Success",
                        activityStatusClassName: "success",
                        activityDate: row.acitivity_date
                    }
                })
                setRecentTableData(optionsFacilities)


            })
            .catch((error) => {
                console.log(error.response.data)
            })


            props.handleRefresh(false)
    }, [props.filterDate, props.dropdownData, filterDate, dropdownData,props.refresh , facilityId])

    // const recentTableData = [
    //     {
    //         activityIconClassName: "icon-payment-solid",
    //         activityName: "Payment Recieved",
    //         activityAmount: "$2,000",
    //         activityStatus: "Success",
    //         activityStatusClassName: "success",
    //         activityDate: "May 5, 2022"
    //     },
    //     {
    //         activityIconClassName: "icon-shift-request-solid",
    //         activityName: "Shift request approved",
    //         activityAmount: "",
    //         activityStatus: "Success",
    //         activityStatusClassName: "success",
    //         activityDate: "May 5, 2022"
    //     },
    //     {
    //         activityIconClassName: "icon-close-solid",
    //         activityName: "No show without notification",
    //         activityAmount: "",
    //         activityStatus: "Failed",
    //         activityStatusClassName: "failed",
    //         activityDate: "May 5, 2022"
    //     },
    //     {
    //         activityIconClassName: "icon-close-solid",
    //         activityName: "Shift cancellation approved",
    //         activityAmount: "",
    //         activityStatus: "Processing",
    //         activityStatusClassName: "processing",
    //         activityDate: "May 5, 2022"
    //     },
    //     {
    //         activityIconClassName: "icon-payment-solid",
    //         activityName: "Payment Recieved",
    //         activityAmount: "$2,000",
    //         activityStatus: "Success",
    //         activityStatusClassName: "success",
    //         activityDate: "May 5, 2022"
    //     },
    //     {
    //         activityIconClassName: "icon-payment-solid",
    //         activityIconClassName: "icon-payment-solid",
    //         activityName: "Payment Recieved",
    //         activityAmount: "$2,000",
    //         activityStatus: "Success",
    //         activityStatusClassName: "success",
    //         activityDate: "May 5, 2022"
    //     }
    // ]

    return (
        <table width="100%">
            <thead>
                <tr>
                    <th>Activity</th>
                    <th></th>
                    <th>Status</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {recentTableData ? (
                    <>
                        {recentTableData.map((val, index) =>
                            <tr key={val.activityName + index}>
                                <td>
                                    <div className="activity-text">
                                        <span className='icon-wrap'>
                                            <i className={val.activityIconClassName}></i>
                                        </span>
                                        <span className='text-wrap'>{val.activityName}</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='amount-text'>
                                        <span>{val.activityAmount}</span>
                                    </div>
                                </td>
                                <td>
                                    <span className={`status-tag ${val.activityStatusClassName}`}>{val.activityStatus}</span>
                                </td>
                                <td>
                                    <span className="date-text">{val.activityDate}</span>
                                </td>

                            </tr>

                        )}
                    </>
                ) : ""}

            </tbody>
        </table>
    )
}

export default RecentActivitiyTable;