import axios from "axios";
import moment from 'moment';
import { ENV } from '../../env/env'

export default {
    notificationTriggerAPI: (notificationMsg,clinician_id)=>{
        var user_id=localStorage.getItem('user_id')
        if(clinician_id!=""&&clinician_id!=undefined){
            user_id=clinician_id
        }
        
        var notification=notificationMsg
        var created_time=moment()
        const notificationdata ={
            user_id,
            notification,
            created_time
        };
        let token = localStorage.getItem('token')
        var link=ENV.ENDPOINT + `/notification/create/`
        axios.post(link, notificationdata, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
             
            })
            .catch((error) => {
                console.log(error.response)
            })
    }
}