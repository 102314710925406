import React, { useState, useEffect } from 'react';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import Rater from 'react-rater'
import 'react-rater/lib/react-rater.css';
import axios from "axios";
import { ENV } from '../../env/env'

const RatePopup = (props) => {

    const [show, setShow] = useState(false);

    const [clinicianData,setClinicianData]=useState()

    const handleClose = () => {
        props.submit()
        props.handleRefresh(true)
        setShow(false);
    }
    const handleShow = () => setShow(true);
  
    useEffect(()=>{
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/clinician/view/?clinician_id=${props.data}`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setClinicianData(response.data.results[0])
              

            })
            .catch((error) => {
                console.log(error)
            })
         
    },[])

    return (
        <>
        {clinicianData?(

       
        <div className="add-user-pop-wrap note-pop-wrap">
            <Button className="border-btn small-btn" onClick={handleShow}>Rate</Button><Modal show={show} onHide={handleClose} className='add-user-popup note-popup rate-popup'>
                <Modal.Header closeButton>
                    <h5>{props.ModelTitle}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='add-user-form note-popup-form'>
                        <Form>
                        <div className="form-inner">
                        <Row className="form-row align-items-center nurse-info">
                        <Col sm={3} className='mr-3'>
                        <img src={clinicianData.avatar_image} alt={clinicianData.firstname+" "+clinicianData.lastname} />
                        </Col>
                        <Col sm={7}>
                        <h5 className='mb-0'>{clinicianData.firstname+" "+clinicianData.lastname}</h5>
                        <Rater rating={0} total={5} onRate={({ rating }) => { props.ratings(rating); } } />
                        </Col>
                        </Row>
                        <Row className="form-row">
                        <Col md={12}>
                        <Form.Label className="">Nurse performance feedback good or bad :</Form.Label>
                        <Form.Control as="textarea" placeholder="Text here" id="Note" onChange={props.onChange} />
                        </Col>
                        </Row>
                        <div className='submit-btn'>
                        <input type="submit" value="Confirm" className="btn btn-primary w-100" onClick={handleClose} />
                        </div>
                        </div>
                        </Form>
                        </div>
                        </Modal.Body>

                        </Modal>
                        </div>
        ):""}
        </>
    )

}

export default RatePopup;
