import React, { useState, useEffect } from 'react';
import { Dropdown, Button, Form } from 'react-bootstrap';
import { RoutesPath } from '../../App';



const CustomDropdown = (props) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [selection, setSelection] = useState("");

    useEffect(() => {
        if(props.dashboard=="true"&&props.optionData!=undefined&&props.optionData!=""){
            const selectedFirst =props.optionData[0].value
            setSelection(selectedFirst);
        }
        //for search filter
        if(props.clinicianDropdown==undefined&&props.editClinician==undefined&&props.data==undefined&&props.optionData!=undefined&&props.optionData!=""&&props.dashboard==undefined){
            const selectedFirst =props.optionData[0].value
            setSelection(selectedFirst);
        }
        //for clinicians
        if(props.editClinician!=undefined&&props.optionData!=undefined&&props.optionData!=""){
            const selectedFirst =props.editClinician
            setSelection(selectedFirst);
        }

        // else if(props.optionData!=undefined&&props.optionData!=""&&props.clinicianDropdown!=undefined){
        //     console.log("with clinician add popup")
        //     const selectedFirst = props.optionData[0].value
        //     setSelection(selectedFirst);
        // }
        // //for admin users role dropdown
        if(props.optionData!=undefined&&props.optionData!=""&&props.data){
           
            //const value=[]
            // props.optionData.forEach((el) => {
            //         if (el.value == props.data) {
            //             value.push(el)
            //         }   
            //     });
            //    console.log(value)
                 const selectedFirst =props.data
             setSelection(selectedFirst); 
        }
        // else if(props.optionData==undefined&&props.optionData==""&&props.data){
        //     console.log("with propsssss22222")
            
        //         const selectedFirst =props.data
        //     setSelection(selectedFirst);  
        // }
        // else if(props.optionData==undefined&&props.optionData==""&&props.data&&props.dashboard==true){
        //     console.log("with propsssss22222")
            
        //     const selectedFirst =props.selectedFirst
        //     setSelection(selectedFirst);  
        // }
        
     
    }, [props.data]);
   

    const handleClose = (e) => {
        if (e.target.innerText !== selection && e.target.innerText !== "") {
            setSelection(e.target.innerText);
        }
        setSearchText("");
        setAnchorEl(null);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    };


    return (
        <div className={`dropdown-wrapper ${props.dropClass}`}>
            <Dropdown id="facilityDropdown" className="custom-dropdown" disable={props.isdisable}>
                <Dropdown.Toggle id="facilityDropdown">
                    {selection}
                </Dropdown.Toggle>
                {renderDashboardMenu()}
            </Dropdown>
        </div>
    );


    function renderDashboardMenu() {
        const displayOptions = props.optionData.map((item) => {
            if (item.value.toLowerCase().includes(searchText.toLowerCase())) {
                return item;
            }
            return undefined;
        })
            .filter((item) => item !== undefined);

        function renderOption(value) {
            if (selection === value) {
                return (
                    <div className="active">
                        {value}
                    </div>
                );
            }
            return value;
        }

        return (
            <Dropdown.Menu onClose={handleClose} >
                <div className="search-input-wrap">

                    <div className="search-icon">
                        <img src="assets/images/ico/search-icon-grey.svg" alt=""/>
                    </div>
                    <Form.Control type="search" onChange={handleSearchChange} placeholder="Search..." name="Search" value={searchText} />

                </div>

                {displayOptions.map((item, index) => {
                    return (
                        <div key={index + item}>
                        {props.filterInput?( <Dropdown.Item  onClick={(e) =>{handleClose(e);props.filterInput(item);}} key={index 
                        + item} isDisable={item.isdisable}>
                            {renderOption(item.value)}
                        </Dropdown.Item>):(
                            <Dropdown.Item onClick={(e) =>{handleClose(e);props.handleChange(item)}} key={index + item} isDisable={item.isdisable}>
                            {renderOption(item.value)}
                        </Dropdown.Item>
                        )}
                       
                        </div>

                    );
                })}
            </Dropdown.Menu>
        );
    }

}


export default CustomDropdown;