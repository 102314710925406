import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import LoginSection from '../components/sections/LoginSection';
import { useNavigate, Link } from 'react-router-dom';
import { RoutesPath } from '../App';
import axios from "axios";
import { Buffer } from 'buffer';
import { ENV } from '../env/env'

const Login = ({ setToken }) => {
    const navigate = useNavigate();
    let email = "";
    let password = "";
    if (localStorage.getItem('username') && localStorage.getItem('password')) {
        email = Buffer.from(localStorage.getItem('username'), 'base64').toString('ascii');
        password = Buffer.from(localStorage.getItem('password'), 'base64').toString('ascii');
    }




    const [data, setData] = useState()
    const [tokenn, setTokenn] = useState()
    
    async function loginUser(credentials) {

        const response = await axios.post(ENV.ENDPOINT+'/user/web/login/', credentials);
        setData(response.data.token);
        setTokenn(response.data.token);
        localStorage.setItem('token', response.data.token.access)
        if(localStorage.getItem('position')=='facility_admin'){
            navigate(RoutesPath.fundingSummary);
        }
        else{
            navigate(RoutesPath.dashboard);
        }

    }

    useEffect(() => {
        // localStorage.clear()
        if (localStorage.getItem('isLoggedIn') == "true") {
            navigate(RoutesPath.dashboard);
        }
        if (email && password) {
            const token = loginUser({
                email,
                password
            });
        }
    });

   
    

    return (
        <>
            <Header />
            <main className='app-main'>
                <LoginSection />
            </main>
        </>
    )
}

export default Login;