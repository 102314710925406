import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { ENV } from '../../env/env'


const TopQuotes = (props) => {
const [quote,setQuote]=useState()


    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT+'/daily_quotes/view/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setQuote(response.data.results[0].quote)
            })

    }, [])

    return (
        <>
            <div className='quotes-wrap'>
                <p>{quote}</p>
            </div>
        </>
    )
}

export default TopQuotes;