import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import React, { useState } from "react";

const MapContainer = (props) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchText, setSearchText] = useState("");

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    setSelectedPlace(place.geometry.location);
    setSearchText(place.formatted_address);
  };

  const onMapClicked = (mapProps, map, event) => {
    setSelectedPlace(event.latLng);
    setSearchText("");
  };

  const onSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const autocompleteRef = React.useRef(null);

  const geocoder = new props.google.maps.Geocoder();

  const getLatLngFromAddress = (address) => {
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          resolve(results[0].geometry.location);
        } else {
          reject(status);
        }
      });
    });
  };

  React.useEffect(() => {
    if (searchText && searchText.length > 0) {
      getLatLngFromAddress(searchText).then((location) =>
        setSelectedPlace(location)
      );
    }
  }, [searchText]);

  const initialCenter = {
    lat: 37.7749,
    lng: -122.4194,
  };

  return (
    <div>
      <input
        ref={autocompleteRef}
        placeholder="Enter a location"
        onKeyUp={onSearchTextChange}
        value={searchText}
        className="mb-4"
      />
      <Map
        google={props.google}
        zoom={14}
        initialCenter={initialCenter}
        onClick={onMapClicked}
        style={{ width: "600px", height: "300px", position: "relative" }}
      >
        {selectedPlace && (
          <Marker position={{ lat: selectedPlace.lat(), lng: selectedPlace.lng() }} />
        )}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyD_dE5pCTFvlVZMOgZAw0xuWreUVz7L7Vo",
})(MapContainer);
