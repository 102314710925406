import AgoraRTC from 'agora-rtc-sdk-ng'
import EventEmitter from 'events'
import axios from "axios";
import { ENV } from '../env/env'


const appID = "c501ed932b7b4571b68af340322001e0"
// console.log(
//   'agora sdk version: ' +
//   AgoraRTC.VERSION +
//   ' compatible: ' +
//   AgoraRTC.checkSystemRequirements()
// )
export default class RTCClient {
  
  constructor() {
    this._client = null
    this._joined = false
    this._localStream = null
    // this._enableBeauty = false;
    this._params = {}
    this._uid = 0
    this._eventBus = new EventEmitter()
    this._showProfile = false
    this._subscribed = false
    this._created = false
    this._localAudioTrack = null
   
  }

  createClient(data) {
    this._client = AgoraRTC.createClient({
      mode: data.mode,
      codec: data.codec
    })
    return this._client
  }

  closeStream() {
    if (this._localStream.isPlaying()) {
      this._localStream.stop()
    }
    this._localStream.close()
  }

  destroy() {
    this._created = false
    this._client = null
  }

  on(evt, callback) {
    const customEvents = ['localStream-added', 'screenShare-canceled']

    if (customEvents.indexOf(evt) !== -1) {
      this._eventBus.on(evt, callback)
      return
    }

    this._client.on(evt, callback)
  }

  setClientRole(role) {
    this._client.setClientRole(role)
  }

  createRTCStream(data) {
    return new Promise((resolve, reject) => {
      this._uid = this._localStream ? this._localStream.getId() : data.uid
      if (this._localStream) {
        this.unpublish()
        this.closeStream()
      }
      // create rtc stream
      const rtcStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: true,
        video: false,
        screen: false,
        microphoneId: data.microphoneId,
        cameraId: data.cameraId
      })


      // init local stream
      rtcStream.init(
        () => {
          this._localStream = rtcStream
          this._eventBus.emit('localStream-added', {
            stream: this._localStream
          })

          if (data.muteAudio === false) {
            this._localStream.muteAudio()
          }
          resolve()
        },
        (err) => {
          reject(err)
       
          console.error('init local stream failed ', err)
        }
      )
    })
  }

  createScreenSharingStream(data) {
    return new Promise((resolve, reject) => {
      // create screen sharing stream
      this._uid = this._localStream ? this._localStream.getId() : data.uid
      if (this._localStream) {
        this._uid = this._localStream.getId()
        this.unpublish()
      }
      const screenSharingStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: true,
        video: false,
        screen: true,
        microphoneId: data.microphoneId,
        cameraId: data.cameraId
      })

      if (data.resolution && data.resolution !== 'default') {
        screenSharingStream.setScreenProfile(`${data.resolution}_1`)
      }

      screenSharingStream.on('stopScreenSharing', (evt) => {
        this._eventBus.emit('stopScreenSharing', evt)
        this.closeStream()
        this.unpublish()
      })

      // init local stream
      screenSharingStream.init(
        () => {
          this.closeStream()
          this._localStream = screenSharingStream

          // run callback
          this._eventBus.emit('localStream-added', {
            stream: this._localStream
          })
          resolve()
        },
        (err) => {
          this.publish()
          reject(err)
        }
      )
    })
  }

  subscribe(stream, callback) {
    this._client.subscribe(stream, callback)
  }

  _createTmpStream() {
    this._uid = 0
    return new Promise((resolve, reject) => {
      if (this._localStream) {
        this._localStream.close()
      }
      // create rtc stream
      const _tmpStream = AgoraRTC.createStream({
        streamID: this._uid,
        audio: true,
        video: false,
        screen: false
      })

      // init local stream
      _tmpStream.init(
        () => {
          this._localStream = _tmpStream
          resolve()
        },
        (err) => {
          reject(err)
          // Toast.error("stream init failed, please open console see more detail");
          console.error('init local stream failed ', err)
        }
      )
    })
  }

  getDevices() {
    return new Promise((resolve, reject) => {
      if (!this._client) {
        this.createClient({ codec: 'vp8', mode: 'rtc' })
      }
      this._createTmpStream().then(() => {
        AgoraRTC.getDevices((devices) => {
          this._localStream.close()
          resolve(devices)
        })
      })
    })
  }

  setStreamFallbackOption(stream, type) {
    this._client.setStreamFallbackOption(stream, type)
  }

  enableDualStream() {
    return new Promise((resolve, reject) => {
      this._client.enableDualStream(resolve, reject)
    })
  }


  //new join func
  async join(data) {
    let token = localStorage.getItem('token')
    const credentials = {
      channelName: data.channel,
    }
    axios.post(ENV.ENDPOINT + `/tokengenerator/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
      .then(async response => {
        localStorage.setItem('agoraToken', response.data.payload)

        //Agora call
        this._client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        const uid = await this._client.join(appID, data.channel, response.data.payload, null);

        // Create an audio track from the audio sampled by a microphone.
        this._localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Publish the local audio track to the channel.
        this._client.publish([this._localAudioTrack]);

        // publish local tracks to channel
        this._client.on("user-published", async (user, mediaType) => {
          // Subscribe to a remote user.
          await this._client.subscribe(user, mediaType);
        
          // If the subscribed track is audio.
          if (mediaType === "audio") {
         
            // Get `RemoteAudioTrack` in the `user` object.
            const remoteAudioTrack = user.audioTrack;
            // Play the audio track. No need to pass any DOM element.
            remoteAudioTrack.play();
          }
        });
        this._client.on("user-unpublished", async user => {
          // Get the dynamically created DIV container.
       
          this._localAudioTrack.close();
          // Leave the channel.
          await this._client.leave();
          if(localStorage.getItem('position')=='facility_admin'){
            window.location.href='/funding-summary'
          }else{
            window.location.href='/dashboard'
          }
          
        });
      })
      .catch((error) => {
        console.log(error.response)

      })

  }



  //new leave call func
  async leave() {
    // Destroy the local audio and track.
    this._localAudioTrack.close();
    // Leave the channel.
    await this._client.leave();

  }
  

  publish() {
    // publish localStream
    this._client.publish(this._localStream, (err) => {
      console.error(err)
    })
  }

  unpublish() {
    if (!this._client) {
      return
    }
    this._client.unpublish(this._localStream, (err) => {
      console.error(err)
    })
  }

 
}
