import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobalState , useGlobalMutation} from '../utils/container'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import useRouter from '../utils/use-router'
import { log } from '../utils/utils'
import { Link,useNavigate } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
  midItem: {
    marginTop: '1rem',
    marginBottom: '6rem'
  },
  item: {
    flex: 1,
    display: 'flex',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  card: {
    display: 'flex',
    minWidth: 700,
    minHeight: 500,
    maxHeight: 500,
    borderRadius: '10px',
    boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
  },
  grid: {
    margin: '0 !important'
  },
  button: {
    lineHeight: '21px',
    color: 'rgba(255,255,255,1)',
    fontSize: '17px',
    textTransform: 'none',
    height: '44px',
    width: '260px',
    '&:hover': {
      backgroundColor: '#82C2FF'
    },
    margin: theme.spacing(1),
    marginTop: '66px',
    backgroundColor: '#44a2fc',
    borderRadius: '30px'
  },
  identity: {
    fontfamily: 'HelveticaNeue',
    fontSize: '21px',
    color: '#949494',
    textAlign: 'left',
    marginTop: '30px',
  },
  coding: {
    fontSize: '48px',
    fontfamily: 'HelveticaNeue',
    color: '#949494',
    textAlign: 'left',
    margin: '15px',
  },
  explain: {
    fontSize: '12px',
    fontfamily: 'HelveticaNeue',
    color: '#949494',
    textAlign:'center',
  }
}))

export default function IndexCard () {
  
  const navigate=useNavigate()
  const classes = useStyles()

  const routerCtx = useRouter()
  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()

  const handleClick = () => {
    // if(stateCtx.rtmClient.status === 'onLine') {
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/`
      })
    // } else {
    //   mutationCtx.toastError('You are not currently online, Please refresh the webpage')
    // }
  }

  useEffect(() => {

    
    stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
     
      mutationCtx.updatePeerCode(remoteInvitation.callerId)
      mutationCtx.updateIncomingCode(remoteInvitation.callerId)
      mutationCtx.updateConfig({ channelName: remoteInvitation.content })
      
     navigate({
        pathname: `/call-invi/card/indexCard/incoming/`
      })
    })

    stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
      navigate('/call-invi')
    })

    stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
      mutationCtx.toastError('Call not answered')
      navigate('/call-invi')
    })

    stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      navigate({
        pathname: `/meeting/${channel}`
      }) 
    })

    stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      navigate({
        pathname: `/meeting/${channel}`
      })
    }) 

    stateCtx.rtmClient.on('LocalInvitationRefused', () => {
      mutationCtx.toastError('Peer is busy')
      navigate('/call-invi')
    })

    stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
      navigate('/call-invi')
    })

    stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
      navigate('/call-invi')
    })

    stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/calling/`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationFailure', () => {
      mutationCtx.toastError('Call process failed')
      navigate('/call-invi')
    })
   
    if(localStorage.getItem('peer_id')!=undefined){
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/`
      })
    }
    
    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners()
    //   navigate('/card')
    }
    
  }, [stateCtx.rtmClient, stateCtx])

  return (
    <Box
      marginTop="114px"
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <Link to="/setting" className="setting-btn" />
      {/* <a
        href="https://github.com/AgoraIO-Usecase/Video-Calling/tree/master"
        className="github"
      ></a> */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <span className={classes.identity}>Your identifier code</span>
        <span className={classes.coding}>{stateCtx.userCode}</span>
        <span className={classes.explain}>Share your code</span>
        <span className={classes.explain}>so that others call you</span>
        <FormControl className={classes.grid}>
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            className={classes.button}
          >                                                     
            Start calling your friend
          </Button>
        </FormControl>
      </Box> 
    </Box>
  )
}
