import React, { useState, useEffect } from 'react';




const ManageUserEditUserSection = (props) => {
    return (
                    <div className="user-tab-col">
                        <div className="col-inner">
                            {props.userTypeData}
                        </div>
                    </div>

      
    )
}

export default ManageUserEditUserSection;