import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Modal, Button } from "react-bootstrap";
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
// import { Chart } from "react-google-charts";
import {
  Chart,
  Series,
  SeriesTemplate,
  CommonSeriesSettings,
  Export,
  HoverStyle,
} from "devextreme-react/chart";
import HalfCirclePieChart from "./HalfCirclePieChart";
import NurseTierRequestPieChart from "./NurseTierRequestPieChart";
import FacilityRequestRatePieChart from "./FacilityRequestRatePieChart";
import axios from "axios";
import moment from "moment";
import { ENV } from "../../env/env";
import { useNavigate } from "react-router-dom";
import RenewToken from "../inner-components/RenewToken";
import { Line } from "react-chartjs-2";
import CustomDropdown from "../inner-components/CustomDropdown1";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsSection = (props) => {
  const navigate = useNavigate();
  const [requestRate, setRequestRate] = useState();
  const [date, setDate] = useState();
  const [totalRequestRow, setTotalRequestRow] = useState();
  const [totalCompletedRequestRow, setTotalCompletedRequestRow] = useState();

  const [facilities, setFacilities] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [value, setValue] = useState([
    new DateObject().setDay(1),
    new DateObject().add(1, "month").setDay(1),
  ]);
  const [dateRange, setDateRange] = useState({
    date1: "",
    date2: "",
  });
  const [noOfMonth, setNoOfMonth] = React.useState(2);

  const calendarRef = useRef();

  useEffect(() => {
    let date1 = value[0].year + "-" + value[0].month + "-" + value[0].day;
    let date2 = "";
    if (value[1] != undefined) {
      date2 = value[1].year + "-" + value[1].month + "-" + value[1].day;
    }

    let filterParam = "";
    if (localStorage.getItem("position") == "facility_admin") {
      filterParam = localStorage.getItem("facility_id");
    }

    setDateRange({ date1: date1, date2: date2 });

    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + `/scheduling/view/?facility_id=${filterParam}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let totalRequestList = [];
        totalRequestList = response.data.results;
        let dateData = [];
        const dateRange = response.data.results.map(function (rowDate) {
          if (
            moment(rowDate.start_date).format("YYYY-MM-DD") >=
            moment(date1).format("YYYY-MM-DD")
          ) {
            if (
              moment(rowDate.start_date).format("YYYY-MM-DD") <=
              moment(date2).format("YYYY-MM-DD")
            ) {
              dateData.push(rowDate);
            }
          }
        });
        const unique = [...new Set(dateData.map((item) => item.start_date))];
        let data = [];
        const graphdata = unique.map(function (row) {
          let completedList = [];
          let perdayTotal = [];

          dateData.map(function (row1) {
            if (row1.start_date == row) {
              if (row1.status == "Completed") {
                completedList.push(row1);
              }
              perdayTotal.push(row1);
            }
          });
          return {
            month: row,
            totalRequest: perdayTotal.length,
            completed: completedList.length,
          };
        });
        setRequestRate(graphdata);

        let dateList = [];
        let totalListRow = [];
        let completedListRow = [];
        graphdata.map(function (date) {
          dateList.push(date.month);
          totalListRow.push(date.totalRequest);
          completedListRow.push(date.completed);
        });
        setDate(dateList);
        setTotalRequestRow(totalListRow);
        setTotalCompletedRequestRow(completedListRow);

        setLoader(false);
      })
      .catch((error) => {
        if (error.response.data.code == "token_not_valid") {
          RenewToken.RenewTokenAPI();
        }
      });
  }, [value]);
  // const dataSource = [{
  //     month: 'May 2',
  //     totalRequest: 12.5,
  //     completed: 4.9,
  // }, {
  //     month: 'May 3',
  //     totalRequest: 10.1,
  //     completed: 2.5,
  // }, {
  //     month: 'May 4',
  //     totalRequest: 7.5,
  //     completed: 6,
  // }, {
  //     month: 'May 5',
  //     totalRequest: 15.2,
  //     completed: 2.4,
  // }, {
  //     month: 'May 6',
  //     totalRequest: 5.2,
  //     completed: 0.5,
  // }, {
  //     month: 'May 7',
  //     totalRequest: 10,
  //     completed: 3,
  // }, {
  //     month: 'May 8',
  //     totalRequest: 15,
  //     completed: 11,
  // }];

  const labelsX = date;

  const BarChartData = {
    labels: labelsX,
    pointBackgroundColor: "transparent",
    datasets: [
      {
        label: "Total Requests",
        data: totalRequestRow,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "#2753B8",
        borderWidth: 2,
      },
      {
        label: "Completed",
        data: totalCompletedRequestRow,
        fill: false,
        borderColor: "rgb(14,168,15)",
        borderWidth: 2,
      },
    ],
  };

  // const options = {
  //   maintainAspectRatio: false,
  //   scaleShowLabels: false,
  //   label: false,
  //   elements: {
  //     point: {
  //       radius: 0,
  //     },
  //   },
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     ticks: {
  //       display: false,
  //     },
  //     y: {
  //       min: 0,
  //       max: 20,
  //       ticks: {
  //         // forces step size to be 50 units
  //         stepSize: 2.5,
  //       },
  //       grid: {
  //         borderColor: "#000",
  //         borderWidth: 2,
  //       },
  //       gridLines: {
  //         display: false,
  //         color: "#fff",
  //       },
  //     },
  //     x: {
  //       grid: {
  //         borderColor: "#000",
  //         borderWidth: 2,
  //       },
  //     },
  //   },
  // };

   const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Line Chart',
      },
    },
  };

  const Item = (props) => (
    <Legend.Item {...props} className="flex-row-reverse" />
  );

  let token = localStorage.getItem("token");
  axios
    .get(ENV.ENDPOINT + "/facility/", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      setFacilities(response.data.payload);
      if (!localStorage.getItem("facility")) {
        localStorage.setItem(
          "facility",
          response.data.payload[0].facility_name
        );
        localStorage.setItem(
          "facility_id",
          response.data.payload[0].facility_id
        );
      }
    })
    .catch((error) => {
      console.log(error);
    });
  const handleFilter = (selection) => {
    // setFilterInput(selection.value)
    localStorage.setItem("facility", selection.value);
    localStorage.setItem("facility_id", selection.id);
    setRefresh(true);
  };

  const optionsFacilities = facilities.map(function (row) {
    return { value: row.facility_name, id: row.facility_id };
  });
  const handleRefresh = (e) => {
    setRefresh(e);
  };
  const [loader, setLoader] = useState(true);
  const [classe, setClasse] = useState(false);
  
  useEffect(() => {
    if (loader == true) {
      setClasse("custom-loader");
    } else {
      setClasse("custom-loader hide");
    }
  }, [loader]);

  return (
    <div className="statistics-section-main">
      <Row className="dashboard-row-main">
        <div className={classe}></div>
        <Col lg="6" className="range-calnedar-with-graph">
          {/* <CustomDropdown optionData={optionsFacilities} dashboard={true} filterInput={handleFilter} selectedFacility={localStorage.getItem('facility')} /> */}
          <DatePicker
            value={value}
            onChange={setValue}
            range
            numberOfMonths={noOfMonth}
            ref={calendarRef}
            onOpen={() => {
              setTimeout(() => {
                const pickerFooter = document.createElement("div");
                const buttonHtml =
                  "<div class='datepicker-footer-btn'><button class='close-picker-btn btn' id='date-picker-cancel-btn'>Cancel</button><button class='apply-btn btn btn-primary' id='apply-picker-btn'>Apply</button></div>";
                pickerFooter.innerHTML = buttonHtml;
                const datepickerFooter = document.querySelector(
                  ".rmdp-range-picker-footer"
                );
                const footerButton = document.querySelector(
                  ".datepicker-footer-btn"
                );
                if (footerButton === null) {
                  datepickerFooter.appendChild(pickerFooter);

                  const applyButton =
                    document.getElementById("apply-picker-btn");
                  const cancelButton = document.getElementById(
                    "date-picker-cancel-btn"
                  );
                  applyButton.addEventListener("click", function () {
                    calendarRef.current.closeCalendar();
                  });
                  cancelButton.addEventListener("click", function () {
                    setValue(value);
                    calendarRef.current.closeCalendar();
                  });
                }
              }, 100);
            }}
            plugins={[<Footer position="bottom" close="Close" apply="Apply" />]}
          />
          <HalfCirclePieChart
            dateRange={dateRange}
            handleRefresh={handleRefresh}
            refresh={refresh}
          />
        </Col>

        {localStorage.getItem("position") == "c_level_emp" ? (
          <Col lg="6" className="bar-chart line-chart-wrap mt-5 mt-lg-0">
            <Line data={BarChartData} options={options} />
          </Col>
        ) : (
          <Col lg="6" className="bar-chart mt-5 mt-lg-0">
            <Chart
              id="chart"
              palette="Soft"
              // title="Percent of Total Energy Production"
              dataSource={requestRate}
            >
              <CommonSeriesSettings
                argumentField="month"
                type="bar"
                barPadding={0}
                ignoreEmptyPoints={true}
              />
              <Series
                valueField="totalRequest"
                name="Total Request"
                color="#2753b8"
              />
              <Series valueField="completed" name="Completed" color="#0ea80f" />
              {/* <Legend verticalAlignment="top" horizontalAlignment="center" itemComponent={Item} /> */}
              <Export enabled={true} />
            </Chart>
          </Col>
        )}

        <NurseTierRequestPieChart
          dateRange={dateRange}
          handleRefresh={handleRefresh}
          refresh={refresh}
        />

        <FacilityRequestRatePieChart
          dateRange={dateRange}
          handleRefresh={handleRefresh}
          refresh={refresh}
        />
      </Row>
    </div>
  );
};

export default StatisticsSection;
