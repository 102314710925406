import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Auth from './Auth';
function ProtectedRoute({ children, permissions }) {
    
    let auth = false
    let auth1 = []
   
    
    if (localStorage.getItem("token") != undefined && localStorage.getItem("token") != null && localStorage.getItem("token") != "") {
        auth = true
    }
    if (localStorage.getItem("position") == "c_level_emp") {
        auth1 = [1]
    }
    else if (localStorage.getItem("position") == "hmc_admin") {
        auth1 = [2]
    }
    else if (localStorage.getItem("position") == "facility_admin") {
        auth1 = [3]
    }
    else if (localStorage.getItem("position") == "poc_admin") {
        auth1 = [4]
    }
    else if (localStorage.getItem("position") == "clinician") {
        auth1 = [5]
    }
   


    return (
        // auth && auth1
        // ? <Outlet/>
        // : <Navigate to='/' />
        // auth ?
        //     (auth1.every(item => permissions.includes(item)) ? (permissions.includes(0)&&localStorage.getItem('scheduling')=='false')?<Navigate to='/notFound' /> : children:<Navigate to='/notFound' />)
        //     : <Navigate to='/' />
        permissions.includes(0)?(
            auth ?
            (auth1.every(item => permissions.includes(item))&&localStorage.getItem('scheduling')=='true' ?  children : <Navigate to='/notFound' />)
            : <Navigate to='/' />
        ):(
            auth ?
            (auth1.every(item => permissions.includes(item)) ?  children : <Navigate to='/notFound' />)
            : <Navigate to='/' />
        )
        // auth ?
        //     (auth1.every(item => permissions.includes(item)) ?  children : <Navigate to='/notFound' />)
        //     : <Navigate to='/' />

    )
}


export default ProtectedRoute;