import React, { useState, useEffect, useCallback } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import UpcomingShift from "./UpcomingShift";
import CustomDropdown from "./CustomDropdown";
import {
  Eventcalendar,
  CalendarNext,
  CalendarPrev,
  snackbar,
  Popup,
  Input,
  Textarea,
  Switch,
  Datepicker,
  getJson,
  setOptions,
  CalendarNav,
  Button,
  CalendarToday,
  SegmentedGroup,
  SegmentedItem,
} from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import AllCustomIcon from "../../JsonDataFile/AllCustomIcon";
import ScheduleShiftData from "../../JsonDataFile/ScheduleShiftData";
import Select, {
  OnChangeValue,
  StylesConfig,
  useStateManager,
} from "react-select";
import axios from "axios";
import moment from "moment";
import { ENV } from "../../env/env";
import ReusableAPI from "./NotificationApi";
import DataSource from "devextreme/data/data_source";
import { useNavigate } from "react-router-dom";
import RenewToken from "../inner-components/RenewToken";
import { startOfDay } from "@fullcalendar/react";
import zIndex from "@material-ui/core/styles/zIndex";

const now = new Date();

const SchedulingCalender = (props) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [view, setView] = React.useState("calendar");
  const [viewdropdownText, setViewdropdownText] = React.useState("Month");
  const [myEvents, setMyEvents] = React.useState();
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const monthCal = React.useRef();
  const dayCal = React.useRef();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const myInvalid = [
    {
      recurring: {
        repeat: "daily",
        until: yesterday,
      },
    },
  ];
  const [calView, setCalView] = React.useState({
    calendar: {
      popover: true,
      labels: "all",
      popoverClass: "custom-event-popup",
    },
  });
  const [tempEvent, setTempEvent] = React.useState(null);
  const [isOpen, setOpen] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [anchor, setAnchor] = React.useState(null);
  const [popupShiftTypeTitle, setPopupShiftTypeTitle] = React.useState("");
  const [calnderLabel, setcalnderLabel] = React.useState("");
  const [shiftUserImageSrc, setShiftUserImageSrc] = React.useState("");
  const [popupShiftUserName, setPopupshiftUserName] = React.useState("");
  const [popupEventAllDay, setAllDay] = React.useState(true);
  const [popupEventStartDate, setStartDate] = React.useState([]);
  const [popupEventEndDate, setEndDate] = React.useState([]);
  const [popupEventStatus, setStatus] = React.useState("busy");
  const [mySelectedDate, setSelectedDate] = React.useState(now);
  const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
  const [colorAnchor, setColorAnchor] = React.useState(null);
  const [selectedColor, setSelectedColor] = React.useState("#0ea80f");
  const [tempColor, setTempColor] = React.useState("");
  const [positionDropdownisOpen, setPositionDropdownisOpen] =
    React.useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [errorStartMessages, setErrorStartMessages] = useState("");
  const [errorEndMessages, setErrorEndMessages] = useState("");
  const [notesErrorMessages, setNotesErrorMessages] = useState("");
  const [clinicianserrorMessages, setClinicianserrorMessages] = useState("");
  const [notes, setNotes] = useState("");
  const [unit, setUnit] = useState("");
  const [schedulingData, setSchedulingData] = useState([]);
  const colorPicker = React.useRef();
  const [array, setArray] = useState([]);
  const [activeCliniciansList, setaActiveCliniciansList] = useState();
  const [clinicianId, setClinicianId] = useState();
  const [ref, setRef] = useState(false);
  const [filteredData, setFilteredData] = useState([]);



  const [userShiftData, setUserShiftData] = useState();
  const [shiftError, setShiftError] = useState();
  const [shiftEndError, setShiftEndError] = useState();
  const [shiftVal, setShiftVal] = useState(true);
  const [clinicianData, setClinicianData] = useState();
  const [dataUpdated, setDataUpdated] = useState(false);

 



  // API TEST
  const [cliniciansData, setCliniciansData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
          try {
            let token = localStorage.getItem('token')
    
            const response = await axios.get(ENV.ENDPOINT + `/scheduling/view`,  {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
    
            // Save the API response in the state
            setCliniciansData(response.data);
          } catch (error) {
            console.error(error);
          }
        };
    
        fetchData();
      }, []);


     


   
  // API TEST END




  const colorButtons = React.useMemo(
    () => [
      "cancel",
      {
        text: "Set",
        keyCode: "enter",
        handler: () => {
          setSelectedColor(tempColor);
          setColorPickerOpen(false);
        },
        cssClass: "mbsc-popup-button-primary",
      },
    ],
    [tempColor]
  );

  const fcmData = (msg, id) => {
    //sending notification to mobile
    const credentials = {
      peerId: localStorage.getItem("user_id"),
      peerName: localStorage.getItem("facility"),
      channelId: localStorage.getItem("facility"),
      message: msg,
      message_type: "text",
      image: localStorage.getItem("avatarImage"),
      receiverId: id,
    };
    let token = localStorage.getItem("token");
    axios
      .post(ENV.ENDPOINT + `/fcm-data/`, credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {})
      .catch((error) => {});
  };
  async function addScheduleAPI(credentials) {
    let token = localStorage.getItem("token");
    await axios
      .post(ENV.ENDPOINT + `/scheduling/create/`, credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(async (response1) => {
        await axios
          .get(ENV.ENDPOINT + `/clinician/view/`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setUnit("");
            let list = [];

            response.data.results.map(function (row) {
              if (row.clinician_position == response1.data.clinician_position) {
                row.job_sites.filter(function (el) {
                  if (el == localStorage.getItem("facility")) {
                    list.push(row.user);
                  }
                });
              }
            });

            if (response1.data.status == "Open") {
              list.map(function (row) {
                //Triggering event notification
                // var notificationMsg = `${response.data.clinician_position} schedule has been filled`
                var notificationMsg = `${response1.data.clinician_position} schedule has been created`;
                ReusableAPI.notificationTriggerAPI(notificationMsg, row);
                fcmData(notificationMsg, row);
              });
            } else {
              list.map(function (row) {
                //Triggering event notification
                // var notificationMsg = `${response.data.clinician_position} schedule has been filled`
                var notificationMsg = `${response1.data.clinician_position} schedule has been assigned`;
                ReusableAPI.notificationTriggerAPI(notificationMsg, row);
                fcmData(notificationMsg, row);
              });
            }

            setTimeout(() => {
              snackbar({
                message: "Shift added successfully",
              });
            });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function notificationAPI(credentials) {
    let token = localStorage.getItem("token");
    await axios
      .post(ENV.ENDPOINT + `/notification/create/`, credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        localStorage.setItem("refresh", false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function activityAPI(credentials) {
    let token = localStorage.getItem("token");
    await axios
      .post(ENV.ENDPOINT + `/activities/create/`, credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRefresh(true);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  async function editScheduleAPI(credentials) {
    let token = localStorage.getItem("token");
    await axios
      .patch(
        ENV.ENDPOINT + `/scheduling/edit/${credentials.id}/`,
        credentials,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        setUnit("");
        // here you can update the event in your storage as well
        setTimeout(() => {
          snackbar({
            message: "Shift edited successfully",
          });
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  useEffect(() => {
    const optionsFacilities = schedulingData.map(function (row) {
      let img;
      if (row.avatar_image == "" || row.avatar_image == undefined) {
        img = "assets/images/user-shift-image.png";
      } else {
        img = row.avatar_image;
      }
      clinicianData &&
        clinicianData.map(async function (clinician) {
          if (
            clinician.clinician_id == row.clinician_id &&
            clinician.avatar_image != row.avatar_image
          ) {
            const credentials = {
              avatar_image: clinician.avatar_image,
            };
            let token = localStorage.getItem("token");
            await axios
              .patch(
                ENV.ENDPOINT + `/scheduling/edit/${row.schedule_id}/`,
                credentials,
                { headers: { Authorization: `Bearer ${token}` } }
              )
              .then((response) => {
                setDataUpdated(true);
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        });
    });
  }, [clinicianData, schedulingData]);

  useEffect(() => {
    let tifOptions = [];
    const unfilterList = [];
    Object.entries(props.filterRequest).map(([key, value], index) => {
      tifOptions.push((key = value));
    });
    Object.keys(tifOptions).forEach((key) => {
      if (tifOptions[key] != " ") {
        unfilterList.push(tifOptions[key]);
      }
    });
    const filterList = unfilterList.filter((element) => {
      return element !== "";
    });
    let token = localStorage.getItem("token");

    axios
      .get(ENV.ENDPOINT + `/clinician/view/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setClinicianData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(
        ENV.ENDPOINT +
          `/scheduling/view/?facility_id=${localStorage.getItem(
            "facility_id"
          )}&?search=${filterList.toString()}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        const filterResults = response.data.results.filter((r) =>
          filterList.some((f) => f === r.clinician_position)
        );

        setSchedulingData(response.data.results);

        if (filterList.length == 0) {
          const optionsFacilities = response.data.results.map(function (row) {
            let img;
            if (row.avatar_image == "" || row.avatar_image == undefined) {
              img = "assets/images/user-shift-image.png";
            } else {
              img = row.avatar_image;
            }

            return {
              id: row.schedule_id,
              color: row.shift_color,
              imageSrc: img,
              start: row.start_time,
              end: row.end_time,
              title: row.title,
              shiftTypeText: row.clinician_position,
              shiftUserName: row.clinician_name,
              shiftNotes: row.clinician_name,
              color: row.shift_color,
              title: "",
              status: row.status,
              clinicianId: row.clinician_id,
            };
          });
          setMyEvents(optionsFacilities);
          // renderTotalLabel();
        } else {
          const optionsFacilities = filterResults.map(function (row) {
            let img;
            if (row.avatar_image == "" || row.avatar_image == undefined) {
              img = "assets/images/user-shift-image.png";
            } else {
              img = row.avatar_image;
            }

            // clinicianData.map(function(clinician){
            //     if(clinician.clinician_id==row.clinician_id){
            //         img = clinician.avatar_image
            //     }
            // })
            return {
              id: row.schedule_id,
              color: row.shift_color,
              imageSrc: img,
              start: row.start_time,
              end: row.end_time,
              title: row.title,
              shiftTypeText: row.clinician_position,
              shiftUserName: row.clinician_name,
              shiftNotes: row.clinician_name,
              color: row.shift_color,
              title: "",
              status: row.status,
            };
          });
          setMyEvents(optionsFacilities);

          // renderTotalLabel();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code == "token_not_valid") {
          RenewToken.RenewTokenAPI();
        }
      });

    axios
      .get(
        ENV.ENDPOINT +
          `/clinician/view/?clinician_position=${popupShiftTypeTitle}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        function filterit(arr) {
          return arr.filter(function (obj) {
            return obj.job_sites.includes(localStorage.getItem("facility"));
          });
        }

        const activeCliniciansList1 = filterit(response.data.results).map(
          function (row) {
            return {
              label: row.firstname + " " + row.lastname,
              value: row.firstname + " " + row.lastname,
              avatar_image: row.avatar_image,
              clinician_id: row.clinician_id,
            };
          }
        );
        setaActiveCliniciansList(activeCliniciansList1);
      })
      .catch((error) => {
        console.log(error);
      });
    setRefresh(false);
    props.handleRefresh(false);
    props.handleAllRender(false);
    setDataUpdated(false);
  }, [props, refresh, ref, popupShiftTypeTitle, dataUpdated]);

  const positionData = [
    { label: "CNA", value: "cna" },
    { label: "RN", value: "rn" },
    { label: "LPN", value: "lpn" },
    { label: "STNA", value: "stna" },
  ];

  const monthView = React.useMemo(() => {
    return {
      calendar: { popover: false, labels: false },
    };
  }, []);

  const changeView = React.useCallback(
    (event) => {
      calenderControlDropdown();
      renderTotalLabel();

      let calendarView;
      switch (event.target.value) {
        case "month":
          setViewdropdownText("Month");
          document
            .querySelector(".calender-wrapper")
            .classList.remove("day-view");
          calendarView = {
            calendar: {
              labels: "all",
              popoverClass: "custom-event-popup",
              popover: true,
            },
          };
          break;
        case "week":
          document
            .querySelector(".calender-wrapper")
            .classList.remove("day-view");
          setViewdropdownText("Week");
          calendarView = {
            schedule: {
              labels: true,
              type: "week",
              startDay: 1,
              endDay: 0,
            },
          };
          break;
        case "day":
          document.querySelector(".calender-wrapper").classList.add("day-view");
          setViewdropdownText("Day");
          calendarView = {
            schedule: { labels: true, type: "day" },
          };
          break;
      }
      setView(event.target.value);
      setCalView(calendarView);
    },
    [setView, setCalView]
  );

  const calenderControlDropdown = () => {
    const dropdownClass = document.querySelector(
      ".md-custom-header-view .mbsc-segmented"
    );
    if (dropdownClass.classList.contains("show")) {
      dropdownClass.classList.remove("show");
    } else {
      dropdownClass.classList.add("show");
    }
  };

  const onSelectedDateChange = React.useCallback(
    (event) => {
      setCurrentDate(event.date);
    },
    [setCurrentDate]
  );

  // const getFirstDayOfWeek = React.useCallback((d, prev) => {
  //     const day = d.getDay();
  //     const diff = d.getDate() - day + (prev ? -7 : 7);
  //     return new Date(d.setDate(diff));
  // }, []);

  // const navigatePage = React.useCallback((prev) => {
  //     if (view == 'month') {
  //         const prevNextPage = new Date(currentDate.getFullYear(), currentDate.getMonth() + (prev ? -1 : 1), 1);
  //         setCurrentDate(prevNextPage);
  //     } else {
  //         const prevNextSunday = getFirstDayOfWeek(currentDate, prev);
  //         setCurrentDate(prevNextSunday);
  //     }
  // }, [view, currentDate, setCurrentDate, getFirstDayOfWeek]);

  const renderEventContent = React.useCallback((data) => {
    renderTotalLabel();
    return (
      <>
        <div
          className={
            "calender-shift-wrappper calender-shift-popup schedule-single " +
            " " +
            data.original.status
          } 
        >
          <div className="shift-inner">
            <div className="schedule-shift-content">
              <div className="inner">
                <div className="md-custom-event-time">
                  {data.start} - {data.end}
                </div>
                <div className="image-wrap">
                  <img src={data.original.imageSrc} alt="" />
                </div>
                <div className="schedule-shift-text">
                  <h6>{data.original.shiftTypeText} </h6>
                  <span>{data.original.shiftUserName}</span>
                </div>
                <button
                  type="button"
                  className="edit-shift-btn"
                  onClick={(domEvent) => onEventClick(domEvent, data.original)}
                >
                  {AllCustomIcon.iconEdit} <span>Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  });

  const responsivePopup = {
    // medium: {
    //     display: 'anchored',
    //     width: 400,
    //     fullScreen: false,
    //     touchUi: false
    // }
  };

  const colorPopup = {
    medium: {
      display: "anchored",
      touchUi: false,
      buttons: [],
    },
  };

  const colors = ["#0ea80f", "#e42961", "#ff9429", "#893deb", "#2753b8"];

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  const rendercliniciansErrorMessage = (name) => {
    return <div className="error">{clinicianserrorMessages.message}</div>;
  };
  const renderStartErrorMessage = (name) => {
    return <div className="error">{errorStartMessages.message}</div>;
  };
  const renderEndErrorMessage = (name) => {
    return <div className="error">{errorEndMessages.message}</div>;
  };
  const renderNotesErrorMessage = (name) => {
    return <div className="error">{notesErrorMessages.message}</div>;
  };

  //function to convert time format
  function formatDate(oDate) {
    var oTempDate = new Date(oDate),
      sMonth = "" + (oTempDate.getMonth() + 1),
      sDay = "" + oTempDate.getDate(),
      iYear = oTempDate.getFullYear();

    if (sMonth.length < 2) {
      sMonth = "0" + sMonth;
    }
    if (sDay.length < 2) {
      sDay = "0" + sDay;
    }

    return [iYear, sMonth, sDay].join("-");
  }

  const saveEvent = React.useCallback(() => {
    const newEvent = {
      id: tempEvent.id,
      title: calnderLabel,
      imageSrc: shiftUserImageSrc,
      shiftTypeText: popupShiftTypeTitle,
      shiftUserName: popupShiftUserName,
      start: popupEventStartDate,
      end: popupEventEndDate,
      status: popupEventStatus,
      color: tempEvent.color,
      color: selectedColor,
      note: notes,
      clinicianId: clinicianId,
    };

    if (isEdit) {
      // update the event in the list
      const index = myEvents.findIndex((x) => x.id === tempEvent.id);

      const newEventList = [...myEvents];

      newEventList.splice(index, 1, newEvent);

      setOpen(false);
      let id = newEvent.id;
      let clinician_name = newEvent.shiftUserName;
      let clinician_position = newEvent.shiftTypeText;
      let start_date = moment(newEvent.start).format("YYYY-MM-DD");
      let start_time =
        formatDate(newEvent.start) +
        "T" +
        newEvent.start.toString().split(" ")[4];
      let end_date = moment(newEvent.end).format("YYYY-MM-DD");
      let end_time =
        formatDate(newEvent.end) + "T" + newEvent.end.toString().split(" ")[4];
      let is_allocated = false;
      let title = newEvent.shiftTypeText;
      let note = newEvent.note;
      let facility_id = localStorage.getItem("facility_id");
      let shift_color = newEvent.color;

      const token = editScheduleAPI({
        id,
        clinician_name,
        clinician_position,
        start_date,
        start_time,
        end_date,
        end_time,
        is_allocated,
        title,
        note,
        facility_id,
        shift_color,
      });

      setOpen(false);
      setColorPickerOpen(false);
      // ...
    } else {
      // add the new event to the list

      if (newEvent.shiftTypeText === undefined) {
        setErrorMessages({
          name: "shiftposition",
          message: "Please enter position",
        });
      } else {
        setErrorMessages({ name: "shiftposition", message: "" });
      }
      if (newEvent.note != undefined && newEvent.note.length > 200) {
        setNotesErrorMessages({
          name: "shiftNote",
          message: "Max 200 Characters",
        });
        return;
      } else {
        setNotesErrorMessages({ name: "shiftNote", message: "" });
      }

      if (
        newEvent.shiftTypeText != undefined &&
        newEvent.start != undefined &&
        newEvent.end != undefined &&
        shiftEndError == undefined &&
        shiftError == undefined
      ) {
        setMyEvents([...myEvents, newEvent]);
        setOpen(false);
        setColorPickerOpen(false);
        setShiftEndError();
        setShiftError();

        let clinician_name = newEvent.shiftUserName;
        let clinician_position = newEvent.shiftTypeText;
        let clinician_id = newEvent.clinicianId;
        let start_date = moment(newEvent.start).format("YYYY-MM-DD");
        let start_time =
          formatDate(newEvent.start) +
          "T" +
          newEvent.start.toString().split(" ")[4];
        let end_date = moment(newEvent.end).format("YYYY-MM-DD");
        let end_time =
          formatDate(newEvent.end) +
          "T" +
          newEvent.end.toString().split(" ")[4];
        let is_allocated = false;
        let title = newEvent.shiftTypeText;
        let avatar_image = newEvent.imageSrc;
        let note = newEvent.note;
        let facility_id = localStorage.getItem("facility_id");
        let shift_color = newEvent.color;
        let status = "";
        let facility_name = localStorage.getItem("facility");
        if (clinician_name == undefined) {
          status = "Open";
        } else {
          status = "Approved";
        }
        setClinicianId();
        const token = addScheduleAPI({
          clinician_name,
          clinician_position,
          start_date,
          start_time,
          end_date,
          end_time,
          is_allocated,
          title,
          note,
          facility_id,
          shift_color,
          status,
          avatar_image,
          clinician_id,
          facility_name,
        });

        let activity_title = `Shift Created`;
        let activity_status = "success";
        let acitivity_date = moment().format("YYYY-MM-DD");
        let category = "Shift request approve";

        const activity = activityAPI({
          activity_title,
          activity_status,
          acitivity_date,
          facility_id,
          category,
        });
      }
    }

    // setSelectedDate(popupEventStartDate);
    // close the popup
    setRefresh(true);
  }, [
    isEdit,
    myEvents,
    popupEventAllDay,
    popupEventStartDate,
    shiftUserImageSrc,
    calnderLabel,
    popupEventEndDate,
    popupShiftUserName,
    popupEventStatus,
    popupShiftTypeTitle,
    tempEvent,
    selectedColor,
    notes,
  ]);

  const deleteEvent = React.useCallback(
    (event) => {
      setMyEvents(myEvents.filter((item) => item.id !== event.id));
      setTimeout(() => {
        snackbar({
          button: {
            action: () => {
              setMyEvents((prevEvents) => [...prevEvents, event]);
            },
            text: "Undo",
          },
          message: "Event deleted",
        });
      });
    },
    [myEvents]
  );

  const loadPopupForm = React.useCallback((event) => {
    if (isEdit) {
      setcalnderLabel(event.title);
      setShiftUserImageSrc(event.imageSrc);
    } else {
      setcalnderLabel("assets/images/user-no-image.png");
      setShiftUserImageSrc("assets/images/user-no-image.png");
    }

    setPopupShiftTypeTitle(event.shiftTypeText);
    setPopupshiftUserName(event.shiftUserName);
    setNotes(event.notes);
    setStartDate([event.start]);
    setEndDate([event.end]);
    setSelectedColor(event.color || "#0ea80f");
  }, []);

  
  // handle popup form changes

  const shiftTitleChange = React.useCallback((ev) => {
    setPopupShiftTypeTitle(ev.label);
  }, []);

  const shiftUserNameChange = React.useCallback((ev) => {
    setPopupshiftUserName(ev.target.value);
  }, []);
  const shiftNote = React.useCallback((ev) => {
    setNotes(ev.target.value);
  }, []);
  const shiftUnit = React.useCallback((ev) => {
    setUnit(ev.target.value);
  }, []);

  const shiftUserNameDropdownChange = React.useCallback((ev) => {
    setPopupshiftUserName(ev.label);
    setShiftUserImageSrc(ev.avatar_image);
    setClinicianId(ev.clinician_id);

    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + `/scheduling/view/?clinician_id=${ev.clinician_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setUserShiftData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.code == "token_not_valid") {
          RenewToken.RenewTokenAPI();
        }
      });
  }, []);

  const allDayChange = React.useCallback((ev) => {
    setAllDay(ev.target.checked);
  }, []);

  // useEffect(() => {
  //   if (shiftError != undefined || shiftEndError != undefined) {
  //     if (shiftError == "start") {
  //       setErrorStartMessages({
  //         name: "startTime",
  //         message: "Clinician already booked a shift on this time",
  //       });

  //       setTimeout(() => {
  //         snackbar({
  //           message: "Clinician Not Available",
  //         });
  //       });
  //     }
  //     if (shiftError == "end") {
  //       setErrorEndMessages({
  //         name: "endTime",
  //         message: "Clinician already booked a shift on this time",
  //       });

  //       setTimeout(() => {
  //         snackbar({
  //           message: "Clinician Not Available",
  //         });
  //       });
  //     }
  //   } else {
  //     setErrorStartMessages({ name: "startTime", message: "" });
  //     setErrorEndMessages({ name: "endTime", message: "" });
  //   }
  // }, [popupEventStartDate, popupEventEndDate]);

  const startShiftErrorDisplay = () => {
    setShiftError("start");
    setErrorStartMessages({
      name: "startTime",
      message: "Clinician already booked a shift on this time",
    });
    setTimeout(() => {
      snackbar({
        message: "Clinician Not Available",
      });
    });
  };
  const endShiftErrorDisplay = (message) => {
    setShiftEndError("end");
    setErrorEndMessages({ name: "endTime", message: message });
    setTimeout(() => {
      snackbar({
        message: "Clinician Not Available",
      });
    });
  };
  let startCurrentTime;
  let endCurrentTime;
  
  const startDateChange = useCallback(
    (args) => {
      setShiftError();
      setStartDate(args.value);
      const time = moment(args.value);
      startCurrentTime = moment(args.value);
      userShiftData &&
        userShiftData.map(function (row) {
          const start_time = moment(row.start_time);
          const end_time = moment(row.end_time);
          if (
            time.isBetween(start_time, end_time) ||
            time.isSame(start_time) ||
            time.isSame(end_time)
          ) {
            startShiftErrorDisplay();
            
          } else {
            setErrorStartMessages({ name: "startTime", message: "" });
            setShiftError();
          }
        });
    },
    [userShiftData]
  );

  const endDateChange = useCallback(
    (args) => {
      setShiftEndError();
      setEndDate(args.value);
      const time = moment(args.value);
      endCurrentTime = moment(args.value);
      userShiftData &&
        userShiftData.map(function (row) {
          const start_time = moment(row.start_time);
          const end_time = moment(row.end_time);
          if (
            time.isSame(start_time) ||
            time.isSame(end_time) ||
            time.isBetween(start_time, end_time)
          ) {
            endShiftErrorDisplay(
              "Clinician already booked a shift on this time"
            );
          } else if (
            start_time.isBetween(startCurrentTime, endCurrentTime) &&
            end_time.isBetween(startCurrentTime, endCurrentTime)
          ) {
            endShiftErrorDisplay(
              "Clinician already booked a shift on this time"
            );
          } else if (
            start_time.isBetween(startCurrentTime, endCurrentTime) ||
            end_time.isBetween(startCurrentTime, endCurrentTime)
          ) {
            endShiftErrorDisplay(
              "Clinician already booked a shift on this time"
            );
          } else if (endCurrentTime.isSameOrAfter(startCurrentTime) != true) {
            endShiftErrorDisplay("Select valid end time");
          } else {
            setErrorEndMessages({ name: "endTime", message: "" });
            setShiftEndError();
          }
        });
    },
    [userShiftData]
  );

  const statusChange = React.useCallback((ev) => {
    setStatus(ev.target.value);
  }, []);

  const onDeleteClick = React.useCallback(() => {
    // deleteEvent(tempEvent);

    handleDelete(tempEvent);
    setOpen(false);
  }, [deleteEvent, tempEvent]);

  //Events delete
  const handleDelete = (val) => {
    let token = localStorage.getItem("token");
    axios
      .delete(ENV.ENDPOINT + `/scheduling/delete/${val.id}/`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setRefresh(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // scheduler options

  const onEventClick = React.useCallback(
    (args, data) => {
      setEdit(true);
      setTempEvent({ ...data });
      // fill popup form with event data
      loadPopupForm(data);
      setAnchor(args.target);
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventCreated = React.useCallback(
    (args, target) => {
      // createNewEvent(args.event, args.target)
      setEdit(false);
      setTempEvent(args.event);
      // fill popup form with event data
      loadPopupForm(args.event);
      setAnchor(args.target);
      // open the popup
      setOpen(true);
    },
    [loadPopupForm]
  );

  const onEventDeleted = React.useCallback(
    (args) => {
      deleteEvent(args.event);
    },
    [deleteEvent]
  );

  const onEventUpdated = React.useCallback((args) => {
    // ...
  }, []);

  // datepicker options
  //

  // popup options
  const headerText = React.useMemo(
    () => (isEdit ? "Edit shift" : "Add shift"),
    [isEdit]
  );
  const popupButtons = React.useMemo(() => {
    if (isEdit) {
      return [
        "cancel",
        {
          handler: () => {
            saveEvent();
          },
          keyCode: "enter",
          text: "Save changes",
          cssClass: "mbsc-popup-button-primary",
        },
      ];
    } else {
      return [
        "cancel",
        {
          handler: () => {
            saveEvent();
          },
          keyCode: "enter",
          text: "Add shift",
          cssClass: "mbsc-popup-button-primary",
        },
      ];
    }
  }, [isEdit, saveEvent]);

  const onClose = React.useCallback(() => {
    setErrorStartMessages({ name: "startTime", message: "" });
    setErrorEndMessages({ name: "endTime", message: "" });
    setUserShiftData();
    if (!isEdit) {
      setErrorMessages({ name: "shiftposition", message: "" });
      setClinicianserrorMessages({ name: "cliniciansname", message: "" });

      // refresh the list, if add popup was canceled, to remove the temporary event
      setMyEvents([...myEvents]);
    }
    setOpen(false);
  }, [isEdit, myEvents]);

  const selectColor = React.useCallback((color) => {
    setTempColor("#42961");
  }, []);

  const openColorPicker = React.useCallback(
    (ev) => {
      selectColor(selectedColor || "");
      setColorAnchor(ev.currentTarget);
      setColorPickerOpen(true);
    },
    [selectColor, selectedColor]
  );

  const changeColor = React.useCallback(
    (ev) => {
      const color = ev.currentTarget.getAttribute("data-value");

      selectColor(color);
      if (!colorPicker.current.s.buttons.length) {
        setSelectedColor(color);
        setColorPickerOpen(false);
      }
    },
    [selectColor, setSelectedColor]
  );

  const positiondropdownDefaultValue = () => {
    var selectedValueoption;
    positionData.map((option) => {
      if (option.label === popupShiftTypeTitle) {
        selectedValueoption = option;
      }
    });
    return selectedValueoption;
  };

  const cliniciansdropdownDefaultValue = () => {
    var cliniciansValueoption;
    activeCliniciansList &&
      activeCliniciansList.map((option) => {
        if (option.label === popupShiftUserName) {
          cliniciansValueoption = option;
        }
      });
    return cliniciansValueoption;
  };


  const customWithNavButtons = () => {
    return (
      <>
        <div className="md-custom-header-controls ">
          <CalendarPrev
            icon="material-arrow-back"
            variant="flat"
            className="md-custom-header-button"
          />
          <CalendarToday className="md-custom-header-today" />
          <CalendarNext
            icon="material-arrow-forward"
            variant="flat"
            className="md-custom-header-button"
          />
        </div>
        <CalendarNav className="md-custom-header-nav " />
        <div className="md-custom-header-view " ref={props.Schedulingref5}>
          <button
            type="button"
            className="btn calender-view-btn"
            onClick={calenderControlDropdown}
          >
            Show:<span className="selected-value">{viewdropdownText}</span>
          </button>
          <SegmentedGroup value={view} onChange={changeView}>
            <SegmentedItem value="month">Month</SegmentedItem>
            <SegmentedItem value="week">Week</SegmentedItem>
            <SegmentedItem value="day">Day</SegmentedItem>
          </SegmentedGroup>
        </div>
      </>
    );
  };

  const renderDayCalender = () => {
    if (view == "day") {
      return (
        <>
          <div className="day-view-calender-wrapper ">
            <Eventcalendar
              ref={monthCal}
              view={monthView}
              data={myEvents}
              theme="windows"
              themeVariant="light"
              selectedDate={currentDate}
              min={moment(new Date()).format("YYYY/MM/DD")}
              onSelectedDateChange={onSelectedDateChange}
            />
            <UpcomingShift />
          </div>
        </>
      );
    } else {
      return false;
    }
  };

  const renderLabel = React.useCallback((data) => {
    renderTotalLabel();

    return (
      <React.Fragment>
        <div
          className={"calender-label-single " + " " + data.original.status}
          style={{ borderColor: data.original.color }}
        >
          <div className="image-wrap">
            <img src={data.original.imageSrc}  alt=""/>
          </div>
        </div>
        {/* <div className='total-shift'>{data.original.status}</div> */}
      </React.Fragment>
    );
  });

  const renderTotalLabel = React.useCallback((event, inst) => {
    setTimeout(() => {
      let sh = document.getElementsByClassName(
        "mbsc-calendar-text mbsc-windows mbsc-ltr mbsc-calendar-custom-label mbsc-calendar-label-end"
      );
      let sh1 = Object.values(sh);
      sh1.forEach((r) => {
        if (
          r.className ==
          "mbsc-calendar-text mbsc-windows mbsc-ltr mbsc-calendar-custom-label mbsc-calendar-label-end"
        ) {
          r.style.display = "none";
        }
      });

      const renderLabelDiv = document.querySelectorAll(
        ".mbsc-calendar-labels,.mbsc-schedule-events"
      );

      [].forEach.call(renderLabelDiv, function (item) {
        const renderTotalLabelDiv = item.querySelector(".total-shilft-text");
        const innerShift = item.querySelector(
          ".calender-label-single, .schedule-single"
        );
        const totalShiftTextDiv = document.createElement("div");
        totalShiftTextDiv.classList.add("total-shilft-text");

        if (renderTotalLabelDiv === null) {
          item.appendChild(totalShiftTextDiv);
        }
        if (innerShift) {
          const totalShift = item.querySelectorAll(
            ".calender-label-single, .schedule-single"
          );
          const bookedData = item.querySelectorAll(
            ".calender-label-single.Approved, .schedule-single.Approved"
          );
          const pendinDataLength = totalShift.length - bookedData.length;

          if (totalShift.length === bookedData.length) {
            renderTotalLabelDiv.classList.add("booked-shift");
            renderTotalLabelDiv.innerText = `${bookedData.length}/${totalShift.length} Booked`;
          } else {
            renderTotalLabelDiv.innerText = `${pendinDataLength}/${totalShift.length} Pending`;
          }
        } else {
          if (renderTotalLabelDiv) {
            renderTotalLabelDiv.innerText = "";
          }
        }
      });
    }, 200);
  });

  const onEventCreateFailed = React.useCallback((args) => {}, []);

  return (
    <>
      <div className="day-view-calender">{renderDayCalender()}</div>
      <div className="schedule-calender">
        <div className="flex absolute bottom-[210px] right-[730px]  h-[140px] w-[100px] bg-transparent" ref={props.Schedulingref2} ></div>
        <Eventcalendar
          invalid={myInvalid}
          onEventCreateFailed={onEventCreateFailed}
          onSelectedDateChange={onSelectedDateChange}
          clickToCreate="double"
          selectedDate={currentDate}
          dragToCreate={true}
          onEventCreated={onEventCreated}
          onEventDeleted={onEventDeleted}
          onEventUpdated={onEventUpdated}
          renderHeader={customWithNavButtons}
          view={calView}
          renderEventContent={renderEventContent}
          renderScheduleEvent={renderEventContent}
          data={myEvents}
          ref={dayCal}
          theme="windows"
          themeVariant="light"
          renderLabel={renderLabel}
          onInit={renderTotalLabel}
        />
        <Popup
          display="bottom"
          fullScreen={true}
          contentPadding={false}
          headerText={headerText}
          anchor={anchor}
          buttons={popupButtons}
          isOpen={isOpen}
          onClose={onClose}
          responsive={responsivePopup}
          className="edit-shift-popup"
        >
          <div className="add-shift-popup custom-popup">
            <div className="form-wrapper">
              <Form>
                <div className="form-inner">
                  <Row className="form-row">
                    <div
                      className="shift-user-label"
                      imagesrc={shiftUserImageSrc}
                    >
                      {calnderLabel}
                    </div>
                    <Col md={12}>
                      <Form.Group
                        className="form-group custom-dropdown"
                        controlId="shiftPosition"
                      >
                        <Form.Label className="">Position</Form.Label>
                        {/* <Select
                                        data={positionData}
                                        touchUi={false}
                                        inputComponent="input"
                                        onChange={shiftTitleChange}
                                        value={popupShiftTypeTitle}
                                    /> */}
                        <Select
                          options={positionData}
                          onChange={shiftTitleChange}
                          defaultValue={positiondropdownDefaultValue}
                          //menuIsOpen="true"
                        />
                        {renderErrorMessage("shiftposition")}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <div className="shift-input-wrap">
                        <Form.Group
                          className="form-group custom-dropdown"
                          controlId="cliniciansName"
                        >
                          <Form.Label className="">Clinician</Form.Label>
                          {isEdit ? (
                            <>
                              <Select
                                options={activeCliniciansList}
                                onChange={shiftUserNameDropdownChange}
                                defaultValue={cliniciansdropdownDefaultValue}
                                
                              />
                              {rendercliniciansErrorMessage("cliniciansname")}
                            </>
                          ) : (
                            <>
                              {/* <Form.Control type="text" placeholder="" name="" onChange={shiftUserNameChange} defaultValue={popupShiftUserName} />
                                                            {rendercliniciansErrorMessage("cliniciansname")} */}
                              <Select
                                options={activeCliniciansList}
                                onChange={shiftUserNameDropdownChange}
                                defaultValue={cliniciansdropdownDefaultValue}
                                
                              />
                            </>
                          )}
                        </Form.Group>

                        <div
                          className="color-dropdown event-color-c"
                          onClick={openColorPicker}
                        >
                          <label className="form-label">Color</label>
                          <div
                            className="event-color"
                            style={{ background: selectedColor }}
                          ></div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form-group textarea-group"
                        controlId="shiftUnit"
                      >
                        <label className="form-label">Unit</label>
                        <Form.Control
                          type="text"
                          placeholder="Text here"
                          name=""
                          onChange={shiftUnit}
                          defaultValue={unit}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <div className="date-input-wrap form-group">
                        <Form.Label className="">Start</Form.Label>
                        <Datepicker
                          controls={["calendar", "time"]}
                          touchUi={true}
                          showRangeLabels={false}
                          inputComponent="input"
                          onChange={startDateChange}
                          value={popupEventStartDate}
                          className="date-picker-popup"
                          min={moment(new Date()).format("YYYY/MM/DD")}
                        />
                        {renderStartErrorMessage("startTime")}
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="date-input-wrap form-group">
                        <Form.Label className="">End</Form.Label>
                        <Datepicker
                          controls={["calendar", "time"]}
                          touchUi={true}
                          inputComponent="input"
                          showRangeLabels={false}
                          onChange={endDateChange}
                          value={popupEventEndDate}
                          min={moment(new Date()).format("YYYY/MM/DD")}
                        />
                        {renderEndErrorMessage("endTime")}
                      </div>
                    </Col>
                    <Col md={12}>
                      <Form.Group
                        className="form-group textarea-group"
                        controlId="shiftNote"
                      >
                        <label className="form-label">Note</label>
                        <textarea
                          placeholder="Text here"
                          onChange={shiftNote}
                          defaultValue={notes}
                        ></textarea>
                      </Form.Group>
                      {renderNotesErrorMessage("shiftNote")}
                    </Col>
                  </Row>
                  {/* <div className='submit-btn'>
                        {isEdit ? <input type="submit" value="Save changes" className="btn btn-primary"/> : <input type="submit" value="Add shift" className="btn btn-primary"/>}
                            
                        </div> */}
                </div>
              </Form>
            </div>

            {isEdit ? (
              <div className="mbsc-button-group">
                <Button
                  className="mbsc-button-block"
                  color="danger"
                  variant="outline"
                  onClick={onDeleteClick}
                >
                  Delete Shift
                </Button>
              </div>
            ) : null}
          </div>
        </Popup>
        <Popup
          display="bottom"
          contentPadding={false}
          showArrow={false}
          showOverlay={false}
          anchor={colorAnchor}
          isOpen={colorPickerOpen}
          buttons={colorButtons}
          responsive={colorPopup}
          ref={colorPicker}
          className="color-picker"
        >
          <div className="crud-color-row ">
            {colors.map((color, index) => {
              if (index < 5) {
                return (
                  <div
                    key={index}
                    onClick={changeColor}
                    className={
                      "crud-color-c " + (tempColor === color ? "selected" : "")
                    }
                    data-value={color}
                  >
                    <div
                      className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check"
                      style={{ background: color }}
                    ></div>
                  </div>
                );
              } else return null;
            })}
          </div>
          <div className="crud-color-row">
            {colors.map((color, index) => {
              if (index >= 5) {
                return (
                  <div
                    key={index}
                    onClick={changeColor}
                    className={
                      "crud-color-c " + (tempColor === color ? "selected" : "")
                    }
                    data-value={color}
                  >
                    <div
                      className="crud-color mbsc-icon mbsc-font-icon mbsc-icon-material-check"
                      style={{ background: color }}
                    ></div>
                  </div>
                );
              } else return null;
            })}
          </div>
        </Popup>
      </div>
    </>
  );
};


export default SchedulingCalender;