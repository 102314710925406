import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useGlobalState, useGlobalMutation } from '../utils/container'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { log, getRoomCode} from '../utils/utils'
import { Link ,useNavigate} from 'react-router-dom'
import InputCall from './inputCall/inputCall'


const useStyles = makeStyles((theme) => ({
  button: {
    lineHeight: '21px',
    color: 'rgba(255,255,255,1)',
    fontSize: '17px',
    textTransform: 'none',
    height: '44px',
    width: '260px',
    '&:hover': {
      backgroundColor: '#82C2FF'
    },
    margin: theme.spacing(1),
    marginTop: '66px',
    backgroundColor: '#44a2fc',
    borderRadius: '30px'
  },
  internumber: {
    fontFamily: 'HelveticaNeue-Medium',
    fontSize: '21px',
    color: '#949494',
    textAlign: 'left',
    margin: '15px',
  },
  outlined: {
    width: '55px',
    margin: '5px',
    fontWeight: 'bold',  
  },
  interexplain: {
    fontFamily: 'PingFangSC-Medium;',
    fontSize: '14px',
    color: '#949494',
    textAlign: 'left',
    margin: '12px',
  },
}))

export default function IndexCard () {
  const navigate=useNavigate()
  const classes = useStyles()

  const [code, setCode] = useState(localStorage.getItem('peer_id_rtm'))
  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()

  
  useEffect(()=>{
    setCode(localStorage.getItem('peer_id_rtm'))
    handleClickCall()
  },[])
 

  const handleClickCall = () => {
    // if(code.length !== 4) {
    //   mutationCtx.toastError('Please enter the complete code')
    //   return
    // }

    stateCtx.rtmClient.inquire([code]).then((res) => {
      if(code === stateCtx.userCode) {
        log('Please do not enter your own code', stateCtx.userCode)
        mutationCtx.toastError('Please do not enter your own code')
        return
      }
      if(res[code]==true||res[code]==false) {
        if(res[code]==true){
          localStorage.setItem("peerStatus",true)
        }else{
          localStorage.setItem("peerStatus",false)
        }
        mutationCtx.updatePeerCode(code)
        // let roomCode = getRoomCode(stateCtx.userCode, code)
        let roomCode = getRoomCode(localStorage.getItem('user_id'), localStorage.getItem('peer_id'))
        mutationCtx.updateConfig({ channelName: roomCode })
        stateCtx.rtmClient.localInvitation(code, roomCode,localStorage.getItem('fullname'),localStorage.getItem('avatarImage'))
      } else {
        mutationCtx.toastError('peer has not logged on')
        if(localStorage.getItem('position')=='facility_admin'){
          navigate('/funding-summary')
        }else{
          navigate('/dashboard')
        }
        return
      }
    }).catch(() => {
      log('The query failed')
      mutationCtx.toastError('The query failed')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })
  }

  const codeChange = (value) => {
    setCode(value)
  }
  // useEffect(() => {
  //   if (stateCtx.loading === true) {
  //     mutationCtx.stopLoading()
  //   }
  // }, [stateCtx.loading, mutationCtx])
  useEffect(() => {
   
    
    stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {
     
      mutationCtx.updatePeerCode(remoteInvitation.callerId)
      mutationCtx.updateIncomingCode(remoteInvitation.callerId)
      mutationCtx.updateConfig({ channelName: remoteInvitation.content })
      
     navigate({
        pathname: `/call-invi/card/indexCard/incoming/`
      })
    })

    stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
      mutationCtx.toastError('Call not answered')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      navigate({
        pathname: `/meeting/${channel}`
      }) 
    })

    stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
      navigate({
        pathname: `/meeting/${channel}`
      })
    }) 

    stateCtx.rtmClient.on('LocalInvitationRefused', () => {
      mutationCtx.toastError('Peer is busy')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
   
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
      navigate({
        pathname: `/call-invi/card/indexCard/dialling/calling/`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationFailure', () => {
      mutationCtx.toastError('Call process failed')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })
   
    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners()
    //   navigate('/card')
    }
    
  }, [stateCtx.rtmClient, stateCtx])

  return (
    <Box
      marginTop="114px"
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="column"
    >
      <Link to="/setting" className="setting-btn" />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        marginTop="15px"
      >
        <span className={classes.internumber}>Dial Number</span>
        <div>
          <div className="input-width">
            <InputCall
              onChange={codeChange}
              initCaptcha={['', '', '', '']}
            />
          </div>
        </div>
        <span className={classes.interexplain}>Enter your friend&apos;s indentifier code here</span>
        <FormControl className={classes.grid}>
          <Button
            onClick={handleClickCall}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Call
          </Button>
        </FormControl>
      </Box>
    </Box>
  )
}

