import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Notification from '../inner-components/Notification';
import MessagesButton from '../inner-components/MessagesButton';
import { RoutesPath } from '../../App';
import CustomDropdown from '../inner-components/CustomDropdown1';
import axios from 'axios';
import { ENV } from '../../env/env'

const SchedulingTopHeaderSection = (props) => {
    const [facilities, setFacilities] = useState([])
    
    useEffect(()=>{
        let token = localStorage.getItem('token')
    axios.get(ENV.ENDPOINT + '/facility/', { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
            setFacilities(response.data.payload)
            if (!localStorage.getItem('facility')) {
                localStorage.setItem("facility", response.data.payload[0].facility_name)
                localStorage.setItem("facility_id", response.data.payload[0].facility_id)
            }
        })
        .catch((error) => {
            console.log(error)
        })
    },[])
    if(localStorage.getItem('position')=="poc_admin"||localStorage.getItem('position')=="facility_admin"){
        localStorage.setItem("facility", localStorage.getItem('facility'))
        localStorage.setItem("facility_id", localStorage.getItem('facility_id'))
    }
    const handleFilter = (selection) => {
        console.log("selection", selection)
        // setFilterInput(selection.value)
        localStorage.setItem("facility", selection.value)
        localStorage.setItem("facility_id", selection.id)
        props.handleRefresh(true)
        // if(selection.value=="All Facilities"){
        //     props.handleFacility("","")
        //     props.handleRefresh(true)
        // }else{
        //     props.handleFacility(selection.value,selection.id)
        //     props.handleRefresh(true)
        // }
     
    }


    let optionsFacilities=facilities.map(function (row) {
        return { value: row.facility_name, id: row.facility_id }
    })
    return (
        <>
            <div className='dashboard-top-wrapper' style={{ backgroundImage: `url("/assets/images/timesheets-banner.jpg")`  }}>
                <Row>
                    <Col xl={7} lg={6} className="left-col-wrap">
                        <h1 className='h2 page-title'>Scheduling</h1>
                    </Col>
                    <Col xl={5} lg={6} className="right-col-wrap top-dropdown-col">
                        <div className='col-inner'>
                            {localStorage.getItem('position')=='c_level_emp'||localStorage.getItem('position')=='hmc_admin'?<CustomDropdown optionData={optionsFacilities} dashboard={true} filterInput={handleFilter} selectedFacility={localStorage.getItem('facility')}/>:""}
                            <Notification />
                            <MessagesButton />
                        </div>
                    </Col>
                    <Col lg={12} className="full-col-wrap text-center">
                        <h4 className='h4'>{localStorage.getItem('facility')}</h4>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SchedulingTopHeaderSection;