import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import MessageNotice from "../inner-components/MessageNotice";
import { RoutesPath } from "../../App";

const WorkforceCalculatorSection = (props) => {
  const [initial, setInitial] = useState(true);
  const [HPR, setHPR] = useState();
  const [markUp, setMarkUp] = useState();
  const [bridgeHPR, setBridgeHPR] = useState();
  const [burden, setBurden] = useState();
  const [empBenifits, setEmpBenifits] = useState();
  const [hrResources, setHRResources] = useState();
  const [totalCostPerHourForDC, setTotalCostPerHourForDC] = useState();
  const [totalCostPerHourForEBC, setTotalCostPerHourForEBC] = useState();
  const [savings, setSavings] = useState();
  const [costSavings, setCostSavings] = useState();
  const [potentialAnnualSavings, setpotentialAnnualSavings] = useState();
  const [numberOfEmp, setNumberOfEmp] = useState();
  const WorkforceCalculatorData = [
    {
      costLabel: "Hourly pay rate",
    },
    {
      costLabel: "Markup",
    },
    {
      costLabel: "Burden",
    },
    {
      costLabel: "Employee Benefits",
    },
    {
      costLabel: "HR Resources",
    },
  ];
  let HPRDefault = 15;
  let burdenDefault = 2.03;
  let empBenifitsDefault = 5.55;
  let hrResourcesDefault = 1.5;
  let markUpDefault = 50;
  useEffect(() => {
    setBridgeHPR(HPR);
  }, [setNumberOfEmp]);
  const handleHPR = (event) => {
    setInitial(false);
    setHPR(event.target.value);
    setBridgeHPR(event.target.value);
    let value = parseInt(event.target.value);
    // let burdenValue = value + 2;
    // setBurden(burdenValue)
    // let empBenifitsValue = value + 4;
    // setEmpBenifits(empBenifitsValue)
    // let hrResourcesValue = value + 6;
    // setHRResources(hrResourcesValue)
    if (event.target.value == "" || event.target.value == undefined) {
      value = "0.00";
      setBurden(value);
      setEmpBenifits(value);
      setHRResources(value);
    }
    setBurden(parseFloat((event.target.value * 13.51) / 100).toFixed(2));
    setEmpBenifits(parseFloat((event.target.value * 37) / 100).toFixed(2));
    setHRResources(parseFloat((event.target.value * 10) / 100).toFixed(2));
  };
  const handleMarkUp = (event) => {
    setInitial(false);
    setMarkUp(parseFloat(event.target.value));
  };
  const handleBurden = (event) => {
    setInitial(false);
    setBurden(parseFloat(event.target.value));
  };
  const handleEmpBenifits = (event) => {
    setInitial(false);
    setEmpBenifits(parseFloat(event.target.value));
  };
  const handleHRResources = (event) => {
    setInitial(false);
    setHRResources(parseFloat(event.target.value));
  };
  const handleNumOfEmp = (event) => {
    setNumberOfEmp(parseFloat(event.target.value));
    if (burden != "" && burden != undefined) {
      burdenDefault = parseFloat(burden);
    }
    if (HPR != "" && HPR != undefined) {
      HPRDefault = parseFloat(HPR);
    }
    if (empBenifits != "" && empBenifits != undefined) {
      empBenifitsDefault = parseFloat(empBenifits);
    }
    if (markUp != "" && markUp != undefined) {
      markUpDefault = parseFloat(markUp);
    }
    if (hrResources != "" && hrResources != undefined) {
      hrResourcesDefault = parseFloat(hrResources);
    }
    let totalCostPerHourForDCValue =
      HPRDefault + burdenDefault + empBenifitsDefault + hrResourcesDefault;
    let totalCostPerHourForEBCValue =
      HPRDefault + (HPRDefault / 100) * markUpDefault;

    let savingsValue = totalCostPerHourForDCValue - totalCostPerHourForEBCValue;

    let costSavingsValue = (savingsValue / totalCostPerHourForDCValue) * 100;
    let potentialAnnualSavingsValue =
      event.target.value * totalCostPerHourForDCValue * HPRDefault * 52.03236 -
      event.target.value * totalCostPerHourForEBCValue * HPRDefault * 52.03236;
    let stringValue = potentialAnnualSavingsValue.toString();
    let string = parseInt(stringValue);
    setpotentialAnnualSavings(
      potentialAnnualSavingsValue.toLocaleString("en-US", {
        maximumFractionDigits: 2,
      })
    );
  };
  const handleResults = () => {
    if (burden != "" && burden != undefined) {
      burdenDefault = parseFloat(burden);
    }
    if (HPR != "" && HPR != undefined) {
      HPRDefault = parseFloat(HPR);
    }
    if (empBenifits != "" && empBenifits != undefined) {
      empBenifitsDefault = parseFloat(empBenifits);
    }
    if (markUp != "" && markUp != undefined) {
      markUpDefault = parseFloat(markUp);
    }
    if (hrResources != "" && hrResources != undefined) {
      hrResourcesDefault = parseFloat(hrResources);
    }
    let totalCostPerHourForDCValue =
      HPRDefault + burdenDefault + empBenifitsDefault + hrResourcesDefault;
    let totalCostPerHourForEBCValue =
      HPRDefault + (HPRDefault / 100) * markUpDefault;
    let savingsValue = totalCostPerHourForDCValue - totalCostPerHourForEBCValue;
    let costSavingsValue = (savingsValue / totalCostPerHourForDCValue) * 100.1;
    setTotalCostPerHourForDC(totalCostPerHourForDCValue.toFixed(2));

    setTotalCostPerHourForEBC(totalCostPerHourForEBCValue.toFixed(2));

    setSavings(savingsValue.toFixed(2));

    setCostSavings(costSavingsValue.toFixed(2));
  };

  const allStyle = {
    color: "black",
    position: "absolute",
    marginLeft: "1px",
    marginTop: "18px",
    fontSize: "20px",
  };
  return (
    <>
      <div className="workforce-calc-wrapper">
        <MessageNotice noticeText="Enter the known costs below. Any inputs that are not entered will be calculated based on national averages. *" />
        <div className="workforce-calculator">
          <div className="workforce-calc-single">
            <div className="workforce-calc-inner">
              <Row className="calc-top-row justify-content-end">
                <Col md={6} className="cost-col">
                  <span>Facility Cost</span>
                </Col>
                <Col md={6} className="cost-col">
                  <span>ShiftAlerts Cost</span>
                </Col>
              </Row>
              {WorkforceCalculatorData.map((val, index) => (
                <Row className="calc-main-row" key={index}>
                  {index == 0 ? (
                    <>
                      <div className="cost-label">
                        <span>{val.costLabel}</span>
                      </div>
                      <Col md={6} className="cost-col dolar-sign">
                        {/* <span style={allStyle}>$</span> */}
                        <input
                          type="number"
                          name="number"
                          id="workforceInput"
                          value={HPR}
                          onChange={handleHPR}
                          placeholder="15"
                        />
                      </Col>
                      <Col md={6}>
                        <input type="number" name="number" value={HPR} />
                      </Col>
                    </>
                  ) : index == 1 ? (
                    <>
                      <div className="cost-label">
                        <span>{val.costLabel}</span>
                      </div>
                      <Col md={6} className="cost-col ">
                        <input type="hidden" name="number" value={0} />
                      </Col>
                      <Col md={6} className="dolar-sign">
                        <input
                          type="number"
                          name="number"
                          value={markUp}
                          onChange={handleMarkUp}
                          placeholder="50"
                        />
                      </Col>
                    </>
                  ) : index == 2 ? (
                    <>
                      <div className="cost-label">
                        <span>{val.costLabel}</span>
                      </div>
                      <Col md={6} className="cost-col dolar-sign">
                        <input
                          type="number"
                          name="number"
                          value={burden}
                          onChange={handleBurden}
                          placeholder="2.03"
                        />
                      </Col>
                      <Col md={6} className="dolar-sign">
                        <input type="number" name="number" readOnly value={0} />
                      </Col>
                    </>
                  ) : index == 3 ? (
                    <>
                      <div className="cost-label">
                        <span>{val.costLabel}</span>
                      </div>
                      <Col md={6} className="cost-col dolar-sign">
                        <input
                          type="number"
                          name="number"
                          value={empBenifits}
                          onChange={handleEmpBenifits}
                          placeholder="5.55"
                        />
                      </Col>
                      <Col md={6} className="dolar-sign">
                        <input type="number" name="number" readOnly value={0} />
                      </Col>
                    </>
                  ) : (
                    <>
                      <div className="cost-label">
                        <span>{val.costLabel}</span>
                      </div>
                      <Col md={6} className="cost-col dolar-sign">
                        <input
                          type="number"
                          name="number"
                          value={hrResources}
                          onChange={handleHRResources}
                          placeholder="1.50"
                        />
                      </Col>
                      <Col md={6} className="dolar-sign">
                        <input type="number" name="number" readOnly value={0} />
                      </Col>
                    </>
                  )}
                </Row>
              ))}
              <div className="calculate-btn text-center">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleResults}
                >
                  Calculate Results
                </button>
              </div>
            </div>
          </div>
          <div className="workforce-calc-single">
            <div className="workforce-calc-inner">
              <Row className="calc-top-row justify-content-end">
                <Col md={6} className="cost-col">
                  <span>Facility Cost</span>
                </Col>
                <Col md={6} className="cost-col">
                  <span>ShiftAlerts Cost</span>
                </Col>
              </Row>

              <Row className="calc-main-row">
                <div className="cost-label">
                  <span>Total cost per hour</span>
                </div>
                <Col md={6} className="cost-col">
                  <input
                    type="number"
                    name="number"
                    value={totalCostPerHourForDC}
                  />
                </Col>
                <Col md={6} className="cost-col">
                  <input
                    type="number"
                    name="number"
                    value={totalCostPerHourForEBC}
                  />
                </Col>
              </Row>
              <Row className="calc-main-row">
                <div className="cost-label">
                  <span>Savings (Per hour)</span>
                </div>
                <Col md={12} className="cost-col">
                  <input type="number" name="number" value={savings} />
                </Col>
              </Row>
              <Row className="calc-main-row">
                <div className="cost-label">
                  <span>Cost savings % (Per hour)</span>
                </div>
                <Col md={12} className="cost-col">
                  <input type="number" name="number" value={costSavings} />
                  <span>
                    To calculate the annual savings impact of a variable
                    workforce, enter the number of employees to be converted
                    below.
                  </span>
                </Col>
              </Row>
              <Row className="calc-main-row">
                <Col md={12} className="cost-col">
                  <input
                    type="number"
                    name="number"
                    placeholder="100"
                    value={numberOfEmp}
                    onChange={handleNumOfEmp}
                  />
                </Col>
              </Row>
              <Row className="calc-main-row">
                <div className="cost-label">
                  <span>Total potential annual savings</span>
                </div>
                <Col md={12} className="cost-col">
                  <input
                    type="tel"
                    name="number"
                    value={potentialAnnualSavings}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkforceCalculatorSection;
