import React, { useState, useEffect, useRef } from "react";
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import SchedulingTopHeaderSection from "../components/scheduling/SchedulingTopHeaderSection";
import SchedulingMainSection from "../components/scheduling/SchedulingMainSection";
import { Row, Col } from 'react-bootstrap';


//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from './utils/container'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { log } from './utils/utils'
import useRouter from './utils/use-router'
import { Outlet, useNavigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'
import { Tour } from "antd";
import useLocalStorage from "./utils/useLocalStorage";
import { useWindowSize } from "./utils/useWindowSize";
import Cookies from "js-cookie";

const Scheduling = () => {

    //for agora chat and call login
    const navigate = useNavigate()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const size = useWindowSize()

    const routerCtx = useRouter()

    const history = useNavigate()
   
    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    useEffect(() => {
        if (stateCtx.rtmClient.status === 'offLine') {
            stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
                
            })
        }
    }, [stateCtx.rtmClient])

    useEffect(() => {


        stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {

            let content=JSON.parse(remoteInvitation.content)
     
            mutationCtx.updatePeerCode(remoteInvitation.callerId)
            mutationCtx.updateIncomingCode(content.name)
            mutationCtx.updateIncomingImage(content.image)
            mutationCtx.updateConfig({ channelName: content.channel })

            navigate({
                pathname: `/call-invi/card/indexCard/incoming/`
            })
        })

        stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
            console.log('remote invitation cancelled')
            // navigate('/dashboard')
        })

        stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
            mutationCtx.toastError('Call not answered')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
            log('Accept success')
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
            mutationCtx.startLoading()
            let channel = stateCtx.config.channelName
            log('channel id', channel)
            navigate({
                pathname: `/meeting/${channel}`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationRefused', () => {
            mutationCtx.toastError('Peer is busy')
            navigate.goBack()
        })

        stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
            console.log("call declined from inter-coding-card")
            navigate('/call-invi')
        })

        stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
            navigate.goBack()
        })

        stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
            navigate({
                pathname: `/dialling`
            })
        })

        stateCtx.rtmClient.on('LocalInvitationFailure', () => {
            mutationCtx.toastError('Call process failed')
            history.goBack()
        })

        return () => {
            stateCtx.rtmClient.eventBus.removeAllListeners()
            //   navigate('/card')
        }

    }, [stateCtx.rtmClient, stateCtx])
    const [refresh, setRefresh] = useState(false)
    const handleRefresh = (e) => {
        setRefresh(e)
    }

    const Schedulingref1 = useRef(null);
    const Schedulingref2 = useRef(null);
    const Schedulingref3 = useRef(null);
    const Schedulingref4 = useRef(null);
    const Schedulingref5 = useRef(null);
    const [openScheduling, setOpenScheduling] = useState(false);
 

    const steps = [
        {
          description:
            "Post a schedule, it automatically reflects the clinician's mobile device access and notifies them of the shift details; it's going to be on a first-come, first-served basis, so whoever applies for that shift will be the one approved",
          target: () => Schedulingref1.current,
          placement: "left",
          mask: {
            style: {
              marginTop: '-300px',
            },
          },
        },
        {
          description:
            "To post a schedule, you can either double click on a specific date here inside the box",
          target: () => Schedulingref2.current,
         
        },
        {
          description: "Or create a template here and create that new shift ",
          target: () => Schedulingref3.current,
          placement: "right",
        },
        {
          description: "You can aprove applied shifts from clinicians here",
          target: () => Schedulingref4.current,
         
          
        },
        {
          description: "Change the view of your calendar as to you preference by clicking here",
          //nextButtonProps : { children: ref5.current, onClick: () => navigate("/scheduling") },
          target: () => Schedulingref5.current,
        }
      ];

     

    //   useEffect(() => {
    //     if(modalScheduling === "openScheduling" && size.width >= 1250){
    //   const clean =  setInterval(() => {
    //             setOpenScheduling(true)
    //             setModalScheduling("closeScheduling")
    //        },1000)
    //          return () => clearInterval(clean);
    //     }
    //   },[modalScheduling , size.width])

    useEffect(() => {
        if(size.width >= 1250){
        const isModalOpen = Cookies.get("modalScheduling") === "open";
        if (!isModalOpen ) {
          Cookies.set("modalScheduling", "open", { expires: 365, path: "" });
          const timeoutId = setTimeout(() => {
            setOpenScheduling(true)
          }, 3000);
          return () => {
            clearTimeout(timeoutId);
          };
         }
        }
      }, [size.width]);
    

    return (
        <>
            <main className='app-main'>
                <HeaderMobile />
                <section className="main-section dashboard-section blue-bg-section">
                    <div className="section-wrap">
                        <Row className="dashboard-row-main">
                            <LeftPanel />
                           
                            <Col lg="9" className="right-content-col right-content-col-with-banner grey-bg-col">
                                <SchedulingTopHeaderSection handleRefresh={handleRefresh}/>
                                {/* <button onClick={() => setOpenScheduling(true)}>start</button> */}
                                <SchedulingMainSection handleRefresh={handleRefresh} refresh={refresh} Schedulingref1={Schedulingref1} Schedulingref2={Schedulingref2} Schedulingref3={Schedulingref3} Schedulingref4={Schedulingref4} Schedulingref5={Schedulingref5}/>
                            </Col>
                        </Row>
                        <Tour open={openScheduling} onClose={() => setOpenScheduling(false)} steps={steps}  indicatorsRender={(current, total) => (
         <></>
        )}/>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Scheduling;