import React, { useState, useEffect } from 'react';
import { Accordion, Form } from 'react-bootstrap';
import CreateTemplatePopup from './CreateTemplatePopup';
import { RoutesPath } from '../../App';
import axios from "axios";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import InputGroupForm from './InputGroupForm';
import ColorPickerDrodown from './ColorPickerDrodown';
import TimeCustomPicker from './TimeCustomPicker';
import { Datepicker } from '@mobiscroll/react';
import Select, { OnChangeValue, StylesConfig } from 'react-select';
import { ENV } from '../../env/env'
import moment from 'moment';


const ShiftTemplateAccordion = ({Schedulingref3}) => {
    const [color, setColor] = useState('#0ea80f')
    const [popupEventStartDate, setStartDate] = React.useState([]);
    const [startTime, setStartTime] = useState()
    const [popupEventEndDate, setEndDate] = React.useState([]);
    const [endTime, setEndTime] = useState()
    const [notes, setNotes] = useState("");
    const [currentTemplate, setCurrentTemplate] = useState()
    const [popupShiftUserName, setPopupshiftUserName] = React.useState('');
    const [popupShiftTypeTitle, setPopupShiftTypeTitle] = React.useState('');
    const [tempColor, setTempColor] = React.useState('');
    const [selectedColor, setSelectedColor] = React.useState('');
    const [colorAnchor, setColorAnchor] = React.useState(null);
    const [colorPickerOpen, setColorPickerOpen] = React.useState(false);
    const colorPicker = React.useRef();
    const [tempEvent, setTempEvent] = React.useState(null);
    const [isOpen, setOpen] = React.useState(false);
    const [activeCliniciansList, setaActiveCliniciansList] = useState()
    const [anchor, setAnchor] = React.useState(null);
    const [refresh,setRefresh]=useState(false)
    const colorButtons = React.useMemo(() => [
        'cancel',
        {
            text: 'Set',
            keyCode: 'enter',
            handler: () => {
                setSelectedColor(tempColor);
                setColorPickerOpen(false);
            },
            cssClass: 'mbsc-popup-button-primary'
        }
    ], [tempColor]);
    const [model, setModel] = useState({
        Clinician: "",
        Position: "",
        Note: "",

    }, []);
    const [templateData, setTemplateData] = useState()
    const SchedulingFilterData = [
        {
            itemlabel: "CNA",
        },
        {
            itemlabel: "RN",
        },
        {
            itemlabel: "LPN",
        },
        {
            itemlabel: "STNA",
        },
        {
            itemlabel: "RN/LPN",
        },
        {
            itemlabel: "STNA/CNA",
        },
    ]
    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + `/shift/view/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setTemplateData(response.data.results)
             
            })
            .catch((error) => {
                console.log(error.response.data)
            })

        axios.get(ENV.ENDPOINT + `/clinician/view/?search=True`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
              
                const activeCliniciansList1 = response.data.results.map(function (row) {
                    return { label: row.firstname + " " + row.lastname, value: row.firstname + " " + row.lastname }
                })
                setaActiveCliniciansList(activeCliniciansList1)

            })
            .catch((error) => {
                console.log(error.response.data)
            })
            setRefresh(false)
    }, [refresh])


    async function addTemplateAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.post(ENV.ENDPOINT + `/scheduling/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
               
            })
            .catch((error) => {
                console.log(error.response)
            })


    }

    async function activityAPI(credentials) {
        let token = localStorage.getItem('token')
        await axios.post(ENV.ENDPOINT + `/activities/create/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
               

            })
            .catch((error) => {
                console.log(error.response)
            })
    }


    const startDateChange = React.useCallback((args) => {
        setStartDate(args.value);
        //setStartDate(currentTemplate.start_time)
       


    }, []);


    const handleChange = (event) => {
        // get the value of the field
      

        const value = event?.target?.value;
        //console.log(value)
        //set it in the model
        setModel({
            ...model,
            [event.target.name]: value
        });

    

    };

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => { setShow(true) };

    const handleColor = (e) => {
        setColor(e)

    }
    function formatDate(oDate) {
        var oTempDate = new Date(oDate),
            sMonth = '' + (oTempDate.getMonth() + 1),
            sDay = '' + oTempDate.getDate(),
            iYear = oTempDate.getFullYear();

        if (sMonth.length < 2) { sMonth = '0' + sMonth; }
        if (sDay.length < 2) { sDay = '0' + sDay; }

        return [iYear, sMonth, sDay].join('-');
    }
    const handleStartTime = (e) => {
        setStartTime(e.hour + ":" + e.minute + ":" + e.second)
        setStartDate(formatDate(e.year + "-" + e.month.number + "-" + e.day))
    }
    const handleEndTime = (e) => {
        setEndTime(e.hour + ":" + e.minute + ":" + e.second)
        setEndDate(formatDate(e.year + "-" + e.month.number + "-" + e.day))
    }
    const endDateChange = React.useCallback((args) => {

        setEndDate(args.value);
    }, []);

    const handleSubmit = () => {


      

        let clinician_name = popupShiftUserName
        let clinician_position = popupShiftTypeTitle
        let start_date = moment().format('YYYY-MM-DD')
        let start_time = moment().format('YYYY-MM-DD') + "T" + popupEventStartDate.toString().split(" ")[4]
        let end_date = moment().format('YYYY-MM-DD')
        let end_time = moment().format('YYYY-MM-DD') + "T" + popupEventEndDate.toString().split(" ")[4]
        let is_allocated = false
        let title = "title"
        let note = model.Note || currentTemplate.shift_note
        let facility_id = localStorage.getItem('facility_id')
        let shift_color = color
        let status = ""
        let facility_name = localStorage.getItem('facility')
        if (clinician_name == '') {
            status = 'Open'
        }
        else {
            status = "Approved"
        }

        const token = addTemplateAPI({


            clinician_name,
            clinician_position,
            start_date,
            start_time,
            end_date,
            end_time,
            is_allocated,
            title,
            note,
            facility_id,
            facility_name,
            status,
            shift_color


        });

        let activity_title = `Shift Created`
        let activity_status = 'success'
        let acitivity_date = moment().format('YYYY-MM-DD')


        const activity = activityAPI({
            activity_title,
            activity_status,
            acitivity_date,
            facility_id,
        });

    }
    const handleData = (val) => {
      
        setCurrentTemplate(val)
        setStartDate(new Date(val.start_date + "T" + val.start_time))
        setEndDate(new Date(val.end_date + "T" + val.end_time))
    }
    const loadPopupForm = React.useCallback((event) => {


        setPopupShiftTypeTitle(event.shiftTypeText);
        setPopupshiftUserName(event.shiftUserName);
        setNotes(event.notes)
        setStartDate([event.start]);
        setEndDate([event.end]);
        setSelectedColor('red');

    }, []);
    const onEventClick = React.useCallback((args, data) => {



        setTempEvent({ ...data });
        // fill popup form with event data
        loadPopupForm(data);
        setAnchor(args.target);
        setOpen(true);
    }, [loadPopupForm]);

    const onEventCreated = React.useCallback((args, target) => {

        // createNewEvent(args.event, args.target)

        setTempEvent(args.event)
        // fill popup form with event data
        loadPopupForm(args.event);
        setAnchor(args.target);
        // open the popup
        setOpen(true);
    }, [loadPopupForm]);
    const positionData = [
        { label: 'CNA', value: 'cna' },
        { label: 'RN', value: 'rn' },
        { label: 'LPN', value: 'lpn' },
        { label: 'STNA', value: 'stna' }
    ];
    // const activeCliniciansList = [
    //     { label: 'Alisa Jones', value: 'alisa-jones' },
    //     { label: 'Camille Moreno', value: 'camille-moreno' },
    //     { label: 'Jane Doe', value: 'jane-doe' },
    //     { label: 'Dianne Edwards', value: 'dianne-edwards' },
    //     { label: 'Shane Hawkins	', value: 'shane-hawkins' },
    //     { label: 'Eduardo Richards', value: 'eduardo-richards' }
    // ];
    const shiftUserNameDropdownChange = React.useCallback((ev) => {
        setPopupshiftUserName(ev.label);

    }, []);
    const cliniciansdropdownDefaultValue = () => {
        var cliniciansValueoption;
        activeCliniciansList.map((option) => {
            if (option.label === popupShiftUserName) {
                cliniciansValueoption = option;
            
            }

        })
        return cliniciansValueoption
    };
    const shiftTitleChange = React.useCallback((ev) => {

        setPopupShiftTypeTitle(ev.label);

    }, []);
    const positiondropdownDefaultValue = () => {
        var selectedValueoption;
        positionData.map((option) => {
            if (option.label === currentTemplate.clinician_position) {
                selectedValueoption = option;
                setPopupShiftTypeTitle(option.label)
            }

        })
        return selectedValueoption
    };
    const selectColor = React.useCallback((color) => {
        setTempColor(color)
    }, []);
    const openColorPicker = React.useCallback((ev) => {
        selectColor('red');
        setColorAnchor(ev.currentTarget);
        setColorPickerOpen(true);
    }, [selectColor, selectedColor]);

    const handleRefresh=()=>[
        setRefresh(true)
    ]
    return (
        <>
            {templateData ? (



                <div className="filter-accrodion shift-template-accordion" ref={Schedulingref3}>
                    <Accordion>
                        <Accordion.Item eventKey="1">
                            <div className="header-custom">
                                <Accordion.Header>Shift Templates</Accordion.Header>
                                <CreateTemplatePopup handleRefresh={handleRefresh}/>
                            </div>

                            <Accordion.Body>
                                <div className="shift-templates-list">
                                    <ul>
                                        {templateData.map((val, index) =>
                                            <li key={index}>
                                                <button className='btn btn-primary' type='button' onClick={(e) => { handleShow(); handleData(val) }}>{val.template_name}</button>
                                            </li>
                                        )}


                                    </ul>
                                </div>
                                {currentTemplate ? (
                                    <Modal show={show} onHide={handleClose} className='mbsc-popup-wrapper add-shift-popup edit-shift-popup custom-popup'>
                                        <Modal.Header closeButton>
                                            <h5>{currentTemplate.template_name} </h5>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <div className='form-wrapper'>
                                                <Form>
                                                    <div className='form-inner'>
                                                        <Row className="form-row">

                                                            <Col md={12}>

                                                                {/* <InputGroupForm inputId="Position" inputLabel="Position" inputType="text" inputPlaceholder="Text here" inputName="Position" inputValue={currentTemplate.clinician_position} handleChange={handleChange} /> */}

                                                                <Form.Group className="form-group custom-dropdown" controlId="shiftPosition">
                                                                    <Form.Label className="">Position</Form.Label>

                                                                    <Select
                                                                        options={positionData}
                                                                        onChange={shiftTitleChange}
                                                                        defaultValue={positiondropdownDefaultValue}
                                                                    // menuIsOpen="true"
                                                                    />
                                                                    {/* {renderErrorMessage("shiftposition")} */}

                                                                </Form.Group>

                                                            </Col>
                                                            <Col md={12}>
                                                                <div className='shift-input-wrap'>
                                                                    <Form.Group className="form-group custom-dropdown" controlId="cliniciansName">
                                                                        <Form.Label className="">Clinician</Form.Label>

                                                                        <>
                                                                            <Select
                                                                                options={activeCliniciansList}
                                                                                onChange={shiftUserNameDropdownChange}
                                                                                defaultValue={cliniciansdropdownDefaultValue}

                                                                            />

                                                                        </>



                                                                    </Form.Group>

                                                                    <div className="color-dropdown event-color-c" onClick={openColorPicker}>
                                                                        <label className="form-label">Color</label>
                                                                        <div className="event-color" style={{ background: currentTemplate.shift_color }}></div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className='date-input-wrap form-group'>
                                                                    <Form.Label className="">Start</Form.Label>
                                                                    <Datepicker
                                                                        controls={['calendar', 'time']}
                                                                        touchUi={true}
                                                                        showRangeLabels={false}
                                                                        inputComponent="input"
                                                                        onChange={startDateChange}
                                                                        value={popupEventStartDate}
                                                                        className="date-picker-popup"
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <div className='date-input-wrap form-group'>
                                                                    <Form.Label className="">End</Form.Label>
                                                                    <Datepicker
                                                                        controls={['calendar', 'time']}
                                                                        touchUi={true}
                                                                        inputComponent="input"
                                                                        showRangeLabels={false}
                                                                        onChange={endDateChange}
                                                                        value={popupEventEndDate}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col md={12}>
                                                                <Form.Group className="form-group textarea-group" controlId="shiftNote">
                                                                    <label className='form-label'>Note</label>
                                                                    <textarea placeholder='Text here' name="Note" onChange={handleChange} defaultValue={currentTemplate.shift_note}></textarea>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <div className='btn-wrap'>
                                                            <button className='btn btn-primary' onClick={handleSubmit}>Add Shift</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>


                                        </Modal.Body>
                                    </Modal>
                                ) : ""}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            ) : ""}
        </>
    )
}

export default ShiftTemplateAccordion;