import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col, Modal, InputGroup } from "react-bootstrap";
import ImageUploadInput from "./ImageUploadInput";
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import { ENV } from "../../env/env";
import { Modal as Antmodal } from "antd";

import { Buffer } from "buffer";
import S3FileUpload from "react-s3";
import ReusableAPI from "./NotificationApi";
import { useNavigate } from "react-router-dom";
import { snackbar } from "@mobiscroll/react";
import map_point from "../../assets/map_point.png";
// @ts-ignore
import useGeolocation from "react-hook-geolocation";
import Map from "./Map";
import MapContainer from "./MapContainer";
import { placeholder } from "@mapbox/mapbox-gl-geocoder/lib/localization";

const AddFacilityPopup1 = (props) => {
  window.Buffer = Buffer;
  const [image, setImage] = useState();
  const [errorMessages, setErrorMessages] = useState("");
  const [loader, setLoader] = useState(false);
  const [classe, setClasse] = useState();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [lng, setLng] = useState(null);
  const [lat, setLat] = useState(null);
  const [address, setAddress] = useState("");

  async function apiCall(credentials) {
    let token = localStorage.getItem("token");

    await axios
      .post(ENV.ENDPOINT + "/facility/view/", credentials, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        props.handleRender(true);
        setLoader(false);
        handleClose();
        reset();

        setTimeout(() => {
          snackbar({
            message: "Facility Added Successfully",
          });
        });

        //Triggering event notification
        var notificationMsg = `Added ${credentials.facility_name} Facility`;
        ReusableAPI.notificationTriggerAPI(notificationMsg);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  useEffect(() => {
    if (loader == true) {
      setClasse("custom-loader");
    } else {
      setClasse("custom-loader hide");
    }
  }, [loader]);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    reset,
    clearErrors,
    setValue,
  } = useForm();

  setValue("latitude", lat);
  setValue("longitude", lng);
  setValue("address", address);

  let pwd = "";

  const handleClose = () => {
    setShow(false);
    setLat(null);
    setLng(null);
    clearErrors();
    setAddress("");
    reset();
  };

  const handleImage = (e) => {
    if (e.size > 500000) {
      setErrorMessages({
        name: "image",
        message: "Please upload image below 500KB in size",
      });
      setImage();
    } else {
      setErrorMessages({ name: "image", message: "" });
      setImage(e);
    }
  };

  const onSubmit = (data) => {
    if (image === undefined) {
      setErrorMessages({ name: "image", message: "Please upload image" });
    } else {
      setErrorMessages({ name: "image", message: "" });
      setLoader(true);
    }

    function getBase64(e) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        reader.onload = async () => {
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
          if (encoded.length % 4 > 0) {
            encoded += "=".repeat(4 - (encoded.length % 4));
          }
          resolve(encoded);
          setImage(encoded);
          const credentials = {
            user_id: localStorage.getItem("user_id"),
            data: encoded,
          };
          let token = localStorage.getItem("token");

          await axios
            .post(ENV.ENDPOINT + `/s3upload/image/`, credentials, {
              headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
              let address = data.address;

              let phone_no = data.mainline;
              let facility_image = response.data.location;
              let facility_name = data.FacilityName;
              let latitude = data.latitude;
              let longitude = data.longitude;

              const token = apiCall({
                address,

                phone_no,
                facility_image,
                facility_name,
                latitude,
                longitude,
              });
            })
            .catch((error) => {
              console.log(error.response.data);
            });
        };
        reader.onerror = (error) => reject(error);
      });
    }
    getBase64(image);
  };
  // Form validation

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <div className="add-user-pop-wrap">
      <Button className="border-btn" onClick={handleShow}>
        <i className="icon-plus"></i>
        {props.ModelTitle}
      </Button>
      <Modal show={show} onHide={handleClose} className="add-user-popup">
        <Modal.Header closeButton>
          <h5>{props.ModelTitle}</h5>
        </Modal.Header>
        <Modal.Body>
          <div className="add-user-form">
            <div className={classe}></div>
            <Form onSubmit={handleSubmit(onSubmit)} method="POST">
              <div className="form-inner">
                <Row className="form-row">
                  <Col md={6}>
                    <div className="image-upload">
                      <ImageUploadInput handleImage={handleImage} />
                    </div>
                    {renderErrorMessage("image")}
                  </Col>
                </Row>
                <Row className="form-row">
                  <Col md={6}>
                    <Form.Group className="form-group" controlId="mainline">
                      <Form.Label className="">Mainline</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Text here"
                        name="mainline"
                        {...register("mainline", {
                          required: true,
                          maxLength: 20,
                          minLength: 10,
                        })}
                      />
                      <span className="error-message">
                        {errors.mainline?.type === "required" &&
                          "Enter Mainline"}
                        {errors.mainline?.type === "maxLength" &&
                          "It accepts maximum 20 characters"}
                        {errors.mainline?.type === "minLength" &&
                          "enter minimum 10 characters"}
                      </span>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-group" controlId="FacilityName">
                      <Form.Label className="">Facility Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        name="FacilityName"
                        {...register("FacilityName", {
                          required: true,
                          maxLength: 100,
                          minLength: 1,
                        })}
                      />
                      <span className="error-message">
                        {errors.FacilityName?.type === "required" &&
                          "Enter Facility name"}
                        {errors.FacilityName?.type === "maxLength" &&
                          "It accepts maximum 100 characters"}
                        {errors.FacilityName?.type === "minLength" &&
                          "enter minimum 1 characters"}
                      </span>
                    </Form.Group>
                  </Col>

                  {/* controlId="latitude" */}
                  <Col md={12}>
                    <Form.Group className="form-group">
                      <div className="relative">
                        <Map
                          setLng={setLng}
                          setLat={setLat}
                          lng={lng}
                          lat={lat}
                          address={address}
                          setAddress={setAddress}
                        />
                      </div>

                      {/* <div className="w-[300px] h-[320px]">
                        <MapContainer />
                      </div> */}
                    </Form.Group>
                  </Col>

                  {/* <Col md={12}>
                    <Form.Group className="form-group">
                      <div >
                       <h3>{currentLocation}</h3>
                      </div>
                    </Form.Group>
                  </Col> */}

                  <Col md={6}>
                    <Form.Group className="form-group" controlId="latitude">
                      <Form.Label className="">Latitude</Form.Label>
                      <div className="relative ">
                        <Form.Control
                          type="number"
                          placeholder="Use Map For Value"
                          name="latitude"
                          readOnly
                          {...register("latitude", {
                            required: true,
                            maxLength: 100,
                            minLength: 3,
                          })}
                        />
                        {(lat === null || lat === undefined) && (
                          <span className="error-message">
                            {errors.latitude?.type === "required" &&
                              "Enter Latitude Value use Map"}
                            {/* {errors.latitude?.type === "maxLength" &&
                           "It accepts maximum 100 characters"}
                             {errors.latitude?.type === "minLength" &&
                           "It accepts minimum 3 characters"} */}
                          </span>
                        )}
                        {/* <button
                          type="button"
                          className="absolute top-0 right-0 py-[15px] px-4 border-none rounded-lg"
                          onClick={showModal}
                        >
                          <img
                            className="w-[20px] h-[19px]"
                            src={map_point}
                            alt="map_point"
                          />
                        </button> */}
                        {/* <Antmodal
                          zIndex={9998}
                          open={isModalOpen}
                          onOk={handleOk}
                          onCancel={handleCancel}
                        >
                        
                        </Antmodal>
                        <Map/> */}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="form-group" controlId="longitude">
                      <Form.Label className="">Longitude </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Use Map For Value"
                        name="longitude"
                        readOnly
                        {...register("longitude", {
                          required: true,
                        })}
                      />
                      {(lng === null || lng === undefined) && (
                        <span className="error-message">
                          {errors.longitude?.type === "required" &&
                            "Enter Longitude Value use Map"}
                        </span>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group className="form-group" controlId="address">
                      <Form.Label className="">Address</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Text here"
                        name="address"
                        {...register("address", {
                          required: true,
                          maxLength: 200,
                          minLength: 3,
                        })}
                      />
                      {address === "" && (
                        <span className="error-message">
                          {errors.address?.type === "required" &&
                            "Enter Address"}
                          {errors.address?.type === "maxLength" &&
                            "It accepts maximum 100 characters"}
                          {errors.address?.type === "minLength" &&
                            "enter minimum 3 characters"}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <div className="submit-btn">
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddFacilityPopup1;
