import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import InputGroupForm from './InputGroupForm';
import ImageUploadInput from './ImageUploadInput';
import DateofBirthInput from './DateofBirthInput';
import { useNavigate, Link } from 'react-router-dom';
import CustomDropdown from './CustomDropdown';
import CredentialTable from './CredentialTable';
import { RoutesPath } from '../../App';
import { useLocation } from 'react-router-dom'
import axios from "axios";
import { ENV } from '../../env/env'
import { Buffer } from 'buffer';
import S3FileUpload from 'react-s3';
import Select from 'react-select'
import MessagesButton1 from './MessagesButton1';
import { snackbar } from '@mobiscroll/react';
import { Block } from '@material-ui/icons';

const EditClinicians = () => {

    const inputRef = useRef(null)

    const navigate = useNavigate();
    const location = useLocation();

    window.Buffer = Buffer;
    const [image, setImage] = useState()
    const [imageURL, setImageURL] = useState(location.state.data.avatar_image)
    const [errorMessages, setErrorMessages] = useState("");
    const [loader, setLoader] = useState(false)
    const [classe, setClasse] = useState()

    const [dob, setDob] = useState(location.state.data.date_of_birth)
    const [model, setModel] = useState({
        userName: location.state.data.username,
        Email: location.state.data.email,
        Password: location.state.data.password,
        FirstName: location.state.data.firstname,
        LastName: location.state.data.lastname,
        Position: location.state.data.clinician_position,
        EmployeeId: location.state.data.role,
        ContactNumber: location.state.data.contact_no,
        jobsites: location.state.data.job_sites,



    });
    const [password, setPassword] = useState(location.state.data.password)
    const jobsite = location.state.data.job_sites.map(function (row) {
        return { value: row, label: row }
    })

    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        await axios.patch(ENV.ENDPOINT + `/clinician/edit/${location.state.data.clinician_id}/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                setLoader(false)
                setTimeout(() => {
                    snackbar({
                        message: 'Clinician Edited successfully'
                    });
                });
                navigate('/manage-users')

            })
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])


    const handleChange = (event) => {
        // get the value of the field
        console.log("hello from child")
        const value = event?.target?.value;
        //set it in the model
        setModel({
            ...model,
            [event.target.name]: value
        });
        console.log(model.userName, "usernameeeeeeeeeeeeeeee")
        console.log(model.Email, "email")

    };
    const handleDropDown = (event) => {
        const value = event?.target?.value;
        console.log(value)
        setModel({
            ...model,
            jobsites: value
        });
    }

    const [year, month, day] = location.state.data.date_of_birth.split('-');
    const result1 = [month, day, year].join('/');
    const handledDate = date => {
        let datevalue = date.format()

        const [month, day, year] = datevalue.split('/');
        const result = [year, month, day].join('-');

        setDob(result)
    }
    const [sites, setSites] = useState()
    const [facilities, setFacilities] = useState([])
    useEffect(() => {
        console.log(model, "modelllllllllllll")
        let token = localStorage.getItem('token')
        axios.get(ENV.ENDPOINT + '/facility/', { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                console.log(response.data.payload)
                setFacilities(response.data.payload)


            })
            .catch((error) => {
                console.log(error)
            })

        setSites(location.state.data.job_sites)
    }, [model])
    const optionsFacilities = facilities.map(function (row) {
        return { value: row.facility_name, label: row.facility_name }
    })


    const handleJobSites = (e) => {
        let list = []

        e.map(function (row) {

            list.push(row.value)
        })
        setSites(list)

    }

    const handleImage = (e) => {
        console.log(e.size)
        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setErrorMessages({ name: "image", message: "" });
            setImage(e)
        }

    }




    const handleSubmit = (event) => {

        setLoader(true)
        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {


                            let username = model.userName
                            let email = model.Email
                            let password = model.Password
                            let clinician_position = model.Position
                            let firstname = model.FirstName
                            let lastname = model.LastName
                            let contact_no = model.ContactNumber
                            let avatar_image = response.data.location
                            let role = model.EmployeeId
                            let job_sites = sites
                            let date_of_birth = dob

                            const token = apiCall({
                                username,
                                email,
                                password,
                                clinician_position,
                                firstname,
                                lastname,
                                contact_no,
                                avatar_image,
                                role,
                                job_sites,
                                date_of_birth
                            });



                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })
                    console.log(encoded)

                };
                reader.onerror = error => reject(error);
            });
        }
        if (image != undefined) {
            getBase64(image)
        }
        else {
            let username = model.userName
            let email = model.Email
            let password = model.Password
            let clinician_position = model.Position
            let firstname = model.FirstName
            let lastname = model.LastName
            let contact_no = model.ContactNumber
            let avatar_image = imageURL
            let role = model.EmployeeId
            let job_sites = sites
            let date_of_birth = dob

            const token = apiCall({
                username,
                email,
                password,
                clinician_position,
                firstname,
                lastname,
                contact_no,
                avatar_image,
                role,
                job_sites,
                date_of_birth
            });
        }





    }

    const handleDelete = () => {
        let token = localStorage.getItem('token')

        axios.delete(ENV.ENDPOINT + `/clinician/delete/${location.state.data.clinician_id}/`, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {

                // console.log(response.data.messages)
                console.log(response.data.results)


            })
            .catch((error) => {
                console.log(error.response.data)
            })
        navigate('/manage-users')
    }

    const handleResetPass = () => {
        inputRef.current.value = ""
    }

    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    return (
        <div className='edit-user-wrapper'>
            <div className='user-header'>
                <Link to={RoutesPath.manageUser} className='back-btn' id="UserbackBtn">
                    <i className='icon-Back-arrow'></i>
                </Link>
                <h6 className='poc-name-title'>{location.state.data.firstname + " " + location.state.data.lastname}</h6>
                {/* <button className='btn border-btn'>
                    <i className='icon-message-solid'></i>
                    Message
                </button> */}
                <MessagesButton1 userData={location.state.data} />
            </div>
            <div className='edit-user'>
            <div className={classe}></div>
                {/* <Form> */}
                <div className="edit-user-top">
                    <div className='user-image'>
                        <div className='image-upload'>
                            <ImageUploadInput UserImage={location.state.data.avatar_image} handleImage={handleImage} />
                        </div>
                        {renderErrorMessage("image")}
                    </div>
                    <div className="edit-user-form">
                        <Row className="form-row">
                            <Col md={6}>
                                <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={location.state.data.username} handleChange={handleChange} />
                            </Col>
                            <Col md={6}>
                                <InputGroupForm inputId="FirstName" inputLabel="Firstname" inputType="text" inputPlaceholder="Text here" inputName="FirstName" inputValue={location.state.data.firstname} handleChange={handleChange} />
                            </Col>
                            <Col md={6}>
                                <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={location.state.data.email} handleChange={handleChange} />
                            </Col>
                            <Col md={6}>
                                <InputGroupForm inputId="LastName" inputLabel="Lastname" inputType="text" inputPlaceholder="Text here" inputName="LastName" inputValue={location.state.data.lastname} handleChange={handleChange} />
                            </Col>
                            {/* <Col md={6}>
                                    <InputGroupForm inputId="Password" inputLabel="Password" inputType="password" inputPlaceholder="*****" inputName="Password"   handleChange={handleChange}/>
                                    <button type='button' className='btn reset-pass-btn btn-primary'>Reset</button>
                                </Col> */}
                            <Col md={6} className="password-field">
                                <div className="password-group">
                                    <Form.Group className="form-group" controlId={'Password'}>
                                        <Form.Label className="">{"Password"}</Form.Label>
                                        <Form.Control ref={inputRef} type={"password"} placeholder={"*****"} name={"Password"} defaultValue={password} onChange={handleChange} />
                                    </Form.Group>
                                    {/* <InputGroupForm inputId="Password" inputLabel="Password" inputType="password" inputPlaceholder="*****" inputName="Password" inputValue={password} /> */}
                                    <button type='button' className='btn reset-pass-btn btn-primary' onClick={handleResetPass}>Reset</button>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="form-group date-of-birth-input">
                                    <label className='form-label'>Date of birth</label>
                                    <DateofBirthInput inputValue={result1} handleChange={handledDate} />
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
                <div className='edit-user-bottom edit-user-form'>
                    <Row className="form-row">
                        <Col md={6}>
                            <InputGroupForm inputId="Position" inputLabel="Position" inputType="text" inputPlaceholder="Text here" inputName="Position" inputValue={location.state.data.clinician_position} handleChange={handleChange} />
                        </Col>
                        <Col md={6}>
                            <InputGroupForm inputId="EmployeeId" inputLabel="Employee ID" inputType="text" inputPlaceholder="Text here" inputName="EmployeeId" inputValue={location.state.data.role} handleChange={handleChange} />
                        </Col>
                        <Col md={6}>
                            {/* <div className='form-group'>
                                    <label className='form-label'>Job Sites</label>
                                    <CustomDropdown optionData={optionsFacilities} editClinician={sites} handleChange={handleJobSites}/>
                                </div> */}
                            <div className='form-group custom-dropdown'>
                                <label className='form-label'>Job Sites</label>
                                <Select options={optionsFacilities} isMulti className="multi-select-dropdown" isClearable="false" defaultValue={jobsite} onChange={handleJobSites} />

                            </div>
                        </Col>
                        <Col md={6}>
                            <InputGroupForm inputId="ContactNumber" inputLabel="Contact Number" inputType="number" inputPlaceholder="Text here" inputName="ContactNumber" inputValue={location.state.data.contact_no} handleChange={handleChange} />
                        </Col>
                    </Row>
                </div>
                <div className="edit-user-form credential-wrapper">
                    <h6 className='table-title'>Credentials</h6>
                    <div className="credential-table-wrapper">
                        <div className="table-wrapper table-responsive">
                            <CredentialTable id={location.state.data.clinician_id} />
                        </div>
                    </div>


                </div>
                <div className='edit-user-footer'>
                    <div className='btn-wrap'>
                        <button className='btn border-btn' onClick={handleDelete}>Delete Clinicians</button>
                        <button className='btn btn-primary' onClick={handleSubmit}>Save Changes</button>
                    </div>
                </div>
                {/* </Form> */}
            </div >

        </div >
    )
}

export default EditClinicians;