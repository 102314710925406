import React, { useEffect } from 'react'
import { useGlobalState, useGlobalMutation } from '../utils/container'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import { log } from '../utils/utils'
import useRouter from '../utils/use-router'
import { Outlet, useNavigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'

import Card from './card'
import Meeting from '../meeting/index'
import Calling from '../calling'
import Incoming from '../incoming'

const Index = () => {
  console.clear()
  const navigate = useNavigate()
  const stateCtx = useGlobalState()
  const mutationCtx = useGlobalMutation()

  const routerCtx = useRouter()

  const history = useNavigate()
  useEffect(() => {
    if (stateCtx.loading === true) {
      mutationCtx.stopLoading()
    }
  }, [stateCtx.loading, mutationCtx])

 
  // useEffect(() => {
  //   if (stateCtx.rtmClient.status === 'offLine') {
  //     stateCtx.rtmClient.login(stateCtx.userCode).then(() => {
  //     }).catch(() => {
  //       log('Login failure')
  //       return
  //     })
  //   }
  // }, [stateCtx.rtmClient])

  useEffect(() => {


    stateCtx.rtmClient.on('RemoteInvitationReceived', (remoteInvitation) => {

      mutationCtx.updatePeerCode(remoteInvitation.callerId)
      mutationCtx.updateIncomingCode(remoteInvitation.callerId)
      mutationCtx.updateConfig({ channelName: remoteInvitation.content[2] })

      navigate({
        pathname: `/call-invi/card/indexCard/incoming/`
      })
    })

    stateCtx.rtmClient.on('RemoteInvitationCanceled', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationFailure', () => {
      mutationCtx.toastError('Call not answered')
      navigate.goBack()
    })

    stateCtx.rtmClient.on('RemoteInvitationAccepted', () => {
    
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
     
      navigate({
        pathname: `/meeting/${channel}`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationAccepted', () => {
      mutationCtx.startLoading()
      let channel = stateCtx.config.channelName
     
      navigate({
        pathname: `/meeting/${channel}`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationRefused', () => {
      mutationCtx.toastError('Peer is busy')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('RemoteInvitationRefused', () => {
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    stateCtx.rtmClient.on('LocalInvitationCanceled', () => {
      navigate('/dasboard')
    })

    stateCtx.rtmClient.on('LocalInvitationReceivedByPeer', () => {
      navigate({
        pathname: `/dialling`
      })
    })

    stateCtx.rtmClient.on('LocalInvitationFailure', () => {
      mutationCtx.toastError('Call process failed')
      if(localStorage.getItem('position')=='facility_admin'){
        navigate('/funding-summary')
      }else{
        navigate('/dashboard')
      }
    })

    if (localStorage.getItem('peer_id') != undefined) {
      navigate('/call-invi/card')
    }

    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners()
      //   navigate('/card')
    }

  }, [stateCtx.rtmClient, stateCtx])

}

export default Index
