import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import InputGroupForm from "./InputGroupForm";
import ImageUploadInput from "./ImageUploadInput";
import { useNavigate, Link } from "react-router-dom";
import { RoutesPath } from "../../App";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { ENV } from "../../env/env";
import { Buffer } from "buffer";
import S3FileUpload from "react-s3";
import SwitchToggle from "./SwitchToggle";
import { snackbar } from "@mobiscroll/react";

const EditNewFacility = () => {
    window.Buffer = Buffer;
    const navigate = useNavigate();
    const location = useLocation();

    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState(location.state.data.facility_image);
    const [errorMessages, setErrorMessages] = useState("");
    const [loader, setLoader] = useState(false);
    const [classe, setClasse] = useState();
    const [model, setModel] = useState({
        FacilityName: location.state.data.facility_name,
        Mainline: location.state.data.phone_no,
        Address: location.state.data.address,
        Image: location.state.data.facility_image,
    });


   //console.log("location" ,location.state.data)    
    async function apiCall(credentials) {
        let token = localStorage.getItem("token");

        await axios
        .patch(
            ENV.ENDPOINT + `/facility/edit/${location.state.data.facility_id}/`,
            credentials,
            { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                console.log( response.data.messages);
                setLoader(false);
                setTimeout(() => {
                    snackbar({
                        message: "Facility Edited successfully",
                    });
                });
                navigate("/manage-users");
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        }

        useEffect(() => {
            if (loader == true) {
                setClasse("custom-loader");
            } else {
                setClasse("custom-loader hide");
            }
        }, [loader]);

        const handleChange = (event) => {
            // get the value of the field
            console.log("hello from child");
            const value = event?.target?.value;
            //set it in the model
            setModel({
                ...model,
                [event.target.name]: value,
            });
        };

        const handleImage = (e) => {
            console.log(e.size);
            if (e.size > 500000) {
                setErrorMessages({
                    name: "image",
                    message: "Please upload image below 500KB in size",
                });
                setImage();
            } else {
                setErrorMessages({ name: "image", message: "" });
                setImage(e);
            }
        };

        const handleSubmit = (event) => {
            setLoader(true);
            function getBase64(e) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(e);
                    reader.onload = async () => {
                        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
                        if (encoded.length % 4 > 0) {
                            encoded += "=".repeat(4 - (encoded.length % 4));
                        }
                        resolve(encoded);
                        setImage(encoded);
                        const credentials = {
                            user_id: localStorage.getItem("user_id"),
                            data: encoded,
                        };
                        let token = localStorage.getItem("token");

                        await axios
                        .post(ENV.ENDPOINT + `/s3upload/image/`, credentials, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((response) => {
                            let address = model.Address;

                            let phone_no = model.Mainline;
                            let facility_image = response.data.location;
                            let facility_name = model.FacilityName;

                            const token = apiCall({
                                address,

                                phone_no,
                                facility_image,
                                facility_name,
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        });
                        console.log(encoded);
                    };
                    reader.onerror = (error) => reject(error);
                });
            }
            if (image != undefined) {
                getBase64(image);
            } else {
                let address = model.Address;

                let phone_no = model.Mainline;
                let facility_image = imageURL;
                let facility_name = model.FacilityName;

                const token = apiCall({
                    address,

                    phone_no,
                    facility_image,
                    facility_name,
                });
            }

    //navigate('/manage-user-super-admin')
};

const handleDelete = () => {
    let token = localStorage.getItem("token");

    axios
    .delete(
        ENV.ENDPOINT + `/facility/delete/${location.state.data.facility_id}/`,
        { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
            // console.log(response.data.messages)
            console.log(response.data.results);
        })
        .catch((error) => {
            console.log(error.response.data);
        });
        navigate("/manage-users");
    };

    const handleScheduleSwitch = (e, id) => {
        console.log(e.target.checked);
        let is_active = e.target.checked;

        async function apiCallSChedulling(credentials) {
            let token = localStorage.getItem("token");
            await axios
            .patch(ENV.ENDPOINT + `/facility/edit/${id}/`, credentials, {
                headers: { Authorization: `Bearer ${token}` },
            })
            .then((response) => {
                console.log(response.data.messages);
            })
            .catch((error) => {
                console.log(error.response.data);
            });
        }

        const token = apiCallSChedulling({
            is_active,
        });
    };

    const renderErrorMessage = (name) =>
    name === errorMessages.name && (
        <div className="error">{errorMessages.message}</div>
        );

        return (
            <div className="edit-user-wrapper">
      <div className={classe}></div>
      <div className="user-header">
      <Link to={RoutesPath.manageUser} className="back-btn" id="UserbackBtn">
      <i className="icon-Back-arrow"></i>
      </Link>
      <h6 className="poc-name-title">{location.state.data.facility_name}</h6>
        {/* <button className='btn border-btn'>
                    <i className='icon-message-solid'></i>
                    Message
                </button> */}
      </div>
      <div className="edit-user">
        {/* <Form> */}
        <div className="edit-user-top">
        <div className="user-image">
        <div className="image-upload">
        <ImageUploadInput
                UserImage={imageURL}
                handleImage={handleImage}
                />
                </div>
                {renderErrorMessage("image")}
                </div>
                <div className="edit-user-form">
            {/* <Row className="form-row">
                                <Col md={6}>
                                    <InputGroupForm inputId="userName" inputLabel="User name" inputType="text" inputPlaceholder="Text here" inputName="userName" inputValue={location.state.data.username}  handleChange={handleChange}/>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="pocFirstName" inputLabel="POC Firstname" inputType="text" inputPlaceholder="Text here" inputName="pocFirstName" inputValue={location.state.data.poc_firstname}  handleChange={handleChange}/>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Email" inputLabel="Email" inputType="email" inputPlaceholder="Text here" inputName="Email" inputValue={location.state.data.email} handleChange={handleChange}/>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="pocLastName" inputLabel="POC Lastname" inputType="text" inputPlaceholder="Text here" inputName="pocLastName" inputValue={location.state.data.poc_lastname}  handleChange={handleChange}/>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="Password" inputLabel="Password" inputType="password" inputPlaceholder="*****" inputName="Password"  inputValue={location.state.data.password} handleChange={handleChange}/>
                                </Col>
                                <Col md={6}>
                                    <InputGroupForm inputId="PocPosition" inputLabel="POC position" inputType="text" inputPlaceholder="Text here" inputName="PocPosition" inputValue={location.state.data.poc_position}  handleChange={handleChange}/>
                                </Col>
                            </Row> */}
          </div>
          </div>
          <div className="edit-user-bottom edit-user-form">
          <Row className="form-row">
          <Col md={6}>
          <InputGroupForm
                inputId="FacilityName"
                inputLabel="Facility Name"
                inputType="text"
                inputPlaceholder="Text here"
                inputName="FacilityName"
                inputValue={location.state.data.facility_name}
                handleChange={handleChange}
                />
                </Col>
                <Col md={6}>
                <InputGroupForm
                inputId="Mainline"
                inputLabel="Mainline"
                inputType="text"
                inputPlaceholder="Text here"
                inputName="Mainline"
                inputValue={location.state.data.phone_no}
                handleChange={handleChange}
                />
                </Col>
                <Col md={12}>
                <InputGroupForm
                inputId="Address"
                inputLabel="Address"
                inputType="text"
                inputPlaceholder="Text here"
                inputName="Address"
                inputValue={location.state.data.address}
                handleChange={handleChange}
                />
                </Col>
                <Col md={6}>
                <div className="scheduling-btn">
                <h6>Facility Status</h6>
                <SwitchToggle
                  dataSwitch={location.state.data.is_active}
                  data={(e) =>
                    handleScheduleSwitch(e, location.state.data.facility_id)
                }
                />
              </div>
              </Col>
              </Row>
              </div>
              <div className="edit-user-footer">
              <div className="btn-wrap">
              <button className="btn border-btn" onClick={handleDelete}>
              Delete Facility
              </button>
              <button className="btn btn-primary" onClick={handleSubmit}>
              Save Changes
              </button>
              </div>
              </div>
        {/* </Form> */}
        </div>
        </div>
  );
};

export default EditNewFacility;
