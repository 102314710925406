import React, { useEffect, useState } from "react";
import { Col, Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { RoutesPath } from "../App";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { ENV } from "../env/env";
import RenewToken from "../components/inner-components/RenewToken";
import { snackbar } from "@mobiscroll/react";
import { useGlobalState, useGlobalMutation } from "../pages/utils/container";
import useWalkThrough from "../pages/utils/useWalkThrough";
import { Tune } from "@material-ui/icons";

const LeftPanel = ({ref1 }) => {
  const stateCtx = useGlobalState();
  const mutationCtx = useGlobalMutation();
  const navigate = useNavigate();
  const [profileImage, setProfileImage] = useState();
  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem('token');
    stateCtx.rtmClient._client.logout();
    navigate("/");
    setTimeout(() => {
      snackbar({
        message: "Logged Out successfully",
      });
    });
  };

 

  const leftPanelMenuData = [
    {
      LinkUrl: "/funding-summary",
      imageWhite: "assets/images/ico/panel-icon/financial-icon.svg",
      imageBlue: "assets/images/ico/panel-icon/financial-icon-filled.svg",
      menuText: "Financial Summary",
    },

    {
      LinkUrl: RoutesPath.dashboard,
      imageWhite: "assets/images/ico/panel-icon/dashboard.svg",
      imageBlue: "assets/images/ico/panel-icon/dashboard-filled.svg",
      menuText: "Dashboard",
      reff : ref1
    },
    {
      LinkUrl: RoutesPath.editProfile,
      imageWhite: "assets/images/ico/panel-icon/calculator.svg",
      imageBlue: "assets/images/ico/panel-icon/calculator.svg",
      menuText: "Edit Profile",
    },
    {
      LinkUrl: "/scheduling",
      imageWhite: "assets/images/ico/panel-icon/calendar.svg",
      imageBlue: "assets/images/ico/panel-icon/calendar-filled.svg",
      menuText: "Scheduling",
    },
    {
      LinkUrl: "/timesheets",
      imageWhite: "assets/images/ico/panel-icon/timesheets.svg",
      imageBlue: "assets/images/ico/panel-icon/timesheets-filled.svg",
      menuText: "Timesheets",
    },
    {
      LinkUrl: "/statistics",
      imageWhite: "assets/images/ico/panel-icon/graph.svg",
      imageBlue: "assets/images/ico/panel-icon/graph-filled.svg",
      menuText: "Facility Statistics",
    },
    {
      LinkUrl: RoutesPath.documents,
      imageWhite: "assets/images/ico/panel-icon/document.svg",
      imageBlue: "assets/images/ico/panel-icon/document-filled.svg",
      menuText: "Your Documents",
    },
    {
      LinkUrl: "/feedback-ratings",
      imageWhite: "assets/images/ico/panel-icon/star.svg",
      imageBlue: "assets/images/ico/panel-icon/star-filled.svg",
      menuText: "FeedBack & Ratings",
    },
    {
      LinkUrl: RoutesPath.manageUser,
      imageWhite: "assets/images/ico/panel-icon/user.svg",
      imageBlue: "assets/images/ico/panel-icon/user-filled.svg",
      menuText: "Manage users",
    },
    {
      LinkUrl: "/statistics",
      imageWhite: "assets/images/ico/panel-icon/graph.svg",
      imageBlue: "assets/images/ico/panel-icon/graph-filled.svg",
      menuText: "Statistics",
    },
    {
      LinkUrl: RoutesPath.documents,
      imageWhite: "assets/images/ico/panel-icon/document.svg",
      imageBlue: "assets/images/ico/panel-icon/document-filled.svg",
      menuText: "Documents",
    },
    {
      LinkUrl: "/add-paycard",
      imageWhite: "assets/images/ico/panel-icon/paycard.svg",
      imageBlue: "assets/images/ico/panel-icon/paycard-filled.svg",
      menuText: "Add Paycard",
    },
    {
      LinkUrl: RoutesPath.notes,
      imageWhite: "assets/images/ico/panel-icon/notes.svg",
      imageBlue: "assets/images/ico/panel-icon/notes-filled.svg",
      menuText: "Notes",
    },
    {
      LinkUrl: RoutesPath.workforceCalculator,
      imageWhite: "assets/images/ico/panel-icon/calculator.svg",
      imageBlue: "assets/images/ico/panel-icon/calculator-filled.svg",
      menuText: "Workforce Calculator",
    },
  ];
  // let SALeftPanel=leftPanelMenuData.filter(item=>!(item.menuText=='Add Paycard'&&'Statistics'))
  // console.log(SALeftPanel,"SALEFTPANELLLLLLLLL")

  const [menuData, setMenuData] = useState();

  useEffect(() => {
    setTimeout(function () {
      let list = [];
      leftPanelMenuData.forEach((el) => {
        if (localStorage.getItem("position") == "hmc_admin") {
          if (el.menuText == "Add Paycard") {
          } else if (el.menuText == "Statistics") {
          } else if (el.menuText == "Financial Summary") {
          } else if (el.menuText == "Edit Profile") {
          } else if (el.menuText == "Add Paycard") {
          } else if (el.menuText == "Facility Statistics") {
          } else if (el.menuText == "Your Documents") {
          } else if (
            el.menuText == "Scheduling" &&
            localStorage.getItem("scheduling") == "false"
          ) {
          } else {
            list.push(el);
          }
        } else if (localStorage.getItem("position") == "c_level_emp") {
          if (el.menuText == "Edit Profile") {
          } else if (el.menuText == "Financial Summary") {
          } else if (el.menuText == "Facility Statistics") {
          } else if (el.menuText == "Your Documents") {
          } else if (
            el.menuText == "Scheduling" &&
            localStorage.getItem("scheduling") == "false"
          ) {
          } else {
            list.push(el);
          }
        } else if (localStorage.getItem("position") == "facility_admin") {
          console.log(el.menuText);
          if (el.menuText == "Add Paycard") {
          } else if (el.menuText == "Scheduling") {
          } else if (el.menuText == "Dashboard") {
          } else if (el.menuText == "Timesheets") {
          } else if (el.menuText == "Manage users") {
          } else if (el.menuText == "Edit Profile") {
          } else if (el.menuText == "Edit Profile") {
          } else if (el.menuText == "Statistics") {
          } else if (el.menuText == "Documents") {
          } else if (el.menuText == "Facility Statistics") {
            list.push(el);
          } else if (el.menuText == "Your Documents") {
            list.push(el);
          } else {
            list.push(el);
          }
        } else if (localStorage.getItem("position") == "poc_admin") {
          if (el.menuText == "Add Paycard") {
          } else if (el.menuText == "Statistics") {
          } else if (el.menuText == "Add Paycard") {
          } else if (el.menuText == "Manage users") {
          } else if (el.menuText == "Notes") {
          } else if (el.menuText == "Workforce Calculator") {
          } else if (el.menuText == "Edit Profile") {
          } else if (el.menuText == "Financial Summary") {
          } else if (el.menuText == "Facility Statistics") {
          } else if (el.menuText == "Your Documents") {
          } else if (
            localStorage.getItem("scheduling") == "false" &&
            el.menuText == "Scheduling"
          ) {
          } else {
            list.push(el);
          }
        } else if (localStorage.getItem("position") == "clinician") {
          if (el.menuText == "Dashboard") {
            list.push(el);
          }
          if (el.menuText == "Edit Profile") {
            list.push(el);
          }
          if (el.menuText == "Add Paycard") {
            list.push(el);
          } else if (el.menuText == "Facility Statistics") {
          } else if (el.menuText == "Your Documents") {
          } else if (el.menuText == "Financial Summary") {
          }
        } else {
          list.push(el);
        }

        setMenuData(list);
      });
    }, 170);
  }, []);

  useEffect(() => {
    setProfileImage(localStorage.getItem("avatarImage"));
  }, [profileImage]);

 


  var classe;
  useEffect(() => {
    if (
      localStorage.getItem("scheduling") == "" ||
      localStorage.getItem("scheduling") == undefined
    ) {
      classe = "custom-loader hide";
    } else {
      classe = "custom-loader";
    }
  });

  let positionRole = "";
  if (localStorage.getItem("position") == "facility_admin") {
    positionRole = "Facility Admin";
  } else if (localStorage.getItem("position") == "c_level_emp") {
    positionRole = "Super Admin";
  } else if (localStorage.getItem("position") == "hmc_admin") {
    positionRole = "HMC Admin";
  }

  return (
    <>
      <div className={classe}></div>

      <Col lg={3} className="left-panel-col left-panel-main" id="mobileMenu">
        <div className="left-panel-wrap">
          <Navbar.Brand href={RoutesPath.dashboard}>
            <figure>
              <img
                src="assets/images/ShiftAlerts_Logo_white.svg"
                alt="App Logo"
              />
            </figure>
           

          </Navbar.Brand>
          <Navbar.Collapse id="dashboard-nav">
            <Nav>
              {menuData &&
                menuData.map((val, index) => (
                  <LinkContainer to={val.LinkUrl} key={index} >
                    <Nav.Link ref={val.reff}>
                      <span className="icon-wrap">
                        <img
                          src={val.imageWhite}
                          alt="menu Icon"
                          className="default-icon"
                        />
                        <img
                          src={val.imageBlue}
                          alt="menu Icon"
                          className="active-icon"
                        />
                      </span>
                      <span className="text-wrap" >{val.menuText}</span>
                    </Nav.Link>
                  </LinkContainer>
                ))}
            </Nav>
          </Navbar.Collapse>
          <div className="profile-wrapper">
            <div className="profile-image-wrapper">
              <Link to={RoutesPath.editProfile}>
                <img
                  src={localStorage.getItem("avatarImage")}
                  alt="profile"
                />
              </Link>
            </div>
            <div className="profile-name">
              <h6>Hi, {localStorage.getItem("username1")}</h6>
              <span>{positionRole}</span>
            </div>
            <div className="logout-btn">
              <button id="logout-btn" className="btn-white" onClick={logout}>
                <i className="icon-logout"></i>
              </button>
            </div>
            <button
              className="close-panel-btn close-btn"
              id="MenucloseBtn"
            ></button>
          </div>
          <div className="logout-btn mobile-logout-btn">
            <button id="logout-btn" className="btn-white" onClick={logout}>
              <i className="icon-logout"></i>
              <span className="logout-text">Log out</span>
            </button>
          </div>
        </div>
      </Col>
    </>
  );
};

export default LeftPanel;
