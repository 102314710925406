import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllCustomIcon from "../../JsonDataFile/AllCustomIcon";
import DatePicker, { DateObject, Calendar } from "react-multi-date-picker";
import Footer from "react-multi-date-picker/plugins/range_picker_footer";
import { RoutesPath } from "../../App";
import { Row, Col } from "react-bootstrap";
import { HalfPieChart } from "half-pie-chart";
import { Chart } from "react-google-charts";
import axios from "axios";
import moment from "moment";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { ENV } from "../../env/env";

const FacilityRequestRateSection = (props) => {
  const [data, setData] = useState();
  const [cancellationCLN, setCancellationCLN] = useState(0);
  const [cancellationFLY, setCancellationFLY] = useState(0);
  const [totalRequest, setTotalRequest] = useState(0);
  const [facilityRequest, setFacilityRequest] = useState(0);
  const [completedRequest, setCompletedRequest] = useState(0);
  const [value, setValue] = useState([
    new DateObject().setDay(1),
    new DateObject().add(1, "month").setDay(1),
  ]);

  useEffect(() => {
    let date1 = value[0].year + "-" + value[0].month + "-" + value[0].day;
    let date2 = "";
    if (value[1] != undefined) {
      date2 = value[1].year + "-" + value[1].month + "-" + value[1].day;
    }

    let filterParam = "";
    if (localStorage.getItem("position") == "facility_admin") {
      filterParam = localStorage.getItem("facility_id");
    }
    let list = [];
    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + `/scheduling/view/?search=${filterParam}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let dateData = [];
        const dateRange = response.data.results.map(function (rowDate) {
          if (
            moment(rowDate.start_date).format("YYYY-MM-DD") >=
            moment(date1).format("YYYY-MM-DD")
          ) {
            if (
              moment(rowDate.start_date).format("YYYY-MM-DD") <=
              moment(date2).format("YYYY-MM-DD")
            ) {
              dateData.push(rowDate);
            }
          }
        });
        let monthList = [];
        const data = [["Month", "Total Request", "Facility Cancelled"]];
        let declinedList = [];
        let declinedListbyCLN = [];
        let facilityRequest = [];
        let completedList = [];
        setTotalRequest(dateData);
        const requestData1 = dateData.map(function (row) {
          let totalList = [];

          if (row.declined_by == "Facility") {
            declinedList.push(row);
          } else if (row.declined_by == "Clinician") {
            declinedListbyCLN.push(row);
          }
          if (row.facility_name == localStorage.getItem("facility")) {
            facilityRequest.push(row);
          }
          if (row.status == "Completed") {
            completedList.push(row);
          }
        });
        const unique = [
          ...new Set(
            dateData.map((item) => moment(item.start_date).format("MMM"))
          ),
        ];
        const requestData11 = unique.map(function (row) {
          let totalList = [];
          let cancelledByFacility = [];
          dateData.map(function (row1) {
            if (moment(row1.start_date).format("MMM") == row) {
              totalList.push(row1);
              if (row1.declined_by == "Facility") {
                cancelledByFacility.push(row1);
              }
            }
          });
          data.push([row, totalList.length, cancelledByFacility.length]);
        });
        setData(data);

        setCancellationFLY(declinedList);
        setCancellationCLN(declinedListbyCLN);
        setFacilityRequest(facilityRequest);
        setCompletedRequest(completedList);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }, [value]);

  useEffect(() => {}, [facilityRequest, totalRequest]);

  const totalRequestRight = [
    {
      value: facilityRequest ? facilityRequest.length : 0,
      color: "#2753b8",
    },
  ];

  const totalRequestLeft = [
    {
      value: totalRequest ? totalRequest.length - facilityRequest.length : 50,
      color: "#fff",
    },
  ];

  const fillRateRight = [
    {
      value: completedRequest ? completedRequest.length : 0,
      color: "#0ea80f",
    },
  ];

  const fillRateLeft = [
    {
      value: totalRequest ? totalRequest.length - completedRequest.length : 50,
      color: "#fff",
    },
  ];

  const cancellationRateCLNRight = [
    {
      value: cancellationCLN ? cancellationCLN.length : 0,
      color: "#e43429",
    },
  ];

  const cancellationRateCLNLeft = [
    {
      value: totalRequest ? totalRequest.length - cancellationCLN.length : 50,
      color: "#fff",
    },
  ];

  const cancellationRateFacilityRight = [
    {
      value: cancellationFLY ? cancellationFLY.length : 0,
      //   displayValue: "0",
      //   text: "Collected",
      color: "#ff9428",
    },
  ];

  const cancellationRateFacilityLeft = [
    {
      value: totalRequest ? totalRequest.length - cancellationFLY.length : 50,
      //   displayValue: "2.9K",
      //   text: "Pending",
      color: "#fff",
    },
  ];

  // const data = [
  //   ["Month", "Total Request", "Facility Cancelled"],
  //   ["Sep 2021", 650, 160],
  //   ["Oct 2021", 400, 180],
  //   ["Nov 2021", 325, 90],
  //   ["Dec 2021", 475, 150],
  //   ["Jan 2022", 90, 40],
  //   ["Feb 2022", 275, 75],
  //   ["Mar 2022", 550, 150],
  //   ["Apr 2022", 50, 10],
  // ];

  const options = {
    // title: "Population of Largest U.S. Cities",
    chartArea: { width: "80%" },
    colors: ["#2753b8", "#e43429"],
    legend: { position: "top" },
    hAxis: {
      title: "Total Request",
      minValue: 0,
      maxValue: 700,
    },
    vAxis: {
      title: "Month",
    },
  };

  return (
    <div className="request-rate-wrapper">
      <div className="edit-user-wrapper">
        <div className="user-header">
          <Link
            to={RoutesPath.statistics}
            className="back-btn"
            id="UserbackBtn"
          >
            <i className="icon-Back-arrow"></i>
          </Link>
          <h6 className="poc-name-title">Facility Request Rate</h6>
        </div>

        <Row className="nurse-tier-row">
          <Col lg="12" className="range-calnedar-with-graph text-center">
            <DatePicker
              value={value}
              onChange={setValue}
              range
              numberOfMonths={2}
              plugins={[<Footer position="bottom" />]}
            />
          </Col>

          <Col sm="6" lg="3" className="bar-chart">
            <HalfPieChart
              name="totalRequest"
              right={totalRequestRight}
              left={totalRequestLeft}
              // title="Rent Status"
              centerText={"Total Request"}
            />
          </Col>

          <Col sm="6" lg="3" className="bar-chart">
            <HalfPieChart
              name="fillRate"
              right={fillRateRight}
              left={fillRateLeft}
              // title="Rent Status"
              centerText={"Fill Rate"}
            />
          </Col>

          <Col sm="6" lg="3" className="bar-chart">
            <HalfPieChart
              name="cancellationRateCLN"
              right={cancellationRateCLNRight}
              left={cancellationRateCLNLeft}
              // title="Rent Status"
              centerText={"Cancellation rate (CLN)"}
            />
          </Col>

          <Col sm="6" lg="3" className="bar-chart">
            <HalfPieChart
              name="cancellationRateFacility"
              right={cancellationRateFacilityRight}
              left={cancellationRateFacilityLeft}
              // title="Rent Status"
              centerText={"Cancellation Rate Facility"}
            />
          </Col>

          <Col lg="12" className="bar-chart">
            <div className="graph-box">
              <Chart
                chartType="BarChart"
                width="100%"
                height="500px"
                data={data}
                options={options}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default FacilityRequestRateSection;
