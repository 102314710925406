import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import ImageUploadInput from './ImageUploadInput';
import DatePicker from "react-multi-date-picker";
import axios from "axios";
import CustomDropdown from '../inner-components/CustomDropdown';
import { ENV } from '../../env/env'

import { Buffer } from 'buffer';
import S3FileUpload from 'react-s3';
import ReusableAPI from './NotificationApi';
import { useNavigate } from 'react-router-dom';
import { snackbar } from '@mobiscroll/react';

// @ts-ignore
const positions = [
    { value: 'Select Admin Position', label: 'Select Admin Position', isdisable: "disable" },
    { value: 'Super Admin', label: 'c_level_emp' },
    { value: 'HMC Admin', label: 'hmc_admin' },

]

const AddAdminPopup = (props) => {

    window.Buffer = Buffer;
    const [image, setImage] = useState()
    const [roles, setRole] = useState()
    const [errorMessages, setErrorMessages] = useState("");
    const [loader, setLoader] = useState(false)
    const [classe, setClasse] = useState()
    const navigate = useNavigate()
    async function apiCall(credentials) {
        let token = localStorage.getItem('token')

        await axios.post(ENV.ENDPOINT + '/admin/create/', credentials, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {


                if (response.data.validationEmail) {

                    setError('email', { type: "server", message: "Email Already Exists" })
                    setLoader(false)
                    return
                }
                if (response.data.validationUsername) {

                    setError('userName', { type: "server", message: "Username Already Exists" })
                    setLoader(false)
                    return
                }


                //Triggering event notification
                var notificationMsg = `Added ${credentials.firstname + " " + credentials.lastname} Admin`
                ReusableAPI.notificationTriggerAPI(notificationMsg)
                if (response.data.errors == undefined) {
                    props.handleRender()
                    setLoader(false)
                    handleClose()
                    reset()

                    setTimeout(() => {
                        snackbar({
                            message: 'Admin Added Successfully'
                        });
                    });

                }



            })
            .catch((error) => {

                if (error.response.data.email) {

                    setError('email', { type: "server", message: "Email Already Exists" })
                }
                if (error.response.data.username) {

                    setError('userName', { type: "server", message: "Username Already Exists" })
                }

            })
    }


    useEffect(() => {
        if (loader == true) {
            setClasse('custom-loader')
        }
        else {
            setClasse('custom-loader hide')
        }
    }, [loader])

    const [dob, setDob] = useState()
    const handledDate = date => {
        let datevalue = date.format()
        setDob(datevalue)

    }




    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, formState: { errors }, handleSubmit, setError, reset } = useForm();
    let pwd = ""


    const handleImage = (e) => {

        if (e.size > 500000) {
            setErrorMessages({ name: "image", message: "Please upload image below 500KB in size" });
            setImage()
        } else {
            setImage(e)
        }

    }

    const handleJobSites = (selection) => {

        setRole(selection.label)


    }

    const onSubmit = (data) => {

        // if (image === undefined) {

        //     setErrorMessages({ name: "image", message: "Please upload image" });
        // } else {
        //     setErrorMessages({ name: "image", message: "" });

        // }

        if (roles === undefined && image === undefined) {
            setErrorMessages({ name: "image", message: "Please upload image" });
            setErrorMessages({ name: "positionDropdown", message: "Please select position" });
        } else if (roles === undefined) {
            setErrorMessages({ name: "positionDropdown", message: "Please select position" });
            setErrorMessages({ name: "image", message: "" });
        }
        else if (image === undefined) {
            setErrorMessages({ name: "positionDropdown", message: "" });
            setErrorMessages({ name: "image", message: "Please upload image & image must be less than 500kb" });
        } else {
            setErrorMessages({ name: "image", message: "" });
            setErrorMessages({ name: "positionDropdown", message: "" });
            if (image instanceof Blob) {
                getBase64(image)
                setLoader(true)
            }
            else {
                setLoader(false)
                const [month, day, year] = dob.split('/');
                const result = [year, month, day].join('-');

                let fullname = data.pocfirstName + " " + data.pocLastName
                let username = data.userName
                let email = data.email
                let date_of_birth = result
                let address = data.address
                let position = roles
                let firstname = data.pocfirstName
                let lastname = data.pocLastName
                let contact_no = data.mainline
                let avatar_image = image
                let role = roles
                let password = data.password


                const token = apiCall({
                    fullname,
                    username,
                    email,
                    password,
                    date_of_birth,
                    address,
                    position,
                    firstname,
                    lastname,
                    contact_no,
                    avatar_image,
                    position,
                    role
                });

            }

        }



        function getBase64(e) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(e);
                reader.onload = async () => {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                    if ((encoded.length % 4) > 0) {
                        encoded += '='.repeat(4 - (encoded.length % 4));
                    }
                    resolve(encoded);
                    // setImage(encoded)
                    const credentials = {
                        user_id: localStorage.getItem('user_id'),
                        data: encoded
                    }
                    let token = localStorage.getItem('token')

                    await axios.post(ENV.ENDPOINT + `/s3upload/image/`, credentials, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(response => {

                            setImage(response.data.location)
                            const [month, day, year] = dob.split('/');
                            const result = [year, month, day].join('-');

                            let fullname = data.pocfirstName + " " + data.pocLastName
                            let username = data.userName
                            let email = data.email
                            let date_of_birth = result
                            let address = data.address
                            let position = roles
                            let firstname = data.pocfirstName
                            let lastname = data.pocLastName
                            let contact_no = data.mainline
                            let avatar_image = response.data.location
                            let role = roles
                            // let user = "1"

                            let password = data.password


                            const token = apiCall({
                                fullname,
                                username,
                                email,
                                password,
                                date_of_birth,
                                address,
                                position,
                                firstname,
                                lastname,
                                contact_no,
                                avatar_image,
                                // user,
                                position,
                                role
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data)
                        })


                };
                reader.onerror = error => reject(error);
            });
        }

    }
    // Form validation


    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );

    return (

        <div className="add-user-pop-wrap">
            <Button className="border-btn" onClick={handleShow}><i className='icon-plus'></i>{props.ModelTitle}</Button>
            <Modal show={show} onHide={handleClose} className='add-user-popup'>
                <Modal.Header closeButton>
                    <h5>{props.ModelTitle}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className={classe}></div>
                    <div className='add-user-form'>
                        <Form onSubmit={handleSubmit(onSubmit)} method="POST">
                            <div className="form-inner">
                                <Row className="form-row">
                                    <Col md={6}>
                                        <div className='image-upload'>
                                            <ImageUploadInput handleImage={handleImage} />
                                        </div>
                                        {renderErrorMessage("image")}
                                    </Col>
                                    <Col md={6}>

                                        <Form.Group className="form-group" controlId="userName">
                                            <Form.Label className="">User name</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="userName"  {...register("userName", {
                                                required: true,
                                                maxLength: 50,
                                                minLength: 1,
                                                pattern: {
                                                    // value: /^[a-zA-Z0-9_@.-]*$/,
                                                    value: /^[a-zA-Z_@.-]*$/,
                                                },

                                            })} />
                                            <span className="error-message">{errors.userName?.type === 'required' && "enter Username"}
                                                {errors.userName?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                {errors.userName?.type === 'minLength' && "enter minimum 1 characters"}
                                                {errors.userName?.type === 'pattern' && "It doesn't allow special characters excepts _ . @ -"}
                                                {errors.userName && errors.userName.message}</span>

                                        </Form.Group>
                                        <Form.Group className="form-group" controlId="Email">
                                            <Form.Label className="">Email</Form.Label>
                                            <Form.Control type="email" placeholder="Text here" name="Email" {...register("email", {
                                                required: true,
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                }
                                            })} />
                                            <span className="error-message">{errors.email?.type === 'required' && "enter Email"}
                                                {errors.email?.type === 'pattern' && "Please enter a valid email"}
                                                {errors.email && errors.email.message}</span>

                                        </Form.Group>
                                        <Form.Group className="form-group" controlId="Password">
                                            <Form.Label className="">Password</Form.Label>
                                            <Form.Control type="password" placeholder="Text here" name="Password"  {...register("password", {
                                                required: true,
                                                pattern: {
                                                    value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,50}$/i,
                                                }
                                            })} />
                                            <span className="error-message">{errors.password?.type === 'required' && "enter Password"}
                                                {errors.password?.type === 'pattern' && "The password must match the following criteria: Minimum 8 characters, Include at least 1 letter, Include at least 1 number and maximum 12 characters long."}</span>

                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='form-row'>
                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="pocFirstName">
                                            <Form.Label className="">Firstname</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="pocFirstName" {...register("pocfirstName", {
                                                required: true,
                                                maxLength: 50,
                                                minLength: 1,
                                                pattern: {
                                                    value: /^[a-zA-Z]*$/,
                                                },
                                            })} />
                                            <span className="error-message">{errors.pocfirstName?.type === 'required' && "enter POC First name"}
                                                {errors.pocfirstName?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                {errors.pocfirstName?.type === 'minLength' && "enter minimum 1 characters"}
                                                {errors.pocfirstName?.type === 'pattern' && "It is text field"}</span>


                                        </Form.Group>

                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="pocLastName">
                                            <Form.Label className="">Lastname</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="pocLastName" {...register("pocLastName", {
                                                required: true,
                                                maxLength: 50,
                                                minLength: 1,
                                                pattern: {
                                                    value: /^[a-zA-Z]*$/,
                                                },
                                            })} />
                                            <span className="error-message">{errors.pocLastName?.type === 'required' && "enter POC Last name"}
                                                {errors.pocLastName?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                {errors.pocLastName?.type === 'minLength' && "enter minimum 1 characters"}
                                                {errors.pocLastName?.type === 'pattern' && "It is text field"}</span>


                                        </Form.Group>

                                    </Col>
                                    <Col md={6}>
                                        {/* <Form.Group className="form-group" controlId="PocPosition">
                                            <Form.Label className="">Position</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="PocPosition" {...register("PocPosition", {
                                                required: true,
                                                maxLength: 50,
                                                minLength: 3,
                                            })} />
                                            <span className="error-message">{errors.PocPosition?.type === 'required' && "enter POC Position"}
                                                {errors.PocPosition?.type === 'maxLength' && "It accepts maximum 50 characters"}
                                                {errors.PocPosition?.type === 'minLength' && "enter minimum 3 characters"}</span>

                                        </Form.Group> */}
                                        <div className='form-group'>
                                            <label className='form-label'>Role</label>
                                            <CustomDropdown optionData={positions} handleChange={handleJobSites} />
                                            {renderErrorMessage("positionDropdown")}
                                        </div>

                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="form-group" controlId="Mainline">
                                            <Form.Label className="">Contact No</Form.Label>
                                            <Form.Control type="number" placeholder="Text here" name="Mainline" {...register("mainline", {
                                                maxLength: 20,
                                                minLength: 10,
                                            })} />
                                            <span className="error-message">{errors.mainline?.type === 'maxLength' && "It accepts maximum 20 characters"}
                                                {errors.mainline?.type === 'minLength' && "enter minimum 10 characters"}</span>

                                        </Form.Group>
                                    </Col>
                                    {/* <Col md={6}>
                                        <div className="form-group date-of-birth-input">
                                            <label className='form-label'>Date of birth</label>
                                            <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" className='form-control' onChange={handledDate}/>
                                            <span className="error-message">{errors.dateBirth?.type === 'required' && "select date of birth"}</span>
                                        </div>
                                    </Col> */}
                                    {/* <Col md={6}>
                                        <Form.Group className="form-group" controlId="FacilityName">
                                            <Form.Label className="">Facility Name</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="FacilityName" {...register("FacilityName", {
                                                required: true,
                                                maxLength: 100,
                                                minLength: 3,
                                            })} />
                                            <span className="error-message">{errors.FacilityName?.type === 'required' && "enter Facility name"}
                                                {errors.FacilityName?.type === 'maxLength' && "It accepts maximum 100 characters"}
                                                {errors.FacilityName?.type === 'minLength' && "enter minimum 3 characters"}</span>

                                        </Form.Group>
                                    </Col> */}
                                    {/* <Col md={6}>
                                        <Form.Group className="form-group date-of-birth-input" controlId="dateofbirth">
                                            <label className='form-label'>Date of birth</label>
                                            <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" name='dateofbirth' {...register("dateofbirth", {
                                                required:true,
                                            })} />
                                            <span className="error-message">{errors.dateofbirth?.type === 'required' && "select date of birth"}</span>
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={6}>
                                        <div className="form-group date-of-birth-input">
                                            <label className='form-label'>Date of birth</label>
                                            <DatePicker format="MM/DD/YYYY" placeholder="mm/dd/yyyy" className='form-control' maxDate={new Date()} onChange={handledDate} />
                                            {/* <span className="error-message">{errors.dateofbirth?.type === 'required' && "select date of birth"}</span> */}
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className="form-group" controlId="Address">
                                            <Form.Label className="">Address</Form.Label>
                                            <Form.Control type="text" placeholder="Text here" name="Address" {...register("address", {
                                                required: true,
                                                maxLength: 200,
                                                minLength: 3,
                                            })} />
                                            <span className="error-message">{errors.address?.type === 'required' && "enter Address"}
                                                {errors.address?.type === 'maxLength' && "It accepts maximum 100 characters"}
                                                {errors.address?.type === 'minLength' && "enter minimum 3 characters"}</span>

                                        </Form.Group>

                                    </Col>
                                </Row>
                                <div className='submit-btn'>
                                    <input type="submit" value="Submit" className="btn btn-primary" />
                                </div>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>

            </Modal>
        </div>

    )

}


export default AddAdminPopup;