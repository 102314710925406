import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AllCustomIcon from "../../JsonDataFile/AllCustomIcon";
import CredentialTable from "../inner-components/CredentialTable";
import { RoutesPath } from "../../App";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { ENV } from "../../env/env";
import moment from "moment";
import MessagesButton1 from "../inner-components/MessagesButton1";

const DocumentsDetailSection = (props) => {
  const [CliniciansData, setCliniciansData] = useState();
  const [personalInfo, setPersonalInfo] = useState();
  useEffect(() => {
    let token = localStorage.getItem("token");
    axios
      .get(ENV.ENDPOINT + `/clinician/view/?clinician_id=${props.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        let data = [];
        setCliniciansData(response.data.results);
        const userData = response.data.results.map(function (row) {
          return [
            {
              svgIcon: AllCustomIcon.iconCalender,
              dataText:
                "DOB: " + moment(row.date_of_birth).format("DD/MM/YYYY"),
            },
            {
              svgIcon: AllCustomIcon.iconMap,
              dataText: row.address,
            },
            {
              svgIcon: AllCustomIcon.iconCall,
              dataText: row.contact_no,
            },
            {
              svgIcon: AllCustomIcon.iconEnvelop,
              dataText: row.email,
            },
          ];
        });
        setPersonalInfo(userData[0]);
      })

      .catch((error) => {
        console.log(error.response.data);
      });
  }, []);
  // const CliniciansData = [
  //     {
  //         svgIcon: AllCustomIcon.iconCalender,
  //         dataText: "DOB: 12/04/1990",
  //     },
  //     {
  //         svgIcon: AllCustomIcon.iconMap,
  //         dataText: "San Fransico, USA",
  //     },
  //     {
  //         svgIcon: AllCustomIcon.iconCall,
  //         dataText: "1234567890",
  //     },
  //     {
  //         svgIcon: AllCustomIcon.iconEnvelop,
  //         dataText: "camille@ondek.com",
  //     }
  // ]

  return (
    <>
      {CliniciansData && personalInfo ? (
        <>
          {CliniciansData.map((val, index) => (
            <div className="document-detail-wrapper">
              <div className="edit-user-wrapper">
                <div className="user-header">
                  <Link
                    to={RoutesPath.documents}
                    className="back-btn"
                    id="UserbackBtn"
                  >
                    <i className="icon-Back-arrow"></i>
                  </Link>
                  <h6 className="poc-name-title">
                    {val.firstname + " " + val.lastname}
                  </h6>
                </div>
                <div className="edit-user">
                  <div className="edit-user-top">
                    <div className="user-image">
                      <img src={val.avatar_image} />
                    </div>
                    <div className="edit-user-form document-details-wrap">
                      <div className="user-header">
                        <h6 className="h5">
                          {val.firstname + " " + val.lastname}
                        </h6>
                        {/* <button className='btn border-btn'>
                                                    <i className='icon-message-solid'></i>
                                                    Message
                                                </button> */}
                        <MessagesButton1 userData={CliniciansData[0]} />
                        <div className="sub-header">
                          <span className="sub-text">
                            {val.clinician_position},{" " + val.job_sites}
                          </span>
                          <div className="rate-feedback-text">
                            <div className="rate-text">
                              {AllCustomIcon.iconStarSolid}
                              <span className="rating-text">4.8</span>
                            </div>
                            <span className="feedback-count-text">
                              (29 feedbacks)
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="document-detail-row">
                        <Row>
                          {personalInfo.map((val, index) => (
                            <Col md={6} className="detail-col">
                              <div className="col-inner">
                                <div className="detail-text">
                                  {val.svgIcon}
                                  <span>{val.dataText}</span>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="credential-wrapper">
                    <h6 className="table-title">Credentials</h6>
                    <div className="credential-table-wrapper">
                      <div className="table-wrapper table-responsive">
                        <CredentialTable id={props.id} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DocumentsDetailSection;
