import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import AddAdminPopup from './AddAdminPopup';
import SearchInput from './SearchInput';
import UserAdminTable from './UserAdminTable';
import TablePagination from './TablePagination';
import CustomDropdown from './CustomDropdown';
import SwitchToggle from './SwitchToggle';
import axios from "axios";
import { ENV } from '../../env/env'

const RoleDropdownData = [
    { value: 'All Admin', label: 'All Admin' },
    { value: 'Active', label: 'active' },
    { value: 'Deactive', label: 'deactive' },
]
const PositionDropdownData = [
    { value: 'All Admin', label: 'All Admin' },
    { value: 'HMC Admin', label: 'hmc_admin' },
    { value: 'Super Admin', label: 'c_level_emp' },
]

const AdminTabContentHMCAdmin = (props) => {

    const [data, setData] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [filterInput, setFilterInput] = useState("")
    const [positionInput, setPositionInput] = useState("")
    const [link, setLink] = useState(ENV.ENDPOINT + `/admin/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState()
    const [reRender, setReRender] = useState(false)

    const handleChange = (event) => {
        setSearchInput(event.target.value)
    }
    const handleFilter = (selection) => {
        if (selection.value == "Active") {
            setFilterInput("true")
        }
        else if(selection.value == "All Admin"){
            setFilterInput("")
        }
        else {
            setFilterInput("false")
        }



    }

    const handlePositionFilter = (selection) => {
        if(selection.value == "All Admin"){
            setPositionInput("")
        }
        else {
            setPositionInput(selection.label)
        }
    }

    const handleRender = () => {
        setReRender(true)
    }
    const handleLink = () => {

        let token = localStorage.getItem('token')
        // let link=`http://127.0.0.1:8000/api/clinician/view/`
        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
            setCurrentPage(response.data.page)
            setTotalPage(response.data.total_pages)
            setData(response.data.results)
            if (response.data.links.next != undefined && response.data.links.next != "") {
                setNextPage(true)
                setNextLink(response.data.links.next)
            }
            setPrevLink(response.data.links.previous)
        }, [])
        .catch((error) => {
            console.log(error.response.data)
        })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
            setData(response.data.results)
            setCurrentPage(response.data.page)
            setTotalPage(response.data.total_pages)
            if (response.data.links.next != undefined && response.data.links.next != "") {
                setNextPage(true)
                setNextLink(response.data.links.next)


            }
            setPrevLink(response.data.links.previous)
        }, [])
        .catch((error) => {
            console.log(error)
        })
    }



    useEffect(() => {
        let token = localStorage.getItem('token')

        axios.get(ENV.ENDPOINT + `/admin/view/only/?search=${filterInput},${positionInput},${searchInput}`, { headers: { "Authorization": `Bearer ${token}` } })
        .then(response => {
            setCurrentPage(response.data.page)
            setData(response.data.results)
            setTotalPage(response.data.total_pages)
            if (response.data.links.next != undefined && response.data.links.next != "") {
                setNextPage(true)
                setNextLink(response.data.links.next)

            }
            setPrevLink(response.data.links.previous)
        })
        .catch((error) => {
            console.log(error.response.data)
        })

        setReRender(false)
    }, [reRender, searchInput, filterInput,positionInput])


    return (

        <div className='facility-tab-content tab-content-wrap admin-tab-content'>
            <div className='filter-row-wrapper'>
            <AddAdminPopup ModelTitle="Add Admin" handleRender={handleRender} />
            <div className='table-filter-row'>
            <SearchInput searchInput={handleChange}/>
            <CustomDropdown optionData={RoleDropdownData} dropClass="without-search"  filterInput={handleFilter}/>
            <CustomDropdown optionData={PositionDropdownData} dropClass="without-search"  filterInput={handlePositionFilter}/>
            </div>
            </div>
            <div className='user-facilty-table-wrapper pagination-table clinicians-table-wrap'>
            <div className="table-wrapper table-responsive">
            <UserAdminTable searchInputKey={searchInput} filterInputKey={filterInput} nextData={data} handleRender={handleRender}/>
            </div>
            <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
            </div>

            </div>
    )

}


export default AdminTabContentHMCAdmin;