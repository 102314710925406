import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import SearchInput from '../inner-components/SearchInput';
import DropdownWithCheckbox from '../inner-components/DropdownWithCheckbox';
import Feedbackstable from '../inner-components/Feedbackstable';
import TablePagination from '../inner-components/TablePagination';
import axios from "axios";
import { ENV } from '../../env/env'
import { useNavigate } from 'react-router-dom'
import RenewToken from '../inner-components/RenewToken';

const PositionsFilter = [
    { value: '', label: 'All Positions' },
    { value: 'STNA', label: 'STNA' },
    { value: 'RN', label: 'RN' },
    { value: 'CNA', label: 'CNA' },
    { value: 'LPN', label: 'LPN' }
]

const FeedbacksContent = (props) => {

    const navigate = useNavigate()
    const [refresh, setrefresh] = useState(false)
    const [searchFilter, setSearchFilter] = useState('')
    const [dropdownFilter, setDropDownFilter] = useState('')
    const [data, setData] = useState([])
    const [link, setLink] = useState(ENV.ENDPOINT + `/timesheet/view/`)
    const [nextLink, setNextLink] = useState(false)
    const [prevLink, setPrevLink] = useState(false)
    const [nextPage, setNextPage] = useState(false)
    const [totalPage, setTotalPage] = useState(false)
    const [currentPage, setCurrentPage] = useState()

    const handleLink = () => {

        let token = localStorage.getItem('token')
        axios.get(nextLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                setData(response.data.results)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)
                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error.response.data)
            })
    }

    const handlePrevLink = () => {
        let token = localStorage.getItem('token')
        axios.get(prevLink, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setData(response.data.results)
                setCurrentPage(response.data.page)
                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)


                }
                setPrevLink(response.data.links.previous)
            }, [])
            .catch((error) => {
                console.log(error)
            })
    }

    const handleRefresh = (e) => {
        setrefresh(e)
    }


    useEffect(() => {
        let token = localStorage.getItem('token');
      
        // let link=ENV.ENDPOINT + `/timesheet/view/?facility_id=${props.facilityId}&?search=${searchFilter},${dropdownFilter}`;
        let link=ENV.ENDPOINT + `/timesheet/view/?search=${searchFilter},${dropdownFilter}`;
        if(props.facilityName!="All Facilities"){
            link=ENV.ENDPOINT + `/timesheet/view/?facility_id=${props.facilityId}&search=${searchFilter},${dropdownFilter}` 
        }
        axios.get(link, { headers: { "Authorization": `Bearer ${token}` } })
            .then(response => {
                setData(response.data.results)

                setCurrentPage(response.data.page)

                setTotalPage(response.data.total_pages)
                if (response.data.links.next != undefined && response.data.links.next != "") {
                    setNextPage(true)
                    setNextLink(response.data.links.next)

                }
                setPrevLink(response.data.links.previous)
                props.handleRefresh(false)
            })
            .catch((error) => {
                console.log(error.response.data)
                if (error.response.data.code == 'token_not_valid') {
                    RenewToken.RenewTokenAPI()
                }
            })
            props.handleRefresh(false)
           
    }, [searchFilter, dropdownFilter, currentPage, refresh,props])

    const handleSearch = (e) => {
        setSearchFilter(e.target.value)
    }

    const handleFilter = (e) => {
        setDropDownFilter(e.value)
    }

    return (

        <div className='facility-tab-content tab-content-wrap'>
            <div className='d-flex justify-content-between filter-row-wrapper'>
                <SearchInput searchInput={handleSearch} />
                <div className='table-filter-row table-filter-timesheets-row'>
                    <DropdownWithCheckbox optionData={PositionsFilter} dropClass="without-search" filterInput={handleFilter} />
                </div>
            </div>
            <div className='user-facilty-table-wrapper flex-table pagination-table'>
                <div className="table-wrapper table-responsive">
                    <Feedbackstable handleRefresh={handleRefresh} refresh={refresh} data={data} />
                </div>
                <TablePagination data={nextLink} prevdata={handlePrevLink} currentPage={currentPage} totalPage={totalPage} handleLink={handleLink} />
            </div>

        </div>
    )

}


export default FeedbacksContent;