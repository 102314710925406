import React, { useEffect, useRef, useState } from "react";
import LeftPanel from "../components/LeftPanel";
import HeaderMobile from "../components/HeaderMobile";
import FeedbacksTopHeaderSection from "../components/feedbacks-and-ratings/FeedbacksTopHeaderSection";
import FeedbacksTopFilterSection from "../components/feedbacks-and-ratings/FeedbacksTopFilterSection";
import { Row, Col } from "react-bootstrap";
import "../assets/css/Custom.css";

//agora chat and calllogin
import { useGlobalState, useGlobalMutation } from "./utils/container";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { log } from "./utils/utils";
import useRouter from "./utils/use-router";
import { Outlet, useNavigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Tour } from "antd";
import useLocalStorage from "./utils/useLocalStorage";
import { useWindowSize } from "./utils/useWindowSize";
import Cookies from "js-cookie";

const FeedbacksAndRatings = () => {
  //for agora chat and call login
  const navigate = useNavigate();
  const stateCtx = useGlobalState();
  const mutationCtx = useGlobalMutation();

  const routerCtx = useRouter();

  const history = useNavigate();

  useEffect(() => {
    if (stateCtx.loading === true) {
      mutationCtx.stopLoading();
    }
  }, [stateCtx.loading, mutationCtx]);

  useEffect(() => {
    if (stateCtx.rtmClient.status === "offLine") {
      stateCtx.rtmClient
        .login(stateCtx.userCode)
        .then(() => {
          log(stateCtx.userCode, "is online");
        })
        .catch(() => {
          log("Login failure");

          return;
        });
    }
  }, [stateCtx.rtmClient]);

  useEffect(() => {
    stateCtx.rtmClient.on("RemoteInvitationReceived", (remoteInvitation) => {
      let content = JSON.parse(remoteInvitation.content);

      mutationCtx.updatePeerCode(remoteInvitation.callerId);
      mutationCtx.updateIncomingCode(content.name);
      mutationCtx.updateIncomingImage(content.image);
      mutationCtx.updateConfig({ channelName: content.channel });

      navigate({
        pathname: `/call-invi/card/indexCard/incoming/`,
      });
    });

    stateCtx.rtmClient.on("RemoteInvitationCanceled", () => {
      // navigate('/dashboard')
    });

    stateCtx.rtmClient.on("RemoteInvitationFailure", () => {
      mutationCtx.toastError("Call not answered");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationAccepted", () => {
      log("Accept success");
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationAccepted", () => {
      mutationCtx.startLoading();
      let channel = stateCtx.config.channelName;
      log("channel id", channel);
      navigate({
        pathname: `/meeting/${channel}`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationRefused", () => {
      mutationCtx.toastError("Peer is busy");
      navigate.goBack();
    });

    stateCtx.rtmClient.on("RemoteInvitationRefused", () => {
      navigate("/call-invi");
    });

    stateCtx.rtmClient.on("LocalInvitationCanceled", () => {
      navigate.goBack();
    });

    stateCtx.rtmClient.on("LocalInvitationReceivedByPeer", () => {
      navigate({
        pathname: `/dialling`,
      });
    });

    stateCtx.rtmClient.on("LocalInvitationFailure", () => {
      mutationCtx.toastError("Call process failed");
      history.goBack();
    });

    return () => {
      stateCtx.rtmClient.eventBus.removeAllListeners();
      //   navigate('/card')
    };
  }, [stateCtx.rtmClient, stateCtx]);
  const [refresh, setRefresh] = useState(false);
  const handleRefresh = (e) => {
    setRefresh(e);
  };
  const [facilityName, setFacilityName] = useState("All Facilities");
  const [facilityId, setFacilityId] = useState("");
  const handleFacility = (a, b) => {
    setFacilityName(a);
    setFacilityId(b);
  };

  const size = useWindowSize()
  const feedbacksref = useRef(null);

  const [openFeedbacks, setOpenFeedbacks] = useState(false);
  const steps = [
    {
      description: (
        <div className="text-center">
          <p>
            Resolve issues on both sides of the fence and more efficiently, the
            functionality lets us and the admins identify which clinicians are
            liabilities, and safekeeping to work with
          </p>
          <p>Be sure to leave our clinicians a feedback</p>
        </div>
      ),
      target: () => feedbacksref.current,
    },
  ];


  useEffect(() => {
    if(size.width >= 1250){
    const isModalOpen = Cookies.get("tourfeedbacks") === "open";
    if (!isModalOpen ) {
      Cookies.set("tourfeedbacks", "open", { expires: 365, path: "" });
      const timeoutId = setTimeout(() => {
        setOpenFeedbacks(true);
      }, 3000);
      return () => {
        clearTimeout(timeoutId);
      };
     }
    }
  }, [size.width]);
  return (
    <>
      <main className="app-main">
        <HeaderMobile />
        <section className="main-section dashboard-section flex-table-section blue-bg-section">
          <div className="section-wrap">
            <Row className="dashboard-row-main">
              <LeftPanel />
              <Col
                lg="9"
                className="right-content-col right-content-col-with-banner grey-bg-col"
              >
                <FeedbacksTopHeaderSection
                  handleRefresh={handleRefresh}
                  handleFacility={handleFacility}
                  facilityName={facilityName}
                />
                <FeedbacksTopFilterSection
                  feedbacksref={feedbacksref}
                  handleRefresh={handleRefresh}
                  refresh={refresh}
                  facilityName={facilityName}
                  facilityId={facilityId}
                />
              </Col>
            </Row>
            <Tour
              open={openFeedbacks}
              onClose={() => setOpenFeedbacks(false)}
              steps={steps}
              indicatorsRender={(current, total) => <></>}
            />
          </div>
        </section>
      </main>
    </>
  );
};

export default FeedbacksAndRatings;
