const ScheduleShiftData = [
    {
        id:1,
        imageSrc:'assets/images/user-shift-image.png',
        start: '2022-09-28T07:00',
        end: '2022-09-28T09:00',
        title: '<img src="assets/images/user-shift-image.png"/>',
        color: '#408cff',
        shiftTypeText: "CNA",
        shiftUserName: "Alisa Jones",
        shiftNotes:"",
        color: '#f44437',
    }, 
    {
        id:2,
        imageSrc:'assets/images/user-shift-image.png',
        start: '2022-08-06T05:00',
        end: '2022-08-06T07:00',
        title: '<img src="assets/images/user-shift-image.png"/>',
        color: '#408cff',
        shiftTypeText: "CNA",
        shiftUserName: "Alisa Jones",
        shiftNotes:"",
        color: '#f44437',
    }, 
    {
        id:3,
        imageSrc:'assets/images/user-shift-image.png',
        start: '2022-08-06T12:00',
        end: '2022-08-06T14:00',
        title: '<img src="assets/images/user-shift-image.png"/>',
        color: '#408cff',
        shiftTypeText: "CNA",
        shiftUserName: "Alisa Jones",
        shiftNotes:"",
        color: '#f44437',
    }, 
    {
        id:4,
        imageSrc:'assets/images/user-shift-image.png',
        start: '2022-08-06T02:00',
        end: '2022-08-06T04:00',
        title: '<img src="assets/images/user-shift-image.png"/>',
        color: '#408cff',
        shiftTypeText: "CNA",
        shiftUserName: "Alisa Jones",
        shiftNotes:"",
        color: '#f44437',
    }, 
    {
        id:5,
        imageSrc:'assets/images/user-shift-image.png',
        start: '2022-08-10T02:00',
        end: '2022-08-10T04:00',
        title: '<img src="assets/images/user-shift-image.png"/>',
        color: '#408cff',
        shiftTypeText: "RN",
        shiftUserName: "Alisa Jones",
        shiftNotes:"",
        color: '#f44437',
    },    
]

export default ScheduleShiftData;